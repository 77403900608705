import {Box} from '@mui/material'
import FusionParamTexte from './FusionParamTexte'
import FusionParamDevise from './FusionParamDevise'
import FusionParamDate from './FusionParamDate'

export default function FusionParam({type, name, label, control, description}) {
  return (
    <Box>
      {type === 'texte_long' && (
        <FusionParamTexte
          helperText={description}
          control={control}
          name={name}
          label={label}
          multiline={true}
        />
      )}
      {type === 'texte_court' && (
        <FusionParamTexte
          helperText={description}
          control={control}
          name={name}
          label={label}
        />
      )}
      {type === 'date' && (
        <FusionParamDate
          helperText={description}
          control={control}
          name={name}
          label={label}
        />
      )}
      {type === 'devise' && (
        <FusionParamDevise
          helperText={description}
          control={control}
          name={name}
          label={label}
        />
      )}
    </Box>
  )
}
