import {
  Select as MuiSelect,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material'
import {useEffect} from 'react'
import {useForm, Controller} from '.'

const Select = ({
  name,
  label,
  items = [],
  required,
  onChange,
  isError,
  errorMessage,
  defaultValue = '',
  size,
  ...props
}) => {
  const {control, errors, isLoading, compact, watch} = useForm()
  const watchValue = watch(name)
  useEffect(() => {
    onChange?.(watchValue)
  }, [watchValue, onChange])
  // return (
  //   <MuiSelect
  //     inputProps={{
  //       'data-testid': `field-${name}`,
  //     }}
  //     disabled={isLoading}
  //     id={`${name}-field`}
  //     labelId={`${name}-field-label`}
  //     variant="outlined"
  //     fullWidth
  //   >
  //     {items.map(x => (
  //       <MenuItem key={x.value} value={x.value}>
  //         {x.text}
  //       </MenuItem>
  //     ))}
  //   </MuiSelect>
  // )
  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
      error={Boolean(errors[name]) || isError}
      size={compact ? 'small' : undefined}
    >
      <InputLabel size={size} id={`${name}-field-label`}>
        {label}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({field}) => (
          <MuiSelect
            native={true}
            size={size}
            inputProps={{
              'data-testid': `field-${name}`,
              'aria-labelledby': `${name}-field-label`,
              'aria-describedby': `${name}-field-helper-text`,
              disabled: isLoading,
            }}
            {...field}
            disabled={isLoading}
            label={label}
            id={`${name}-field`}
            labelId={`${name}-field-label`}
            {...props}
          >
            <option value=""></option>
            {items.map(x => (
              <option key={x.value} value={x.value}>
                {x.text}
              </option>
            ))}
          </MuiSelect>
        )}
      />
      <FormHelperText id={`${name}-field-helper-text`}>
        {errors[name]?.message || errorMessage}
      </FormHelperText>
    </FormControl>
  )
}

export default Select
