import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import {useForm} from 'react-hook-form'
import {useState} from 'react'
import ClientAutocompleteInputControlled from '../controlled/ClientAutocompleteInputControlled'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import { partialUpdateContrat } from 'src/services/crm-api'
import { useEditContrat, usePartialEditContrat } from 'src/hooks/api-hooks'

const schema = yup.object().shape({    
  client_id: yup
    .mixed()
    .label('Client ID')
    .transform(value => (value === '' ? undefined : value))
    .required()
})

export default function ContratTransferDialog({
  open,
  type,
  initialVariables = {},
  client,
  contrat,
  onCancel = () => {},
  onSubmit = () => {},
  title = 'Transfert du contrat ' + contrat?.reagi_id + ' vers un nouveau client',
  devis,
}) {
  const [currentContrat, setCurrentContrat] = useState()
  const [isFolderStep, setIsFolderStep] = useState(false)
  const {control, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(schema),
  })

  const handleCancel = (...args) => {
    onCancel(args)
    setIsFolderStep(false)    
  }

  const {data, isLoading, isError, mutate} = usePartialEditContrat(contrat?.id)

  var dialogContent = null
  var dialogActions = null

  
  dialogContent = (
    <>
      <Typography>
        Saisir le nom du client dans le champ ci-dessous :
      </Typography>
      <ClientAutocompleteInputControlled 
        control={control}
        name="client_id"
        label="Client"
        margin="dense"
        size="small"        
        error={!!errors?.client_id?.message}
        helperText={errors?.client_id?.message}
      />
    </>
  )
  dialogActions = (
    <>
      <Button onClick={handleCancel} color="primary" autoFocus>
        Annuler
      </Button>
      <Button type={'submit'}>
          Transférer
      </Button>
    </>
  )  

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth={'md'}
      fullWidth
      scroll={'paper'}
    >
      <form
        onSubmit={handleSubmit(values => {
          const body = {
            ...values,
            client:  values.client_id,
            client_id: values.client_id.id,
          }
                 
          mutate(body, {  
            onSuccess: () => onSubmit(data),
          })                                    
        })}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {dialogContent}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </form>
    </Dialog>
  )
}
