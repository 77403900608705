import React from 'react'
import {Typography} from '@mui/material'

const Title = ({title, ...props}) => {
  return (
    <Typography color="textPrimary" variant="h5" {...props}>
      {title}
    </Typography>
  )
}
export default Title
