import {Grid, TextField} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'
import DeviseInput from '../inputs/DeviseInput'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
  libelle_libre: yup.string().label('Libellé libre').required(),
})

const DetteForm = ({dette, client, onSubmit, disabled, ...props}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...dette,
      montant: Boolean(dette?.montant) ? -parseFloat(dette.montant) : undefined,
      date: Boolean(dette?.date) ? moment(dette.date, 'DD/MM/YYYY') : undefined,
    },
  })

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.montant = -body.montant
        body.type = 'Dette'
        body.client_id = client.id
        body.date = moment(values.date).format('DD/MM/YYYY')
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('id')} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            defaultValue={moment(today).format('MM/DD/YYYY')}
            render={({field}) => (
              <DesktopDatePicker
                {...field}
                label="Date"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    {...params}
                    disabled={disabled}
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors?.date?.message}
                    helperText={errors?.date?.message}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            defaultValue={''}
            render={({field}) => (
              <DeviseInput
                {...field}
                disabled={disabled}
                margin="dense"
                size="small"
                fullWidth
                error={!!errors?.montant?.message}
                helperText={errors?.montant?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="libelle_libre"
            defaultValue=""
            render={({field}) => (
              <TextField
                {...field}
                disabled={disabled}
                error={!!errors?.libelle_libre?.message}
                helperText={errors?.libelle_libre?.message}
                margin="dense"
                size="small"
                fullWidth
                label="Libellé libre"
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default DetteForm
