import {Grid, TextField, Typography} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'

import TypesReglementInput from 'src/components/inputs/TypesReglementInput'
import BanquesRemiseInput from '../inputs/BanquesRemiseInput'
import DeviseInput from '../inputs/DeviseInput'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  type_reglement: yup
    .mixed()
    .label('Type de règlement')
    .transform(value => (value === '' ? undefined : value))
    .required(),
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
  numero_cheque: yup
    .string()
    .label('Numéro chèque')
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required().matches(/^[0-9]{7}$/, 'doit être 7 chiffres')
        : schema,
    ),
  banque: yup
    .mixed()
    .label('Banque chèque')
    .transform(value => (value === '' ? undefined : value))
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required()
        : schema,
    ),
  libelle_libre: yup.string(),
})

const defaultBanqueRemise = {id: 1, nom: 'CEPAC Prime'}

const RemboursementForm = ({remboursement, client, onSubmit, ...props}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...remboursement,
      montant: Boolean(remboursement?.montant)
        ? -parseFloat(remboursement.montant)
        : undefined,
      date: Boolean(remboursement?.date)
        ? moment(remboursement.date, 'DD/MM/YYYY')
        : undefined,
    },
  })

  const isCheque = watch('type_reglement')?.nom?.toLowerCase() === 'chèque'

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.type_reglement_id = body.type_reglement?.id
        body.banque_id = body.banque?.id
        body.banque_remise_id = body.banque_remise?.id
        body.date = moment(values.date).format('DD/MM/YYYY')
        body.type = 'Remboursement'
        body.montant = -body.montant
        body.client_id = client.id
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('id')} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="type_reglement"
            defaultValue={null}
            render={({field: {value, onChange}}) => (
              <TypesReglementInput
                remboursement={true}
                onChange={(event, item) => {
                  onChange(item)
                }}
                label="Type de remboursement"
                value={value}
                error={!!errors?.type_reglement?.message}
                helperText={errors?.type_reglement?.message}
                margin="dense"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            defaultValue={''}
            render={({field}) => (
              <DeviseInput
                {...field}
                margin="dense"
                size="small"
                fullWidth
                error={!!errors?.debit?.message}
                helperText={errors?.debit?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            defaultValue=""
            render={({field}) => (
              <DesktopDatePicker
                {...field}
                label="Date"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors?.date?.message}
                    helperText={errors?.date?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="libelle_libre"
            defaultValue=""
            render={({field}) => (
              <TextField
                {...field}
                error={!!errors?.libelle_libre?.message}
                helperText={errors?.libelle_libre?.message}
                margin="dense"
                size="small"
                fullWidth
                label="Libellé libre"
              />
            )}
          />
        </Grid>
        {isCheque ? (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Infos chèque</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="banque"
                defaultValue={defaultBanqueRemise}
                render={({field: {value, onChange}}) => (
                  <BanquesRemiseInput
                    onChange={(event, item) => {
                      onChange(item)
                    }}
                    label="Banque chèque"
                    value={value}
                    error={!!errors?.banque?.message}
                    helperText={errors?.banque?.message}
                    margin="dense"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="numero_cheque"
                defaultValue=""
                render={({field}) => (
                  <TextField
                    {...field}
                    error={!!errors?.numero_cheque?.message}
                    helperText={errors?.numero_cheque?.message}
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Numéro chèque"
                  />
                )}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </form>
  )
}

export default RemboursementForm
