import {Dialog} from '@mui/material'

import {useState} from 'react'
import EnvoiDeFondCreateDialogCSVStep from './EnvoiDeFondCreateDialogCSVStep'
import EnvoiDeFondCreateDialogPreviewStep from './EnvoiDeFondCreateDialogPreviewStep'

const EnvoiDeFondCreateDialog = ({
  open = true,
  dette,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) => {
  const [preview, setPreview] = useState()
  const [createData, setCreateData] = useState()
  const isPreviewState = Boolean(preview)

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      maxWidth="xl"
      fullWidth
    >
      {isPreviewState ? (
        <EnvoiDeFondCreateDialogPreviewStep
          handleClose={handleClose}
          setPreview={setPreview}
          preview={preview}
          createData={createData}
          setCreateData={setCreateData}
        />
      ) : (
        <EnvoiDeFondCreateDialogCSVStep
          handleClose={handleClose}
          setPreview={setPreview}
          preview={preview}
          createData={createData}
          setCreateData={setCreateData}
        />
      )}
    </Dialog>
    // </form>
  )
}

export default EnvoiDeFondCreateDialog
