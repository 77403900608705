import AutocompleteInput from './AutocompleteInput'

const primeTypes = ['Confié', 'Non confié']

export default function PrimeTypesInput({
  label = 'Type',
  error,
  helperText,
  ...props
}) {
  return (
    <AutocompleteInput
      options={primeTypes}
      label={label}
      error={error}
      helperText={helperText}
      {...props}
    />
  )
}
