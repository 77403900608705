import {useFolder} from '../../hooks/api-hooks'
import {useEffect, useState} from 'react'
import FolderList from './FolderList'
import {Box, Breadcrumbs, CircularProgress, Link} from '@mui/material'

export default function BrowseFolder({rootPath, onFolderChange}) {
  const [currentPath, setCurrentPath] = useState(encodeURIComponent(rootPath))
  const {data, isLoading} = useFolder(currentPath, {lazy: true})
  const navs = []
  decodeURIComponent(currentPath)
    .split('/')
    .reduce((previousValue, currentValue) => {
      const path =
        previousValue !== '' ? previousValue + '/' + currentValue : rootPath
      navs.push({
        path,
        name: currentValue,
      })
      return path
    }, '')

  useEffect(() => {
    onFolderChange?.(data)
  }, [data])

  return (
    <Box>
      <Box p={1} px={2}>
        <Breadcrumbs aria-label="breadcrumb">
          {navs.map(nav => (
            <Link
              style={{cursor: 'pointer'}}
              underline="hover"
              onClick={() => {
                setCurrentPath(encodeURIComponent(nav.path))
              }}
            >
              {nav.name}
            </Link>
          ))}
        </Breadcrumbs>
      </Box>
      {isLoading ? (
        <Box
          minHeight={300}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress />{' '}
        </Box>
      ) : (
        <FolderList
          items={data?.children}
          simple={true}
          onClickOnItem={item => {
            if (item.type === 'folder') {
              setCurrentPath(encodeURIComponent(item.path))
            }
          }}
        />
      )}
    </Box>
  )
}
