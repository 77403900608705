import DatePickerControlled from '../controlled/DatePickerInputControlled'

export default function FusionParamDate({name, label, control, ...props}) {
  return (
    <DatePickerControlled
      name={name}
      label={label}
      control={control}
      {...props}
    />
  )
}
