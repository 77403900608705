import HomeIcon from '@mui/icons-material/Home'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ApartmentIcon from '@mui/icons-material/Apartment'
import {Box, useTheme} from '@mui/system'
import {Avatar, Typography} from '@mui/material'
import {green, orange, red} from '@mui/material/colors'

export default function CahierDeTransmissionStatusIcon({cahierDeTransmission}) {
  const theme = useTheme()
  const {status} = cahierDeTransmission
  if (status === 'Agence') {
    const color = theme.palette.primary.main
    return (
      <Box m={4} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{bgcolor: color, p: 1}}>
          <HomeIcon fontSize="large" />
        </Avatar>

        <Typography color={color} variant="subtitle1">
          Actuellement en agence
        </Typography>
      </Box>
    )
  }

  if (status === 'Vers Prima') {
    const color = orange[500]
    return (
      <Box m={4} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{bgcolor: color, p: 1}}>
          <DirectionsWalkIcon fontSize="large" />
        </Avatar>

        <Typography color={color} variant="subtitle1">
          Se dirige vers Prima
        </Typography>
      </Box>
    )
  }

  if (status === 'Prima') {
    const color = theme.palette.primary.main
    return (
      <Box m={4} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{bgcolor: color, p: 1}}>
          <ApartmentIcon fontSize="large" />
        </Avatar>

        <Typography color={color} variant="subtitle1">
          Actuellement à Prima
        </Typography>
      </Box>
    )
  }

  if (status === 'Vers Banque') {
    const color = orange[500]
    return (
      <Box m={4} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{bgcolor: color, p: 1}}>
          <DirectionsWalkIcon fontSize="large" />
        </Avatar>

        <Typography color={color} variant="subtitle1">
          Se dirige vers la banque
        </Typography>
      </Box>
    )
  }

  if (status === 'Banque') {
    const color = green[500]
    return (
      <Box m={4} display="flex" flexDirection="column" alignItems="center">
        <Avatar sx={{bgcolor: color, p: 1}}>
          <AccountBalanceIcon fontSize="large" />
        </Avatar>

        <Typography color={color} variant="subtitle1">
          À la banque
        </Typography>
      </Box>
    )
  }
  const color = red[500]

  return (
    <Box m={4} display="flex" flexDirection="column" alignItems="center">
      {/* <Avatar sx={{bgcolor: color, p:1}}>
        <QuestionMark fontSize="large" />
      </Avatar> */}

      <Typography color={color} variant="subtitle1">
        Statut inconnu
      </Typography>
    </Box>
  )
}
