import {useState} from 'react'
import {Button} from '@mui/material'
import ConfirmDialog from '../ConfirmDialog'
import {useRistourneQuittance} from '../../hooks/api-hooks'

const RistourneQuittanceButton = ({
  onLoading,
  operation,
  onRejet,
  ...props
}) => {
  const {mutate: ristourne, isLoading} = useRistourneQuittance()
  const [openDialog, setOpenDialog] = useState(false)

  if (operation?.type !== 'Quittance') {
    return null
  }

  const handleConfirm = () => {
    ristourne(operation.id)
    onRejet()
  }
  return (
    <>
      <ConfirmDialog
        title={`Voulez-vous vraiment créer une ristourne à partir de cette quittance ?`}
        loading={isLoading}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
      />
      <Button
        variant="contained"
        color="warning"
        onClick={() => setOpenDialog(true)}
        {...props}
      >
        Créer une ristourne
      </Button>
    </>
  )
}

export default RistourneQuittanceButton
