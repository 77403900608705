import {Link, Typography} from '@mui/material'
import {WrappedUniversalTable} from '../../../components/UniversalTable'
import useUniversalTableData from '../../../hooks/useUniversalTableData'
import {useContrats, useDevis} from '../../../hooks/api-hooks'
import {createColumnHelper} from '@tanstack/react-table'
import {Link as RouterLink} from 'react-router-dom'
import {useMotifResiliations} from '../../../hooks/api-hooks'

export default function ClientDetailsContratTab({client}) {
  if (!client) {
    return 'Chargement'
  }
  return (
    <div>
      <ContratTable
        initialFilters={{client: client.id}}
        devis={true}
        title={'Devis'}
      />
      <ContratTable
        initialFilters={{client: client.id, etat__ne: 'Résilié'}}
        title={'Liste des contrats'}
      />

      <ContratTable
        initialFilters={{client: client.id, etat: 'Résilié'}}
        title={'Liste des contrats résiliés'}
      />
    </div>
  )
}

const columnHelper = createColumnHelper()
const ContratTable = ({initialFilters, title, devis = false}) => {
  const {data: motifs} = useMotifResiliations()

  const columns = [
    columnHelper.accessor('reagi_id', {
      id: 'reagi_id',
      header: 'N° Contrat',
      enableHiding: false,
      cell: info => {
        return (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/${devis ? 'devis' : 'contrats'}/${info.cell.row.original.id}`}
            variant="subtitle2"
            data-testid="cell-field-nom"
          >
            {info.getValue()}
          </Link>
        )
      },
    }),
    columnHelper.accessor('produit.compagnie', {
      id: 'produit.compagnie',
      header: 'Compagnie',
    }),
    columnHelper.accessor('produit.code', {
      id: 'produit__code',
      header: 'Produit',
    }),
    columnHelper.accessor('objet_de_risque', {
      id: 'objet_de_risque',
      header: 'Objet de risque',
    }),
    columnHelper.accessor('referent.display_name', {
      id: 'referent___display_name',
      header: 'Client',
    }),
    columnHelper.accessor('date_effet_contrat', {
      id: 'date_effet_contrat',
      header: 'Date effet',
    }),
    columnHelper.accessor('date_fin_sp', {
      id: 'date_fin_sp',
      header: 'Date échéance',
    }),
    columnHelper.accessor('etat', {
      id: 'etat',
      header: 'État',
    }),
    columnHelper.accessor('date_resiliation_expiration', {
      id: 'date_resiliation_expiration',
      header: 'Date résiliation',
    }),
    columnHelper.accessor('motif_resilitation_expiration__nom', {
      id: 'motif_resilitation_expiration__nom',
      header: 'Cause de résiliation',
      enableSorting: false,
    }),
    columnHelper.accessor('prime_type', {
      id: 'prime_type',
      header: 'Encaissement',
    }),
    columnHelper.accessor('prime_fractionnement', {
      id: 'prime_fractionnement',
      header: 'Fractionnement',
    }),
    columnHelper.accessor('produit.nom', {id: 'produit__nom', header: 'Nom'}),
  ]
  const universalTableData = useUniversalTableData(
    devis ? useDevis : useContrats,
    {
      initialFilters,
      rowsPerPageOptions: ['10', '25', '50', '100'],
      defaultRowPerPage: 10,
    },
  )

  if (devis && !universalTableData?.data?.results?.length) return null
  return (
    <>
      <Typography variant={'h4'} mb={1}>
        {title}
      </Typography>
      <WrappedUniversalTable
        universalTableData={universalTableData}
        columns={columns}
        name={'contrats-list'}
      />
    </>
  )
}
