import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'
import DeviseInput from '../inputs/DeviseInput'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0)
    .max(1)
    .required(),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
  sens: yup.string().required(),
})

const OperationDiverse = ({perteEtProfit, client, onSubmit, ...props}) => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...perteEtProfit,
      sens:
        Boolean(perteEtProfit?.montant) && parseFloat(perteEtProfit.montant) < 0
          ? 'debit'
          : 'credit',
      montant: Boolean(perteEtProfit?.montant)
        ? Math.abs(parseFloat(perteEtProfit.montant))
        : undefined,
      date: Boolean(perteEtProfit?.date)
        ? moment(perteEtProfit.date, 'DD/MM/YYYY')
        : undefined,
    },
  })
  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {
          ...values,
          montant: values.sens === 'credit' ? values.montant : -values.montant,
        }
        body.type = 'Perte et profit'
        body.client_id = client.id
        body.date = moment(values.date).format('DD/MM/YYYY')
        delete body.sens
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('id')} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            defaultValue={moment(today).format('MM/DD/YYYY')}
            render={({field}) => (
              <DesktopDatePicker
                {...field}
                label="Date"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors?.date?.message}
                    helperText={errors?.date?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            render={({field}) => (
              <DeviseInput
                {...field}
                margin="dense"
                size="small"
                fullWidth
                error={!!errors?.montant?.message}
                helperText={errors?.montant?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="sens"
            defaultValue={
              perteEtProfit?.credit > perteEtProfit?.debit ? 'credit' : 'debit'
            }
            render={({field: {value, onChange}}) => (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="sens"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={(event, value) => onChange(value)}
                >
                  <FormControlLabel
                    value="debit"
                    control={<Radio size="small" />}
                    label="Profit"
                  />
                  <FormControlLabel
                    value="credit"
                    control={<Radio size="small" />}
                    label="Perte"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default OperationDiverse
