import PageTemplate from 'src/components/templates/PageTemplate'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Tooltip,
} from '@mui/material'
import LoadingCard from 'src/components/LoadingCard'
import ScrollBar from 'react-perfect-scrollbar'
import Settings from '@mui/icons-material/Settings'
import StandardTable from '../../components/Tables/StandardTable'
import {useMemo, useState} from 'react'
import DeviseLabel from '../../components/DeviseLabel'
import {Delete, Download, UploadFile} from '@mui/icons-material'
import EnvoiDeFondExportDialog from '../../components/dialogs/EnvoiDeFondExportDialog'
import EnvoiDeFondCreateDialog from '../../components/dialogs/EnvoiDeFondCreateDialog'
import useFilters from '../../hooks/useFilters'
import {useDeleteEnvoiDeFond, useEnvoisDeFonds} from '../../hooks/api-hooks'
import useLocalPagination from '../../hooks/useLocalPagination'
import useModalConfirmation from '../../hooks/useModalConfirmation'
import CompagnieInput from '../../components/inputs/CompagnieInput'
import DatePickerInput from '../../components/inputs/DatePickerInput'
import moment from 'moment'
import PermissionHidder from 'src/components/PermissionHidder'

export default function EnvoiDeFondList() {
  const {offset, limit, PaginationComponent} = useLocalPagination({})
  const [ordering, setOrdering] = useState('-id')
  const {filters, setFilters} = useFilters()
  const {isLoading, data, isFetching} = useEnvoisDeFonds({
    offset,
    limit,
    ordering,
    ...filters,
  })
  const {mutate: deleteEnvoiDeFond} = useDeleteEnvoiDeFond()

  const {
    ConfirmDialogElement,
    args: envoiDeFondToDelete,
    setArgs: setEnvoiDeFondToDelete,
  } = useModalConfirmation({
    onConfirm: args => {
      deleteEnvoiDeFond(envoiDeFondToDelete.id)
      setEnvoiDeFondToDelete()
    },
  })

  const [dialog, setDialog] = useState(null)
  const showExportModal = dialog === 'export'
  const showImportModal = dialog === 'import'

  const onDeleteClick = data => {
    setEnvoiDeFondToDelete(data)
  }

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
      },
      {
        Header: 'Date arrêté des comptes',
        accessor: 'date_arrete',
      },
      {
        Header: 'Libellé',
        accessor: 'libelle',
      },
      {
        Header: 'Montant',
        accessor: 'montant',
        Cell: ({value}) => {
          return (
            <>
              <DeviseLabel>{value}</DeviseLabel>
            </>
          )
        },
      },
      {
        Header: 'Actions',
        Cell: ({row}) => {
          return (
            <>
              <Tooltip title="Effacer définitivement l'envoi de fond" arrow>
                <IconButton
                  sx={{ml: 1, mr: 1}}
                  size="small"
                  color="primary"
                  onClick={() => onDeleteClick(row.original)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )
        },
      },
    ],
    [],
  )
  const rightActions = [
    <PermissionHidder mainRole={'is_superuser'}>
      <Button
        startIcon={<UploadFile fontSize="small" />}
        variant="contained"
        to="/envois-de-fonds/create"
        onClick={() => setDialog('import')}
        sx={{mr: 1}}
      >
        Créer un envoi de fond
      </Button>
    </PermissionHidder>,

    <Button
      startIcon={<Download fontSize="small" />}
      variant="contained"
      to="/envois-de-fonds/create"
      color="success"
      onClick={() => setDialog('export')}
    >
      Exporter pour envoi
    </Button>,
  ]

  const handleCloseDialog = () => setDialog(null)

  return (
    <PageTemplate
      title="Envoi de fond"
      sections={[{title: 'Envoi de fond', current: true}]}
      rightActions={rightActions}
    >
      {isLoading ? (
        <LoadingCard />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <PaginationComponent isLoading={isLoading} count={data.count} />
            <ScrollBar>
              <TableContainer component={Paper}>
                <StandardTable
                  columns={columns}
                  items={data.results}
                  ordering={ordering}
                  setOrdering={setOrdering}
                />
              </TableContainer>
            </ScrollBar>
            <PaginationComponent isLoading={isLoading} count={data.count} />
          </Grid>
          <Grid
            item
            md={3}
            xs={12}
            alignSelf="flex-start"
            sx={{position: 'sticky', top: '64px'}}
          >
            <Card>
              <CardHeader title="Filtres" avatar={<Settings />} />
              <Divider />
              <CardContent>
                <Box mb={1}>
                  <CompagnieInput
                    onChange={(event, value) => {
                      setFilters({compagnie: value})
                    }}
                  />
                </Box>
                <Box mb={1}>
                  <DatePickerInput
                    label={"Date d'arrêté début"}
                    value={
                      filters?.date_arrete__gte
                        ? moment(filters.date_arrete__gte, 'DD/MM/YYYY')
                        : null
                    }
                    onChange={value => {
                      setFilters({
                        date_arrete__gte: moment(value).format('DD/MM/YYYY'),
                      })
                    }}
                  />{' '}
                </Box>
                <Box mb={1}>
                  <DatePickerInput
                    label={"Date d'arrêté fin"}
                    value={
                      filters?.date_arrete__lte
                        ? moment(filters.date_arrete__lte, 'DD/MM/YYYY')
                        : null
                    }
                    onChange={value => {
                      setFilters({
                        date_arrete__lte: moment(value).format('DD/MM/YYYY'),
                      })
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {showExportModal ? (
        <EnvoiDeFondExportDialog handleClose={handleCloseDialog} />
      ) : null}
      {showImportModal ? (
        <EnvoiDeFondCreateDialog handleClose={handleCloseDialog} />
      ) : null}
      {Boolean(envoiDeFondToDelete) && (
        <ConfirmDialogElement>
          Vous êtes sur le point de supprimer cet envoi de fond:
          <br />
          <strong>ID :</strong> {envoiDeFondToDelete.id} <br />
          <strong>Montant :</strong>{' '}
          <DeviseLabel>{envoiDeFondToDelete.montant}</DeviseLabel>
          <br />
          <strong>Nombre de quittances :</strong>{' '}
          {envoiDeFondToDelete.quittances?.length ?? 0} <br />
          <strong>Nombre de ristournes :</strong>{' '}
          {envoiDeFondToDelete.ristournes?.length ?? 0}
          <br />
          <strong>Nombre d'écritures partielles :</strong>{' '}
          {envoiDeFondToDelete.ecritures_partielles?.length ?? 0}
        </ConfirmDialogElement>
      )}
    </PageTemplate>
  )
}
