import {Controller} from 'react-hook-form'
import DatePickerInput from '../inputs/DatePickerInput'
import DayMonthInput from '../inputs/DayMonthInput'

export default function DayMonthInputControlled({
  control,
  label,
  name,
  error,
  defaultValue = null,
  helperText,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field: {ref, ...field}}) => (
        <DayMonthInput
          {...field}
          label={label}
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
