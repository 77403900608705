import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import AddButton from '../../buttons/AddButton'
import DeviseLabel from '../../DeviseLabel'
import {useCreateEnvoiDeFond} from '../../../hooks/api-hooks'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import CompagnieInputControlled from '../../controlled/CompagnieInputControlled'
import DatePickerControlled from '../../controlled/DatePickerInputControlled'
import moment from 'moment'
import {errorResponseToJson} from '../../../services/crm-api'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  date_arrete: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date arrêté')
    .max(today, "Date d'arrêté doit être antérieur ou aujourd'hui")
    .required(),
  compagnie: yup
    .mixed()
    .transform(value => (value === '' ? undefined : value))
    .label('Compagnie')
    .required(),
})

export default function ({
  preview,
  setPreview,
  handleClose,
  setCreateData,
  createData,
}) {
  const {mutate, isLoading, error} = useCreateEnvoiDeFond()
  const {
    handleSubmit,
    control,
    formState: {errors: formErrors},
  } = useForm({
    resolver: yupResolver(schema),
  })

  const errors = {...formErrors, ...errorResponseToJson(error)}

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.date_arrete = moment(values.date_arrete).format('DD/MM/YYYY')
        mutate({...createData, ...body}, {onSuccess: () => handleClose()})
      })}
    >
      <DialogTitle>Création d'un envoi de fond</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} textAlign={'center'}>
            <Typography variant="subtitle1" display="block">
              Montant
            </Typography>
            <Typography variant="h3" display="inline">
              <DeviseLabel>{preview?.montant}</DeviseLabel>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CompagnieInputControlled
              control={control}
              name={'compagnie'}
              defaultValue={''}
              error={!!errors?.compagnie?.message}
              helperText={errors?.compagnie?.message}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerControlled
              control={control}
              name={'date_arrete'}
              label={'Date arrêté'}
              error={!!errors?.date_arrete?.message}
              helperText={errors?.date_arrete?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'h6'} sx={{mb: 2}}>
              Modification
            </Typography>
            <Table size={'small'}>
              <TableBody>
                <TableRow>
                  <TableCell>Nombre de quittances</TableCell>
                  <TableCell>{preview.nombre_quittances}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nombre de ristournes</TableCell>
                  <TableCell>{preview.nombre_ristournes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nombre d'écritures partielles</TableCell>
                  <TableCell>{preview.nombre_ecritures_partielles}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nombre de régularisations</TableCell>
                  <TableCell>
                    {preview.nombre_ecritures_regularisations}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'h6'} sx={{mb: 2}}>
              Impact sur les comptes comptables
            </Typography>
            <Table size={'small'}>
              <TableBody>
                {preview.comptes_comptables.map(x => (
                  <TableRow key={x.id}>
                    <TableCell>{x.id}</TableCell>
                    <TableCell align={'right'}>
                      <DeviseLabel>{x.montant}</DeviseLabel>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setPreview(undefined)
            setCreateData(undefined)
          }}
          color="primary"
        >
          Revenir
        </Button>
        <AddButton loading={isLoading} color="primary">
          Créer l'envoi de fond
        </AddButton>
      </DialogActions>
    </form>
  )
}
