import {Switch} from '@mui/material'

export default function BooleanInput({
  label = 'Boolean',
  error,
  helperText,
  value,
  ...props
}) {
  return (
    <Switch
      label={label}
      error={error}
      helperText={helperText}
      checked={value}
      defaultChecked={value}
      {...props}
    />
  )
}
