const produits = [
  {
    id: 1,
    code: '4A10',
    nom: 'Automobile',
    type: 'Automobile',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 2,
    code: '4Z12',
    nom: 'Véhicules de plus de 125 cc',
    type: '2 roues',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 3,
    code: '4Y11',
    nom: 'Transport Public Marchandises',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 4,
    code: '4Z11',
    nom: 'Véhicules moins/égal à 125 cc',
    type: '2 roues',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 5,
    code: '4Z10',
    nom: 'Véhicules de moins de 50 cc',
    type: '2 roues',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 6,
    code: '4Y15',
    nom: 'Engins de chantier',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 7,
    code: '4Y10',
    nom: 'Transport pour Propre Compte',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 8,
    code: '4Y16',
    nom: 'Tracteurs & machines agricoles',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 9,
    code: '4A20',
    nom: 'Flotte à tarif automatique',
    type: 'Flotte auto',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 10,
    code: '4Y14',
    nom: 'Remorque TPC',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 11,
    code: '4A13',
    nom: 'Flotte Auto à la carte',
    type: 'Flotte auto',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 12,
    code: '4Y13',
    nom: 'Remorque TPM',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 13,
    code: 'PREM',
    nom: 'Prémuni T1 et T2',
    type: 'Santé Particulier',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 14,
    code: 'PRSA',
    nom: 'Prudence Santé',
    type: 'Santé Particulier',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 15,
    code: 'SACR',
    nom: 'Santé Créole',
    type: 'Santé Particulier',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 16,
    code: '2A11',
    nom: 'Protection Prudence Créole',
    type: 'Prévoyance individuelle',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 17,
    code: 'FGCP',
    nom: 'Santé Groupe Ouvert',
    type: 'Santé Entreprise',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 18,
    code: 'SPRO',
    nom: 'Santé Créole Entreprise',
    type: 'Santé Entreprise',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 19,
    code: 'ACS',
    nom: 'Santé ACS DOM',
    type: 'Santé Particulier',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 20,
    code: 'CSS',
    nom: 'Santé Créole Solidaire',
    type: 'Santé Particulier',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 21,
    code: '30',
    nom: 'Bris de machine',
    type: 'Dommage aux biens',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 22,
    code: '6A01',
    nom: 'RC Entreprise Industr. & Commerc.',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 23,
    code: '6A05',
    nom: 'RC Professionnelle',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 24,
    code: '6A06',
    nom: 'RC Diverses',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 25,
    code: '6A03',
    nom: 'RC Vie privée',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 26,
    code: '6A02',
    nom: 'RC Bâtiment & Travaux Publics',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 27,
    code: '6A04',
    nom: 'RC Agricole',
    type: 'RC',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 28,
    code: '5P01',
    nom: 'Navire de plaisance',
    type: 'Transport',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 29,
    code: '7A03',
    nom: 'Multirisque',
    type: 'Multirisque à la carte',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 30,
    code: '7A10',
    nom: 'Multirisque Habitation',
    type: 'MRH',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 31,
    code: '7A20',
    nom: 'M.R Propriétaire non Occupant',
    type: 'MRH',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 32,
    code: '7A02',
    nom: 'Multirisque Risque Entreprise',
    type: 'Multirisque à la carte',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 33,
    code: '7A15',
    nom: 'Assurance Scolaire',
    type: 'Multirisque Scolaire Automatique',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 34,
    code: '7A50',
    nom: 'M.Risque Professionnelle MCA',
    type: 'Multirisque à tarification automatique',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 35,
    code: '7A51',
    nom: '100% Pro Artisans - Commerçants',
    type: 'Multirisque à tarification automatique',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 36,
    code: '7A52',
    nom: '100% Pro Services',
    type: 'Multirisque à tarification automatique',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 37,
    code: '7A12',
    nom: 'Multirisque Habitation SHLMR',
    type: 'MRH',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 38,
    code: 'PJVP',
    nom: 'Protection Juridique Vie Privée',
    type: 'PJ',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 39,
    code: 'V007',
    nom: 'Convention Obsèques 2013',
    type: 'Prévoyance individuelle',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 40,
    code: 'm6rq0',
    nom: 'Véhicules moins/égal à 125 cc',
    type: 'Automobile de +3.5T',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 41,
    code: 'c7UI0',
    nom: 'Transport Public Marchandises',
    type: 'Automobile',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
  {
    id: 42,
    code: 'A6TD0',
    nom: 'Véhicules moins/égal à 125 cc',
    type: 'Automobile',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: true,
  },
  {
    id: 43,
    code: 'I3PW0',
    nom: 'Véhicules de moins de 50 cc',
    type: 'Automobile',
    compagnie: 'Prudence Créole',
    contrat_a_aliments: false,
  },
]

export {produits}
