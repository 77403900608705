const quittances = [
  {
    id: 1,
    type: 'quittance',
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '2021-06-30T11:00:50.250365Z',
    date_debut: '2021-08-30T11:00:50.250365Z',
    date_fin: '2022-06-30T11:00:50.250365Z',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Confié',
    credit: 20,
    debit: 0,
    prime_ttc: 20,
    prime_ht: 19,
    commission: 5,
    frais_hva: 5,
  },
  {
    id: 2,
    type: 'ristourne',
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '2021-06-30T11:00:50.250365Z',
    date_debut: '2021-08-30T11:00:50.250365Z',
    date_fin: '2022-06-30T11:00:50.250365Z',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Non confié',
    credit: 0,
    debit: 200,
    prime_ttc: 200,
    prime_ht: 190,
    commission: 10,
    frais_hva: 10,
  },
  {
    id: 3,
    type: 'quittance',
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '2020-06-30T11:00:50.250365Z',
    date_debut: '2021-08-30T11:00:50.250365Z',
    date_fin: '2022-06-30T11:00:50.250365Z',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Confié',
    credit: 999,
    debit: 0,
    prime_ttc: 999,
    prime_ht: 900,
    commission: 15,
    frais_hva: 20,
  },
]

export {quittances}
