import {
  Button,
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {Preview, UploadFile} from '@mui/icons-material'
import {useTheme} from '@material-ui/core/styles'
import {LoadingButton} from '@mui/lab'

import {parse} from 'csv-parse/lib/sync'
import {useCallback, useState} from 'react'
import {previewEnvoiDeFond} from '../../../services/crm-api'
import {useDropzone} from 'react-dropzone'

function CSVDropZone({setCsv}) {
  const theme = useTheme()
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const text = reader.result
          setCsv(parse(text, {delimiter: ';'}))
        }
        reader.readAsText(file)
      })
    },
    [setCsv],
  )

  const {getRootProps, isDragActive, acceptedFiles} = useDropzone({
    onDrop,
    accept: ['text/csv'],
  })

  const files = acceptedFiles.map(file => (
    <div key={file.path}>
      {file.path} - {file.size} bytes
    </div>
  ))

  const isDragged = files.length > 0

  return (
    <div
      {...getRootProps()}
      style={{
        border: '3px dashed #999',
        backgroundColor: isDragged
          ? colors.green['500']
          : isDragActive
          ? theme.palette.primary.main
          : '#eee',
        color: isDragActive || isDragged ? 'white' : 'black',
        marginTop: '10px',
        padding: '50px',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <UploadFile />
        <Typography variant={'overline'} ml={1}>
          Importer un CSV d'envoi de fond
        </Typography>
      </div>

      {isDragged ? <>{files}</> : null}
    </div>
  )
}

export default function EnvoiDeFondCreateDialogCSVStep({
  handleClose,
  setPreview,
  setCreateData /*, createData*/,
}) {
  const [csv, setCsv] = useState()
  const headers = csv?.[0]
  const rows = csv?.slice(1)
  const [isLoading, setIsLoading] = useState(false)
  const [errorText, setErrorText] = useState()
  return (
    <>
      <DialogTitle>Création d'un envoi de fond</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <TextField*/}
          {/*    multiline={true}*/}
          {/*    rows={1}*/}
          {/*    onChange={event => {*/}
          {/*      setCsv(parse(event.target.value, {delimiter: ';'}))*/}
          {/*    }}*/}
          {/*    disabled={isLoading}*/}
          {/*    margin="dense"*/}
          {/*    size="small"*/}
          {/*    fullWidth*/}
          {/*    label="CSV"*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <CSVDropZone setCsv={setCsv} />
          </Grid>
          <Grid item xs={12} sx={{mt: 2}}>
            {errorText ? (
              <Typography variant={'subtitle1'} color={'error'}>
                <pre>{errorText}</pre>
              </Typography>
            ) : (
              <Table
                sx={{minWidth: 650}}
                aria-label="simple table"
                size={'small'}
              >
                <TableHead>
                  <TableRow>
                    {headers?.map(header => (
                      <TableCell key={header}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map(row => (
                    <TableRow
                      key={`${row[0]}-${row[1]}`}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      {row?.map(entry => (
                        <TableCell key={entry}>{entry}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={!csv}
          startIcon={<Preview fontSize="small" />}
          variant="contained"
          onClick={() => {
            const quittances = csv
              .filter(x => x[0] === 'EF REGLEMENT')
              .map(x => ({
                id: x[1],
                montant:
                  x[4].toLowerCase() === 'c'
                    ? parseFloat(x[3].replace(',', '.'))
                    : -parseFloat(x[3].replace(',', '.')),
              }))

            const ristournes = csv
              .filter(x => x[0] === 'EF RISTOURNE')
              .map(x => ({
                id: x[1],
                montant:
                  x[4].toLowerCase() === 'c'
                    ? parseFloat(x[3].replace(',', '.'))
                    : -parseFloat(x[3].replace(',', '.')),
              }))

            const partielles = csv
              .filter(x => x[0] === 'EF PARTIEL')
              .map(x => ({
                id: x[1],
                libelle: x[2],
                montant:
                  x[4].toLowerCase() === 'c'
                    ? parseFloat(x[3].replace(',', '.'))
                    : -parseFloat(x[3].replace(',', '.')),
              }))

            const reguls = csv
              .filter(x => x[0].toLowerCase() === 'régularisation')
              .map(x => ({
                libelle: x[2],
                montant: x[3].replace(',', '.'),
                sens: x[4],
                compte_comptable: x[5],
              }))

            const body = {
              quittances_objs: quittances,
              ristournes_objs: ristournes,
              ecritures_partielles_objs: partielles,
              regularisations_objs: reguls,
            }

            setIsLoading(true)

            previewEnvoiDeFond(body)
              .then(x => {
                setPreview(x)
                setCreateData(body)
              })
              .catch(err => {
                if (err?.response?.data) {
                  const data = err.response.data
                  const errors = []

                  if (data['quittances_objs']) {
                    errors.push('EF REGLEMENT')
                    errors.push('---------------')
                    data['quittances_objs'].map(x => errors.push(x))
                    errors.push('')
                  }

                  if (data['ristournes_objs']) {
                    errors.push('EF RISTOURNE')
                    errors.push('---------------')
                    data['ristournes_objs'].map(x => errors.push(x))
                    errors.push('')
                  }

                  if (data['ecritures_partielles_objs']) {
                    errors.push('EF PARTIEL')
                    errors.push('---------------')
                    data['ecritures_partielles_objs'].map(x => errors.push(x))
                    errors.push('')
                  }

                  if (data['regularisations_objs']) {
                    errors.push('Régularisation')
                    errors.push('---------------')
                    data['regularisations_objs'].map(x => errors.push(x))
                    errors.push('')
                  }
                  return setErrorText(errors.join('\n'))
                }
                setErrorText('Une erreur est survenue')
              })
              .finally(() => setIsLoading(false))
          }}
        >
          Prévisualiser
        </LoadingButton>
      </DialogActions>
    </>
  )
}
