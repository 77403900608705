import {useEffect} from 'react'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router-dom'

import {
  createUser,
  errorResponseToJson,
  fetchAgences,
  fetchFonctions,
  fetchServices,
} from 'src/services/crm-api'
import UserForm from 'src/components/forms/UserForm'
import useAsync from 'src/hooks/useAsync'
import PageTemplate from 'src/components/templates/PageTemplate'

const UserCreate = () => {
  const {
    data: dataDependencies,
    run: runDependencies,
    isLoading: dependenciesIsLoading,
    isIdle: dependenciesIsIdle,
  } = useAsync()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const {run, isLoading: isLoadingCreation, error, setError} = useAsync()

  useEffect(() => {
    const getDependencies = Promise.all([
      fetchAgences()
        .then(response => response)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
      fetchFonctions()
        .then(response => response.data)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
      fetchServices()
        .then(response => response.data)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
    ])
    runDependencies(getDependencies)
  }, [runDependencies])

  const errors = errorResponseToJson(error)

  const handleSubmit = values => {
    run(
      createUser(values).then(() => {
        setError()
        navigate('/users')
        enqueueSnackbar('Utilisateur créé', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
          variant: 'success',
        })
      }),
    ).catch(error => {
      enqueueSnackbar('Utilisateur non créé', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      })
    })
  }

  const isLoading =
    dependenciesIsIdle || dependenciesIsLoading || isLoadingCreation
  const [agences, fonctions, services] = dataDependencies ?? [[], [], []]
  return (
    <PageTemplate
      title="Ajouter un utilisateur"
      sections={[
        {title: 'Utilisateurs', path: '/users'},
        {title: 'Ajouter un utilisateur', current: true},
      ]}
      data-testid="page-users-create"
    >
      <UserForm
        errors={errors}
        onSubmit={handleSubmit}
        loading={isLoading}
        agences={agences}
        fonctions={fonctions}
        services={services}
      />
    </PageTemplate>
  )
}

export default UserCreate
