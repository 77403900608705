import {useRoutes} from 'react-router'
// import {ThemeProvider} from '@mui/material'

import SplashScreen from './components/SplashScreen'
import useAuth from 'src/hooks/useAuth'
import routes from './routes'

function App() {
  const auth = useAuth()
  const content = useRoutes(routes)

  return auth.isInitialized ? content : <SplashScreen />
  // <ThemeProvider theme={theme}>
  // </ThemeProvider>
}

export default App
