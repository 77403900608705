import {useEffect, useRef} from 'react'

import {createContext, useContext} from 'react'
import {useForm as reactFormHook, Controller} from 'react-hook-form'
// import {errorResponseToJson} from 'src/services/crm-api'

const FormContext = createContext()
FormContext.displayName = 'FormContext'

const useForm = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error('useForm must be used within <Form />')
  }
  return context
}

const Form = ({
  resolver,
  defaultValues = {},
  onSubmit,
  errors,
  children,
  values,
  isLoading,
  noValidate = true,
}) => {
  const formHook = reactFormHook({
    resolver: resolver,
    mode: 'onBlur',
    defaultValues,
  })

  const previousValuesRef = useRef()

  useEffect(() => {
    if (previousValuesRef.current !== values) {
      previousValuesRef.current = values

      formHook.reset(values)
    }
  }, [defaultValues, formHook, values])

  const allErrors = {
    ...formHook.formState?.errors,
    ...errors,
  }

  return (
    <FormContext.Provider
      value={{
        ...formHook,
        errors: allErrors,
        isLoading,
      }}
    >
      <form
        noValidate={noValidate}
        onSubmit={formHook.handleSubmit(onSubmit)}
        autoComplete="off"
      >
        {children}
      </form>
    </FormContext.Provider>
  )
}

export {Form as default, useForm, Controller}
