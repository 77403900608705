import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import React, {Fragment} from 'react'
import {CarRental} from '@mui/icons-material'

export default function ChampsAdditionnelsMulti({contrat, ...props}) {
  if (contrat?.produit?.multi) {
    return (
      <Card {...props}>
        <CardHeader title="Aliments" avatar={<CarRental />}></CardHeader>
        <CardContent>
          <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {contrat?.produit?.odr?.champs?.map(x => (
                  <TableCell key={x.key}>{x.nom}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contrat?.champs_additionnels?.map((x, index) => (
                <TableRow key={index}>
                  {contrat?.produit?.odr?.champs?.map(y => (
                    <TableCell key={`${index}-${x.key}`}>{x[y.key]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    )
  }
  return null
}
