import {LoadingButton} from '@mui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'
import {useState} from 'react'
import AutocompleteInput from 'src/components/inputs/AutocompleteInput'
import {useStatusRemiseEnBanque} from 'src/hooks/api-hooks'

export default function ChangeStatusDialog({
  remiseEnBanque,
  onCancel,
  onConfirm,
  open = false,
  ...props
}) {
  const [status, setStatus] = useState(remiseEnBanque?.status)
  const {
    isLoading,
    mutate,
    isError,
    status: networkStatus,
  } = useStatusRemiseEnBanque(remiseEnBanque?.id)
  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>Changer le statut</DialogTitle>
      <DialogContent>
        <AutocompleteInput
          options={['Agence', 'Vers Prima', 'Prima', 'Vers Banque', 'Banque']}
          label="Statut"
          onChange={(evt, value) => {
            setStatus(value)
          }}
          value={status}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuler</Button>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            mutate(status, {
              onSuccess: onCancel,
            })
          }}
          disabled={!status}
        >
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
