import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Edit, Home, Close} from '@mui/icons-material'

import Form, {AlertError, TextField, Autocomplete} from 'src/components/Form'
import useSimplePartialUpdateForm from 'src/hooks/useSimplePartialUpdateForm'
import {errorResponseToJson, partialUpdateClient} from 'src/services/crm-api'
import CardSubtitle from 'src/components/labels/CardSubtitle'

import countries from 'src/services/countries'
import {usePartialEditClient} from 'src/hooks/api-hooks'

const resolver = yupResolver(
  yup.object().shape({
    adresse_principale_1: yup.string().label("L'adresse").required().max(50),
    adresse_principale_code_postal: yup
      .string()
      .label('Code postal')
      .required()
      .max(10),
    adresse_principale_ville: yup.string().label('Ville').required().max(30),
    adresse_principale_pays: yup.string().label('Pays').required().max(30),
  }),
)

const DisplayBody = ({
  client,
  setEditing,
  editing,
  isLoading,
  modificationDisabled,
}) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <CardSubtitle>Principale</CardSubtitle>
            </TableCell>
            <TableCell>
              <div>{client.adresse_principale_1}</div>
              <div>{client.adresse_principale_2}</div>
              <div>{client.adresse_principale_3}</div>
              <div> {client.adresse_principale_4}</div>
              <div>{client.adresse_principale_ville}</div>
              <div>{client.adresse_principale_code_postal}</div>
              <div>{client.adresse_principale_pays}</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <CardSubtitle>Secondaire</CardSubtitle>
            </TableCell>
            <TableCell>
              <div>{client.adresse_secondaire_1} </div>
              <div>{client.adresse_secondaire_2} </div>
              <div>{client.adresse_secondaire_3}</div>
              <div>{client.adresse_secondaire_4}</div>
              <div>{client.adresse_secondaire_ville}</div>
              <div>{client.adresse_secondaire_code_postal}</div>
              <div>{client.adresse_secondaire_pays}</div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={modificationDisabled}
        >
          Modifier
        </Button>
      </CardActions>
    </>
  )
}

const EditBody = ({
  client,
  onSubmit,
  setEditing,
  editing,
  modificationDisabled,
}) => {
  const {isLoading, error, mutate} = usePartialEditClient(client.id)

  const errors = errorResponseToJson(error)
  const defaultValue = {
    adresse_principale_1: client.adresse_principale_1,
    adresse_principale_2: client.adresse_principale_2,
    adresse_principale_3: client.adresse_principale_3,
    adresse_principale_4: client.adresse_principale_4,
    adresse_principale_ville: client.adresse_principale_ville,
    adresse_principale_code_postal: client.adresse_principale_code_postal,
    adresse_principale_pays: client.adresse_principale_pays,
    adresse_secondaire_1: client.adresse_secondaire_1,
    adresse_secondaire_2: client.adresse_secondaire_2,
    adresse_secondaire_3: client.adresse_secondaire_3,
    adresse_secondaire_4: client.adresse_secondaire_4,
    adresse_secondaire_ville: client.adresse_secondaire_ville,
    adresse_secondaire_code_postal: client.adresse_secondaire_code_postal,
    adresse_secondaire_pays: client.adresse_secondaire_pays,
  }

  return (
    <Form
      onSubmit={values => {
        mutate(values)
        setEditing(false)
      }}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardSubtitle>Adresse principale:</CardSubtitle>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_1"
                    label="Adresse 1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_2"
                    label="Adresse 2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_3"
                    label="Adresse 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_4"
                    label="Adresse 4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_code_postal"
                    label="Code Postal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_principale_ville"
                    label="Ville"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    items={countries}
                    label="Pays"
                    name="adresse_principale_pays"
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardSubtitle>Adresse secondaire:</CardSubtitle>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_1"
                    label="Adresse 1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_2"
                    label="Adresse 2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_3"
                    label="Adresse 3"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_4"
                    label="Adresse 4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_code_postal"
                    label="Code Postal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    name="adresse_secondaire_ville"
                    label="Ville"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    items={countries}
                    label="Pays"
                    name="adresse_secondaire_pays"
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading | modificationDisabled}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ClientAddressCard = ({client, modificationDisabled, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Adresses" avatar={<Home />} />
      <Divider />
      {editing ? (
        <EditBody
          client={client}
          setEditing={setEditing}
          editing={editing}
          modificationDisabled={modificationDisabled}
        />
      ) : (
        <DisplayBody
          client={client}
          setEditing={setEditing}
          editing={editing}
          modificationDisabled={modificationDisabled}
        />
      )}
    </Card>
  )
}

export default ClientAddressCard
