import {Grid, Typography} from '@mui/material'

import {
  ContratSituationCard,
  ContratIdentificationCard,
  ContratGestionCard,
  ContratPrimeCard,
} from '.'
import ChampsAdditionnelsMulti from './ChampsAdditionnelsMulti'

const ContratDetailsInfo = ({contrat = {}, setContrat}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <ContratIdentificationCard
          contrat={contrat}
          setContrat={setContrat}
          sx={{mb: 3}}
        />
        <ContratGestionCard
          contrat={contrat}
          setContrat={setContrat}
          sx={{mb: 3}}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ContratSituationCard
          contrat={contrat}
          setContrat={setContrat}
          sx={{mb: 3}}
        />
        <ContratPrimeCard
          contrat={contrat}
          setContrat={setContrat}
          sx={{mb: 3}}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Typography color="textPrimary" variant="h6">
          Activités:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ChampsAdditionnelsMulti contrat={contrat} sx={{mb: 3}} />

        {/*<ContratDetailsAuto contrat={contrat} sx={{mb: 3}} />*/}
      </Grid>
    </Grid>
  )
}

export default ContratDetailsInfo
