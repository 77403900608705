import ClientEntrepriseInfoCard from './ClientEntrepriseInfoCard'
import ClientParticulierInfoCard from './ClientParticulierInfoCard'

const ClientInfoCard = ({client, modificationDisabled, ...props}) => {
  return client?.type === 'Personne morale' ? (
    <ClientEntrepriseInfoCard
      client={client}
      modificationDisabled={modificationDisabled}
    />
  ) : (
    <ClientParticulierInfoCard
      client={client}
      modificationDisabled={modificationDisabled}
    />
  )
}

export default ClientInfoCard
