import {
  useCreateOperation,
  useEditOperation,
  useOperation,
} from 'src/hooks/api-hooks'

export default function useOperationDialog(operationId) {
  const isEditing = Boolean(operationId)
  const {data: operation, isFetched} = useOperation(
    operationId,
    {},
    {
      enabled: isEditing,
    },
  )
  const {mutate: create, isLoading: isCreateLoading} = useCreateOperation()
  const {mutate: update, isLoading: isUpdateLoading} =
    useEditOperation(operationId)

  const isMutateLoading = isCreateLoading || isUpdateLoading
  const isLoading = !isFetched && isEditing

  return {
    isEditing,
    operation,
    isFetched,
    create,
    update,
    isMutateLoading,
    isLoading,
  }
}
