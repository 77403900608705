import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
  Switch,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Assignment, Edit, Close} from '@mui/icons-material'

import Form, {AlertError} from 'src/components/Form'
import LabelRow from 'src/components/LabelRow'
import useSimplePartialUpdateForm from 'src/hooks/useSimplePartialUpdateForm'
import {errorResponseToJson, partialUpdateContrat} from 'src/services/crm-api'

const resolver = yupResolver(yup.object().shape({}))

const DisplayBody = ({contrat, setEditing, editing, isLoading}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="Etat">{contrat?.etat}</LabelRow>
          <LabelRow label="Durée ferme">
            <Switch
              checked={contrat?.duree_ferme}
              defaultChecked={contrat?.duree_ferme}
            />
          </LabelRow>
          <LabelRow label="Date d'effet">
            {contrat?.date_effet_contrat}
          </LabelRow>
          <LabelRow label="Date renouvellement">
            {contrat?.date_renouvellement}
          </LabelRow>
          <LabelRow label="Date résiliation / expiration">
            {contrat?.date_resiliation_expiration}
          </LabelRow>
          <LabelRow label="Motif résiliation">
            {contrat?.motif_resilitation_expiration?.nom}
          </LabelRow>
          {contrat?.repreneur !== '' ? (
            <LabelRow label="Repreneur">{contrat?.repreneur}</LabelRow>
          ) : null}
          {contrat?.commentaire !== '' ? (
            <LabelRow label="Commentaire">{contrat?.commentaire}</LabelRow>
          ) : null}
        </TableBody>
      </Table>
      {/* <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={!Boolean(contrat)}
        >
          Modifier
        </Button>
      </CardActions> */}
    </>
  )
}

const EditBody = ({contrat, onSubmit, setEditing, editing, setContrat}) => {
  const {isLoading, error, save} = useSimplePartialUpdateForm({
    promise: partialUpdateContrat,
    id: contrat.id,
    onSuccess: data => {
      setContrat(data)
      setEditing(false)
    },
  })

  const errors = errorResponseToJson(error)
  const defaultValue = {}
  return (
    <Form
      onSubmit={save}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ContratPrimeCard = ({contrat, setContrat, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Situation" avatar={<Assignment />} />
      <Divider />
      {editing ? (
        <EditBody
          contrat={contrat}
          setContrat={setContrat}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          contrat={contrat}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ContratPrimeCard
