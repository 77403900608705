/////////////////////////// TO PUT IN OTHER FILE
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {useEffect, useState} from 'react'
import {convertReactTableSortingToOrdering} from '../services/crm-api'
import {HTML5Backend} from 'react-dnd-html5-backend'

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0],
  )
  return [...columnOrder]
}
const DraggableColumnHeader = ({header, table}) => {
  const {getState, setColumnOrder} = table
  const {columnOrder} = getState()
  const {column} = header

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: draggedColumn => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder,
      )
      setColumnOrder(newColumnOrder)
    },
  })

  const [{isDragging}, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  })

  return (
    <TableCell
      ref={dropRef}
      key={header.id}
      colSpan={header.colSpan}
      width={header.getSize()}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div style={{display: 'flex'}}>
        <TableSortLabel
          active={header.column.getIsSorted() ?? false}
          disabled={!header.column.getCanSort()}
          direction={header.column.getIsSorted() || undefined}
          onClick={header.column.getToggleSortingHandler()}
          ref={previewRef}
        >
          <div ref={dragRef}>
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        </TableSortLabel>
      </div>
    </TableCell>
  )
}

export function StandardTableComponent({data, columns, setOrdering, ordering}) {
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    const ordering = convertReactTableSortingToOrdering(sorting)
    setOrdering(ordering)
  }, [sorting])

  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id),
  )
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnOrder,
      sorting,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    getRowId: row => row.path,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          size={'small'}
          style={{
            tableLayout: 'auto',
          }}
          width={table.getCenterTotalSize()}
        >
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <DraggableColumnHeader
                    key={header.id}
                    header={header}
                    table={table}
                  />
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow hover key={row.id} onClick={() => {}}>
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    width={cell.column.getSize()}
                    style={{width: cell.column.getSize()}}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DndProvider>
  )
}
