import ConfirmDialog from '../components/ConfirmDialog'
import {useState} from 'react'

const defaultOptions = {
  onConfirm: () => {},
  onCancel: null,
}

export default function useModalConfirmation(options) {
  const [args, setArgs] = useState(null)

  const {onConfirm, onCancel, TextComponent, validText, cancelText} = {
    ...defaultOptions,
    ...options,
  }

  const ConfirmDialogElement = ({children}) =>
    Boolean(args) ? (
      <ConfirmDialog
        title="Confirmation de suppression"
        onConfirm={() => {
          onConfirm(args)
        }}
        onCancel={() => onCancel?.() ?? setArgs(null)}
        open={!!args}
      >
        {children}
      </ConfirmDialog>
    ) : null

  return {ConfirmDialogElement, args, setArgs}
}
