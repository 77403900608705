import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material'
import AddButton from 'src/components/buttons/AddButton'
import useAuth from 'src/hooks/useAuth'
import {Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {DesktopDatePicker} from '@mui/lab'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import moment from 'moment'
import TextInputControlled from '../../controlled/TextInputControlled'
import AgencesInputControlled from '../../controlled/AgencesInputControlled'
import UsersInputControlled from '../../controlled/UsersInputController'
import DatePickerInputControlled from '../../controlled/DatePickerInputControlled'
import ContratEtatsInputControlled from '../../controlled/ContratEtatsInputControlled'
import PrimeFractionnementsInputControlled from '../../controlled/PrimeFractionnementsInputControlled'
import PrimeTypesInputControlled from '../../controlled/PrimeTypesInputControlled'
import MotifResiliationsInputControlled from '../../controlled/MotifResiliationsInputControlled'
import BooleanInputControlled from '../../controlled/BooleanInputControlled'
import ProduitsInputControlled from '../../controlled/ProduitsInputControlled'
import DayMonthInputControlled from '../../controlled/DayMonthInputControlled'
import EditButton from '../../buttons/EditButton'
import CloseButton from '../../buttons/CloseButton'
import MarquesAutoInputControlled from '../../controlled/MarquesAutoInputControlled'
import RepreneursInputControlled from '../../controlled/RepreneursInputControlled'

const defaultValues = {
  // Identification contrat
  reagi_id: '',
  produit_id: '',
  branche: '',

  // Situation du contrat
  etat: '',
  duree_ferme: false,
  date_effet_contrat: '',
  date_renouvellement: '',
  date_resiliation_expiration: '',
  motif_resilitation_expiration_id: '',

  // Prime
  prime_type: '',
  prime_fractionnement: '',

  // Gestion du contrat
  agence_id: '',
  referent_id: '',
  date_debut_sp: null,
  date_fin_sp: null,
  decision_sp_n: '',
  decision_sp_n_m_1: '',

  // Précontentieux
  est_precontentieux: false,
  precontentieux_date_mise_en_demeure: null,
  precontentieux_date_suspension_garantie: null,
}

const ContratForm = ({
  submitLabel,
  errors,
  onSubmit,
  contrat,
  clientId,
  devis = false,
}) => {
  const baseSchema = useMemo(() => {
    const schema = {
      reagi_id: yup.string().label('id RéAGI').required(),
      produit_id: yup.mixed().label('Produit').required(),
      date_resiliation_expiration: yup
        .mixed()
        .label('Date résiliation / expiration')
        .when('duree_ferme', (duree_ferme, schema) =>
          duree_ferme ? schema.required() : schema,
        ),
      motif_resilitation_expiration_id: yup
        .mixed()
        .label('Motif de résiliation')
        .when('duree_ferme', (duree_ferme, schema) =>
          duree_ferme ? schema.required() : schema,
        ),
      date_effet_contrat: yup.mixed().label("Date d'effet").required(),
      date_renouvellement: yup.mixed().label('Date renouvellement').required(),
      prime_type: yup.mixed().label('Type').required(),
      prime_fractionnement: yup.mixed().label('Fractionnement').required(),
      agence_id: yup.mixed().label('Agence').required(),
      referent_id: yup.mixed().label('Réferent').required(),
      est_precontentieux: yup.bool().label('Précontentieux').notRequired(),
      precontentieux_date_mise_en_demeure: yup
        .mixed()
        .label('Date Mise en demeurre')
        .notRequired(),
      precontentieux_date_suspension_garantie: yup
        .mixed()
        .label('Date suspension garanties')
        .notRequired(),
    }
    if (!devis) {
      schema['etat'] = yup.mixed().label('État').required()
    }

    return yup.object().shape(schema)
  }, [devis])

  const {user} = useAuth()

  const defaultValues = useMemo(() => {
    const produit = contrat?.produit
    if (produit?.odr) {
      produit?.odr?.champs?.forEach(x => {
        if (x.widget === 'date') {
          const newChampsAdditionnels = []
          contrat.champs_additionnels.forEach((y, index) => {
            newChampsAdditionnels.push({
              ...y,
              [x.key]: Boolean(y[x.key])
                ? moment(y[x.key], 'DD/MM/YYYY')
                : undefined,
            })
          })
          contrat.champs_additionnels = newChampsAdditionnels
        }
      })
    }

    return {
      ...contrat,
      produit_id: contrat?.produit,
      referent_id: contrat?.referent ?? {
        id: user?.id,
        display_name: user?.display_name,
      },
      agence_id: contrat?.agence ?? user?.agence,
      motif_resilitation_expiration_id: contrat?.motif_resilitation_expiration,
      date_effet_contrat: Boolean(contrat?.date_effet_contrat)
        ? moment(contrat.date_effet_contrat, 'DD/MM/YYYY')
        : undefined,
      date_renouvellement: Boolean(contrat?.date_renouvellement)
        ? moment(contrat.date_renouvellement, 'DD/MM/YYYY')
        : undefined,
      date_resiliation_expiration: Boolean(contrat?.date_resiliation_expiration)
        ? moment(contrat.date_resiliation_expiration, 'DD/MM/YYYY')
        : undefined,
      date_debut_sp: Boolean(contrat?.date_debut_sp)
        ? moment(contrat.date_debut_sp, 'DD/MM/YYYY')
        : undefined,
      date_fin_sp: Boolean(contrat?.date_fin_sp)
        ? moment(contrat.date_fin_sp, 'DD/MM/YYYY')
        : undefined,
      est_precontentieux: Boolean(contrat?.est_precontentieux) ? true : false,
      precontentieux_date_mise_en_demeure: Boolean(contrat?.est_precontentieux)
        ? moment(contrat.precontentieux_date_mise_en_demeure, 'DD/MM/YYYY')
        : null,
      precontentieux_date_suspension_garantie: Boolean(
        contrat?.precontentieux_date_suspension_garantie,
      )
        ? moment(contrat.precontentieux_date_suspension_garantie, 'DD/MM/YYYY')
        : null,
    }
  }, [contrat])

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {errors: formErrors},
  } = useForm({
    resolver: yupResolver(baseSchema),
    defaultValues,
  })
  const editing = Boolean(contrat)
  const allErrors = {...errors, ...formErrors}

  const produitSelected = watch('produit_id')
  const motifResiliation = watch('motif_resilitation_expiration_id')
  const showRepreneur = motifResiliation?.nom
    .toLowerCase()
    .includes('loi hamon')
  const showAmiable = motifResiliation?.nom.toLowerCase().includes('amiable')

  const [isPrecontentieuxChecked, setIsPrecontentieuxChecked] = useState(
    contrat?.est_precontentieux ? true : false,
  )

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {
          ...values,
          client_id: clientId,
          produit_id: values.produit_id?.id,
          agence_id: values.agence_id?.id,
          referent_id: values.referent_id?.id,
          motif_resilitation_expiration_id:
            values.motif_resilitation_expiration_id?.id ?? null,
        }
        if (body.date_debut_sp) {
          body.date_debut_sp = moment(values.date_debut_sp).format('DD/MM/YYYY')
        }
        if (body.date_fin_sp) {
          body.date_fin_sp = moment(values.date_fin_sp).format('DD/MM/YYYY')
        }
        if (!showRepreneur) {
          body.repreneur = ''
        }
        if (!showAmiable) {
          body.commentaire = ''
        }
        if (body.date_effet_contrat) {
          body.date_effet_contrat = moment(values.date_effet_contrat).format(
            'DD/MM/YYYY',
          )
        }
        if (body.date_renouvellement) {
          body.date_renouvellement = moment(values.date_renouvellement).format(
            'DD/MM',
          )
        }
        if (body.date_resiliation_expiration) {
          body.date_resiliation_expiration = moment(
            values.date_resiliation_expiration,
          ).format('DD/MM/YYYY')
        }

        body.est_precontentieux = body.est_precontentieux

        if (body.est_precontentieux) {
          body.precontentieux_date_mise_en_demeure =
            body.precontentieux_date_mise_en_demeure
              ? moment(values.precontentieux_date_mise_en_demeure).format(
                  'DD/MM/YYYY',
                )
              : undefined

          body.precontentieux_date_suspension_garantie =
            body.precontentieux_date_suspension_garantie
              ? moment(values.precontentieux_date_suspension_garantie).format(
                  'DD/MM/YYYY',
                )
              : null
        } else {
          body.precontentieux_date_mise_en_demeure = null
          body.precontentieux_date_suspension_garantie = null
        }

        // Reformatage des produits nécéssaire !
        produitSelected?.odr?.champs?.forEach(x => {
          if (x.widget === 'date') {
            // Dans le cas des dates
            const newChampsAdditionnels = []
            // On itère sur tous les champs additionnels
            body.champs_additionnels.forEach((y, index) => {
              // on remplace la clé qui est en widget date par le bon format
              newChampsAdditionnels.push({
                ...y,
                [x.key]: moment(y[x.key]).format('DD/MM/YYYY'),
              })
            })
            // On assigne
            body.champs_additionnels = newChampsAdditionnels
          }
        })
        onSubmit?.(body)
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {/* Identification du contrat */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Identification" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="reagi_id"
                      label="Id RéAGI"
                      error={!!allErrors?.reagi_id?.message}
                      helperText={allErrors?.reagi_id?.message}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <ProduitsInputControlled
                      name="produit_id"
                      control={control}
                      error={!!allErrors.produit_id}
                      helperText={allErrors.produit_id?.message}
                    />
                  </Grid>
                  <Grid item xs={12} className={'form-grid-dense'}>
                    <ChampsAdditionnels
                      control={control}
                      setValue={setValue}
                      allErrors={allErrors}
                      odr={produitSelected?.odr}
                      multi={produitSelected?.multi}
                      defaultValue={contrat?.champs_additionnels ?? ''}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {/* Situation */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Situation" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    {devis ? null : (
                      <ContratEtatsInputControlled
                        control={control}
                        error={!!allErrors?.etat?.message}
                        helperText={allErrors?.etat?.message}
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12} display="flex" alignItems="center">
                    <BooleanInputControlled
                      control={control}
                      label={'Durée ferme ?'}
                      name={'duree_ferme'}
                      error={!!allErrors?.duree_ferme?.message}
                      helperText={allErrors?.duree_ferme?.message}
                    />{' '}
                    Durée ferme
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <DatePickerInputControlled
                      name="date_effet_contrat"
                      control={control}
                      label="Date d'effet"
                      error={!!allErrors?.date_effet_contrat?.message}
                      helperText={allErrors?.date_effet_contrat?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <DayMonthInputControlled
                      name="date_renouvellement"
                      control={control}
                      label="Date de renouvellement"
                      error={!!allErrors?.date_renouvellement?.message}
                      helperText={allErrors?.date_renouvellement?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <DatePickerInputControlled
                      name="date_resiliation_expiration"
                      control={control}
                      label="Date de résiliation / expiration"
                      error={!!allErrors?.date_resiliation_expiration?.message}
                      helperText={
                        allErrors?.date_resiliation_expiration?.message
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <MotifResiliationsInputControlled
                      control={control}
                      error={
                        !!allErrors?.motif_resilitation_expiration_id?.message
                      }
                      helperText={
                        allErrors?.motif_resilitation_expiration_id?.message
                      }
                    />
                  </Grid>
                  {showRepreneur ? (
                    <Grid item md={6} xs={12} className={'form-grid-dense'}>
                      <RepreneursInputControlled
                        control={control}
                        name="repreneur"
                        label="Repreneur"
                        error={!!allErrors?.repreneur?.message}
                        helperText={allErrors?.repreneur?.message}
                        size="small"
                      />
                    </Grid>
                  ) : null}
                  {showAmiable ? (
                    <Grid item md={6} xs={12} className={'form-grid-dense'}>
                      <TextInputControlled
                        control={control}
                        name="commentaire"
                        label="Commentaire"
                        error={!!allErrors?.commentaire?.message}
                        helperText={allErrors?.commentaire?.message}
                        size="small"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Prime */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Prime" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <PrimeTypesInputControlled
                      control={control}
                      error={!!allErrors?.prime_type?.message}
                      helperText={allErrors?.prime_type?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <PrimeFractionnementsInputControlled
                      control={control}
                      error={!!allErrors?.prime_fractionnement?.message}
                      helperText={allErrors?.prime_fractionnement?.message}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Gestion */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Gestion" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <AgencesInputControlled
                      name="agence_id"
                      control={control}
                      error={!!allErrors.agence_id}
                      helperText={allErrors.agence_id?.message}
                    />
                  </Grid>
                  {/* Si utilisateur normal, masque simplement le champ du Referent lors de l'édition d'un contrat */}
                  {user.is_superuser && editing ? (
                    <Grid item md={6} xs={12} className={'form-grid-dense'}>
                      <UsersInputControlled
                        control={control}
                        name={'referent_id'}
                        error={!!allErrors.referent_id}
                        helperText={allErrors.referent_id?.message}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className={'form-grid-dense'}
                      style={{display: 'none'}}
                    >
                      <UsersInputControlled
                        control={control}
                        name={'referent_id'}
                        error={!!allErrors.referent_id}
                        helperText={allErrors.referent_id?.message}
                      />
                    </Grid>
                  )}
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <DatePickerInputControlled
                      name="date_debut_sp"
                      control={control}
                      label="Date début SP"
                      error={!!allErrors?.date_debut_sp?.message}
                      helperText={allErrors?.date_debut_sp?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <DatePickerInputControlled
                      name="date_fin_sp"
                      control={control}
                      label="Date fin SP"
                      error={!!allErrors?.date_fin_sp?.message}
                      helperText={allErrors?.date_fin_sp?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="decision_sp_n"
                      label="Décision SP année n"
                      multiline
                      rows={2}
                      error={!!allErrors?.decision_sp_n?.message}
                      helperText={allErrors?.decision_sp_n?.message}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="decision_sp_n_m_1"
                      label="Décision SP année n-1"
                      multiline
                      rows={2}
                      error={!!allErrors?.decision_sp_n_m_1?.message}
                      helperText={allErrors?.decision_sp_n_m_1?.message}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>

          {/* Précontentieux */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 3,
            }}
          >
            <FormControlLabel
              control={
                <Controller
                  name="est_precontentieux"
                  control={control}
                  defaultValue={false}
                  render={({field: {value, onChange}}) => (
                    <Checkbox
                      checked={value}
                      onChange={e => {
                        e.target.checked
                          ? setIsPrecontentieuxChecked(true)
                          : setIsPrecontentieuxChecked(false)
                        onChange(e.target.checked)
                      }}
                    />
                  )}
                />
              }
              label="Précontentieux ?"
            />
          </Box>

          {isPrecontentieuxChecked && (
            <Box sx={{mb: 3}}>
              <Card>
                <CardHeader title="Précontentieux" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className={'form-grid-dense'}>
                      <Controller
                        name="precontentieux_date_mise_en_demeure"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <DesktopDatePicker
                            {...field}
                            label="Date Mise en demeurre"
                            inputFormat="dd/MM/yyyy"
                            renderInput={params => (
                              <TextField
                                {...params}
                                margin="dense"
                                size="small"
                                fullWidth
                                error={
                                  !!errors?.precontentieux_date_mise_en_demeure
                                    ?.message
                                }
                                helperText={
                                  errors?.precontentieux_date_mise_en_demeure
                                    ?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} className={'form-grid-dense'}>
                      <Controller
                        name="precontentieux_date_suspension_garantie"
                        control={control}
                        defaultValue=""
                        render={({field}) => (
                          <DesktopDatePicker
                            {...field}
                            label="Date Suspension des garanties"
                            inputFormat="dd/MM/yyyy"
                            renderInput={params => (
                              <TextField
                                {...params}
                                margin="dense"
                                size="small"
                                fullWidth
                                error={
                                  !!errors
                                    ?.precontentieux_date_suspension_garantie
                                    ?.message
                                }
                                helperText={
                                  errors
                                    ?.precontentieux_date_suspension_garantie
                                    ?.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 3,
            }}
          >
            {editing ? (
              <EditButton>Modifier</EditButton>
            ) : (
              <AddButton>{submitLabel}</AddButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default ContratForm

function generateEmptyEntryFromOdr(odr) {
  const computedDefaultValues = {}
  odr?.champs?.map(x => {
    computedDefaultValues[x.key] = ''
  })
  return computedDefaultValues
}

function ChampsAdditionnelsWidget({index, champ, control, allErrors}) {
  const commonProps = {
    name: `champs_additionnels[${index}].[${champ.key}]`,
    label: champ.nom,
    control: control,
    error: !!allErrors?.champs_additionnels?.[index]?.[champ.key]?.message,
    helperText: allErrors?.champs_additionnels?.[index]?.[champ.key]?.message,
  }
  if (champ.widget === 'auto_marque') {
    return <MarquesAutoInputControlled {...commonProps} />
  }
  if (champ.widget === 'date') {
    return <DatePickerInputControlled {...commonProps} />
  }
  return <TextInputControlled {...commonProps} />
}

const ChampsAdditionnels = ({
  odr,
  multi = false,
  allErrors,
  defaultValue = '',
  control,
  setValue,
}) => {
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'champs_additionnels',
  })

  const currentOdr = useRef()

  const defaultNewEntry = useMemo(() => {
    return generateEmptyEntryFromOdr(odr)
  }, [defaultValue, odr])

  useEffect(() => {
    if (
      !defaultValue ||
      defaultValue === '' ||
      (!!currentOdr.current && currentOdr.current != odr)
    ) {
      currentOdr.current = odr
      const computedDefaultValues = generateEmptyEntryFromOdr(odr)
      setValue('champs_additionnels', [computedDefaultValues])
    }
    if (currentOdr.current != odr) {
      currentOdr.current = odr
    }
  }, [defaultValue, odr])

  if (!odr) return null
  return (
    <Grid container spacing={2}>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            {multi ? (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            ) : null}
            {multi ? <Grid item xs={1} /> : null}
            <Grid item xs={multi ? 10 : 12}>
              <Grid container spacing={2}>
                {odr.champs.map(x => (
                  <Grid item md={6} xs={12} key={x.key}>
                    <ChampsAdditionnelsWidget
                      champ={x}
                      index={k}
                      allErrors={allErrors}
                      control={control}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {multi ? (
              <Grid item xs={1}>
                <CloseButton
                  onClick={event => {
                    event.preventDefault()
                    remove(k)
                  }}
                />
              </Grid>
            ) : null}
          </Fragment>
        )
      })}
      {multi ? (
        <>
          <Grid item xs={11}>
            <AddButton
              size="small"
              onClick={event => {
                event.preventDefault()
                append(defaultNewEntry)
              }}
            >
              Ajouter
            </AddButton>
          </Grid>
        </>
      ) : null}
    </Grid>
  )
}
