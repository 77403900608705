import {useEffect, useState} from 'react'
import {Box, Button, CircularProgress} from '@mui/material'
import {useNavigate, useParams} from 'react-router'
import PageTemplate from 'src/components/templates/PageTemplate'
import {useAuth} from 'src/hooks/useAuth'
import {ContratDetailsInfo} from '.'
import EditButton from 'src/components/buttons/EditButton'
import useSnackbar from 'src/hooks/useSnackbar'
import {useDeleteContrat} from 'src/hooks/api-hooks'
import ConfirmDialog from 'src/components/ConfirmDialog'
import {
  Engineering,
  DriveFileMove,
  Delete,
  DeleteForever,
} from '@mui/icons-material'
import ContratTransferDialog from '../../../components/dialogs/ContratTransferDialog'
import {useContrat, useOneDevis} from 'src/hooks/api-hooks'
import FusionDialog from '../../../components/FusionDialog'

const ContratDetails = ({devis = false}) => {
  const hook = devis ? useOneDevis : useContrat
  const [contrat, setContrat] = useState(null)
  const [fusionOpen, setFusionOpen] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const {id} = useParams()
  const {user} = useAuth()

  const {isLoading: deleteLoading, mutate} = useDeleteContrat(id)
  const navigate = useNavigate()

  const [transferOpen, setTransferOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const {isLoading, data, isIdle} = hook(id)

  useEffect(() => {
    setContrat(data)
  }, [data])

  const {showSuccess, ShowError} = useSnackbar()
  const handleDelete = () => {
    mutate(contrat.id, {
      onSuccess: () => {
        setDeleteDialog(false)
        navigate('/contrats')
      },
      onError: () => setDeleteDialog(false),
    })
  }

  const rightActions = (
    <>
      {user.is_superuser && (
        <>
          <Button
            onClick={() => setDeleteDialog(true)}
            startIcon={<DeleteForever />}
            variant={'contained'}
            color="error"
            style={{marginRight: 10}}
          >
            Supprimer
          </Button>
          <ConfirmDialog
            title={`Voulez-vous vraiment supprimer le contrat ?`}
            loading={deleteLoading}
            open={deleteDialog}
            onCancel={() => setDeleteDialog(false)}
            onConfirm={handleDelete}
          />
        </>
      )}
      {user.is_superuser && !devis && (
        <Button
          onClick={() => setTransferOpen(true)}
          startIcon={<DriveFileMove />}
          variant={'contained'}
          style={{marginRight: 10}}
        >
          Transférer
        </Button>
      )}
      <ContratTransferDialog
        open={transferOpen}
        onCancel={() => setTransferOpen(false)}
        contrat={contrat}
        client={contrat?.client}
        onSubmit={data => {
          setContrat(data)
          setTransferOpen(false)
        }}
      />

      {user.has_fusion_access && (
        <Button
          onClick={() => setFusionOpen(true)}
          startIcon={<Engineering />}
          variant={'contained'}
        >
          Créer une fusion
        </Button>
      )}

      <FusionDialog
        open={fusionOpen}
        onCancel={() => setFusionOpen(false)}
        type={'Contrat'}
        client={contrat?.client}
        initialVariables={{contrat: contrat?.id}}
      />
      <EditButton to={`/${devis ? 'devis' : 'contrats'}/${id}/edit`}>
        Modifier
      </EditButton>
    </>
  )

  return (
    <PageTemplate
      title={`Consultation d'un ${devis ? 'devis' : 'contrat'}`}
      sections={[
        {title: 'Contrats', path: '/contrats'},
        {title: 'Consultation', current: true},
      ]}
      rightActions={rightActions}
      data-testid="page-contrats-details"
    >
      {Boolean(isLoading || isIdle) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: 300}}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ContratDetailsInfo contrat={contrat} setContrat={setContrat} />
      )}
    </PageTemplate>
  )
}

export default ContratDetails
