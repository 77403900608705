import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from '@mui/material'
import {useFieldArray} from 'react-hook-form'
import AddButton from 'src/components/buttons/AddButton'
import CloseButton from 'src/components/buttons/CloseButton'
import AutocompleteInputControlled from 'src/components/controlled/AutocompleteInputControlled'
import DeviseInputControlled from 'src/components/controlled/DeviseInputControlled'
import TextInputControlled from 'src/components/controlled/TextInputControlled'
import * as yup from 'yup'

const OperationSchema = yup.object().shape({
  type: yup.mixed().required().label('Type'),
  personne: yup.string().required().label('Personne'),
  objet: yup.string().required().label('Objet'),
  montant: yup.number().label('Montant'),
})

const OperationsField = ({control, errors}) => {
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'operations',
  })
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Personne</TableCell>
            <TableCell>Objet</TableCell>
            <TableCell>Montant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell width="20%">
                <AutocompleteInputControlled
                  options={['retrait', 'remise']}
                  control={control}
                  name={`operations.${index}.type`}
                  error={!!errors?.operations?.[index]?.type}
                  helperText={errors?.operations?.[index]?.type?.message}
                  label=""
                  variant="standard"
                />
              </TableCell>
              <TableCell width="20%">
                <TextInputControlled
                  control={control}
                  name={`operations.${index}.personne`}
                  error={!!errors?.operations?.[index]?.personne}
                  helperText={errors?.operations?.[index]?.personne?.message}
                  label=""
                  variant="standard"
                />
              </TableCell>
              <TableCell width="30%">
                <TextInputControlled
                  control={control}
                  name={`operations.${index}.objet`}
                  error={!!errors?.operations?.[index]?.objet}
                  helperText={errors?.operations?.[index]?.objet?.message}
                  label=""
                  variant="standard"
                />
              </TableCell>
              <TableCell width="20%">
                <DeviseInputControlled
                  control={control}
                  name={`operations.${index}.montant`}
                  error={!!errors?.operations?.[index]?.montant}
                  helperText={errors?.operations?.[index]?.montant?.message}
                  label=""
                  variant="standard"
                />
              </TableCell>
              <TableCell width="10%">
                <CloseButton
                  onClick={event => {
                    event.preventDefault()
                    remove(index)
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={4}>
              <AddButton
                size="small"
                onClick={event => {
                  event.preventDefault()
                  append({
                    type: 'retrait',
                    personne: '',
                    objet: '',
                    montant: 0,
                  })
                }}
              >
                Ajouter une opération
              </AddButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export {OperationsField as default, OperationSchema}
