import {useEffect, useMemo, useRef, useState} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import TextInput from '../inputs/TextInput'
import SizeFormatter from '../SizeFormatter'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import {useDeleteFile, usePartialEditFile} from '../../hooks/api-hooks'
import {LoadingButton} from '@mui/lab'
import {Add, Delete, Download} from '@mui/icons-material'
import ConfirmDialog from '../ConfirmDialog'
import moment from 'moment'
import {fetchFile} from '../../services/crm-api'
import useAuth from 'src/hooks/useAuth'

const PreviewMedia = ({url, width}) => {
  console.log('RENDER', url)
  const [numPages, setNumPages] = useState(null)
  if (!url) {
    return null
  }
  const type = url.split('.').pop().toLowerCase()

  if (['png', 'jpg', 'jpeg'].includes(type)) {
    return <img src={url} style={{width, alignSelf: 'center'}} />
  }
  return (
    <Document
      loading={'Chargement de la prévisualisation'}
      file={{
        url: url,
        withCredentials: true,
      }}
      onLoadSuccess={({numPages}) => {
        setNumPages(numPages)
      }}
      onLoadError={console.error}
    >
      {Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
        .map(page => (
          <Page pageNumber={page} width={width} />
        ))}
    </Document>
  )
}

export default function PreviewFile({file, onClose = () => {}}) {
  const [modal, setModal] = useState()
  const [editState, setEditState] = useState()
  const [filename, setFilename] = useState(file.name)
  const {mutate, isLoading: isLoadingEdit} = usePartialEditFile()
  const {mutate: deleteFile, isLoading: isLoadingDelete} = useDeleteFile()
  const [previewUrl, setPreviewUrl] = useState(file.preview)
  const isEditing = !!editState
  
  const {user} = useAuth()

  const fetchTimer = useRef()
  useEffect(() => {
    return () => {
      if (fetchTimer.current) {
        clearTimeout(fetchTimer.current)
      }
    }
  }, [file, fetchTimer, previewUrl])

  const fetchPreviewUrl = () => {
    return fetchFile(file.id).then(data => {
      if (!data?.preview) {
        clearTimeout(fetchTimer.current)
        fetchTimer.current = setTimeout(fetchPreviewUrl, 1000)
      } else {
        setPreviewUrl(data.preview)
      }
    })
  }

  const currentFile = useRef()
  useEffect(() => {
    if (!file.preview) {
      fetchPreviewUrl()
    }
    if (currentFile.current != file) {
      setEditState()
      currentFile.current = file
      setFilename(file.name)
    }
    setPreviewUrl(file.preview)
  }, [file])

  const [numPages, setNumPages] = useState(null)

  const confirmationOpen = modal === 'delete'
  const confirmationForGoodOpen = modal === 'delete-for-good'
  const confirmationRestoreOpen = modal === 'restore'
  const bigPreviewOpen = modal === 'big-preview'

  const isLoading = isLoadingEdit || isLoadingDelete

  const handleUserKeyPress = event => {
    if (event.code === 'Escape') {
      setEditState(undefined)
      setFilename(file.name)
    }

    if (event.code === 'Enter') {
      mutate(
        {id: file.id, body: {name: editState + '.' + extension}},
        {
          onSettled: () => {
            setFilename(editState + '.' + extension)
            setEditState(undefined)
          },
        },
      )
    }
  }

  const previewComponent = useMemo(
    () => <PreviewMedia width={200} url={previewUrl} />,
    [previewUrl, file],
  )

  const bigPreviewComponent = useMemo(
    () => <PreviewMedia width={800} url={previewUrl} />,
    [previewUrl, file],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const filenameWithoutExtension = filename.split('.').slice(0, -1).join('.')
  const extension = filename.split('.').pop()
  const editInputRef = useRef()
  useEffect(() => {
    if (editInputRef.current) {
      editInputRef.current.select()
    }
  }, [isEditing])

  return (
    <Box
      borderLeft={'1px solid black'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box p={1}>
        {isEditing ? (
          <TextInput
            value={editState}
            onChange={event => setEditState(event.nativeEvent.target.value)}
            inputRef={editInputRef}
            disabled={isLoading}
          />
        ) : (
          <Typography
            variant={'h6'}
            onDoubleClick={() => setEditState(filenameWithoutExtension)}
            style={{wordWrap: 'break-word'}}
          >
            {filename}
          </Typography>
        )}

        <Typography variant={'caption'}>
          <SizeFormatter sizeInBytes={file.size} />
          <Link
            component={Button}
            target={'_blank'}
            href={file.file_infos}
            startIcon={<Download />}
            fullWidth={true}
            xs={{mb: 1}}
          >
            Télécharger
          </Link>
        </Typography>
      </Box>
      {Boolean(previewUrl) ? (
        <Box
          overflow={'scroll'}
          border={'1px solid black'}
          display={'flex'}
          justifyContent={'center'}
          backgroundColor={'gray'}
          flex={1}
          padding={1}
          maxHeight={250}
          onClick={() => setModal('big-preview')}
          style={{cursor: 'pointer'}}
        >
          {previewComponent}
        </Box>
      ) : (
        <CircularProgress sx={{alignSelf: 'center', justifySelf: 'center'}} />
      )}
      <Dialog
        open={bigPreviewOpen}
        onClose={() => setModal(undefined)}
        fullScreen
      >
        <DialogTitle>
          <Box display={'flex'} justifyContent={'space-between'}>
            {file?.name} (<SizeFormatter sizeInBytes={file.size} />)
            <Button onClick={() => setModal(undefined)} variant={'contained'}>
              Fermer
            </Button>
          </Box>
        </DialogTitle>
        <Box
          overflow={'scroll'}
          border={'1px solid black'}
          display={'flex'}
          justifyContent={'center'}
          backgroundColor={'gray'}
          flex={1}
          padding={1}
        >
          {bigPreviewComponent}
        </Box>
        <DialogActions>
          <Link
            component={Button}
            target={'_blank'}
            href={file.file_infos}
            startIcon={<Download />}
            fullWidth={true}
            xs={{mb: 1}}
          >
            Télécharger
          </Link>
          <Button onClick={() => setModal(undefined)} variant={'contained'}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={1}>
        {Boolean(file.trash_date) ? (
          <>
            <LoadingButton
              variant={'contained'}
              color={'success'}
              startIcon={<Add />}
              fullWidth={true}
              loading={isLoading}
              onClick={() => setModal('restore')}
              sx={{mb: 2}}
            >
              Restaurer
            </LoadingButton>
            <LoadingButton
              variant={'contained'}
              color={'error'}
              startIcon={<Delete />}
              fullWidth={true}
              loading={isLoading}
              onClick={() => setModal('delete-for-good')}
            >
              Supprimer
            </LoadingButton>
          </>
        ) : (
          user.is_superuser && (
              <LoadingButton
                variant={'contained'}
                color={'error'}
                startIcon={<Delete />}
                fullWidth={true}
                loading={isLoading}
                onClick={() => setModal('delete')}
              >
                Corbeille
              </LoadingButton>
          )
        )}
        {confirmationOpen ? (
          <ConfirmDialog
            loading={isLoading}
            title="Confirmation de mise à la corbeille"
            onConfirm={() => {
              mutate(
                {
                  id: file.id,
                  body: {
                    trash_date: moment().toISOString(),
                  },
                },
                {
                  onSuccess: () => {
                    setModal(undefined)
                    onClose(file)
                  },
                },
              )
            }}
            onCancel={() => {
              setModal(undefined)
            }}
            open={confirmationOpen}
          >
            Vous êtes sur le point de mettre à la corbeille le fichier{' '}
            <b>{file.name}</b>
          </ConfirmDialog>
        ) : undefined}

        {confirmationForGoodOpen ? (
          <ConfirmDialog
            loading={isLoading}
            title="Confirmation de suppression définitive"
            onConfirm={() => {
              deleteFile(file.id, {
                onSuccess: () => {
                  setModal(undefined)
                  onClose(file)
                },
              })
            }}
            onCancel={() => {
              setModal(undefined)
            }}
            open={confirmationForGoodOpen}
          >
            Vous êtes sur le point de supprimer <b>définitivement</b> le fichier{' '}
            <b>{file.name}</b>
          </ConfirmDialog>
        ) : undefined}

        {confirmationRestoreOpen ? (
          <ConfirmDialog
            loading={isLoading}
            title="Confirmation de restauration"
            onConfirm={() => {
              mutate(
                {
                  id: file.id,
                  body: {
                    trash_date: null,
                  },
                },
                {
                  onSuccess: () => {
                    setModal(undefined)
                    onClose(file)
                  },
                },
              )
            }}
            onCancel={() => {
              setModal(undefined)
            }}
            open={confirmationRestoreOpen}
          >
            Vous êtes sur le point de restaurer le fichier <b>{file.name}</b>
          </ConfirmDialog>
        ) : undefined}
      </Box>
    </Box>
  )
}
