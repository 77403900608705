import {useState} from 'react'
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
  Button,
} from '@mui/material'
import Label from 'src/components/Label'
import LockIcon from '@mui/icons-material/Lock'
import ChangePasswordDialog from './ChangePasswordDialog'

const UserCard = ({user, ...props}) => {
  const [open, setOpen] = useState(false)

  const role = item => {
    if (item.is_superuser) {
      return 'Admin'
    }
    if (item.is_staff) {
      return 'Utilisateur'
    }
    return 'Lecture Seule'
  }

  return (
    <>
      <Card {...props}>
        <CardHeader title="Détails" />
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-email"
                >
                  {user.email}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Rôle
                </Typography>
              </TableCell>
              <TableCell data-testid="user-role">
                {role(user) === 'Admin' ? (
                  <Label color="error">{role(user)}</Label>
                ) : role(user) === 'Lecture Seule' ? (
                  <Label color="warning">{role(user)}</Label>
                ) : (
                  role(user)
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Actif
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-status"
                >
                  {user.is_active ? 'Actif' : 'Désactivé'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  data-testid="user-nom"
                >
                  Nom
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-lastname"
                >
                  {user.lastname}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Prénom
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-firstname"
                >
                  {user.firstname}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Agence
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-agence"
                >
                  {user.agence.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Service
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-service"
                >
                  {user.service?.name}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Fonction
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  data-testid="user-fonction"
                >
                  {user.fonction?.name}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'flex',
            flexDirection: 'column',
            p: 1,
          }}
        >
          <Button
            color="primary"
            startIcon={<LockIcon fontSize="small" />}
            variant="text"
            onClick={() => {
              setOpen(true)
            }}
          >
            Changer le mot de passe
          </Button>
        </Box>
      </Card>
      <ChangePasswordDialog user={user} open={open} setOpen={setOpen} />
    </>
  )
}

export default UserCard
