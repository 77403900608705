import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {History, AccountBalance, ReceiptLong, FilePresent} from '@mui/icons-material'
import CardSubtitle from 'src/components/labels/CardSubtitle'


const resolver = yupResolver(
  yup.object().shape({
    adresse_principale_1: yup.string().label("L'adresse").required().max(50),
    adresse_principale_code_postal: yup
      .string()
      .label('Code postal')
      .required()
      .max(10),
    adresse_principale_ville: yup.string().label('Ville').required().max(30),
    adresse_principale_pays: yup.string().label('Pays').required().max(30),
  }),
)

const DisplayBody = ({
  client,
  setEditing,
  editing,
  isLoading,
  modificationDisabled,
}) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell valign='center' align='center'>  
              <AccountBalance title={"Compte"} /><br/>Compte
            </TableCell>
            <TableCell>
              Ajout règlement ID 217
            </TableCell>
            <TableCell>
              Cédric V. 
              20/04/23
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell valign='center' align='center'>
            <ReceiptLong title={"Compte"} /><br/>Quittance
            </TableCell>
            <TableCell>
              Modification Quittance-A000019-230417
            </TableCell>
            <TableCell>
              Cédric V. 
              17/04/23
            </TableCell>
          </TableRow>

          
          <TableRow>
            <TableCell valign='center' align='center'>
              <FilePresent title={"Document"} /><br/>Documents
            </TableCell>
            <TableCell>
              Ajout "Quittance-EX123.pdf"
            </TableCell>
            <TableCell>
              Nabila D.
              03/04/23
            </TableCell>
          </TableRow>          
        </TableBody>
      </Table>
    </>
  )
}

const ClientActivityCard = ({client, modificationDisabled, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Activités" avatar={<History />} />
      <Divider />      
        <DisplayBody />      
    </Card>
  )
}

export default ClientActivityCard
