import {TextField as MuiTextField} from '@mui/material'
import {Controller} from 'react-hook-form'
import {useForm} from './Form'

// Should use technics here : https://codesandbox.io/s/rhf-mui-refreshing-filled-state-wtti9?file=/src/example-refresh-label.js:286-333
// to make better perf
const TextField = ({
  name,
  defaultValue = '',
  readOnly = false,
  isError,
  errorMessage,
  ...props
}) => {
  const {errors, isLoading, control} = useForm()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field}) => (
        <MuiTextField
          id={`${name}-field`}
          helperText={errors[name]?.message || errorMessage}
          disabled={isLoading}
          variant="outlined"
          fullWidth
          error={Boolean(errors[name]) || isError}
          InputProps={{
            readOnly: readOnly,
            inputProps: {
              'data-testid': `field-${name}`,
            },
          }}
          {...props}
          {...field}
          value={field.value ?? ''}
        />
      )}
    />
  )
  // const {ref: inputRef, ...inputProps} = register(name)

  // return (
  //   <MuiTextField
  //     InputProps={{
  //       readOnly: readOnly,
  //       inputProps: {
  //         'data-testid': `field-${name}`,
  //       },
  //     }}
  //     id={`${name}-field`}
  //     fullWidth
  //     name={name}
  //     inputRef={inputRef}
  //     error={Boolean(errors[name])}
  //     helperText={errors[name]?.message}
  //     variant="outlined"
  //     disabled={isLoading}
  //     {...props}
  //     {...inputProps}
  //   />
  // )
}

export default TextField
