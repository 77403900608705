import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material'

import AddButton from '../buttons/AddButton'
import EditButton from '../buttons/EditButton'
import OperationDiverseForm from '../forms/OperationDiverseForm'
import DeleteOperationButton from './DeleteOperationButton'
import useOperationDialog from './useOperationDialog'

const OperationDiverseFormDialog = ({
  open = true,
  operationDiverse,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) => {
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(operationDiverse?.id)

  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      create(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="operation-diverse-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="operation-diverse-dialog">
        {isEditing ? 'Modifier' : 'Ajouter'} une operation diverse
      </DialogTitle>
      <Divider />
      <DialogContent>
        <OperationDiverseForm
          client={client}
          operationDiverse={operation}
          onSubmit={handleSubmit}
          id="operation-diverse-dialog-form"
        />
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          'Chargement ...'
        ) : (
          <DeleteOperationButton
            sx={{mr: 'auto'}}
            operationId={operationDiverse?.id}
            onDelete={handleClose}
          />
        )}
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        {isEditing ? (
          <EditButton
            form="operation-diverse-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Modifier
          </EditButton>
        ) : (
          <AddButton
            form="operation-diverse-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default OperationDiverseFormDialog
