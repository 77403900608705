import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

const PermissionHidder = ({mainRole, ...props}) => {
  const {children} = props
  const {user} = useAuth()

  switch(mainRole) {
    case 'is_superuser':
      return user.is_superuser ? (<>{children}</>) : ""
    break;
    case 'is_staff':
      return user.is_staff ? (<>{children}</>) : ""
    break;
    default:
      return null
    break;
  }
}

PermissionHidder.propTypes = {
  children: PropTypes.node,
}

export default PermissionHidder
