import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {useFusions} from 'src/hooks/api-hooks'

const AgencesInput = ({
  label = 'Patron de conception',
  error,
  helperText,
  margin = 'dense',
  size = 'small',
  type,
  ...props
}) => {
  const params = type ? {type} : undefined
  const {
    isLoading: loading,
    data: options,
    isError: loadingError,
    error: errorException,
  } = useFusions(params)

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name ?? ''}
      options={options ?? []}
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorException.message : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default AgencesInput
