import React from 'react'
import {Grid} from '@mui/material'

const Header = ({
  className,
  children,
  sections = [],
  title,
  rightActions,
  ...props
}) => {
  const clonedChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {sections, title})
  })

  return (
    <Grid container justifyContent="space-between" spacing={2} {...props}>
      <Grid item>{clonedChildren}</Grid>
      <Grid item>{rightActions}</Grid>
    </Grid>
  )
}

export default Header
