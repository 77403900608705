import {useState} from 'react'
import {Button} from '@mui/material'
import ConfirmDialog from '../ConfirmDialog'
import {useDeleteOperation} from 'src/hooks/api-hooks'

const DeleteOperationButton = ({
  onLoading,
  operationId,
  onDelete,
  ...props
}) => {
  const {mutate: remove, isLoading} = useDeleteOperation()
  const [openDialog, setOpenDialog] = useState(false)

  if (!operationId) {
    return null
  }
  const handleConfirm = () => {
    remove(operationId)
    onDelete()
  }
  return (
    <>
      <ConfirmDialog
        title={`Voulez-vous vraiment supprimer cette écriture ?`}
        loading={isLoading}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
      />
      <Button
        variant="contained"
        color="error"
        onClick={() => setOpenDialog(true)}
        {...props}
      >
        Effacer
      </Button>
    </>
  )
}

export default DeleteOperationButton
