const countries = [
  {text: 'Réunion', value: 'Réunion'},
  {text: 'France', value: 'France'},
  {text: 'Maurice', value: 'Maurice'},
  {text: 'Afghanistan', value: 'Afghanistan'},
  {text: 'Albanie', value: 'Albanie'},
  {text: 'Antarctique', value: 'Antarctique'},
  {text: 'Algérie', value: 'Algérie'},
  {text: 'Samoa Américaines', value: 'Samoa Américaines'},
  {text: 'Andorre', value: 'Andorre'},
  {text: 'Angola', value: 'Angola'},
  {text: 'Antigua-et-Barbuda', value: 'Antigua-et-Barbuda'},
  {text: 'Azerbaïdjan', value: 'Azerbaïdjan'},
  {text: 'Argentine', value: 'Argentine'},
  {text: 'Australie', value: 'Australie'},
  {text: 'Autriche', value: 'Autriche'},
  {text: 'Bahamas', value: 'Bahamas'},
  {text: 'Bahreïn', value: 'Bahreïn'},
  {text: 'Bangladesh', value: 'Bangladesh'},
  {text: 'Arménie', value: 'Arménie'},
  {text: 'Barbade', value: 'Barbade'},
  {text: 'Belgique', value: 'Belgique'},
  {text: 'Bermudes', value: 'Bermudes'},
  {text: 'Bhoutan', value: 'Bhoutan'},
  {text: 'Bolivie', value: 'Bolivie'},
  {text: 'Bosnie-Herzégovine', value: 'Bosnie-Herzégovine'},
  {text: 'Botswana', value: 'Botswana'},
  {text: 'Île Bouvet', value: 'Île Bouvet'},
  {text: 'Brésil', value: 'Brésil'},
  {text: 'Belize', value: 'Belize'},
  {
    text: "Territoire Britannique de l'Océan Indien",
    value: "Territoire Britannique de l'Océan Indien",
  },
  {text: 'Îles Salomon', value: 'Îles Salomon'},
  {text: 'Îles Vierges Britanniques', value: 'Îles Vierges Britanniques'},
  {text: 'Brunéi Darussalam', value: 'Brunéi Darussalam'},
  {text: 'Bulgarie', value: 'Bulgarie'},
  {text: 'Myanmar', value: 'Myanmar'},
  {text: 'Burundi', value: 'Burundi'},
  {text: 'Bélarus', value: 'Bélarus'},
  {text: 'Cambodge', value: 'Cambodge'},
  {text: 'Cameroun', value: 'Cameroun'},
  {text: 'Canada', value: 'Canada'},
  {text: 'Cap-vert', value: 'Cap-vert'},
  {text: 'Îles Caïmanes', value: 'Îles Caïmanes'},
  {text: 'République Centrafricaine', value: 'République Centrafricaine'},
  {text: 'Sri Lanka', value: 'Sri Lanka'},
  {text: 'Tchad', value: 'Tchad'},
  {text: 'Chili', value: 'Chili'},
  {text: 'Chine', value: 'Chine'},
  {text: 'Taïwan', value: 'Taïwan'},
  {text: 'Île Christmas', value: 'Île Christmas'},
  {text: 'Îles Cocos (Keeling)', value: 'Îles Cocos (Keeling)'},
  {text: 'Colombie', value: 'Colombie'},
  {text: 'Comores', value: 'Comores'},
  {text: 'Mayotte', value: 'Mayotte'},
  {text: 'République du Congo', value: 'République du Congo'},
  {
    text: 'République Démocratique du Congo',
    value: 'République Démocratique du Congo',
  },
  {text: 'Îles Cook', value: 'Îles Cook'},
  {text: 'Costa Rica', value: 'Costa Rica'},
  {text: 'Croatie', value: 'Croatie'},
  {text: 'Cuba', value: 'Cuba'},
  {text: 'Chypre', value: 'Chypre'},
  {text: 'République Tchèque', value: 'République Tchèque'},
  {text: 'Bénin', value: 'Bénin'},
  {text: 'Danemark', value: 'Danemark'},
  {text: 'Dominique', value: 'Dominique'},
  {text: 'République Dominicaine', value: 'République Dominicaine'},
  {text: 'Équateur', value: 'Équateur'},
  {text: 'El Salvador', value: 'El Salvador'},
  {text: 'Guinée Équatoriale', value: 'Guinée Équatoriale'},
  {text: 'Éthiopie', value: 'Éthiopie'},
  {text: 'Érythrée', value: 'Érythrée'},
  {text: 'Estonie', value: 'Estonie'},
  {text: 'Îles Féroé', value: 'Îles Féroé'},
  {text: 'Îles (malvinas) Falkland', value: 'Îles (malvinas) Falkland'},
  {
    text: 'Géorgie du Sud et les Îles Sandwich du Sud',
    value: 'Géorgie du Sud et les Îles Sandwich du Sud',
  },
  {text: 'Fidji', value: 'Fidji'},
  {text: 'Finlande', value: 'Finlande'},
  {text: 'Îles Åland', value: 'Îles Åland'},
  {text: 'Guyane Française', value: 'Guyane Française'},
  {text: 'Polynésie Française', value: 'Polynésie Française'},
  {text: 'Terres Australes Françaises', value: 'Terres Australes Françaises'},
  {text: 'Djibouti', value: 'Djibouti'},
  {text: 'Gabon', value: 'Gabon'},
  {text: 'Géorgie', value: 'Géorgie'},
  {text: 'Gambie', value: 'Gambie'},
  {
    text: 'Territoire Palestinien Occupé',
    value: 'Territoire Palestinien Occupé',
  },
  {text: 'Allemagne', value: 'Allemagne'},
  {text: 'Ghana', value: 'Ghana'},
  {text: 'Gibraltar', value: 'Gibraltar'},
  {text: 'Kiribati', value: 'Kiribati'},
  {text: 'Grèce', value: 'Grèce'},
  {text: 'Groenland', value: 'Groenland'},
  {text: 'Grenade', value: 'Grenade'},
  {text: 'Guadeloupe', value: 'Guadeloupe'},
  {text: 'Guam', value: 'Guam'},
  {text: 'Guatemala', value: 'Guatemala'},
  {text: 'Guinée', value: 'Guinée'},
  {text: 'Guyana', value: 'Guyana'},
  {text: 'Haïti', value: 'Haïti'},
  {text: 'Îles Heard et Mcdonald', value: 'Îles Heard et Mcdonald'},
  {
    text: 'Saint-Siège (état de la Cité du Vatican)',
    value: 'Saint-Siège (état de la Cité du Vatican)',
  },
  {text: 'Honduras', value: 'Honduras'},
  {text: 'Hong-Kong', value: 'Hong-Kong'},
  {text: 'Hongrie', value: 'Hongrie'},
  {text: 'Islande', value: 'Islande'},
  {text: 'Inde', value: 'Inde'},
  {text: 'Indonésie', value: 'Indonésie'},
  {text: "République Islamique d'Iran", value: "République Islamique d'Iran"},
  {text: 'Iraq', value: 'Iraq'},
  {text: 'Irlande', value: 'Irlande'},
  {text: 'Israël', value: 'Israël'},
  {text: 'Italie', value: 'Italie'},
  {text: "Côte d'Ivoire", value: "Côte d'Ivoire"},
  {text: 'Jamaïque', value: 'Jamaïque'},
  {text: 'Japon', value: 'Japon'},
  {text: 'Kazakhstan', value: 'Kazakhstan'},
  {text: 'Jordanie', value: 'Jordanie'},
  {text: 'Kenya', value: 'Kenya'},
  {
    text: 'République Populaire Démocratique de Corée',
    value: 'République Populaire Démocratique de Corée',
  },
  {text: 'République de Corée', value: 'République de Corée'},
  {text: 'Koweït', value: 'Koweït'},
  {text: 'Kirghizistan', value: 'Kirghizistan'},
  {
    text: 'République Démocratique Populaire Lao',
    value: 'République Démocratique Populaire Lao',
  },
  {text: 'Liban', value: 'Liban'},
  {text: 'Lesotho', value: 'Lesotho'},
  {text: 'Lettonie', value: 'Lettonie'},
  {text: 'Libéria', value: 'Libéria'},
  {text: 'Jamahiriya Arabe Libyenne', value: 'Jamahiriya Arabe Libyenne'},
  {text: 'Liechtenstein', value: 'Liechtenstein'},
  {text: 'Lituanie', value: 'Lituanie'},
  {text: 'Luxembourg', value: 'Luxembourg'},
  {text: 'Macao', value: 'Macao'},
  {text: 'Madagascar', value: 'Madagascar'},
  {text: 'Malawi', value: 'Malawi'},
  {text: 'Malaisie', value: 'Malaisie'},
  {text: 'Maldives', value: 'Maldives'},
  {text: 'Mali', value: 'Mali'},
  {text: 'Malte', value: 'Malte'},
  {text: 'Martinique', value: 'Martinique'},
  {text: 'Mauritanie', value: 'Mauritanie'},
  {text: 'Mexique', value: 'Mexique'},
  {text: 'Monaco', value: 'Monaco'},
  {text: 'Mongolie', value: 'Mongolie'},
  {text: 'République de Moldova', value: 'République de Moldova'},
  {text: 'Montserrat', value: 'Montserrat'},
  {text: 'Maroc', value: 'Maroc'},
  {text: 'Mozambique', value: 'Mozambique'},
  {text: 'Oman', value: 'Oman'},
  {text: 'Namibie', value: 'Namibie'},
  {text: 'Nauru', value: 'Nauru'},
  {text: 'Népal', value: 'Népal'},
  {text: 'Pays-Bas', value: 'Pays-Bas'},
  {text: 'Antilles Néerlandaises', value: 'Antilles Néerlandaises'},
  {text: 'Aruba', value: 'Aruba'},
  {text: 'Nouvelle-Calédonie', value: 'Nouvelle-Calédonie'},
  {text: 'Vanuatu', value: 'Vanuatu'},
  {text: 'Nouvelle-Zélande', value: 'Nouvelle-Zélande'},
  {text: 'Nicaragua', value: 'Nicaragua'},
  {text: 'Niger', value: 'Niger'},
  {text: 'Nigéria', value: 'Nigéria'},
  {text: 'Niué', value: 'Niué'},
  {text: 'Île Norfolk', value: 'Île Norfolk'},
  {text: 'Norvège', value: 'Norvège'},
  {text: 'Îles Mariannes du Nord', value: 'Îles Mariannes du Nord'},
  {
    text: 'Îles Mineures Éloignées des États-Unis',
    value: 'Îles Mineures Éloignées des États-Unis',
  },
  {text: 'États Fédérés de Micronésie', value: 'États Fédérés de Micronésie'},
  {text: 'Îles Marshall', value: 'Îles Marshall'},
  {text: 'Palaos', value: 'Palaos'},
  {text: 'Pakistan', value: 'Pakistan'},
  {text: 'Panama', value: 'Panama'},
  {text: 'Papouasie-Nouvelle-Guinée', value: 'Papouasie-Nouvelle-Guinée'},
  {text: 'Paraguay', value: 'Paraguay'},
  {text: 'Pérou', value: 'Pérou'},
  {text: 'Philippines', value: 'Philippines'},
  {text: 'Pitcairn', value: 'Pitcairn'},
  {text: 'Pologne', value: 'Pologne'},
  {text: 'Portugal', value: 'Portugal'},
  {text: 'Guinée-Bissau', value: 'Guinée-Bissau'},
  {text: 'Timor-Leste', value: 'Timor-Leste'},
  {text: 'Porto Rico', value: 'Porto Rico'},
  {text: 'Qatar', value: 'Qatar'},
  {text: 'Roumanie', value: 'Roumanie'},
  {text: 'Fédération de Russie', value: 'Fédération de Russie'},
  {text: 'Rwanda', value: 'Rwanda'},
  {text: 'Sainte-Hélène', value: 'Sainte-Hélène'},
  {text: 'Saint-Kitts-et-Nevis', value: 'Saint-Kitts-et-Nevis'},
  {text: 'Anguilla', value: 'Anguilla'},
  {text: 'Sainte-Lucie', value: 'Sainte-Lucie'},
  {text: 'Saint-Pierre-et-Miquelon', value: 'Saint-Pierre-et-Miquelon'},
  {
    text: 'Saint-Vincent-et-les Grenadines',
    value: 'Saint-Vincent-et-les Grenadines',
  },
  {text: 'Saint-Marin', value: 'Saint-Marin'},
  {text: 'Sao Tomé-et-Principe', value: 'Sao Tomé-et-Principe'},
  {text: 'Arabie Saoudite', value: 'Arabie Saoudite'},
  {text: 'Sénégal', value: 'Sénégal'},
  {text: 'Seychelles', value: 'Seychelles'},
  {text: 'Sierra Leone', value: 'Sierra Leone'},
  {text: 'Singapour', value: 'Singapour'},
  {text: 'Slovaquie', value: 'Slovaquie'},
  {text: 'Viet Nam', value: 'Viet Nam'},
  {text: 'Slovénie', value: 'Slovénie'},
  {text: 'Somalie', value: 'Somalie'},
  {text: 'Afrique du Sud', value: 'Afrique du Sud'},
  {text: 'Zimbabwe', value: 'Zimbabwe'},
  {text: 'Espagne', value: 'Espagne'},
  {text: 'Sahara Occidental', value: 'Sahara Occidental'},
  {text: 'Soudan', value: 'Soudan'},
  {text: 'Suriname', value: 'Suriname'},
  {text: 'Svalbard etÎle Jan Mayen', value: 'Svalbard etÎle Jan Mayen'},
  {text: 'Swaziland', value: 'Swaziland'},
  {text: 'Suède', value: 'Suède'},
  {text: 'Suisse', value: 'Suisse'},
  {text: 'République Arabe Syrienne', value: 'République Arabe Syrienne'},
  {text: 'Tadjikistan', value: 'Tadjikistan'},
  {text: 'Thaïlande', value: 'Thaïlande'},
  {text: 'Togo', value: 'Togo'},
  {text: 'Tokelau', value: 'Tokelau'},
  {text: 'Tonga', value: 'Tonga'},
  {text: 'Trinité-et-Tobago', value: 'Trinité-et-Tobago'},
  {text: 'Émirats Arabes Unis', value: 'Émirats Arabes Unis'},
  {text: 'Tunisie', value: 'Tunisie'},
  {text: 'Turquie', value: 'Turquie'},
  {text: 'Turkménistan', value: 'Turkménistan'},
  {text: 'Îles Turks et Caïques', value: 'Îles Turks et Caïques'},
  {text: 'Tuvalu', value: 'Tuvalu'},
  {text: 'Ouganda', value: 'Ouganda'},
  {text: 'Ukraine', value: 'Ukraine'},
  {
    text: "L'ex-République Yougoslave de Macédoine",
    value: "L'ex-République Yougoslave de Macédoine",
  },
  {text: 'Égypte', value: 'Égypte'},
  {text: 'Royaume-Uni', value: 'Royaume-Uni'},
  {text: 'Île de Man', value: 'Île de Man'},
  {text: 'République-Unie de Tanzanie', value: 'République-Unie de Tanzanie'},
  {text: 'États-Unis', value: 'États-Unis'},
  {text: 'Îles Vierges des États-Unis', value: 'Îles Vierges des États-Unis'},
  {text: 'Burkina Faso', value: 'Burkina Faso'},
  {text: 'Uruguay', value: 'Uruguay'},
  {text: 'Ouzbékistan', value: 'Ouzbékistan'},
  {text: 'Venezuela', value: 'Venezuela'},
  {text: 'Wallis et Futuna', value: 'Wallis et Futuna'},
  {text: 'Samoa', value: 'Samoa'},
  {text: 'Yémen', value: 'Yémen'},
  {text: 'Serbie-et-Monténégro', value: 'Serbie-et-Monténégro'},
  {text: 'Zambie', value: 'Zambie'},
]

export default countries
