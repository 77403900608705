import TextInputControlled from '../controlled/TextInputControlled'

export default function FusionParamTexte({
  name,
  control,
  label,
  multiline = false,
  ...props
}) {
  return (
    <TextInputControlled
      control={control}
      name={name}
      label={label}
      multiline={multiline}
      rows={5}
      {...props}
    />
  )
}
