import {useSnackbar as useOriginalSnackBar} from 'notistack'
import {errorMessage} from 'src/services/crm-api'
const useSnackbar = () => {
  const {enqueueSnackbar} = useOriginalSnackBar()

  const showError = error => {
    enqueueSnackbar(errorMessage(error), {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      variant: 'error',
    })
  }

  // const showErrorIfMessage = error => {
  //   errors = errorResponseToJson(error)
  //   enqueueSnackbar(errorMessage(error), {
  //     anchorOrigin: {
  //       horizontal: 'right',
  //       vertical: 'top',
  //     },
  //     variant: 'error',
  //   })
  // }

  const toast = (message, options = {}) => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'bottom',
        vertical: 'center',
      },
      ...options,
    })
  }

  const showSuccess = message => {
    enqueueSnackbar(message, {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      },
      variant: 'success',
    })
  }
  return {
    showSuccess,
    showError,
    toast,
  }
}

export default useSnackbar
