import {Controller} from 'react-hook-form'
import FormeJuridiqueInput from '../inputs/FormeJuridiqueInput'

export default function FormeJuridiqueInputControlled({
  control,
  name = 'forme_juridique',
  label = 'Forme juridique',
  error,
  helperText,
  defaultValue = '',
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {value, onChange}}) => (
        <FormeJuridiqueInput
          onChange={(event, item) => {
            onChange(item)
          }}
          label={label}
          value={value}
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
