import {useState} from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'

import Form, {ClientAutocomplete, LiaisonsField} from 'src/components/Form'
import {useCreateLiaisons} from 'src/hooks/api-hooks'

const LiaisonsClientsSchema = yup.object().shape({
  client_2_id: yup.string().required().label('Autre client'),
  lien_id: yup.string().required().label('Nature du lien'),
})

const defaultValue = {
  client_2_id: '',
  lien_id: '',
}

const ClientAddLiaisonDialog = ({
  open,
  onClose = () => {},
  onSubmit = () => {},
  modificationDisabled,
  client,
}) => {
  const {isLoading, isError, error, mutate} = useCreateLiaisons(client.id)

  const handleSubmit = values => {
    mutate(values, {onSuccess: () => onClose()})
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth={'md'}
    >
      <Form
        onSubmit={handleSubmit}
        resolver={yupResolver(LiaisonsClientsSchema)}
        loading={isLoading}
      >
        <DialogTitle id="form-dialog-title">
          Ajout d'une liaison vers un autre client
        </DialogTitle>
        {isError && (
          <Alert color="error">Une erreur est survenue: {error.message} </Alert>
        )}
        <DialogContent>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={12} md={6}>
              <ClientAutocomplete
                name={`client_2_id`}
                label="Autre client"
                defaultValue={defaultValue.client_2_id}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LiaisonsField defaultValue={defaultValue.lien_id} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={isLoading}>
            Annuler
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isLoading || modificationDisabled}
          >
            Ajouter
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ClientAddLiaisonDialog
