import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  InputAdornment,
} from '@mui/material'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm, Controller} from 'react-hook-form'
import * as yup from 'yup'

import {useMemo} from 'react'

const schema = yup.object().shape({
  operation: yup.mixed().label('Ecriture').required(),
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0)
    .when(['operation', 'credit'], (operation, credit, schema) =>
      schema.max(
        Math.min(
          Math.abs(parseFloat(operation.montant_non_affecte)),
          parseFloat(credit),
        ),
      ),
    )
    .required(),
})

const AffectationForm = ({onSubmit, operation, operations = [], ...props}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)})

  const creditDepart = parseFloat(operation.montant_non_affecte)
  const restant = Math.max(0, creditDepart - (watch('montant') ?? 0))

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.credit_id = operation.id
        body.debit_id = values.operation.id
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('credit_id')} value={operation.id} />
      <input type="hidden" {...register('credit')} value={creditDepart} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" component="span">
            {restant}€ restant
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="operation"
            control={control}
            defaultValue={null}
            render={({field: {value, onChange}}) => (
              <Autocomplete
                onChange={(event, item) => {
                  console.log(item)
                  const max = Math.min(
                    creditDepart,
                    Math.abs(parseFloat(item?.montant_non_affecte ?? 0)),
                  )
                  onChange(item)
                  if (!!max) {
                    setValue('montant', max)
                  }
                }}
                value={value}
                options={operations}
                getOptionLabel={option =>
                  `${Math.abs(option.montant_non_affecte)}€ - ${
                    option.libelle
                  }` ?? ''
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Ecriture"
                    size="small"
                    margin="dense"
                    error={!!errors?.operation?.message}
                    helperText={errors?.operation?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            defaultValue={0}
            render={({field}) => (
              <TextField
                {...field}
                type="number"
                margin="dense"
                size="small"
                fullWidth
                label="Montant"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                error={!!errors?.montant?.message}
                helperText={errors?.montant?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default AffectationForm
