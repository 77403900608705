import {useMemo, useState} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import AddButton from 'src/components/buttons/AddButton'
import moment from 'moment'
import {Delete, Edit, Engineering, KeyboardArrowUp} from '@mui/icons-material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import DeviseLabel from '../../../components/DeviseLabel'
import {createColumnHelper} from '@tanstack/react-table'
import {WrappedUniversalTable} from '../../../components/UniversalTable'
import useUniversalTableData from '../../../hooks/useUniversalTableData'
import {
  useDeleteAffectation,
  useOperation,
  useQuittances,
} from '../../../hooks/api-hooks'
import ConfirmDialog from '../../../components/ConfirmDialog'
import AutocompleteInput from '../../../components/inputs/AutocompleteInput'
import QuittanceFormDialog from '../../../components/dialogs/QuittanceFormDialog'
import useAuth from '../../../hooks/useAuth'
import FusionDialog from '../../../components/FusionDialog'

const columnHelper = createColumnHelper()

const QuittancesTable = ({
  onEditButtonClicked,
  onFusionButtonClicked,
  showEditButton,
  filters,
  listType,
}) => {
  const {user} = useAuth()
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'expand',
        enableHiding: false,
        cell: ({row}) => {
          return row.getCanExpand() ? (
            <Icon
              aria-label="expand row"
              size="small"
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: {cursor: 'pointer'},
              }}
            >
              {row.getIsExpanded() ? (
                <KeyboardArrowUp />
              ) : (
                <KeyboardArrowDown />
              )}
            </Icon> // <button
          ) : null
        },
      }),

      columnHelper.accessor('libelle', {
        id: 'libelle',
        header: 'Libellé',
      }),
      columnHelper.accessor('contrat', {
        id: 'contrat',
        header: 'Contrat',
        cell: ({row}) =>
          `${row.original.contrat.reagi_id} ${row.original.contrat.produit.compagnie}`,
      }),
      columnHelper.accessor('date', {
        header: 'Date',
        id: 'date',
      }),
      columnHelper.accessor('date_debut', {
        header: 'Date début',
        id: 'date_debut',
      }),
      columnHelper.accessor('date_fin', {
        header: 'Date fin',
        id: 'date_fin',
      }),
      columnHelper.accessor('type_encaissement', {
        header: 'Confié ?',
        id: 'type_encaissement',
      }),
      columnHelper.accessor('montant', {
        header: 'TTC',
        id: 'montant',
        cell: info => <DeviseLabel>{-info.getValue()}</DeviseLabel>,
      }),
      columnHelper.accessor('commission', {
        header: 'Commission',
        id: 'commission',
        cell: info => <DeviseLabel>{
          info.cell.row.original.type == "Ristourne" ? 
           -info.renderValue() ?? 0 
          : info.renderValue() ?? 0
          }</DeviseLabel>,
      }),
      columnHelper.accessor('frais_hva', {
        header: 'Frais',
        id: 'frais_hva',
        cell: info => <DeviseLabel>{info.renderValue() ?? 0}</DeviseLabel>,
      }),
      columnHelper.accessor('montant_non_affecte', {
        header: 'Restant',
        id: 'montant_non_affecte',
        cell: info => <DeviseLabel>{info.renderValue() ?? 0}</DeviseLabel>,
      }),
      columnHelper.accessor('type_avenant.nom', {
        header: "Type d'avenant",
        id: 'type_avenant__nom',
        enableSorting: false,
      }),
      columnHelper.accessor('date_precontentieux', {
        header: 'Date précontentieux',
        id: 'date_precontentieux',
        // cell: info => info.getValue(),
      }),
      columnHelper.accessor('etat_precontentieux', {
        header: 'Précontentieux',
        id: 'etat_precontentieux__nom',
        enableSorting: false,
        cell: info => (info.renderValue() ? info.renderValue()?.nom : ''),
      }),
      columnHelper.accessor('contrat.produit.compagnie', {
        id: 'contrat__produit__compagnie',
        enableSorting: false,
        header: 'Compagnie',
      }),
      columnHelper.display({
        header: ' ',
        enableHiding: false,
        cell: ({row}) => {
          return (
            <Box display={'flex'}>
              {user.has_fusion_access && (
                <IconButton
                  onClick={() => {
                    onFusionButtonClicked?.(row.original)
                  }}
                  variant={'contained'}
                  style={{marginRight: 10}}
                  sx={{fontSize: 15}}
                >
                  <Engineering fontSize="inherit" />
                </IconButton>
              )}
              {row.original.est_modifiable && (
                <IconButton
                  size="small"
                  onClick={() => {
                    onEditButtonClicked?.(row.original)
                  }}
                  sx={{fontSize: 15}}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          )
        },
      }),
    ],
    [onEditButtonClicked],
  )

  const universalTableData = useUniversalTableData(useQuittances, {
    initialFilters: filters,
  })

  return (
    <WrappedUniversalTable
      dataAccessor={null}
      columns={columns}
      universalTableData={universalTableData}
      name={`quittances-${listType}-list`}
      getRowCanExpand={row => !!row.original.est_affectee}
      renderSubComponent={renderSubComponent}
    />
  )
}

const ClientDetailsQuittanceTabs = ({client, ...props}) => {
  const [dialog, setDialog] = useState()
  const handleAdd = operation => {
    setDialog(null)
  }

  const handleCloseOrDeleteDialog = idOrEvent => {
    setDialog(null)
  }
  const isQuittanceFormDialog = dialog?.type === 'Quittance'
  const isRistourneFormDialog = dialog?.type === 'Ristourne'
  const isFusionFormDialog = dialog?.type === 'fusion'

  const startYear = 2020
  const endYear = moment().year().toString()
  // from https://stackoverflow.com/questions/8069315/create-array-of-all-integers-between-two-numbers-inclusive-in-javascript-jquer
  const years = useMemo(
    () =>
      Array.from({length: endYear - startYear + 1}, (_, i) => startYear + i),
    [startYear, endYear],
  )
  const [currentYear, setCurrentYear] = useState(endYear)

  const handleEditButtonClicked = row => {
    setDialog({
      type: row.type,
      args: row,
    })
  }

  const handleFusionButtonClicked = row => {
    console.log('gogogo')
    console.log({
      type: 'fusion',
      args: row,
    })
    setDialog({
      type: 'fusion',
      args: row,
    })
  }

  return (
    <Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="subtitle1">Quittances en cours</Typography>
        </Box>
        <Box>
          <AddButton
            size="small"
            onClick={() => {
              setDialog({type: 'Quittance'})
            }}
          >
            Ajouter une quittance
          </AddButton>
          <AddButton
            size="small"
            onClick={() => setDialog({type: 'Ristourne'})}
            sx={{mr: 0}}
          >
            Ajouter une ristourne
          </AddButton>
        </Box>
      </Box>

      <QuittancesTable
        listType={'en-cours'}
        filters={{
          client: client.id,
        }}
        onEditButtonClicked={handleEditButtonClicked}
        onFusionButtonClicked={handleFusionButtonClicked}
      />

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box>
          <Typography variant="subtitle1">Quittances réglées</Typography>
        </Box>
        <Box width={200}>
          <AutocompleteInput
            options={years}
            value={currentYear}
            onChange={(_, value) => setCurrentYear(value)}
            label={'Année'}
          />
        </Box>
      </Box>

      <QuittancesTable
        listType={'reglees'}
        filters={{
          client: client.id,
          totalement_envoyee: true,
          annee: currentYear,
        }}
        onEditButtonClicked={handleEditButtonClicked}
        onFusionButtonClicked={handleFusionButtonClicked}
      />
      {isFusionFormDialog ? (
        <FusionDialog
          open={isFusionFormDialog}
          onCancel={handleCloseOrDeleteDialog}
          type={'Quittance'}
          client={dialog?.args?.client}
          initialVariables={{quittance: dialog?.args?.id}}
        />
      ) : null}
      {isQuittanceFormDialog ? (
        <QuittanceFormDialog
          client={client}
          quittance={dialog?.args}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
      {isRistourneFormDialog ? (
        <QuittanceFormDialog
          client={client}
          quittance={dialog?.args}
          isRistourne={true}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
    </Box>
  )
}

const renderSubComponent = props => {
  return <SubComponent {...props} />
}

const SubComponent = ({row}) => {
  // Bouton delete

  const [openDialog, setOpenDialog] = useState(false)
  const [currentId, setCurrentId] = useState()

  const {isLoading, data} = useOperation(row.original.id)
  const {isLoading: isDeleteLoading, mutate} = useDeleteAffectation()

  const handleConfirm = () => {
    mutate(currentId, {onSuccess: () => setOpenDialog(false)})
  }

  return (
    <Box p={2}>
      <Typography variant={'subtitle1'}>Affectations</Typography>
      {isLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {data.affectations?.map(x => {
            return (
              <ListItem key={x.id}>
                <IconButton
                  size="small"
                  onClick={event => {
                    setCurrentId(x.id)
                    setOpenDialog(true)
                  }}
                  sx={{fontSize: 15}}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    {x.lettrage} - {x.montant}€ - {x.credit_libelle}
                  </Box>
                </Box>
              </ListItem>
            )
          })}
        </List>
      )}
      <ConfirmDialog
        title={`Voulez-vous vraiment supprimer l'affectation ?`}
        loading={isDeleteLoading}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
      />
    </Box>
  )
}

export default ClientDetailsQuittanceTabs
