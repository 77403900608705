import AutocompleteInput from './AutocompleteInput'

const civilites = ['Monsieur', 'Madame', 'Mademoiselle', 'Docteur', 'Maître']

const CiviliteInput = ({label = 'Civilité', error, helperText, ...props}) => (
  <AutocompleteInput
    options={civilites}
    label={label}
    error={error}
    helperText={helperText}
    {...props}
  />
)

export default CiviliteInput
