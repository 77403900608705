import {Link as RouterLink} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import {Box, Card, CardContent, Container, Typography} from '@mui/material'
import Logo from 'src/components/Logo'
import LoginForm from 'src/components/forms/LoginForm'
import useAuth from 'src/hooks/useAuth'
import useAsync from 'src/hooks/useAsync'

const Login = () => {
  const {login} = useAuth()
  const {isLoading, run} = useAsync()

  const handleSubmit = async ({email, password}) => {
    run(login(email, password))
  }
  return (
    <>
      <Helmet>
        <title>Se connecter | HVA</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{py: '80px'}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Se connecter
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Connectez-vous à votre espace
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <LoginForm onSubmit={handleSubmit} loading={isLoading} />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  )
}

export default Login
