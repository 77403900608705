import {useEffect} from 'react'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router-dom'

import {
  errorResponseToJson,
  fetchAgences,
  fetchFonctions,
  fetchServices,
  fetchUser,
  updateUser,
} from 'src/services/crm-api'
import UserForm from 'src/components/forms/UserForm'
import useAsync from 'src/hooks/useAsync'
import PageTemplate from 'src/components/templates/PageTemplate'

const UserEdit = () => {
  const {id} = useParams()
  const {
    data: dataDependencies,
    run: runDependencies,
    isLoading: dependenciesIsLoading,
    isIdle: dependenciesIsIdle,
  } = useAsync()
  const {enqueueSnackbar} = useSnackbar()
  // const navigate = useNavigate()
  const {
    run,
    isLoading: isLoadingGetOrUpdate,
    error,
    data: user,
    setError,
  } = useAsync()

  useEffect(() => {
    const getDependencies = Promise.all([
      fetchAgences()
        .then(response => response)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
      fetchFonctions()
        .then(response => response.data)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
      fetchServices()
        .then(response => response.data)
        .then(items => items.map(x => ({text: x.name, value: x.id}))),
    ])
    runDependencies(getDependencies)
  }, [runDependencies])

  useEffect(() => {
    run(fetchUser(id))
  }, [id, run])

  const errors = errorResponseToJson(error)

  const handleSubmit = values => {
    const update = async () => {
      setError()
      const returnValues = await updateUser(id, values)
      // navigate(`/users/${returnValues.id}`)
      enqueueSnackbar('Utilisateur modifié', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'success',
      })
      return returnValues
    }
    run(update()).catch(error => {
      enqueueSnackbar('Utilisateur non modifié', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      })
    })
  }

  const isLoading =
    dependenciesIsIdle || dependenciesIsLoading || isLoadingGetOrUpdate
  const [agences, fonctions, services] = dataDependencies ?? [[], [], []]

  return (
    <PageTemplate
      title="Modifier un utilisateur"
      sections={[
        {title: 'Utilisateurs', path: '/users'},
        {title: 'Modifier un utilisateur', current: true},
      ]}
      data-testid="page-users-edit"
    >
      <UserForm
        errors={errors}
        onSubmit={handleSubmit}
        loading={isLoading}
        agences={agences}
        fonctions={fonctions}
        services={services}
        user={user}
        isEditForm={true}
      />
    </PageTemplate>
  )
}

export default UserEdit
