import {
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  TableBody,
} from '@mui/material'
import {useMemo} from 'react'
import {useTable, useSortBy, useRowSelect} from 'react-table'
import moment from 'moment'
import DeviseLabel from 'src/components/DeviseLabel'

export default function RemiseEnBanqueDetailsTable({reglements}) {
  const columns = useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'client.description',
      },
      {
        Header: 'Date',
        accessor: 'date',
        sortType: 'datetime',
        Cell: ({value}) => {
          // REAL SOUCIS HERE
          return moment(value).format('DD/MM/YYYY')
        },
      },
      {
        Header: 'Montant',
        accessor: 'montant',
        Cell: ({value}) => {
          return (
            <>
              <DeviseLabel>{value}</DeviseLabel>
            </>
          )
        },
      },
    ],
    [],
  )
  const data = useMemo(() => {
    return (
      reglements?.map(x => ({
        ...x,
        date: moment(x.date, 'DD/MM/YYYY').toDate(),
      })) ?? []
    )
  }, [reglements])

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: 'Date',
              desc: true,
            },
          ],
        },
      },
      useSortBy,
      useRowSelect,
    )

  return (
    <>
      <Table {...getTableProps()} size="small">
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column =>
                column.canSort ? (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      {column.render('Header')}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </TableCell>
                ),
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
