import {Link as RouterLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {AppBar, Box, Hidden, IconButton, Toolbar} from '@mui/material'
import {experimentalStyled} from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import AccountPopover from './AccountPopover'
// import ContactsPopover from './ContactsPopover'
// import ContentSearch from './ContentSearch'
// import LanguagePopover from './LanguagePopover'
import Logo from 'src/components/Logo'
// import NotificationsPopover from './NotificationsPopover'

const DashboardNavbarRoot = experimentalStyled(AppBar)(({theme}) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}))

const DashboardNavbar = props => {
  const {onSidebarMobileOpen, ...other} = props

  return (
    <DashboardNavbarRoot data-testid="dashboard-top" {...other}>
      <Toolbar sx={{minHeight: 64}}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            data-testid="left-menu-button"
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <LanguagePopover /> */}
        <Box sx={{ml: 1}}>{/* <ContentSearch /> */}</Box>
        <Box sx={{ml: 1}}>{/* <ContactsPopover /> */}</Box>
        <Box sx={{ml: 1}}>{/* <NotificationsPopover /> */}</Box>
        <Box sx={{ml: 2}}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
}

export default DashboardNavbar
