import {Fragment, useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
  TableRow,
  TableCell,
} from '@mui/material'
import {NavLink as RouterLink} from 'react-router-dom'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Info, Edit, Close, ArrowRightAlt} from '@mui/icons-material'

import Form, {AlertError} from 'src/components/Form'
import LabelRow from 'src/components/LabelRow'
import useSimplePartialUpdateForm from 'src/hooks/useSimplePartialUpdateForm'
import {
  errorResponseToJson,
  partialUpdateContrat,
  clientDisplayName,
  produitLabel,
} from 'src/services/crm-api'
import CopiableBox from 'src/components/CopiableBox'

const resolver = yupResolver(yup.object().shape({}))

const ChampsAdditionnels = ({contrat}) => {
  if (!contrat?.produit?.multi) {
    const odr = contrat?.produit?.odr
    return (
      <>
        {odr?.champs?.map(x => (
          <LabelRow key={x.key} label={x.nom}>
            {contrat?.champs_additionnels?.[0]?.[x.key]}
          </LabelRow>
        ))}
      </>
    )
  }
  return null
}

const DisplayBody = ({contrat, setEditing, editing, isLoading}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="Client">
            {contrat?.client ? (
              <Button
                component={RouterLink}
                to={`/clients/${contrat?.client.id}`}
              >
                <ArrowRightAlt size="small" />
                {clientDisplayName(contrat?.client)}
              </Button>
            ) : (
              ''
            )}
          </LabelRow>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
          <LabelRow label="ID RéAGI">
            <CopiableBox copyString={contrat?.reagi_id}>
              {contrat?.reagi_id}
            </CopiableBox>
          </LabelRow>
          <LabelRow label="Produit">{produitLabel(contrat?.produit)}</LabelRow>
          <ChampsAdditionnels contrat={contrat} />
        </TableBody>
      </Table>
    </>
  )
}

const EditBody = ({contrat, onSubmit, setEditing, editing, setContrat}) => {
  const {isLoading, error, save} = useSimplePartialUpdateForm({
    promise: partialUpdateContrat,
    id: contrat.id,
    onSuccess: data => {
      setContrat(data)
      setEditing(false)
    },
  })

  const errors = errorResponseToJson(error)
  const defaultValue = {}
  return (
    <Form
      onSubmit={save}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ContratIdentificationCard = ({contrat, setContrat, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Identification" avatar={<Info />} />
      <Divider />
      {editing ? (
        <EditBody
          contrat={contrat}
          setContrat={setContrat}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          contrat={contrat}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ContratIdentificationCard
