import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  TextField,
  Typography,
} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'
import useAuth from 'src/hooks/useAuth'

import TypesReglementInput from 'src/components/inputs/TypesReglementInput'
import BanquesInput from '../inputs/BanquesInput'
import BanquesRemiseInput from '../inputs/BanquesRemiseInput'
import DeviseInput from '../inputs/DeviseInput'
import AgencesInputControlled from '../controlled/AgencesInputControlled'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  type_reglement: yup
    .mixed()
    .label('Type de reglement')
    .transform(value => (value === '' ? undefined : value))
    .required(),
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'espèces'
        ? schema.max(1000)
        : schema,
    )
    .required(),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date de comptabilisation')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
  emetteur_cheque: yup
    .string()
    .label('Emetteur chèque')
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required()
        : schema,
    ),

  date_cheque: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label("Date d'émission")
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required()
        : schema,
    ),
  numero_cheque: yup
    .string()
    .label('Numéro chèque')
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required().matches(/^[0-9]{7}$/, 'doit être 7 chiffres')
        : schema,
    ),
  banque_cheque: yup
    .mixed()
    .label('Banque chèque')
    .transform(value => (value === '' ? undefined : value))
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'chèque'
        ? schema.required()
        : schema,
    ),
  libelle_libre: yup.string(),
  banque_remise: yup
    .mixed()
    .label('Banque remise')
    .transform(value => (value === '' ? undefined : value))
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() !== 'cb en ligne' &&
      type_reglement?.nom?.toLowerCase() !== 'cb 2 fois' &&
      type_reglement?.nom?.toLowerCase() !== 'cb 3 fois' &&
      type_reglement?.nom?.toLowerCase() !== 'cb 4 fois' &&
      type_reglement?.nom?.toLowerCase() !== 'cb en agence'
        ? schema.required()
        : schema,
    ),
  agence_id: yup
    .mixed()
    .label('Agence')
    .transform(value => (value === '' ? undefined : value))
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() !== 'cb en ligne'
        ? schema.required()
        : schema,
    ),
  date_cb1: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date CB')
    .when('type_reglement', (type_reglement, schema) =>
      type_reglement?.nom?.toLowerCase() === 'cb 2 fois' &&
      type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
      type_reglement?.nom?.toLowerCase() === 'cb 4 fois'
        ? schema.required()
        : schema,
    ),
  montant_cb1: yup
    .number()
    .label('Montant CB 1')
    .transform(value => (isNaN(value) ? undefined : value))
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 2 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
  date_cb2: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date CB 2')
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = 'false', schema) => {
        return type_reglement?.nom?.toLowerCase() === 'cb 2 fois' &&
          type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
          type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
          is_editing === 'false'
          ? schema.required()
          : schema
      },
    ),
  montant_cb2: yup
    .number()
    .label('Montant CB 2')
    .transform(value => (isNaN(value) ? undefined : value))
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = false, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 2 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
  date_cb3: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date CB 3')
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = false, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
  montant_cb3: yup
    .number()
    .label('Montant CB 3')
    .transform(value => (isNaN(value) ? undefined : value))
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = false, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 3 fois' &&
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
  date_cb4: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date CB 4')
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = false, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
  montant_cb4: yup
    .number()
    .label('Montant CB 4')
    .transform(value => (isNaN(value) ? undefined : value))
    .when(
      ['type_reglement', 'is_editing'],
      (type_reglement, is_editing = false, schema) =>
        type_reglement?.nom?.toLowerCase() === 'cb 4 fois' &&
        is_editing === 'false'
          ? schema.required()
          : schema,
    ),
})

const defaultBanqueRemise = {id: 1, nom: 'CEPAC Prime'}

const ReglementForm = ({reglement, client, onSubmit, disabled, ...props}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...reglement,
      agence_id: Boolean(reglement?.agence) ? reglement.agence : undefined,
      montant: Boolean(reglement?.montant)
        ? parseFloat(reglement.montant)
        : undefined,
      date: Boolean(reglement?.date)
        ? moment(reglement.date, 'DD/MM/YYYY')
        : undefined,
      date_cheque: Boolean(reglement?.date_cheque)
        ? moment(reglement.date_cheque, 'DD/MM/YYYY')
        : undefined,
      date_cb1: Boolean(reglement?.date_cb)
        ? moment(reglement.date_cb, 'DD/MM/YYYY')
        : undefined,
    },
  })

  const isEditing = Boolean(reglement?.id)

  const typeReglement = watch('type_reglement')

  const isCheque = typeReglement?.nom?.toLowerCase() === 'chèque'
  const isCB2 = typeReglement?.nom?.toLowerCase() === 'cb 2 fois'
  const isCB3 = typeReglement?.nom?.toLowerCase() === 'cb 3 fois'
  const isCB4 = typeReglement?.nom?.toLowerCase() === 'cb 4 fois'
  const isCBLigne = typeReglement?.nom?.toLowerCase() === 'cb en ligne'
  const isCBAgence = typeReglement?.nom?.toLowerCase() === 'cb en agence'
  const isCB = isCBLigne || isCBAgence || isCB3 || isCB2 || isCB4

  const {user} = useAuth()

  const fillMontantCb2 = () => {
    const base = watch('montant') * 100
    const rest = base % 2
    const perMonth = (base - rest) / 2
    setValue('montant_cb1', (perMonth + rest) / 100)
    setValue('montant_cb2', perMonth / 100)
    setValue('montant_cb3', perMonth / 100)
  }

  const fillMontantCb3 = () => {
    const base = watch('montant') * 100
    const rest = base % 3
    const perMonth = (base - rest) / 3
    setValue('montant_cb1', (perMonth + rest) / 100)
    setValue('montant_cb2', perMonth / 100)
    setValue('montant_cb3', perMonth / 100)
  }

  const fillMontantCb4 = () => {
    const base = watch('montant') * 100
    const rest = base % 4
    const perMonth = (base - rest) / 4
    setValue('montant_cb1', (perMonth + rest) / 100)
    setValue('montant_cb2', perMonth / 100)
    setValue('montant_cb3', perMonth / 100)
    setValue('montant_cb4', perMonth / 100)
  }

  const fillMontant = () => {
    isCB2 ? fillMontantCb2() : isCB3 ? fillMontantCb3() : fillMontantCb4()
  }

  const fillDateCb2 = () => {
    const cb1Date = moment(watch('date_cb1'))
    const cb2Date = cb1Date?.isValid() ? cb1Date.clone().add(1, 'M') : null

    setValue('date_cb2', cb2Date)
  }

  const fillDateCb3 = () => {
    const cb1Date = moment(watch('date_cb1'))
    const cb2Date = cb1Date?.isValid() ? cb1Date.clone().add(1, 'M') : null
    const cb3Date = cb2Date?.isValid() ? cb2Date.clone().add(1, 'M') : null

    setValue('date_cb2', cb2Date)
    setValue('date_cb3', cb3Date)
  }

  const fillDateCb4 = () => {
    const cb1Date = moment(watch('date_cb1'))
    const cb2Date = cb1Date?.isValid() ? cb1Date.clone().add(1, 'M') : null
    const cb3Date = cb2Date?.isValid() ? cb2Date.clone().add(1, 'M') : null
    const cb4Date = cb3Date?.isValid() ? cb3Date.clone().add(1, 'M') : null

    setValue('date_cb2', cb2Date)
    setValue('date_cb3', cb3Date)
    setValue('date_cb4', cb4Date)
  }

  const cbMontantError = isCB2
    ? parseFloat(watch('montant_cb1')) + parseFloat(watch('montant_cb2')) !==
      parseFloat(watch('montant'))
    : isCB3
    ? parseFloat(watch('montant_cb1')) +
        parseFloat(watch('montant_cb2')) +
        parseFloat(watch('montant_cb3')) !==
      parseFloat(watch('montant'))
    : isCB4
    ? parseFloat(watch('montant_cb1')) +
        parseFloat(watch('montant_cb2')) +
        parseFloat(watch('montant_cb3')) +
        parseFloat(watch('montant_cb4')) !==
      parseFloat(watch('montant'))
    : false

  console.log(
    'DEBUGG',
    cbMontantError,
    parseFloat(watch('montant_cb1')),
    parseFloat(watch('montant_cb2')),
    parseFloat(watch('montant_cb1')) + parseFloat(watch('montant_cb2')),
  )

  if (cbMontantError && !isEditing) {
    errors.montant_cb1 = {message: 'Les montants ne correspondent pas'}
    errors.montant_cb2 = {message: 'Les montants ne correspondent pas'}
    errors.montant_cb3 = {message: 'Les montants ne correspondent pas'}
    errors.montant_cb4 = {message: 'Les montants ne correspondent pas'}
  } else {
    delete errors.montant_cb1
    delete errors.montant_cb2
    delete errors.montant_cb3
    delete errors.montant_cb4
  }

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.client_id = client.id
        body.avance = isCB ? false : body.avance
        body.type_reglement_id = body.type_reglement?.id
        body.banque_cheque_id = body.banque_cheque?.id
        body.banque_remise_id = body.banque_remise?.id
        body.agence_id = body.agence_id?.id
        body.date = moment(values.date).format('DD/MM/YYYY')
        if (Boolean(body.date_cheque)) {
          body.date_cheque = moment(values.date_cheque).format('DD/MM/YYYY')
        }
        if (Boolean(body.date_cb1)) {
          body.date_cb1 = moment(values.date_cb1).format('DD/MM/YYYY')
          if (isEditing) {
            body.date_cb = body.date_cb1
            delete body.date_cb1
          }
        }
        if (Boolean(body.date_cb2)) {
          body.date_cb2 = moment(values.date_cb2).format('DD/MM/YYYY')
        }
        if (Boolean(body.date_cb3)) {
          body.date_cb3 = moment(values.date_cb3).format('DD/MM/YYYY')
        }
        if (Boolean(body.date_cb4)) {
          body.date_cb4 = moment(values.date_cb4).format('DD/MM/YYYY')
        }
        body.type = 'Reglement'
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('id')} />
      <input
        type="hidden"
        {...register('is_editing')}
        value={isEditing ?? false}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="type_reglement"
            defaultValue={null}
            render={({field: {value, onChange}}) => (
              <TypesReglementInput
                disabled={disabled}
                onChange={(event, item) => {
                  onChange(item)
                }}
                value={value}
                error={!!errors?.type_reglement?.message}
                helperText={errors?.type_reglement?.message}
                margin="dense"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            defaultValue={''}
            render={({field}) => (
              <DeviseInput
                disabled={disabled}
                {...field}
                margin="dense"
                size="small"
                fullWidth
                label="Montant"
                error={!!errors?.montant?.message}
                helperText={errors?.montant?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            defaultValue={moment(today).format('MM/DD/YYYY')}
            render={({field}) => (
              <DesktopDatePicker
                {...field}
                disabled={disabled}
                label="Date de comptabilisation"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    disabled={disabled}
                    {...params}
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors?.date?.message}
                    helperText={errors?.date?.message}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="libelle_libre"
            defaultValue=""
            render={({field}) => (
              <TextField
                {...field}
                disabled={disabled}
                error={!!errors?.libelle_libre?.message}
                helperText={errors?.libelle_libre?.message}
                margin="dense"
                size="small"
                fullWidth
                label="Libellé libre"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} mt={2} mb={2}>
          <Divider />
        </Grid>
        {!isCB && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="banque_remise"
              defaultValue={defaultBanqueRemise}
              render={({field: {value, onChange}}) => (
                <BanquesRemiseInput
                  disabled={disabled}
                  onChange={(event, item) => {
                    onChange(item)
                  }}
                  label="Banque remise"
                  value={value}
                  error={!!errors?.banque_remise?.message}
                  helperText={errors?.banque_remise?.message}
                  margin="dense"
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {!isCBLigne && (
          <Grid item xs={12} md={6}>
            <AgencesInputControlled
              defaultValue={isEditing ? undefined : user.agence}
              name="agence_id"
              control={control}
              error={!!errors?.agence_id?.message}
              helperText={errors?.agence_id?.message}
            />
          </Grid>
        )}
        {(isCB2 || isCB3 || isCB4) && isEditing && (
          <Grid item xs={12} md={6}>
            <Controller
              name="date_cb1"
              control={control}
              defaultValue=""
              render={({field}) => (
                <DesktopDatePicker
                  {...field}
                  onChange={event => {
                    field.onChange(event)
                    isCB2
                      ? fillDateCb2()
                      : isCB3
                      ? fillDateCb3()
                      : fillDateCb4()
                  }}
                  label="Date CB 1"
                  inputFormat="dd/MM/yyyy"
                  renderInput={params => (
                    <TextField
                      disabled={disabled}
                      {...params}
                      margin="dense"
                      size="small"
                      fullWidth
                      error={!!errors?.date_cb1?.message}
                      helperText={errors?.date_cb1?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        )}
        {(isCB3 || isCB2 || isCB4) && !isEditing && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Infos CB x3
                <Button size="small" sx={{ml: 2}} onClick={fillMontant}>
                  Remplir automatiquement
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="date_cb1"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <DesktopDatePicker
                    {...field}
                    onChange={event => {
                      field.onChange(event)
                      isCB2
                        ? fillDateCb2()
                        : isCB3
                        ? fillDateCb3()
                        : fillDateCb4()
                    }}
                    label="Date CB 1"
                    inputFormat="dd/MM/yyyy"
                    renderInput={params => (
                      <TextField
                        disabled={disabled}
                        {...params}
                        margin="dense"
                        size="small"
                        fullWidth
                        error={!!errors?.date_cb1?.message}
                        helperText={errors?.date_cb1?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid md={6} xs={12} item>
              <Controller
                control={control}
                name="montant_cb1"
                defaultValue=""
                render={({field}) => (
                  <DeviseInput
                    {...field}
                    disabled={disabled}
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Montant CB 1"
                    error={!!errors?.montant_cb1?.message}
                    helperText={errors?.montant_cb1?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="date_cb2"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <DesktopDatePicker
                    {...field}
                    label="Date CB 2"
                    inputFormat="dd/MM/yyyy"
                    renderInput={params => (
                      <TextField
                        {...params}
                        disabled={disabled}
                        margin="dense"
                        size="small"
                        fullWidth
                        error={!!errors?.date_cb2?.message}
                        helperText={errors?.date_cb2?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid md={6} xs={12} item>
              <Controller
                control={control}
                name="montant_cb2"
                defaultValue=""
                render={({field}) => (
                  <DeviseInput
                    {...field}
                    disabled={disabled}
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Montant CB 2"
                    error={!!errors?.montant_cb2?.message}
                    helperText={errors?.montant_cb2?.message}
                  />
                )}
              />
            </Grid>
            {(isCB3 || isCB4) && (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="date_cb3"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <DesktopDatePicker
                        {...field}
                        label="Date CB 3"
                        inputFormat="dd/MM/yyyy"
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={disabled}
                            margin="dense"
                            size="small"
                            fullWidth
                            error={!!errors?.date_cb3?.message}
                            helperText={errors?.date_cb3?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid md={6} xs={12} item>
                  <Controller
                    control={control}
                    name="montant_cb3"
                    defaultValue=""
                    render={({field}) => (
                      <DeviseInput
                        {...field}
                        disabled={disabled}
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Montant CB 3"
                        error={!!errors?.montant_cb3?.message}
                        helperText={errors?.montant_cb3?.message}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {isCB4 && (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="date_cb4"
                    control={control}
                    defaultValue=""
                    render={({field}) => (
                      <DesktopDatePicker
                        {...field}
                        label="Date CB 4"
                        inputFormat="dd/MM/yyyy"
                        renderInput={params => (
                          <TextField
                            {...params}
                            disabled={disabled}
                            margin="dense"
                            size="small"
                            fullWidth
                            error={!!errors?.date_cb4?.message}
                            helperText={errors?.date_cb4?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid md={6} xs={12} item>
                  <Controller
                    control={control}
                    name="montant_cb4"
                    defaultValue=""
                    render={({field}) => (
                      <DeviseInput
                        {...field}
                        disabled={disabled}
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Montant CB 4"
                        error={!!errors?.montant_cb4?.message}
                        helperText={errors?.montant_cb4?.message}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {isCheque ? (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Infos chèque</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="emetteur_cheque"
                defaultValue=""
                render={({field}) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    error={!!errors?.emetteur_cheque?.message}
                    helperText={errors?.emetteur_cheque?.message}
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Emetteur chèque"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="date_cheque"
                control={control}
                defaultValue=""
                render={({field}) => (
                  <DesktopDatePicker
                    {...field}
                    label="Date d'émission"
                    inputFormat="dd/MM/yyyy"
                    renderInput={params => (
                      <TextField
                        {...params}
                        disabled={disabled}
                        margin="dense"
                        size="small"
                        fullWidth
                        error={!!errors?.date_cheque?.message}
                        helperText={errors?.date_cheque?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="numero_cheque"
                defaultValue=""
                render={({field}) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    error={!!errors?.numero_cheque?.message}
                    helperText={errors?.numero_cheque?.message}
                    margin="dense"
                    size="small"
                    fullWidth
                    label="Numéro chèque"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="banque_cheque"
                defaultValue={null}
                render={({field: {value, onChange}}) => (
                  <BanquesInput
                    disabled={disabled}
                    onChange={(event, item) => {
                      onChange(item)
                    }}
                    label="Banque chèque"
                    value={value}
                    error={!!errors?.banque_cheque?.message}
                    helperText={errors?.banque_cheque?.message}
                    margin="dense"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Hidden mdDown>
              <Grid item md={6} />
            </Hidden>
          </>
        ) : null}
        {!isEditing && !isCB && (
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Controller
                  name="avance"
                  control={control}
                  defaultValue={false}
                  render={({field: {value, onChange}}) => (
                    <Checkbox
                      disabled={disabled}
                      {...props}
                      checked={value}
                      onChange={e => onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label="C'est une avance"
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}

export default ReglementForm
