import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material'

import AddButton from '../buttons/AddButton'
import AffectationForm from '../forms/AffectationForm'
import {useCreateAffectation, useOperationsForClient} from 'src/hooks/api-hooks'

const AffectationFormDialog = ({
  open = true,
  operation,
  handleClose = () => {},
  onSubmit,
  ...props
}) => {
  const {isFetched, data, isError, error} = useOperationsForClient(
    operation.client_id,
    {totalement_affectee: false},
  )

  const {isLoading, mutate} = useCreateAffectation()

  const operations = data?.results.filter(x => x.montant < 0)

  const handleSubmit = values => {
    mutate(values, {
      onSuccess: handleClose,
    })
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="affectation-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="affectation-dialog">
        Affecter {operation?.libelle}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isError ? (
          `Une erreur est survenue: ${error}`
        ) : isFetched ? (
          <AffectationForm
            operation={operation}
            operations={operations}
            onSubmit={handleSubmit}
            id="affectation-dialog-form"
          />
        ) : (
          'Chargement ...'
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          Annuler
        </Button>
        <AddButton
          form="affectation-dialog-form"
          loading={isLoading}
          loadingPosition="start"
          type="submit"
        >
          Ajouter
        </AddButton>
      </DialogActions>
    </Dialog>
  )
}

export default AffectationFormDialog
