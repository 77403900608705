import React from 'react'
import {Breadcrumbs, Link, Typography} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Link as RouterLink} from 'react-router-dom'

const customBreadcrumbs = ({sections = [], title, ...props} = {}) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon fontSize="small" />}
      sx={{mt: 1}}
    >
      <Link
        color="textPrimary"
        component={RouterLink}
        to="/"
        variant="subtitle2"
      >
        Accueil
      </Link>
      {sections.map(x => {
        if (x.current || !x.path) {
          return (
            <Typography
              color={x.current ? 'textSecondary' : 'textPrimary'}
              variant="subtitle2"
              key={x.title}
            >
              {x.title}
            </Typography>
          )
        } else {
          return (
            <Link
              color="textPrimary"
              component={RouterLink}
              to={x.path ?? undefined}
              variant="subtitle2"
              key={x.title}
            >
              {x.title}
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}

export default customBreadcrumbs
