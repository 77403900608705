import {errorMessage} from 'src/services/crm-api'

const {Card, Box, Alert} = require('@mui/material')

const ErrorCard = ({error}) => (
  <Card>
    <Box
      sx={{
        p: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 300,
      }}
      data-testid="error"
    >
      <Alert color="error">{errorMessage(error)}</Alert>
    </Box>
  </Card>
)

export default ErrorCard
