import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'

import AddButton from '../buttons/AddButton'
import EditButton from '../buttons/EditButton'
import ReglementForm from '../forms/ReglementForm'
import DeleteOperationButton from './DeleteOperationButton'
import useOperationDialog from './useOperationDialog'
import RejetReglementButton from './RejetReglementButton'

export default function ReglementFormDialog({
  open = true,
  reglement,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) {
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(reglement?.id)

  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      const isAvance = values.avance
      create(values, {
        onSuccess: results => {
          if (isAvance) {
            const dette = {
              type: 'Dette',
              montant: -results.montant,
              date: results.date,
              client_id: results.client_id,
              libelle_libre: 'Avance ' + results.libelle_libre,
            }
            create(dette, {
              onSuccess: results => {
                console.log(results)
              },
            })
          }
          onSubmit?.(results)
        },
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="reglement-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="reglement-dialog">
        {isEditing ? 'Modifier' : 'Ajouter'} un Règlement
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          'Chargement ...'
        ) : (
          <ReglementForm
            client={client}
            reglement={operation}
            onSubmit={handleSubmit}
            id="reglement-dialog-form"
          />
        )}
      </DialogContent>
      <DialogActions>
        <RejetReglementButton operation={operation} onRejet={handleClose} />
        <DeleteOperationButton
          sx={{mr: 'auto'}}
          operationId={reglement?.id}
          onDelete={handleClose}
        />
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        {isEditing ? (
          <EditButton
            form="reglement-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Modifier
          </EditButton>
        ) : (
          <AddButton
            form="reglement-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
