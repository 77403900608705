const banques = [
  {id: 1, nom: 'BFC OI'},
  {id: 2, nom: 'BNP Paribas'},
  {id: 3, nom: 'BRED'},
  {id: 4, nom: 'Crédit Agricole'},
  {id: 5, nom: 'CASDEN'},
  {id: 6, nom: 'Caisse des dépôts et consignations'},
  {id: 7, nom: 'CEPAC'},
  {id: 8, nom: 'CIC'},
  {id: 9, nom: 'CMM'},
  {id: 10, nom: 'Crédit Mutuel'},
  {id: 11, nom: 'Fiducial Banque'},
  {id: 12, nom: 'Fortuneo'},
  {id: 13, nom: 'HSBC'},
  {id: 14, nom: 'I.E.D.O.M.'},
  {id: 15, nom: 'ING Direct'},
  {id: 16, nom: 'La Banque Postale'},
  {id: 17, nom: 'Crédit Lyonnais'},
  {id: 18, nom: 'Monobanq'},
  {id: 19, nom: 'Ma French Bank'},
  {id: 20, nom: 'Orange Bank'},
  {id: 21, nom: 'Société Général'},
]

export {banques}
