import DeviseInputControlled from '../controlled/DeviseInputControlled'

export default function FusionParamDevise({name, label, control, ...props}) {
  return (
    <DeviseInputControlled
      control={control}
      name={name}
      label={label}
      {...props}
    />
  )
}
