import {useState} from 'react'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  TextField,
  Typography,
} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import moment from 'moment'

import TypesAvenantInput from 'src/components/inputs/TypesAvenantInput'
import {Box} from '@mui/system'
import DeviseInput from '../inputs/DeviseInput'
import ConfirmDialog from '../ConfirmDialog'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  contrat: yup.mixed().label('Contrat').required(),
  numero_avenant: yup
    .number()
    .label('Numéro avenant')
    .transform(value => (isNaN(value) ? undefined : value))
    .min(0)
    .max(1000)
    .when('contrat', (contrat, schema) =>
      contrat?.produit?.compagnie === 'Prudence Créole'
        ? schema.required(
            'Numéro avenant est un champs obligatoire pour la Prudence Créole',
          )
        : schema,
    ),
  numero_appel_prime: yup
    .number()
    .label('Numéro appel prime')
    .transform(value => (isNaN(value) ? undefined : value))
    .min(1)
    .max(1000)
    .when('contrat', (contrat, schema) =>
      contrat?.produit?.compagnie === 'Prudence Créole'
        ? schema.required(
            'Numéro appel prime est un champs obligatoire pour la Prudence Créole',
          )
        : schema,
    ),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .label('Date de comptabilisation')
    .required(),
  date_debut: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date début')
    .required(),
  date_fin: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date fin')
    .when('date_debut', (date_debut, schema) =>
      date_debut instanceof Date && !isNaN(date_debut)
        ? schema.min(date_debut, 'Date fin doit être après date début')
        : schema,
    )
    .required(),
  type_avenant: yup
    .mixed()
    .label('Type avenant')
    .transform(value => (value === '' ? undefined : value))
    .required(),
  type_encaissement: yup.mixed().label('Encaissement').required(),
  prime_ttc: yup
    .number()
    .label('Prime TTC')
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  prime_ht: yup
    .number()
    .label('Prime HT')
    .typeError('Prime HT doit être une devise valide')
    .transform(value => (isNaN(value) ? undefined : value)),
  commission: yup
    .number()
    .label('Commission')
    .typeError('Commission doit être une devise valide')
    .transform(value => (isNaN(value) ? undefined : value)),
  frais_hva: yup
    .number()
    .typeError('Frais HVA doit être une devise valide')
    .label('Frais HVA')
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
})

const QuittanceForm = ({
  contrats,
  quittance,
  isRistourne,
  onSubmit,
  ...props
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...quittance,
      prime_ttc: Boolean(quittance?.prime_ttc)
        ? parseFloat(quittance.prime_ttc)
        : undefined,
      prime_ht: Boolean(quittance?.prime_ht)
        ? parseFloat(quittance.prime_ht)
        : undefined,
      frais_hva: Boolean(quittance?.frais_hva)
        ? parseFloat(quittance.frais_hva)
        : undefined,
      commission: Boolean(quittance?.commission)
        ? parseFloat(quittance.commission)
        : undefined,
      date: Boolean(quittance?.date)
        ? moment(quittance.date, 'DD/MM/YYYY')
        : undefined,
      date_debut: Boolean(quittance?.date_debut)
        ? moment(quittance.date_debut, 'DD/MM/YYYY')
        : undefined,
      date_fin: Boolean(quittance?.date_fin)
        ? moment(quittance.date_fin, 'DD/MM/YYYY')
        : undefined,
    },
  })

  console.log(contrats)
  const [disabledDialogOpen, setDisabledDialogOpen] = useState(false)

  const isEditing = !!quittance
  const montantCompagnie =
    parseFloat(watch('prime_ttc')) - parseFloat(watch('commission'))
  const montantClient =
    parseFloat(watch('prime_ttc')) + parseFloat(watch('frais_hva'))
  const forceDisabled = montantCompagnie === 0 && montantClient === 0
  const validSubmit = values => {
    if (forceDisabled) {
      setDisabledDialogOpen(true)
      return
    }
    const body = {...values}
    if (isRistourne) {
      body.type = 'Ristourne'
      body.credit = body.prime_ttc
      body.debit = 0
    } else {
      body.type = 'Quittance'
      body.debit = body.prime_ttc
      body.credit = 0
    }
    delete body.ristourne

    body.date = moment(values.date).format('DD/MM/YYYY')
    body.date_debut = moment(values.date_debut).format('DD/MM/YYYY')
    body.date_fin = moment(values.date_fin).format('DD/MM/YYYY')
    body.contrat_id = body.contrat?.id
    body.type_avenant_id = body.type_avenant?.id
    onSubmit?.(body)
  }

  const [bufferValues, setBufferValues] = useState()
  return (
    <>
      <form
        onSubmit={handleSubmit(values => {
          if (!values.commission || (!values.prime_ht && isRistourne)) {
            setBufferValues(values)
            return
          }
          validSubmit(values)
        })}
        {...props}
      >
        <input type="hidden" {...register('id')} />
        <Box display={{xs: 'block', md: 'flex'}}>
          <Box width={{xs: '100%', md: 2 / 3}}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="contrat"
                  control={control}
                  defaultValue={null}
                  render={({field: {value, onChange}}) => (
                    <Autocomplete
                      onChange={(event, item) => {
                        onChange(item)
                      }}
                      value={value}
                      options={contrats}
                      getOptionLabel={option => `${option.reagi_id}` ?? ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Contrat"
                          size="small"
                          margin="dense"
                          error={!!errors?.contrat?.message}
                          helperText={errors?.contrat?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} alignSelf="center">
                <Typography variant="subtitle2">
                  {watch('contrat')?.produit?.compagnie}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="type_avenant"
                  defaultValue={null}
                  render={({field: {value, onChange}}) => (
                    <TypesAvenantInput
                      onChange={(event, item) => {
                        onChange(item)
                      }}
                      isRistourne={isRistourne}
                      value={value}
                      error={!!errors?.type_avenant?.message}
                      helperText={errors?.type_avenant?.message}
                      margin="dense"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item md={6} />
              </Hidden>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="numero_avenant"
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      {...field}
                      error={!!errors?.numero_avenant?.message}
                      helperText={errors?.numero_avenant?.message}
                      margin="dense"
                      size="small"
                      fullWidth
                      label="Numero avenant"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="numero_appel_prime"
                  defaultValue=""
                  render={({field}) => (
                    <TextField
                      {...field}
                      error={!!errors?.numero_appel_prime?.message}
                      helperText={errors?.numero_appel_prime?.message}
                      margin="dense"
                      size="small"
                      fullWidth
                      label="Numero appel prime"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={moment(today).format('MM/DD/YYYY')}
                  render={({field}) => (
                    <DesktopDatePicker
                      {...field}
                      label="Date de comptabilisation"
                      inputFormat="dd/MM/yyyy"
                      renderInput={params => (
                        <TextField
                          {...params}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={!!errors?.date?.message}
                          helperText={errors?.date?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Hidden mdDown>
                <Grid item md={6} />
              </Hidden>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date_debut"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <DesktopDatePicker
                      {...field}
                      label="Date de début"
                      inputFormat="dd/MM/yyyy"
                      renderInput={params => (
                        <TextField
                          {...params}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={!!errors?.date_debut?.message}
                          helperText={errors?.date_debut?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date_fin"
                  control={control}
                  defaultValue=""
                  render={({field}) => (
                    <DesktopDatePicker
                      {...field}
                      label="Date de fin"
                      inputFormat="dd/MM/yyyy"
                      renderInput={params => (
                        <TextField
                          {...params}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={!!errors?.date_fin?.message}
                          helperText={errors?.date_fin?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            width={{xs: '100%', md: 1 / 3}}
            pl={{md: 3, xs: 0}}
            alignSelf="center"
          >
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="type_encaissement"
                    defaultValue={'Confié'}
                    render={({field: {value, onChange}}) => (
                      <Autocomplete
                        onChange={(event, item) => {
                          onChange(item)
                        }}
                        value={value}
                        options={['Confié', 'Non confié']}
                        getOptionLabel={option => option}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Encaissement"
                            size="small"
                            margin="dense"
                            error={!!errors?.type_encaissement?.message}
                            helperText={errors?.type_encaissement?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="prime_ttc"
                    defaultValue={''}
                    render={({field}) => (
                      <DeviseInput
                        {...field}
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Prime TTC"
                        error={!!errors?.prime_ttc?.message}
                        helperText={errors?.prime_ttc?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="commission"
                    defaultValue=""
                    render={({field}) => (
                      <DeviseInput
                        {...field}
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Commission"
                        error={!!errors?.commission?.message}
                        helperText={errors?.commission?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    control={control}
                    name="frais_hva"
                    defaultValue={0}
                    render={({field}) => (
                      <DeviseInput
                        {...field}
                        margin="dense"
                        size="small"
                        fullWidth
                        label="Frais HVA"
                        error={!!errors?.frais_hva?.message}
                        helperText={errors?.frais_hva?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign="center">
                    Prime TTC Client
                  </Typography>
                  <Typography variant="h5" textAlign="center">
                    {isNaN(montantClient) ? '0' : montantClient.toFixed(2)} €
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" textAlign="center">
                    Montant compagnie
                  </Typography>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    sx={{color: isNaN(montantCompagnie) ? '' : ``}}
                  >
                    {isNaN(montantCompagnie)
                      ? '0'
                      : `${montantCompagnie.toFixed(2)}`}{' '}
                    €
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </form>
      <ConfirmDialog
        open={!!bufferValues}
        title={'Certains champs ne sont non remplis'}
        confirmColor={'primary'}
        onConfirm={() => {
          validSubmit(bufferValues)
          setBufferValues(undefined)
        }}
        onCancel={() => setBufferValues(undefined)}
      >
        Le champ commission {isRistourne ? 'ou Montant HT' : ''} n'est pas
        défini. Voulez-vous malgré tout continuer ?
      </ConfirmDialog>
      <Dialog
        open={disabledDialogOpen}
        onClose={() => setDisabledDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Les montants sont incorrectes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Au moins l'un des champs "montant" doit être supérieur à zero
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisabledDialogOpen(false)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default QuittanceForm
