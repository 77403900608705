import {Autocomplete} from '.'
import {useEffect, useState} from 'react'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import {useRef} from 'react'

const LoadingAutocomplete = ({promise, transformItems, ...props}) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const isMountedRef = useIsMountedRef()
  const once = useRef(false)

  useEffect(() => {
    if (!once.current) {
      once.current = true
      setLoading(true)
      promise()
        .then(items => {
          if (isMountedRef.current) {
            setItems(items.map(transformItems))
            setLoading(false)
          }
        })
        .catch(e => {
          setError(e)
        })
    }
  }, [isMountedRef, promise, transformItems])

  return (
    <Autocomplete
      loading={loading}
      loadingText={error ? 'Une erreur est survenue' : undefined}
      name="lien_id"
      label="Nature du lien"
      items={items}
      {...props}
    />
  )
}

export default LoadingAutocomplete
