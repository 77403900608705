import {Autocomplete, TextField} from '@mui/material'

const formeJuridiques = [
  'SARL',
  'SAS',
  'SCI',
  'EI',
  'Association',
  'SASU',
  'SELARL',
  'EURL',
  'SCP',
  'Succession',
  'SA',
  'SCM',
  'GIE',
  'SCCV',
  'GFA',
  'SDC',
  'ASL',
  'EP',
  'SCEA',
  'AARPI',
  'SCA',
]

const FormeJuridiqueInput = ({
  label = 'Forme juridique',
  error,
  helperText,
  ...props
}) => (
  <Autocomplete
    options={formeJuridiques}
    {...props}
    renderInput={params => (
      <TextField
        {...params}
        label={label}
        helperText={helperText}
        error={error}
        margin="dense"
        size="small"
      />
    )}
  />
)

export default FormeJuridiqueInput
