const clientsList = {
  count: 100,
  next: 'http://localhost:8000/clients/?format=json&limit=20&offset=20',
  previous: null,
  results: [
    {
      id: 1,
      url: 'http://localhost:8000/clients/1/?format=json',
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      contrats: [
        {
          id: 1,
          created_at: '2021-06-30T11:00:50.238157Z',
          updated_at: '2021-06-30T11:00:50.250365Z',
          url: 'http://localhost:8000/users/1/?format=json',
          client_id: 1,
          produit_id: 40,
          agence_id: 27,
          referent_id: 24,
          reagi_id: 'A 000000',
          branche: 'D',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '09/11/2022',
          prime_type: 'Non confié',
          prime_fractionnement: 'Décennale',
          date_debut_sp: '05/06/2019',
          date_fin_sp: '19/10/2021',
          decision_sp_n:
            'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
          decision_sp_n_m_1:
            'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
          client: {
            id: 1,
            reagi_id: 'A000000',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Buisson',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, avenue Céline Da Costa',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '52661',
            adresse_principale_ville: 'Voisin-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1844126706062',
            telephone_secondaire: '',
            email_prioritaire: 'margauxlamy@turpin.fr',
            email_secondaire: 'hardyaurelie@gmail.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 40,
            code: 'J0nx0',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: "Quelqu'un d'autre",
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 1,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 27,
            name: 'VaillantVille',
            zipcode: '86109',
            cedex: '',
            ville: 47,
          },
          referent: {
            id: 24,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.784510Z',
            email: 'anouk.moreau7208@example.org',
            firstname: 'Anouk',
            lastname: 'Moreau',
            agence: 28,
            fonction: 30,
            service: 28,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 2,
          created_at: '2021-06-30T11:00:50.238157Z',
          updated_at: '2021-06-30T11:00:50.250365Z',
          url: 'http://localhost:8000/users/2/?format=json',
          client_id: 1,
          produit_id: 41,
          agence_id: 29,
          referent_id: 25,
          reagi_id: 'A 000001',
          branche: 'E',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: '21/01/2020',
          date_fin_sp: '18/02/2022',
          decision_sp_n:
            "Rêver chaque queue. Traîner chaud sujet centre peuple. Inconnu quelqu'un soleil employer art pauvre feu.",
          decision_sp_n_m_1:
            'Étrange affaire masse chemin position elle. En chien protéger étendue règle bout.',
          client: {
            id: 1,
            reagi_id: 'A000000',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Buisson',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, avenue Céline Da Costa',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '52661',
            adresse_principale_ville: 'Voisin-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1844126706062',
            telephone_secondaire: '',
            email_prioritaire: 'margauxlamy@turpin.fr',
            email_secondaire: 'hardyaurelie@gmail.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 41,
            code: 't9oz1',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 29,
            name: 'Pages',
            zipcode: '86109',
            cedex: '',
            ville: 49,
          },
          referent: {
            id: 25,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.807786Z',
            email: 'claude.lopes7408@example.org',
            firstname: 'Claude',
            lastname: 'Lopes',
            agence: 30,
            fonction: 31,
            service: 29,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 3,
          created_at: '2021-06-30T11:00:50.238157Z',
          updated_at: '2021-06-30T11:00:50.250365Z',
          url: 'http://localhost:8000/users/3/?format=json',
          client_id: 1,
          produit_id: 42,
          agence_id: 31,
          referent_id: 26,
          reagi_id: 'A 000002',
          branche: 'D',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 1,
            reagi_id: 'A000000',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Buisson',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, avenue Céline Da Costa',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '52661',
            adresse_principale_ville: 'Voisin-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1844126706062',
            telephone_secondaire: '',
            email_prioritaire: 'margauxlamy@turpin.fr',
            email_secondaire: 'hardyaurelie@gmail.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 42,
            code: 'L9dO2',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 31,
            name: 'Mace',
            zipcode: '86109',
            cedex: '',
            ville: 51,
          },
          referent: {
            id: 26,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.827498Z',
            email: 'patrick.lecomte7680@example.org',
            firstname: 'Patrick',
            lastname: 'Lecomte',
            agence: 32,
            fonction: 32,
            service: 30,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: 'Ceci est un commentaire très important',
      important: '',
    },
    {
      id: 2,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/2/?format=json',
      contrats: [
        {
          id: 4,
          url: 'http://localhost:8000/users/4/?format=json',
          client_id: 2,
          produit_id: 43,
          agence_id: 33,
          referent_id: 27,
          reagi_id: 'A 000003',
          branche: 'D',
          etat: 'Résilié',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: '16/11/2020',
          date_fin_sp: '27/03/2022',
          decision_sp_n:
            'Possible feuille répandre oublier victime. Voyage réalité relation nord. Air froid réserver taille.',
          decision_sp_n_m_1:
            'Vieillard manier flot saint. Avancer appuyer grâce obéir désir plaindre tendre. Principe port recommencer ouvrir bureau perdu.\nCraindre amour examiner emporter fois. État vin juste.',
          client: {
            id: 2,
            reagi_id: 'A000001',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Etienne',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '991, boulevard Potier',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '39648',
            adresse_principale_ville: 'Pasquiernec',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1176744836763',
            telephone_secondaire: '',
            email_prioritaire: 'olliviervictor@laposte.net',
            email_secondaire: 'danielmeunier@club-internet.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 43,
            code: 'W0FD3',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 33,
            name: 'Barthelemy',
            zipcode: '86109',
            cedex: '',
            ville: 53,
          },
          referent: {
            id: 27,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.844635Z',
            email: 'capucine.martel656@example.org',
            firstname: 'Capucine',
            lastname: 'Martel',
            agence: 34,
            fonction: 33,
            service: 31,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 5,
          url: 'http://localhost:8000/users/5/?format=json',
          client_id: 2,
          produit_id: 44,
          agence_id: 35,
          referent_id: 28,
          reagi_id: 'A 000004',
          branche: 'E',
          etat: 'Suspendu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '23/02/2023',
          prime_type: 'Non confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: '14/04/2020',
          date_fin_sp: '11/11/2021',
          decision_sp_n:
            'Bord pas chemise ignorer. Glisser midi mille asseoir.\nJournal silence portier vers. Après peser vaste ordre. Hier bas gagner avec dire clef.\nCampagne celui qui large porte froid importer.',
          decision_sp_n_m_1:
            'Chiffre agiter demain. Placer couper nouveau rencontrer résoudre. Sauvage envie somme enfin angoisse est courage. Mener endroit champ palais.',
          client: {
            id: 2,
            reagi_id: 'A000001',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Etienne',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '991, boulevard Potier',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '39648',
            adresse_principale_ville: 'Pasquiernec',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1176744836763',
            telephone_secondaire: '',
            email_prioritaire: 'olliviervictor@laposte.net',
            email_secondaire: 'danielmeunier@club-internet.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 44,
            code: 'Y4Ns4',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 2,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 35,
            name: 'Sainte Dorothée',
            zipcode: '86109',
            cedex: '',
            ville: 55,
          },
          referent: {
            id: 28,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.864157Z',
            email: 'stéphane.boutin9487@example.org',
            firstname: 'Stéphane',
            lastname: 'Boutin',
            agence: 36,
            fonction: 34,
            service: 32,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 6,
          url: 'http://localhost:8000/users/6/?format=json',
          client_id: 2,
          produit_id: 45,
          agence_id: 37,
          referent_id: 29,
          reagi_id: 'A 000005',
          branche: 'C',
          etat: 'Résilié',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Décennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 2,
            reagi_id: 'A000001',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Etienne',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '991, boulevard Potier',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '39648',
            adresse_principale_ville: 'Pasquiernec',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1176744836763',
            telephone_secondaire: '',
            email_prioritaire: 'olliviervictor@laposte.net',
            email_secondaire: 'danielmeunier@club-internet.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 45,
            code: 'K7tQ5',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 37,
            name: 'Meunier-sur-Mer',
            zipcode: '86109',
            cedex: '',
            ville: 57,
          },
          referent: {
            id: 29,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.882387Z',
            email: 'aimée.bazin4975@example.org',
            firstname: 'Aimée',
            lastname: 'Bazin',
            agence: 38,
            fonction: 35,
            service: 33,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000001',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Etienne',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '991, boulevard Potier',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '39648',
      adresse_principale_ville: 'Pasquiernec',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1176744836763',
      telephone_secondaire: '',
      email_prioritaire: 'olliviervictor@laposte.net',
      email_secondaire: 'danielmeunier@club-internet.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 3,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/3/?format=json',
      contrats: [
        {
          id: 7,
          url: 'http://localhost:8000/users/7/?format=json',
          client_id: 3,
          produit_id: 46,
          agence_id: 39,
          referent_id: 30,
          reagi_id: 'A 000006',
          branche: '',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 3,
            reagi_id: 'A000002',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Moreno S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '8, rue de Julien',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '90103',
            adresse_principale_ville: 'GuilbertVille',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3091292783262',
            telephone_secondaire: '',
            email_prioritaire: 'plefebvre@guillon.net',
            email_secondaire: 'agathe36@charrier.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 46,
            code: 'j7Mh6',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 39,
            name: 'Toussaint',
            zipcode: '86109',
            cedex: '',
            ville: 59,
          },
          referent: {
            id: 30,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.901131Z',
            email: 'vincent.pasquier8150@example.org',
            firstname: 'Vincent',
            lastname: 'Pasquier',
            agence: 40,
            fonction: 36,
            service: 34,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 8,
          url: 'http://localhost:8000/users/8/?format=json',
          client_id: 3,
          produit_id: 47,
          agence_id: 41,
          referent_id: 31,
          reagi_id: 'A 000007',
          branche: 'G',
          etat: 'Échu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '13/12/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 3,
            reagi_id: 'A000002',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Moreno S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '8, rue de Julien',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '90103',
            adresse_principale_ville: 'GuilbertVille',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3091292783262',
            telephone_secondaire: '',
            email_prioritaire: 'plefebvre@guillon.net',
            email_secondaire: 'agathe36@charrier.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 47,
            code: 't4eE7',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 3,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 41,
            name: 'Philippedan',
            zipcode: '86109',
            cedex: '',
            ville: 61,
          },
          referent: {
            id: 31,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.921862Z',
            email: 'marcel.mendes3859@example.org',
            firstname: 'Marcel',
            lastname: 'Mendes',
            agence: 42,
            fonction: 37,
            service: 35,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 9,
          url: 'http://localhost:8000/users/9/?format=json',
          client_id: 3,
          produit_id: 48,
          agence_id: 43,
          referent_id: 32,
          reagi_id: 'A 000008',
          branche: 'D',
          etat: 'Suspendu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '07/02/2023',
          prime_type: 'Confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: '25/10/2020',
          date_fin_sp: '25/01/2022',
          decision_sp_n:
            'Mode passion bouche précis difficile. Peine rose soirée vie nature.',
          decision_sp_n_m_1:
            'Secret face grave gauche vif lever.\nDominer fatiguer troubler tendre en. Venir doucement français ce hasard comment faveur. Poussière quand beau.',
          client: {
            id: 3,
            reagi_id: 'A000002',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Moreno S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '8, rue de Julien',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '90103',
            adresse_principale_ville: 'GuilbertVille',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3091292783262',
            telephone_secondaire: '',
            email_prioritaire: 'plefebvre@guillon.net',
            email_secondaire: 'agathe36@charrier.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 48,
            code: 'o1oN8',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 4,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 43,
            name: 'Sauvage-les-Bains',
            zipcode: '86109',
            cedex: '',
            ville: 63,
          },
          referent: {
            id: 32,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.942544Z',
            email: 'laure.vincent9590@example.org',
            firstname: 'Laure',
            lastname: 'Vincent',
            agence: 44,
            fonction: 38,
            service: 36,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000002',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Moreno S.A.R.L.',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '8, rue de Julien',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '90103',
      adresse_principale_ville: 'GuilbertVille',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '3091292783262',
      telephone_secondaire: '',
      email_prioritaire: 'plefebvre@guillon.net',
      email_secondaire: 'agathe36@charrier.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 4,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/4/?format=json',
      contrats: [
        {
          id: 10,
          url: 'http://localhost:8000/users/10/?format=json',
          client_id: 4,
          produit_id: 49,
          agence_id: 45,
          referent_id: 33,
          reagi_id: 'A 000009',
          branche: 'E',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '09/12/2022',
          prime_type: 'Non confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 4,
            reagi_id: 'A000003',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Lemoine',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '1, chemin Lejeune',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '65815',
            adresse_principale_ville: 'Garcia-sur-Leclerc',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1217543524254',
            telephone_secondaire: '',
            email_prioritaire: 'bernardarnaude@rodrigues.com',
            email_secondaire: 'etienne28@free.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 49,
            code: 'M7mw9',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 5,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 45,
            name: 'Leclercq',
            zipcode: '86109',
            cedex: '',
            ville: 65,
          },
          referent: {
            id: 33,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.962464Z',
            email: 'emmanuel.brunel7750@example.org',
            firstname: 'Emmanuel',
            lastname: 'Brunel',
            agence: 46,
            fonction: 39,
            service: 37,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 11,
          url: 'http://localhost:8000/users/11/?format=json',
          client_id: 4,
          produit_id: 50,
          agence_id: 47,
          referent_id: 34,
          reagi_id: 'A 000010',
          branche: 'E',
          etat: 'Suspendu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '03/04/2023',
          prime_type: 'Non confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: '31/08/2020',
          date_fin_sp: '31/10/2021',
          decision_sp_n:
            'Tout faible froid fumée. Regretter beaux manquer creuser conscience contenter erreur. Dès banc parent.',
          decision_sp_n_m_1:
            'Franc environ agent arracher. Intérieur mer manquer condition conduire rendre.',
          client: {
            id: 4,
            reagi_id: 'A000003',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Lemoine',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '1, chemin Lejeune',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '65815',
            adresse_principale_ville: 'Garcia-sur-Leclerc',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1217543524254',
            telephone_secondaire: '',
            email_prioritaire: 'bernardarnaude@rodrigues.com',
            email_secondaire: 'etienne28@free.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 50,
            code: 'Q7xu10',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 6,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 47,
            name: 'Hoarau',
            zipcode: '86109',
            cedex: '',
            ville: 67,
          },
          referent: {
            id: 34,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:32.983758Z',
            email: 'zacharie.gay8803@example.org',
            firstname: 'Zacharie',
            lastname: 'Gay',
            agence: 48,
            fonction: 40,
            service: 38,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000003',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Lemoine',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '1, chemin Lejeune',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '65815',
      adresse_principale_ville: 'Garcia-sur-Leclerc',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1217543524254',
      telephone_secondaire: '',
      email_prioritaire: 'bernardarnaude@rodrigues.com',
      email_secondaire: 'etienne28@free.fr',
      commentaire: '',
      important:
        "Commentaire  très important à remplir pour plus d'information",
    },
    {
      id: 5,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/5/?format=json',
      contrats: [
        {
          id: 12,
          url: 'http://localhost:8000/users/12/?format=json',
          client_id: 5,
          produit_id: 51,
          agence_id: 49,
          referent_id: 35,
          reagi_id: 'A 000011',
          branche: 'J',
          etat: 'En cours',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: '16/05/2021',
          date_fin_sp: '09/07/2021',
          decision_sp_n:
            'Vent semaine honte changement défaut arracher fenêtre. Après silencieux présence six geste soirée. Vers personne poids présent.',
          decision_sp_n_m_1:
            "Agiter bord quarante aucun. Déposer volonté aujourd'hui faute diriger.\nDoucement tracer tendre prouver quitter plein fond.",
          client: {
            id: 5,
            reagi_id: 'A000004',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Vaillant Noel SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '91, avenue de Martins',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '17085',
            adresse_principale_ville: 'Royer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '7633763180057',
            telephone_secondaire: '',
            email_prioritaire: 'celine11@barre.org',
            email_secondaire: 'marguerite79@gmail.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 51,
            code: 'j8KA11',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 49,
            name: 'Clement',
            zipcode: '86109',
            cedex: '',
            ville: 69,
          },
          referent: {
            id: 35,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.004805Z',
            email: 'élisabeth.masse485@example.org',
            firstname: 'Élisabeth',
            lastname: 'Masse',
            agence: 50,
            fonction: 41,
            service: 39,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 13,
          url: 'http://localhost:8000/users/13/?format=json',
          client_id: 5,
          produit_id: 52,
          agence_id: 51,
          referent_id: 36,
          reagi_id: 'A 000012',
          branche: 'F',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Biennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 5,
            reagi_id: 'A000004',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Vaillant Noel SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '91, avenue de Martins',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '17085',
            adresse_principale_ville: 'Royer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '7633763180057',
            telephone_secondaire: '',
            email_prioritaire: 'celine11@barre.org',
            email_secondaire: 'marguerite79@gmail.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 52,
            code: 'Q7uR12',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 51,
            name: 'Bessonboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 71,
          },
          referent: {
            id: 36,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.025664Z',
            email: 'michelle.payet3730@example.org',
            firstname: 'Michelle',
            lastname: 'Payet',
            agence: 52,
            fonction: 42,
            service: 40,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000004',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Vaillant Noel SA',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '91, avenue de Martins',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '17085',
      adresse_principale_ville: 'Royer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '7633763180057',
      telephone_secondaire: '',
      email_prioritaire: 'celine11@barre.org',
      email_secondaire: 'marguerite79@gmail.com',
      commentaire: '',
      important: '',
    },
    {
      id: 6,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/6/?format=json',
      contrats: [
        {
          id: 14,
          url: 'http://localhost:8000/users/14/?format=json',
          client_id: 6,
          produit_id: 53,
          agence_id: 53,
          referent_id: 37,
          reagi_id: 'A 000013',
          branche: 'B',
          etat: 'En cours',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: '18/08/2019',
          date_fin_sp: '23/12/2021',
          decision_sp_n:
            "Traverser mort genre donc. Près terre soldat haut.\nGarder comprendre justice âge aujourd'hui premier réel. Saint soi brusquement étroit affaire donc résister.",
          decision_sp_n_m_1:
            "Capable expression père l'une jeu. Relation lutte sous par trou. Calme figurer magnifique part.\nTranquille couler passer nourrir. Cause même docteur abandonner gloire.",
          client: {
            id: 6,
            reagi_id: 'A000005',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Picard',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '86, rue Leroux',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '64826',
            adresse_principale_ville: 'Lopez',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5695568718196',
            telephone_secondaire: '',
            email_prioritaire: 'benoitdaniel@wanadoo.fr',
            email_secondaire: 'olivie04@clement.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 53,
            code: 'z0EG13',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 53,
            name: 'Rodriguez',
            zipcode: '86109',
            cedex: '',
            ville: 73,
          },
          referent: {
            id: 37,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.044989Z',
            email: 'honoré.potier7513@example.org',
            firstname: 'Honoré',
            lastname: 'Potier',
            agence: 54,
            fonction: 43,
            service: 41,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 15,
          url: 'http://localhost:8000/users/15/?format=json',
          client_id: 6,
          produit_id: 54,
          agence_id: 55,
          referent_id: 38,
          reagi_id: 'A 000014',
          branche: '',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: '12/11/2019',
          date_fin_sp: '12/06/2021',
          decision_sp_n:
            'Sans certainement comprendre gauche.\nSentir note perte exécuter remonter quinze voie. Silencieux beau étonner partout malheur voici.',
          decision_sp_n_m_1:
            'Réfléchir théâtre accuser aspect muet casser céder coup. Obéir autrement gauche. Île drame seconde public.',
          client: {
            id: 6,
            reagi_id: 'A000005',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Picard',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '86, rue Leroux',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '64826',
            adresse_principale_ville: 'Lopez',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5695568718196',
            telephone_secondaire: '',
            email_prioritaire: 'benoitdaniel@wanadoo.fr',
            email_secondaire: 'olivie04@clement.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 54,
            code: 'z5Ey14',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 55,
            name: 'Royerboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 75,
          },
          referent: {
            id: 38,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.062265Z',
            email: 'auguste.maurice8372@example.org',
            firstname: 'Auguste',
            lastname: 'Maurice',
            agence: 56,
            fonction: 44,
            service: 42,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000005',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Picard',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '86, rue Leroux',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '64826',
      adresse_principale_ville: 'Lopez',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '5695568718196',
      telephone_secondaire: '',
      email_prioritaire: 'benoitdaniel@wanadoo.fr',
      email_secondaire: 'olivie04@clement.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 7,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/7/?format=json',
      contrats: [
        {
          id: 16,
          url: 'http://localhost:8000/users/16/?format=json',
          client_id: 7,
          produit_id: 55,
          agence_id: 57,
          referent_id: 39,
          reagi_id: 'A 000015',
          branche: 'G',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: '30/03/2020',
          date_fin_sp: '24/11/2021',
          decision_sp_n:
            'Lourd indiquer avouer pierre éclairer rôle étouffer franc. Caresser rose cacher accepter réserver matière venir. Ruine aide chemise heure danger pur sol paysage.',
          decision_sp_n_m_1:
            'Tenir soi elle détail.\nRéussir frère souffrance tendre. Cercle dormir gloire. Éternel montagne mais désirer importance résoudre.',
          client: {
            id: 7,
            reagi_id: 'A000006',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Laroche Regnier S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '16, boulevard Imbert',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '27595',
            adresse_principale_ville: 'Guillou-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6095770118812',
            telephone_secondaire: '',
            email_prioritaire: 'brunelrenee@bouygtel.fr',
            email_secondaire: 'njacquot@gros.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 55,
            code: 'X7Yh15',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 57,
            name: 'Baudry-les-Bains',
            zipcode: '86109',
            cedex: '',
            ville: 77,
          },
          referent: {
            id: 39,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.079594Z',
            email: 'timothée.lefort4741@example.org',
            firstname: 'Timothée',
            lastname: 'Lefort',
            agence: 58,
            fonction: 45,
            service: 43,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 17,
          url: 'http://localhost:8000/users/17/?format=json',
          client_id: 7,
          produit_id: 56,
          agence_id: 59,
          referent_id: 40,
          reagi_id: 'A 000016',
          branche: 'B',
          etat: 'Résilié',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Trimestrielle',
          date_debut_sp: '08/03/2021',
          date_fin_sp: '10/10/2021',
          decision_sp_n:
            'Mine intelligence taille avec intérieur présenter cri vaste. Commencer poète marché poste question sourd étendue.',
          decision_sp_n_m_1:
            'Me réponse garder occasion classe.\nPoids midi point de magnifique de désigner. Lourd fenêtre diriger retenir. Blond colon intérieur réunir. Préférer arrêter plusieurs observer jeune traîner ignorer.',
          client: {
            id: 7,
            reagi_id: 'A000006',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Laroche Regnier S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '16, boulevard Imbert',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '27595',
            adresse_principale_ville: 'Guillou-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6095770118812',
            telephone_secondaire: '',
            email_prioritaire: 'brunelrenee@bouygtel.fr',
            email_secondaire: 'njacquot@gros.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 56,
            code: 'i4RR16',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 59,
            name: 'Gauthier',
            zipcode: '86109',
            cedex: '',
            ville: 79,
          },
          referent: {
            id: 40,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.095563Z',
            email: 'raymond.baudry7321@example.org',
            firstname: 'Raymond',
            lastname: 'Baudry',
            agence: 60,
            fonction: 46,
            service: 44,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 18,
          url: 'http://localhost:8000/users/18/?format=json',
          client_id: 7,
          produit_id: 57,
          agence_id: 61,
          referent_id: 41,
          reagi_id: 'A 000017',
          branche: 'F',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '12/05/2023',
          prime_type: 'Non confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: '26/02/2020',
          date_fin_sp: '17/07/2021',
          decision_sp_n:
            'Très rue bien mur ci ciel. Haut soldat année pierre. Paix subir comprendre quitter souffrance.',
          decision_sp_n_m_1:
            'Espérer contre jeter amuser apercevoir répondre. Cheval lentement parce que paraître ramasser.',
          client: {
            id: 7,
            reagi_id: 'A000006',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Laroche Regnier S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '16, boulevard Imbert',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '27595',
            adresse_principale_ville: 'Guillou-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6095770118812',
            telephone_secondaire: '',
            email_prioritaire: 'brunelrenee@bouygtel.fr',
            email_secondaire: 'njacquot@gros.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 57,
            code: 'S2id17',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 7,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 61,
            name: 'Leveque',
            zipcode: '86109',
            cedex: '',
            ville: 81,
          },
          referent: {
            id: 41,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.114856Z',
            email: 'lucie.fournier8878@example.org',
            firstname: 'Lucie',
            lastname: 'Fournier',
            agence: 62,
            fonction: 47,
            service: 45,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000006',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Laroche Regnier S.A.R.L.',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '16, boulevard Imbert',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '27595',
      adresse_principale_ville: 'Guillou-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '6095770118812',
      telephone_secondaire: '',
      email_prioritaire: 'brunelrenee@bouygtel.fr',
      email_secondaire: 'njacquot@gros.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 8,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/8/?format=json',
      contrats: [
        {
          id: 19,
          url: 'http://localhost:8000/users/19/?format=json',
          client_id: 8,
          produit_id: 58,
          agence_id: 63,
          referent_id: 42,
          reagi_id: 'A 000018',
          branche: 'A',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 8,
            reagi_id: 'A000007',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gomes',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '9, chemin Merle',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '72889',
            adresse_principale_ville: 'Perrot-les-Bains',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3393226676540',
            telephone_secondaire: '',
            email_prioritaire: 'reynaudvalentine@tele2.fr',
            email_secondaire: 'zpayet@raymond.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 58,
            code: 'o3Fm18',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 63,
            name: 'Saint Lorraine',
            zipcode: '86109',
            cedex: '',
            ville: 83,
          },
          referent: {
            id: 42,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.131096Z',
            email: 'julie.ollivier2273@example.org',
            firstname: 'Julie',
            lastname: 'Ollivier',
            agence: 64,
            fonction: 48,
            service: 46,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 20,
          url: 'http://localhost:8000/users/20/?format=json',
          client_id: 8,
          produit_id: 59,
          agence_id: 65,
          referent_id: 43,
          reagi_id: 'A 000019',
          branche: 'E',
          etat: 'Résilié',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 8,
            reagi_id: 'A000007',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gomes',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '9, chemin Merle',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '72889',
            adresse_principale_ville: 'Perrot-les-Bains',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3393226676540',
            telephone_secondaire: '',
            email_prioritaire: 'reynaudvalentine@tele2.fr',
            email_secondaire: 'zpayet@raymond.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 59,
            code: 'X7sf19',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 65,
            name: 'Remy',
            zipcode: '86109',
            cedex: '',
            ville: 85,
          },
          referent: {
            id: 43,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.149472Z',
            email: 'bertrand.gosselin1630@example.org',
            firstname: 'Bertrand',
            lastname: 'Gosselin',
            agence: 66,
            fonction: 49,
            service: 47,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 21,
          url: 'http://localhost:8000/users/21/?format=json',
          client_id: 8,
          produit_id: 60,
          agence_id: 67,
          referent_id: 44,
          reagi_id: 'A 000020',
          branche: 'G',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 8,
            reagi_id: 'A000007',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gomes',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '9, chemin Merle',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '72889',
            adresse_principale_ville: 'Perrot-les-Bains',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '3393226676540',
            telephone_secondaire: '',
            email_prioritaire: 'reynaudvalentine@tele2.fr',
            email_secondaire: 'zpayet@raymond.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 60,
            code: 'a6ox20',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 67,
            name: 'Pelletier',
            zipcode: '86109',
            cedex: '',
            ville: 87,
          },
          referent: {
            id: 44,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.165868Z',
            email: 'michelle.bouchet4710@example.org',
            firstname: 'Michelle',
            lastname: 'Bouchet',
            agence: 68,
            fonction: 50,
            service: 48,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000007',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Gomes',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '9, chemin Merle',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '72889',
      adresse_principale_ville: 'Perrot-les-Bains',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '3393226676540',
      telephone_secondaire: '',
      email_prioritaire: 'reynaudvalentine@tele2.fr',
      email_secondaire: 'zpayet@raymond.com',
      commentaire: '',
      important: '',
    },
    {
      id: 9,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/9/?format=json',
      contrats: [
        {
          id: 22,
          url: 'http://localhost:8000/users/22/?format=json',
          client_id: 9,
          produit_id: 61,
          agence_id: 69,
          referent_id: 45,
          reagi_id: 'A 000021',
          branche: 'A',
          etat: 'En cours',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: '02/07/2020',
          date_fin_sp: '01/12/2021',
          decision_sp_n:
            'Détail respecter journal seulement. Position ci aller haute prêt sens.\nFils commander cela chaud courage fatigue. Brusquement voix céder public secret.',
          decision_sp_n_m_1:
            'Contre soir remplacer oser pont même chef. Reste étendre résoudre français lueur doute. Leur doigt dont lettre allumer chaud.\nRetomber siècle oui étude dormir soi. Je décrire frère dire.',
          client: {
            id: 9,
            reagi_id: 'A000008',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Ferrand',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, rue Gaillard',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '51318',
            adresse_principale_ville: 'Aubert',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5320488463224',
            telephone_secondaire: '',
            email_prioritaire: 'stephaniemasson@yahoo.fr',
            email_secondaire: 'chantal88@yahoo.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 61,
            code: 'A6kk21',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 69,
            name: 'Godard',
            zipcode: '86109',
            cedex: '',
            ville: 89,
          },
          referent: {
            id: 45,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.182578Z',
            email: 'renée.julien236@example.org',
            firstname: 'Renée',
            lastname: 'Julien',
            agence: 70,
            fonction: 51,
            service: 49,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 23,
          url: 'http://localhost:8000/users/23/?format=json',
          client_id: 9,
          produit_id: 62,
          agence_id: 71,
          referent_id: 46,
          reagi_id: 'A 000022',
          branche: 'J',
          etat: 'Échu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '06/11/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Biennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 9,
            reagi_id: 'A000008',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Ferrand',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, rue Gaillard',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '51318',
            adresse_principale_ville: 'Aubert',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5320488463224',
            telephone_secondaire: '',
            email_prioritaire: 'stephaniemasson@yahoo.fr',
            email_secondaire: 'chantal88@yahoo.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 62,
            code: 'q4Ua22',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 8,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 71,
            name: 'Valetteboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 91,
          },
          referent: {
            id: 46,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.201047Z',
            email: 'maurice.costa9440@example.org',
            firstname: 'Maurice',
            lastname: 'Costa',
            agence: 72,
            fonction: 52,
            service: 50,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 24,
          url: 'http://localhost:8000/users/24/?format=json',
          client_id: 9,
          produit_id: 63,
          agence_id: 73,
          referent_id: 47,
          reagi_id: 'A 000023',
          branche: 'E',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '26/01/2023',
          prime_type: 'Non confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: '09/06/2020',
          date_fin_sp: '29/08/2021',
          decision_sp_n:
            'Lune répondre inconnu dépasser. Léger planche prêt poésie oreille. Dormir soldat retomber raison comment.\nVue milieu même français frère. Histoire forêt état aimer.',
          decision_sp_n_m_1:
            'Naturel vaincre demande voie vieil oublier pied. Nord revenir ton plusieurs cesser. Terreur saint reconnaître côte parti cour.',
          client: {
            id: 9,
            reagi_id: 'A000008',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Ferrand',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '42, rue Gaillard',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '51318',
            adresse_principale_ville: 'Aubert',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5320488463224',
            telephone_secondaire: '',
            email_prioritaire: 'stephaniemasson@yahoo.fr',
            email_secondaire: 'chantal88@yahoo.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 63,
            code: 'j5qI23',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 9,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 73,
            name: 'Bourdon',
            zipcode: '86109',
            cedex: '',
            ville: 93,
          },
          referent: {
            id: 47,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.218802Z',
            email: 'vincent.perez1007@example.org',
            firstname: 'Vincent',
            lastname: 'Perez',
            agence: 74,
            fonction: 53,
            service: 51,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000008',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Ferrand',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, rue Gaillard',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '51318',
      adresse_principale_ville: 'Aubert',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '5320488463224',
      telephone_secondaire: '',
      email_prioritaire: 'stephaniemasson@yahoo.fr',
      email_secondaire: 'chantal88@yahoo.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 10,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/10/?format=json',
      contrats: [
        {
          id: 25,
          url: 'http://localhost:8000/users/25/?format=json',
          client_id: 10,
          produit_id: 64,
          agence_id: 75,
          referent_id: 48,
          reagi_id: 'A 000024',
          branche: 'A',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: '10/08/2019',
          date_fin_sp: '10/08/2021',
          decision_sp_n:
            'Promener assister devoir prendre résister désigner. Côte sourire lier petit soumettre. Réserver gauche chance sien ou enlever beauté.',
          decision_sp_n_m_1:
            'Peine respect repousser jour intérieur chant par. Espèce faire effet pauvre regarder peu accuser.',
          client: {
            id: 10,
            reagi_id: 'A000009',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Turpin',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '38, boulevard de Marty',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '68473',
            adresse_principale_ville: 'Charrier-sur-Mer',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1616481077684',
            telephone_secondaire: '',
            email_prioritaire: 'celine88@gautier.net',
            email_secondaire: 'ferreiraclemence@live.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 64,
            code: 'l5cR24',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 75,
            name: 'LucasVille',
            zipcode: '86109',
            cedex: '',
            ville: 95,
          },
          referent: {
            id: 48,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.235353Z',
            email: 'patricia.dupuy5844@example.org',
            firstname: 'Patricia',
            lastname: 'Dupuy',
            agence: 76,
            fonction: 54,
            service: 52,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000009',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Turpin',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '38, boulevard de Marty',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '68473',
      adresse_principale_ville: 'Charrier-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1616481077684',
      telephone_secondaire: '',
      email_prioritaire: 'celine88@gautier.net',
      email_secondaire: 'ferreiraclemence@live.com',
      commentaire: '',
      important: '',
    },
    {
      id: 11,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/11/?format=json',
      contrats: [
        {
          id: 26,
          url: 'http://localhost:8000/users/26/?format=json',
          client_id: 11,
          produit_id: 65,
          agence_id: 77,
          referent_id: 49,
          reagi_id: 'A 000025',
          branche: 'F',
          etat: 'Résilié',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Décennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 11,
            reagi_id: 'A000010',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Faivre',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '4, avenue Julie De Oliveira',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '31123',
            adresse_principale_ville: 'Saint Manon',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '0870796173966',
            telephone_secondaire: '',
            email_prioritaire: 'loiseaudominique@free.fr',
            email_secondaire: 'charlotteguilbert@maurice.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 65,
            code: 'Z8lV25',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 77,
            name: 'Sainte Yves',
            zipcode: '86109',
            cedex: '',
            ville: 97,
          },
          referent: {
            id: 49,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.254335Z',
            email: 'susan.ferreira4523@example.org',
            firstname: 'Susan',
            lastname: 'Ferreira',
            agence: 78,
            fonction: 55,
            service: 53,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000010',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Faivre',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '4, avenue Julie De Oliveira',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '31123',
      adresse_principale_ville: 'Saint Manon',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '0870796173966',
      telephone_secondaire: '',
      email_prioritaire: 'loiseaudominique@free.fr',
      email_secondaire: 'charlotteguilbert@maurice.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 12,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/12/?format=json',
      contrats: [
        {
          id: 27,
          url: 'http://localhost:8000/users/27/?format=json',
          client_id: 12,
          produit_id: 66,
          agence_id: 79,
          referent_id: 50,
          reagi_id: 'A 000026',
          branche: 'B',
          etat: 'Échu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '05/12/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 12,
            reagi_id: 'A000011',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Tanguy Bernier SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '737, rue Dominique Picard',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '77553',
            adresse_principale_ville: 'Malletnec',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '8252116337466',
            telephone_secondaire: '',
            email_prioritaire: 'louiseroger@richard.net',
            email_secondaire: 'constanceneveu@sfr.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 66,
            code: 's3Pu26',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 10,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 79,
            name: 'Saint Jérômeboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 99,
          },
          referent: {
            id: 50,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.276599Z',
            email: 'virginie.marty7829@example.org',
            firstname: 'Virginie',
            lastname: 'Marty',
            agence: 80,
            fonction: 56,
            service: 54,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 28,
          url: 'http://localhost:8000/users/28/?format=json',
          client_id: 12,
          produit_id: 67,
          agence_id: 81,
          referent_id: 51,
          reagi_id: 'A 000027',
          branche: '',
          etat: 'Suspendu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '05/06/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: '23/05/2020',
          date_fin_sp: '09/03/2022',
          decision_sp_n:
            'Lumière faible vérité marier frère seconde trop divers. Part quarante sentier famille autrefois relever. Reconnaître étranger moment somme servir certain disparaître.',
          decision_sp_n_m_1:
            'Finir traîner fois perte ennemi manger haïr. Réalité théâtre vague ouvrir elle avancer.\nDormir coeur entrer et toute. Valeur devoir beau terreur ombre.',
          client: {
            id: 12,
            reagi_id: 'A000011',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Tanguy Bernier SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '737, rue Dominique Picard',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '77553',
            adresse_principale_ville: 'Malletnec',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '8252116337466',
            telephone_secondaire: '',
            email_prioritaire: 'louiseroger@richard.net',
            email_secondaire: 'constanceneveu@sfr.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 67,
            code: 'A9Sj27',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 11,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 81,
            name: 'Chartier-les-Bains',
            zipcode: '86109',
            cedex: '',
            ville: 101,
          },
          referent: {
            id: 51,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.303067Z',
            email: 'charlotte.blin4413@example.org',
            firstname: 'Charlotte',
            lastname: 'Blin',
            agence: 82,
            fonction: 57,
            service: 55,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000011',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Tanguy Bernier SA',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '737, rue Dominique Picard',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '77553',
      adresse_principale_ville: 'Malletnec',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '8252116337466',
      telephone_secondaire: '',
      email_prioritaire: 'louiseroger@richard.net',
      email_secondaire: 'constanceneveu@sfr.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 13,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/13/?format=json',
      contrats: [
        {
          id: 29,
          url: 'http://localhost:8000/users/29/?format=json',
          client_id: 13,
          produit_id: 68,
          agence_id: 83,
          referent_id: 52,
          reagi_id: 'A 000028',
          branche: 'D',
          etat: 'Échu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '04/02/2023',
          prime_type: 'Confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 13,
            reagi_id: 'A000012',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Dumont S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '4, rue Leleu',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '89636',
            adresse_principale_ville: 'Sainte Cécile',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '9621019363619',
            telephone_secondaire: '',
            email_prioritaire: 'gcharpentier@dbmail.com',
            email_secondaire: 'mbaron@ruiz.org',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 68,
            code: 'h3MT28',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 12,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 83,
            name: 'GoncalvesBourg',
            zipcode: '86109',
            cedex: '',
            ville: 103,
          },
          referent: {
            id: 52,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.322415Z',
            email: 'david.fabre3431@example.org',
            firstname: 'David',
            lastname: 'Fabre',
            agence: 84,
            fonction: 58,
            service: 56,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 30,
          url: 'http://localhost:8000/users/30/?format=json',
          client_id: 13,
          produit_id: 69,
          agence_id: 85,
          referent_id: 53,
          reagi_id: 'A 000029',
          branche: 'F',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 13,
            reagi_id: 'A000012',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Dumont S.A.R.L.',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '4, rue Leleu',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '89636',
            adresse_principale_ville: 'Sainte Cécile',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '9621019363619',
            telephone_secondaire: '',
            email_prioritaire: 'gcharpentier@dbmail.com',
            email_secondaire: 'mbaron@ruiz.org',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 69,
            code: 'l7gD29',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 85,
            name: 'Carre',
            zipcode: '86109',
            cedex: '',
            ville: 105,
          },
          referent: {
            id: 53,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.337607Z',
            email: 'lorraine.mathieu4472@example.org',
            firstname: 'Lorraine',
            lastname: 'Mathieu',
            agence: 86,
            fonction: 59,
            service: 57,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000012',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Dumont S.A.R.L.',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '4, rue Leleu',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '89636',
      adresse_principale_ville: 'Sainte Cécile',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '9621019363619',
      telephone_secondaire: '',
      email_prioritaire: 'gcharpentier@dbmail.com',
      email_secondaire: 'mbaron@ruiz.org',
      commentaire: '',
      important: '',
    },
    {
      id: 14,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/14/?format=json',
      contrats: [
        {
          id: 31,
          url: 'http://localhost:8000/users/31/?format=json',
          client_id: 14,
          produit_id: 70,
          agence_id: 87,
          referent_id: 54,
          reagi_id: 'A 000030',
          branche: 'B',
          etat: 'Résilié',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '23/10/2022',
          prime_type: 'Non confié',
          prime_fractionnement: 'Décennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 14,
            reagi_id: 'A000013',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gerard',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '39, chemin Samson',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '19634',
            adresse_principale_ville: 'Saint Émile',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '9794554996096',
            telephone_secondaire: '',
            email_prioritaire: 'margaud76@hotmail.fr',
            email_secondaire: 'susanne94@live.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 70,
            code: 'x7KC30',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 13,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 87,
            name: 'Sainte Lucy-la-Forêt',
            zipcode: '86109',
            cedex: '',
            ville: 107,
          },
          referent: {
            id: 54,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.354812Z',
            email: 'monique.mace563@example.org',
            firstname: 'Monique',
            lastname: 'Mace',
            agence: 88,
            fonction: 60,
            service: 58,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 32,
          url: 'http://localhost:8000/users/32/?format=json',
          client_id: 14,
          produit_id: 71,
          agence_id: 89,
          referent_id: 55,
          reagi_id: 'A 000031',
          branche: 'J',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '06/02/2023',
          prime_type: 'Confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: '24/03/2020',
          date_fin_sp: '02/11/2021',
          decision_sp_n:
            'Absolument autre visage lumière sable. Changer parcourir figurer préparer tandis que valeur perte.\nPeser rare de intelligence. Envelopper haut auquel apporter court point révolution.',
          decision_sp_n_m_1:
            'Silencieux problème attitude jeune attirer.\nOccasion remplir faute. Chaleur diriger plante soir caractère monde.\nPaysage jeter suffire retirer ni prétendre vers.',
          client: {
            id: 14,
            reagi_id: 'A000013',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gerard',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '39, chemin Samson',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '19634',
            adresse_principale_ville: 'Saint Émile',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '9794554996096',
            telephone_secondaire: '',
            email_prioritaire: 'margaud76@hotmail.fr',
            email_secondaire: 'susanne94@live.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 71,
            code: 'H5zN31',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 14,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 89,
            name: 'Vallet',
            zipcode: '86109',
            cedex: '',
            ville: 109,
          },
          referent: {
            id: 55,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.372179Z',
            email: 'émilie.laurent3688@example.org',
            firstname: 'Émilie',
            lastname: 'Laurent',
            agence: 90,
            fonction: 61,
            service: 59,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 33,
          url: 'http://localhost:8000/users/33/?format=json',
          client_id: 14,
          produit_id: 72,
          agence_id: 91,
          referent_id: 56,
          reagi_id: 'A 000032',
          branche: 'F',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 14,
            reagi_id: 'A000013',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Gerard',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '39, chemin Samson',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '19634',
            adresse_principale_ville: 'Saint Émile',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '9794554996096',
            telephone_secondaire: '',
            email_prioritaire: 'margaud76@hotmail.fr',
            email_secondaire: 'susanne94@live.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 72,
            code: 'a8kV32',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 91,
            name: 'Costeboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 111,
          },
          referent: {
            id: 56,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.388063Z',
            email: 'martine.bousquet12@example.org',
            firstname: 'Martine',
            lastname: 'Bousquet',
            agence: 92,
            fonction: 62,
            service: 60,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000013',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Gerard',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '39, chemin Samson',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '19634',
      adresse_principale_ville: 'Saint Émile',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '9794554996096',
      telephone_secondaire: '',
      email_prioritaire: 'margaud76@hotmail.fr',
      email_secondaire: 'susanne94@live.com',
      commentaire: '',
      important: '',
    },
    {
      id: 15,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/15/?format=json',
      contrats: [
        {
          id: 34,
          url: 'http://localhost:8000/users/34/?format=json',
          client_id: 15,
          produit_id: 73,
          agence_id: 93,
          referent_id: 57,
          reagi_id: 'A 000033',
          branche: 'D',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 15,
            reagi_id: 'A000014',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Schneider Raymond et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '29, boulevard de Renault',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '23578',
            adresse_principale_ville: 'Bernardboeuf',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '4208749329356',
            telephone_secondaire: '',
            email_prioritaire: 'pruvostmichelle@hotmail.fr',
            email_secondaire: 'andrerocher@bouvier.com',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 73,
            code: 'k1Ca33',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 93,
            name: 'Saint Agnès-les-Bains',
            zipcode: '86109',
            cedex: '',
            ville: 113,
          },
          referent: {
            id: 57,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.403531Z',
            email: 'colette.verdier1472@example.org',
            firstname: 'Colette',
            lastname: 'Verdier',
            agence: 94,
            fonction: 63,
            service: 61,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000014',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Schneider Raymond et Fils',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '29, boulevard de Renault',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '23578',
      adresse_principale_ville: 'Bernardboeuf',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '4208749329356',
      telephone_secondaire: '',
      email_prioritaire: 'pruvostmichelle@hotmail.fr',
      email_secondaire: 'andrerocher@bouvier.com',
      commentaire: '',
      important: '',
    },
    {
      id: 16,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/16/?format=json',
      contrats: [
        {
          id: 35,
          url: 'http://localhost:8000/users/35/?format=json',
          client_id: 16,
          produit_id: 74,
          agence_id: 95,
          referent_id: 58,
          reagi_id: 'A 000034',
          branche: 'J',
          etat: 'Résilié',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '21/03/2023',
          prime_type: 'Confié',
          prime_fractionnement: 'Biennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 16,
            reagi_id: 'A000015',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Clerc Delannoy SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: 'chemin Michel Rey',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '26426',
            adresse_principale_ville: 'Blot',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5074220449643',
            telephone_secondaire: '',
            email_prioritaire: 'thibaultmarianne@breton.org',
            email_secondaire: 'leonthibault@orange.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 74,
            code: 'W6oC34',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 15,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 95,
            name: 'Baudryboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 115,
          },
          referent: {
            id: 58,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.427363Z',
            email: 'simone.olivier4726@example.org',
            firstname: 'Simone',
            lastname: 'Olivier',
            agence: 96,
            fonction: 64,
            service: 62,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 36,
          url: 'http://localhost:8000/users/36/?format=json',
          client_id: 16,
          produit_id: 75,
          agence_id: 97,
          referent_id: 59,
          reagi_id: 'A 000035',
          branche: 'B',
          etat: 'Suspendu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 16,
            reagi_id: 'A000015',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Clerc Delannoy SA',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: 'chemin Michel Rey',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '26426',
            adresse_principale_ville: 'Blot',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5074220449643',
            telephone_secondaire: '',
            email_prioritaire: 'thibaultmarianne@breton.org',
            email_secondaire: 'leonthibault@orange.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 75,
            code: 'U7TX35',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 97,
            name: 'Fernandez-sur-Barthelemy',
            zipcode: '86109',
            cedex: '',
            ville: 117,
          },
          referent: {
            id: 59,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.443073Z',
            email: 'benoît.ribeiro9939@example.org',
            firstname: 'Benoît',
            lastname: 'Ribeiro',
            agence: 98,
            fonction: 65,
            service: 63,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000015',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Clerc Delannoy SA',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: 'chemin Michel Rey',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '26426',
      adresse_principale_ville: 'Blot',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '5074220449643',
      telephone_secondaire: '',
      email_prioritaire: 'thibaultmarianne@breton.org',
      email_secondaire: 'leonthibault@orange.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 17,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/17/?format=json',
      contrats: [
        {
          id: 37,
          url: 'http://localhost:8000/users/37/?format=json',
          client_id: 17,
          produit_id: 76,
          agence_id: 99,
          referent_id: 60,
          reagi_id: 'A 000036',
          branche: 'B',
          etat: 'Résilié',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '28/03/2023',
          prime_type: 'Non confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 17,
            reagi_id: 'A000016',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Pineau Charpentier et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '35, chemin de Lelievre',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '08644',
            adresse_principale_ville: 'Verdier',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '0504787119124',
            telephone_secondaire: '',
            email_prioritaire: 'neveuauguste@ifrance.com',
            email_secondaire: 'fgoncalves@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 76,
            code: 'L0EC36',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 16,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 99,
            name: 'Bertrandboeuf',
            zipcode: '86109',
            cedex: '',
            ville: 119,
          },
          referent: {
            id: 60,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.460935Z',
            email: 'geneviève.lenoir6379@example.org',
            firstname: 'Geneviève',
            lastname: 'Lenoir',
            agence: 100,
            fonction: 66,
            service: 64,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 38,
          url: 'http://localhost:8000/users/38/?format=json',
          client_id: 17,
          produit_id: 77,
          agence_id: 101,
          referent_id: 61,
          reagi_id: 'A 000037',
          branche: 'E',
          etat: 'En cours',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '10/03/2023',
          prime_type: 'Confié',
          prime_fractionnement: 'Unique',
          date_debut_sp: '20/02/2020',
          date_fin_sp: '15/08/2021',
          decision_sp_n:
            'Verser crier cri mille ou maintenir hors jeune. Malgré par large émotion. Maintenir pauvre nu du remonter.',
          decision_sp_n_m_1:
            'Homme si embrasser mais. Seuil fixe ni poursuivre préparer sortir plaine. Chaque plaire immobile accompagner.',
          client: {
            id: 17,
            reagi_id: 'A000016',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Pineau Charpentier et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '35, chemin de Lelievre',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '08644',
            adresse_principale_ville: 'Verdier',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '0504787119124',
            telephone_secondaire: '',
            email_prioritaire: 'neveuauguste@ifrance.com',
            email_secondaire: 'fgoncalves@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 77,
            code: 'G7Yk37',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 17,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 101,
            name: 'Sainte Jeanne',
            zipcode: '86109',
            cedex: '',
            ville: 121,
          },
          referent: {
            id: 61,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.478451Z',
            email: 'josette.levy7931@example.org',
            firstname: 'Josette',
            lastname: 'Levy',
            agence: 102,
            fonction: 67,
            service: 65,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 39,
          url: 'http://localhost:8000/users/39/?format=json',
          client_id: 17,
          produit_id: 78,
          agence_id: 103,
          referent_id: 62,
          reagi_id: 'A 000038',
          branche: 'F',
          etat: 'Échu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: '08/07/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 17,
            reagi_id: 'A000016',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Pineau Charpentier et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '35, chemin de Lelievre',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '08644',
            adresse_principale_ville: 'Verdier',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '0504787119124',
            telephone_secondaire: '',
            email_prioritaire: 'neveuauguste@ifrance.com',
            email_secondaire: 'fgoncalves@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 78,
            code: 'X1Bx38',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 18,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 103,
            name: 'Petit',
            zipcode: '86109',
            cedex: '',
            ville: 123,
          },
          referent: {
            id: 62,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.496658Z',
            email: 'aimé.gilles6958@example.org',
            firstname: 'Aimé',
            lastname: 'Gilles',
            agence: 104,
            fonction: 68,
            service: 66,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000016',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Pineau Charpentier et Fils',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '35, chemin de Lelievre',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '08644',
      adresse_principale_ville: 'Verdier',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '0504787119124',
      telephone_secondaire: '',
      email_prioritaire: 'neveuauguste@ifrance.com',
      email_secondaire: 'fgoncalves@noos.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 18,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/18/?format=json',
      contrats: [
        {
          id: 40,
          url: 'http://localhost:8000/users/40/?format=json',
          client_id: 18,
          produit_id: 79,
          agence_id: 105,
          referent_id: 63,
          reagi_id: 'A 000039',
          branche: 'D',
          etat: 'Échu',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '15/02',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Triennale',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 18,
            reagi_id: 'A000017',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Monnier',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '40, chemin de Lebon',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '30283',
            adresse_principale_ville: 'Simon-sur-Pelletier',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '1128700010607',
            telephone_secondaire: '',
            email_prioritaire: 'etienne92@live.com',
            email_secondaire: 'kpetitjean@pichon.net',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 79,
            code: 'Q8ZN39',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 105,
            name: 'Giraud-sur-Lejeune',
            zipcode: '86109',
            cedex: '',
            ville: 125,
          },
          referent: {
            id: 63,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.518972Z',
            email: 'hugues.barbier3670@example.org',
            firstname: 'Hugues',
            lastname: 'Barbier',
            agence: 106,
            fonction: 69,
            service: 67,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000017',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Monnier',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '40, chemin de Lebon',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '30283',
      adresse_principale_ville: 'Simon-sur-Pelletier',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1128700010607',
      telephone_secondaire: '',
      email_prioritaire: 'etienne92@live.com',
      email_secondaire: 'kpetitjean@pichon.net',
      commentaire: '',
      important: '',
    },
    {
      id: 19,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/19/?format=json',
      contrats: [
        {
          id: 41,
          url: 'http://localhost:8000/users/41/?format=json',
          client_id: 19,
          produit_id: 80,
          agence_id: 107,
          referent_id: 64,
          reagi_id: 'A 000040',
          branche: '',
          etat: 'Résilié',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '10/08/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: '01/01/2020',
          date_fin_sp: '09/01/2022',
          decision_sp_n:
            'Événement marche aucun soirée quelque préparer on. Élément sourire saluer travail lutter école.',
          decision_sp_n_m_1:
            'Profondément apparaître transformer heureux bas cour curiosité.\nAprès jaune parole sembler général. Quel malade cinq secret force éternel bien.',
          client: {
            id: 19,
            reagi_id: 'A000018',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Verdier',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '22, chemin Madeleine Goncalves',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '28093',
            adresse_principale_ville: 'Saint Michelle',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6181776418476',
            telephone_secondaire: '',
            email_prioritaire: 'josephinedelorme@wanadoo.fr',
            email_secondaire: 'elisabeth67@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 80,
            code: 'T2RL40',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 19,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 107,
            name: 'Evrarddan',
            zipcode: '86109',
            cedex: '',
            ville: 127,
          },
          referent: {
            id: 64,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.539897Z',
            email: 'henriette.joubert6666@example.org',
            firstname: 'Henriette',
            lastname: 'Joubert',
            agence: 108,
            fonction: 70,
            service: 68,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 42,
          url: 'http://localhost:8000/users/42/?format=json',
          client_id: 19,
          produit_id: 81,
          agence_id: 109,
          referent_id: 65,
          reagi_id: 'A 000041',
          branche: 'J',
          etat: 'En cours',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Non confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 19,
            reagi_id: 'A000018',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Verdier',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '22, chemin Madeleine Goncalves',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '28093',
            adresse_principale_ville: 'Saint Michelle',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6181776418476',
            telephone_secondaire: '',
            email_prioritaire: 'josephinedelorme@wanadoo.fr',
            email_secondaire: 'elisabeth67@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 81,
            code: 'E9wO41',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 109,
            name: 'Diallo-sur-Mer',
            zipcode: '86109',
            cedex: '',
            ville: 129,
          },
          referent: {
            id: 65,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.557535Z',
            email: 'michelle.noel711@example.org',
            firstname: 'Michelle',
            lastname: 'Noel',
            agence: 110,
            fonction: 71,
            service: 69,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 43,
          url: 'http://localhost:8000/users/43/?format=json',
          client_id: 19,
          produit_id: 82,
          agence_id: 111,
          referent_id: 66,
          reagi_id: 'A 000042',
          branche: 'J',
          etat: 'En cours',
          duree_ferme: false,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: null,
          prime_type: 'Confié',
          prime_fractionnement: 'Mensuelle',
          date_debut_sp: '11/05/2021',
          date_fin_sp: '03/03/2022',
          decision_sp_n:
            "Lumière accrocher cacher son pénétrer repas. Sommet point pluie désormais fruit. Contre aujourd'hui fixe grand faible dépasser problème.",
          decision_sp_n_m_1:
            'Point parole fatiguer inconnu étage. Depuis ensemble terme tête inconnu désormais.',
          client: {
            id: 19,
            reagi_id: 'A000018',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'Association',
            denomination_sociale: 'Verdier',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '22, chemin Madeleine Goncalves',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '28093',
            adresse_principale_ville: 'Saint Michelle',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '6181776418476',
            telephone_secondaire: '',
            email_prioritaire: 'josephinedelorme@wanadoo.fr',
            email_secondaire: 'elisabeth67@noos.fr',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 82,
            code: 'R5lq42',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: null,
          agence: {
            id: 111,
            name: 'Saint Alexandrie',
            zipcode: '86109',
            cedex: '',
            ville: 131,
          },
          referent: {
            id: 66,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.575219Z',
            email: 'claudine.michaud3212@example.org',
            firstname: 'Claudine',
            lastname: 'Michaud',
            agence: 112,
            fonction: 72,
            service: 70,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000018',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'Association',
      denomination_sociale: 'Verdier',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '22, chemin Madeleine Goncalves',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '28093',
      adresse_principale_ville: 'Saint Michelle',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '6181776418476',
      telephone_secondaire: '',
      email_prioritaire: 'josephinedelorme@wanadoo.fr',
      email_secondaire: 'elisabeth67@noos.fr',
      commentaire: '',
      important: '',
    },
    {
      id: 20,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/clients/20/?format=json',
      contrats: [
        {
          id: 44,
          url: 'http://localhost:8000/users/44/?format=json',
          client_id: 20,
          produit_id: 83,
          agence_id: 113,
          referent_id: 67,
          reagi_id: 'A 000043',
          branche: '',
          etat: 'Résilié',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '22/08/2022',
          prime_type: 'Confié',
          prime_fractionnement: 'Semestrielle',
          date_debut_sp: '07/01/2021',
          date_fin_sp: '04/10/2021',
          decision_sp_n:
            'Million encore barbe accorder appartement jeunesse. Traverser enfance yeux tranquille cour huit six.',
          decision_sp_n_m_1:
            'Mot leur siège continuer. Bande année résultat nombre cacher journée.\nBien savoir rester toit mariage. Trésor minute dont casser élément confiance espèce jaune. Bois partir expérience en détail oh.',
          client: {
            id: 20,
            reagi_id: 'A000019',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Guibert et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '6, avenue Marguerite Valentin',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '21567',
            adresse_principale_ville: 'Poulain-la-Forêt',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5430815255357',
            telephone_secondaire: '',
            email_prioritaire: 'nicolasthibault@joly.fr',
            email_secondaire: 'edithbarbe@laposte.net',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 83,
            code: 'c6kU43',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 20,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 113,
            name: 'Ferreira',
            zipcode: '86109',
            cedex: '',
            ville: 133,
          },
          referent: {
            id: 67,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.595653Z',
            email: 'philippine.chretien6497@example.org',
            firstname: 'Philippine',
            lastname: 'Chretien',
            agence: 114,
            fonction: 73,
            service: 71,
            groups: [],
            user_permissions: [],
          },
        },
        {
          id: 45,
          url: 'http://localhost:8000/users/45/?format=json',
          client_id: 20,
          produit_id: 84,
          agence_id: 115,
          referent_id: 68,
          reagi_id: 'A 000044',
          branche: 'G',
          etat: 'Suspendu',
          duree_ferme: true,
          date_effet_contrat: '18/06/1994',
          date_renouvellement: '06/06',
          date_resiliation_expiration: '03/06/2022',
          prime_type: 'Non confié',
          prime_fractionnement: 'Annuelle',
          date_debut_sp: null,
          date_fin_sp: null,
          decision_sp_n: '',
          decision_sp_n_m_1: '',
          client: {
            id: 20,
            reagi_id: 'A000019',
            type: 'Personne morale',
            civilite: '',
            nom: '',
            prenom: '',
            forme_juridique: 'SARL',
            denomination_sociale: 'Guibert et Fils',
            interlocuteurs: [],
            date_naissance: null,
            nom_naissance: '',
            adresse_principale_1: '6, avenue Marguerite Valentin',
            adresse_principale_2: '',
            adresse_principale_3: '',
            adresse_principale_4: '',
            adresse_principale_code_postal: '21567',
            adresse_principale_ville: 'Poulain-la-Forêt',
            adresse_principale_pays: 'France',
            adresse_secondaire_1: '',
            adresse_secondaire_2: '',
            adresse_secondaire_3: '',
            adresse_secondaire_4: '',
            adresse_secondaire_code_postal: '',
            adresse_secondaire_ville: '',
            adresse_secondaire_pays: '',
            telephone_prioritaire: '5430815255357',
            telephone_secondaire: '',
            email_prioritaire: 'nicolasthibault@joly.fr',
            email_secondaire: 'edithbarbe@laposte.net',
            commentaire: '',
            important: '',
            liaisons_clients: [],
          },
          produit: {
            id: 84,
            code: 'P1YK44',
            nom: 'Transport Public Marchandises',
            type: 'Automobile de +3.5T',
            compagnie: 'Prudence Créole',
            contrat_a_aliments: false,
          },
          motif_resilitation_expiration: {
            id: 21,
            nom: 'Loi Hamon',
          },
          agence: {
            id: 115,
            name: 'Pintonec',
            zipcode: '86109',
            cedex: '',
            ville: 135,
          },
          referent: {
            id: 68,
            password: '',
            last_login: null,
            is_superuser: false,
            first_name: '',
            last_name: '',
            is_staff: true,
            is_active: true,
            date_joined: '2021-05-31T06:03:33.615658Z',
            email: 'julie.charles9514@example.org',
            firstname: 'Julie',
            lastname: 'Charles',
            agence: 116,
            fonction: 74,
            service: 72,
            groups: [],
            user_permissions: [],
          },
        },
      ],
      liaisons_clients: [],
      reagi_id: 'A000019',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Guibert et Fils',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '6, avenue Marguerite Valentin',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '21567',
      adresse_principale_ville: 'Poulain-la-Forêt',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '5430815255357',
      telephone_secondaire: '',
      email_prioritaire: 'nicolasthibault@joly.fr',
      email_secondaire: 'edithbarbe@laposte.net',
      commentaire: '',
      important: '',
    },
  ],
}

export {clientsList}
