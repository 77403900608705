const ecritures = [
  {
    id: 1,
    type: 'Quittance',
    client_id: 1,
    description: 'Quittance 1',
    client: {
      id: 1,
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: '',
      important: '',
      liaisons_clients: [],
    },
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-01-01T11:00:50.238157Z',
      updated_at: '2021-01-01T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '30/06/2020',
    date_debut: '30/08/2021',
    date_fin: '30/06/2022',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Confié',
    credit: 0,
    debit: 100,
    prime_ttc: 100,
    prime_ht: 73,
    commission: 20,
    frais_hva: 0,
    affectations: [
      {
        id: 1,
        lettrage: 'aaa',
        debit_id: 1,
        credit_id: 3,
        montant: 40,
      },
      {
        id: 2,
        lettrage: 'aab',
        debit_id: 1,
        credit_id: 4,
        montant: 40,
      },
    ],
  },
  {
    id: 2,
    client_id: 1,
    description: 'Quittance 2',
    client: {
      id: 1,
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: '',
      important: '',
      liaisons_clients: [],
    },
    type: 'Quittance',
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '30/06/2021',
    date_debut: '31/08/2020',
    date_fin: '30/08/2022',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Confié',
    credit: 0,
    debit: 60,
    prime_ttc: 45,
    prime_ht: 40,
    commission: 0,
    frais_hva: 15,
  },
  {
    id: 3,
    client_id: 1,
    description: 'Reglement 1',
    client: {
      id: 1,
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: '',
      important: '',
      liaisons_clients: [],
    },
    type: 'Reglement',
    type_reglement: {
      id: 1,
      nom: 'Chèque',
      confie: true,
      banque_remise_id: 1,
      banque_remise: {
        id: 1,
        nom: 'CEPAC Prime',
      },
    },
    agence: {
      id: 1,
      url: 'http://localhost:8000/agences/1/',
      ville: {
        id: 1,
        url: 'http://localhost:8000/villes/1/',
        name: 'Saint-Denis',
      },
      name: 'Saint-Denis',
      zipcode: '97400',
      cedex: 'cedex',
    },
    banque_remise: {
      id: 1,
      nom: 'CEPAC Prime',
    },
    libelle_libre: 'Règlement en agence',
    banque_cheque: {
      id: 4,
      nom: 'Crédit Agricole',
    },
    numero_cheque: '1234567',
    date_cheque: '15/09/2021',
    emetteur_cheque: 'John',
    date: '16/09/2021',
    credit: 60,
    debit: 0,
    avance: true,
    type_reglement_id: 1,
    banque_remise_id: 1,
    agence_id: 1,
    affectations: [
      {
        id: 1,
        lettrage: 'aaa',
        debit_id: 1,
        credit_id: 3,
        montant: 40,
      },
    ],
  },
  {
    id: 4,
    client_id: 1,
    description: 'Remboursement 1',
    client: {
      id: 1,
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: '',
      important: '',
      liaisons_clients: [],
    },
    type: 'Remboursement',
    libelle_libre: '',
    type_reglement: {
      id: 1,
      nom: 'Chèque',
      confie: true,
      banque_remise_id: 1,
      banque_remise: {
        id: 1,
        nom: 'CEPAC Prime',
      },
      moyen_remboursement: true,
    },
    banque_cheque: {
      id: 1,
      nom: 'BFC OI',
    },
    numero_cheque: '1234567',
    date: '12/09/2021',
    credit: 0,
    debit: 40,
    type_reglement_id: 1,
    affectations: [
      {
        id: 2,
        lettrage: 'aab',
        debit_id: 1,
        credit_id: 4,
        montant: 40,
      },
    ],
  },
  {
    id: 5,
    client_id: 1,
    description: 'Ristourne 1',
    client: {
      id: 1,
      reagi_id: 'A000000',
      type: 'Personne morale',
      civilite: '',
      nom: '',
      prenom: '',
      forme_juridique: 'SARL',
      denomination_sociale: 'Buisson',
      interlocuteurs: [],
      date_naissance: null,
      nom_naissance: '',
      adresse_principale_1: '42, avenue Céline Da Costa',
      adresse_principale_2: '',
      adresse_principale_3: '',
      adresse_principale_4: '',
      adresse_principale_code_postal: '52661',
      adresse_principale_ville: 'Voisin-sur-Mer',
      adresse_principale_pays: 'France',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_4: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_prioritaire: '1844126706062',
      telephone_secondaire: '',
      email_prioritaire: 'margauxlamy@turpin.fr',
      email_secondaire: 'hardyaurelie@gmail.com',
      commentaire: '',
      important: '',
      liaisons_clients: [],
    },
    type: 'Ristourne',
    contrat_id: 1,
    contrat: {
      id: 1,
      created_at: '2021-06-30T11:00:50.238157Z',
      updated_at: '2021-06-30T11:00:50.250365Z',
      url: 'http://localhost:8000/users/1/?format=json',
      client_id: 1,
      produit_id: 40,
      agence_id: 27,
      referent_id: 24,
      reagi_id: 'A 000000',
      branche: 'D',
      etat: 'En cours',
      duree_ferme: true,
      date_effet_contrat: '18/06/1994',
      date_renouvellement: '15/02',
      date_resiliation_expiration: '09/11/2022',
      prime_type: 'Non confié',
      prime_fractionnement: 'Décennale',
      date_debut_sp: '05/06/2019',
      date_fin_sp: '19/10/2021',
      decision_sp_n:
        'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
      decision_sp_n_m_1:
        'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
      client: {
        id: 1,
        reagi_id: 'A000000',
        type: 'Personne morale',
        civilite: '',
        nom: '',
        prenom: '',
        forme_juridique: 'SARL',
        denomination_sociale: 'Buisson',
        interlocuteurs: [],
        date_naissance: null,
        nom_naissance: '',
        adresse_principale_1: '42, avenue Céline Da Costa',
        adresse_principale_2: '',
        adresse_principale_3: '',
        adresse_principale_4: '',
        adresse_principale_code_postal: '52661',
        adresse_principale_ville: 'Voisin-sur-Mer',
        adresse_principale_pays: 'France',
        adresse_secondaire_1: '',
        adresse_secondaire_2: '',
        adresse_secondaire_3: '',
        adresse_secondaire_4: '',
        adresse_secondaire_code_postal: '',
        adresse_secondaire_ville: '',
        adresse_secondaire_pays: '',
        telephone_prioritaire: '1844126706062',
        telephone_secondaire: '',
        email_prioritaire: 'margauxlamy@turpin.fr',
        email_secondaire: 'hardyaurelie@gmail.com',
        commentaire: '',
        important: '',
        liaisons_clients: [],
      },
      produit: {
        id: 40,
        code: 'J0nx0',
        nom: 'Transport Public Marchandises',
        type: 'Automobile de +3.5T',
        compagnie: "Quelqu'un d'autre",
        contrat_a_aliments: false,
      },
      motif_resilitation_expiration: {
        id: 1,
        nom: 'Loi Hamon',
      },
      agence: {
        id: 27,
        name: 'VaillantVille',
        zipcode: '86109',
        cedex: '',
        ville: 47,
      },
      referent: {
        id: 24,
        password: '',
        last_login: null,
        is_superuser: false,
        first_name: '',
        last_name: '',
        is_staff: true,
        is_active: true,
        date_joined: '2021-05-31T06:03:32.784510Z',
        email: 'anouk.moreau7208@example.org',
        firstname: 'Anouk',
        lastname: 'Moreau',
        agence: 28,
        fonction: 30,
        service: 28,
        groups: [],
        user_permissions: [],
      },
    },
    numero_avenant: 3,
    numero_appel_prime: 5,
    etat_precontentieux_id: 3,
    etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
    date_precontentieux: '30/06/2021',
    date_debut: '02/09/2021',
    date_fin: '30/06/2022',
    type_avenant: {
      id: 1,
      nom: 'Affaire Nouvelle',
    },
    type_avenant_id: 1,
    encaissement: 'Non confié',
    credit: 60,
    debit: 0,
    prime_ttc: 60,
    prime_ht: 50,
    commission: 0,
    frais_hva: 0,
  },
  // {
  //   id: 3,
  //   type: 'dette',
  //   libelle_libre: 'Ok',
  //   date: '2021-09-11T20:00:00.000Z',
  //   debit: 1000,
  //   credit: 0,
  // },
  {
    id: 6,
    description: 'OD 1',
    type: 'Operation diverse',
    libelle_libre: 'Opération Diverse exemple',
    date: '11/09/2021',
    debit: 0,
    credit: 10,
  },
  // {
  //   id: 5,
  //   type: 'operation_diverse',
  //   libelle_libre: 'Opération Diverse exemple',
  //   date: '2021-09-11T20:00:00.000Z',
  //   debit: 0,
  //   credit: 10,
  // },
  // {
  //   id: 6,
  //   type: 'Quittance',
  //   contrat_id: 1,
  //   contrat: {
  //     id: 1,
  //     created_at: '2021-06-30T11:00:50.238157Z',
  //     updated_at: '2021-06-30T11:00:50.250365Z',
  //     url: 'http://localhost:8000/users/1/?format=json',
  //     client_id: 1,
  //     produit_id: 40,
  //     agence_id: 27,
  //     referent_id: 24,
  //     reagi_id: 'A 000000',
  //     branche: 'D',
  //     etat: 'En cours',
  //     duree_ferme: true,
  //     date_effet_contrat: '18/06/1994',
  //     date_renouvellement: '15/02',
  //     date_resiliation_expiration: '09/11/2022',
  //     prime_type: 'Non confié',
  //     prime_fractionnement: 'Décennale',
  //     date_debut_sp: '05/06/2019',
  //     date_fin_sp: '19/10/2021',
  //     decision_sp_n:
  //       'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
  //     decision_sp_n_m_1:
  //       'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
  //     client: {
  //       id: 1,
  //       reagi_id: 'A000000',
  //       type: 'Personne morale',
  //       civilite: '',
  //       nom: '',
  //       prenom: '',
  //       forme_juridique: 'SARL',
  //       denomination_sociale: 'Buisson',
  //       interlocuteurs: [],
  //       date_naissance: null,
  //       nom_naissance: '',
  //       adresse_principale_1: '42, avenue Céline Da Costa',
  //       adresse_principale_2: '',
  //       adresse_principale_3: '',
  //       adresse_principale_4: '',
  //       adresse_principale_code_postal: '52661',
  //       adresse_principale_ville: 'Voisin-sur-Mer',
  //       adresse_principale_pays: 'France',
  //       adresse_secondaire_1: '',
  //       adresse_secondaire_2: '',
  //       adresse_secondaire_3: '',
  //       adresse_secondaire_4: '',
  //       adresse_secondaire_code_postal: '',
  //       adresse_secondaire_ville: '',
  //       adresse_secondaire_pays: '',
  //       telephone_prioritaire: '1844126706062',
  //       telephone_secondaire: '',
  //       email_prioritaire: 'margauxlamy@turpin.fr',
  //       email_secondaire: 'hardyaurelie@gmail.com',
  //       commentaire: '',
  //       important: '',
  //       liaisons_clients: [],
  //     },
  //     produit: {
  //       id: 40,
  //       code: 'J0nx0',
  //       nom: 'Transport Public Marchandises',
  //       type: 'Automobile de +3.5T',
  //       compagnie: "Quelqu'un d'autre",
  //       contrat_a_aliments: false,
  //     },
  //     motif_resilitation_expiration: {
  //       id: 1,
  //       nom: 'Loi Hamon',
  //     },
  //     agence: {
  //       id: 27,
  //       name: 'VaillantVille',
  //       zipcode: '86109',
  //       cedex: '',
  //       ville: 47,
  //     },
  //     referent: {
  //       id: 24,
  //       password: '',
  //       last_login: null,
  //       is_superuser: false,
  //       first_name: '',
  //       last_name: '',
  //       is_staff: true,
  //       is_active: true,
  //       date_joined: '2021-05-31T06:03:32.784510Z',
  //       email: 'anouk.moreau7208@example.org',
  //       firstname: 'Anouk',
  //       lastname: 'Moreau',
  //       agence: 28,
  //       fonction: 30,
  //       service: 28,
  //       groups: [],
  //       user_permissions: [],
  //     },
  //   },
  //   numero_avenant: 3,
  //   numero_appel_prime: 5,
  //   etat_precontentieux_id: 3,
  //   etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
  //   date_precontentieux: '2021-06-30T11:00:50.250365Z',
  //   date_debut: '2021-08-30T11:00:50.250365Z',
  //   date_fin: '2022-06-30T11:00:50.250365Z',
  //   type_avenant: {
  //     id: 1,
  //     nom: 'Affaire Nouvelle',
  //   },
  //   type_avenant_id: 1,
  //   encaissement: 'Confié',
  //   credit: 20,
  //   debit: 0,
  //   prime_ttc: 20,
  //   prime_ht: 19,
  //   commission: 5,
  //   frais_hva: 5,
  // },
  // {
  //   id: 8,
  //   type: 'Quittance',
  //   contrat_id: 1,
  //   contrat: {
  //     id: 1,
  //     created_at: '2021-06-30T11:00:50.238157Z',
  //     updated_at: '2021-06-30T11:00:50.250365Z',
  //     url: 'http://localhost:8000/users/1/?format=json',
  //     client_id: 1,
  //     produit_id: 40,
  //     agence_id: 27,
  //     referent_id: 24,
  //     reagi_id: 'A 000000',
  //     branche: 'D',
  //     etat: 'En cours',
  //     duree_ferme: true,
  //     date_effet_contrat: '18/06/1994',
  //     date_renouvellement: '15/02',
  //     date_resiliation_expiration: '09/11/2022',
  //     prime_type: 'Non confié',
  //     prime_fractionnement: 'Décennale',
  //     date_debut_sp: '05/06/2019',
  //     date_fin_sp: '19/10/2021',
  //     decision_sp_n:
  //       'Tout devant vague douceur professeur compagnon fête. Demande place cause mais extraordinaire vieux situation. Peu loi général résister roi famille doute. Portier confiance chaud cher.',
  //     decision_sp_n_m_1:
  //       'Semaine saint étoile disparaître lieu événement déposer. Feuille rang préférer épaule mon installer. Position marquer présent terreur saison. Continuer gens haïr écrire chaud marquer dehors.',
  //     client: {
  //       id: 1,
  //       reagi_id: 'A000000',
  //       type: 'Personne morale',
  //       civilite: '',
  //       nom: '',
  //       prenom: '',
  //       forme_juridique: 'SARL',
  //       denomination_sociale: 'Buisson',
  //       interlocuteurs: [],
  //       date_naissance: null,
  //       nom_naissance: '',
  //       adresse_principale_1: '42, avenue Céline Da Costa',
  //       adresse_principale_2: '',
  //       adresse_principale_3: '',
  //       adresse_principale_4: '',
  //       adresse_principale_code_postal: '52661',
  //       adresse_principale_ville: 'Voisin-sur-Mer',
  //       adresse_principale_pays: 'France',
  //       adresse_secondaire_1: '',
  //       adresse_secondaire_2: '',
  //       adresse_secondaire_3: '',
  //       adresse_secondaire_4: '',
  //       adresse_secondaire_code_postal: '',
  //       adresse_secondaire_ville: '',
  //       adresse_secondaire_pays: '',
  //       telephone_prioritaire: '1844126706062',
  //       telephone_secondaire: '',
  //       email_prioritaire: 'margauxlamy@turpin.fr',
  //       email_secondaire: 'hardyaurelie@gmail.com',
  //       commentaire: '',
  //       important: '',
  //       liaisons_clients: [],
  //     },
  //     produit: {
  //       id: 40,
  //       code: 'J0nx0',
  //       nom: 'Transport Public Marchandises',
  //       type: 'Automobile de +3.5T',
  //       compagnie: "Quelqu'un d'autre",
  //       contrat_a_aliments: false,
  //     },
  //     motif_resilitation_expiration: {
  //       id: 1,
  //       nom: 'Loi Hamon',
  //     },
  //     agence: {
  //       id: 27,
  //       name: 'VaillantVille',
  //       zipcode: '86109',
  //       cedex: '',
  //       ville: 47,
  //     },
  //     referent: {
  //       id: 24,
  //       password: '',
  //       last_login: null,
  //       is_superuser: false,
  //       first_name: '',
  //       last_name: '',
  //       is_staff: true,
  //       is_active: true,
  //       date_joined: '2021-05-31T06:03:32.784510Z',
  //       email: 'anouk.moreau7208@example.org',
  //       firstname: 'Anouk',
  //       lastname: 'Moreau',
  //       agence: 28,
  //       fonction: 30,
  //       service: 28,
  //       groups: [],
  //       user_permissions: [],
  //     },
  //   },
  //   numero_avenant: 3,
  //   numero_appel_prime: 5,
  //   etat_precontentieux_id: 3,
  //   etat_precontentieux: {id: 3, nom: 'En mise en demeure'},
  //   date_precontentieux: '2020-06-30T11:00:50.250365Z',
  //   date_debut: '2021-08-30T11:00:50.250365Z',
  //   date_fin: '2022-06-30T11:00:50.250365Z',
  //   type_avenant: {
  //     id: 1,
  //     nom: 'Affaire Nouvelle',
  //   },
  //   type_avenant_id: 1,
  //   encaissement: 'Confié',
  //   credit: 999,
  //   debit: 0,
  //   prime_ttc: 999,
  //   prime_ht: 900,
  //   commission: 15,
  //   frais_hva: 20,
  // },
]
export {ecritures}
