import PageTemplate from 'src/components/templates/PageTemplate'
import {useMemo, useState} from 'react'
import {useCahiersDeTransmission} from 'src/hooks/api-hooks'
import {IconButton, Link, Tooltip} from '@mui/material'
import TypesReglementInput from 'src/components/inputs/TypesReglementInput'
import BanquesRemiseInput from 'src/components/inputs/BanquesRemiseInput'
import AgencesInput from 'src/components/inputs/AgencesInput'
import ChangeStatusDialog from '../remises-en-banque/ChangeStatusDialog'
import {createColumnHelper} from '@tanstack/react-table'
import {Link as RouterLink} from 'react-router-dom'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import moment from 'moment'
import DeviseLabel from '../../components/DeviseLabel'
import {TransferWithinAStation} from '@mui/icons-material'
import AutocompleteInput from '../../components/inputs/AutocompleteInput'
import PermissionHidder from 'src/components/PermissionHidder'

const columnHelper = createColumnHelper()

export default function CahierTransmissionList() {
  const [remiseEnBanqueToChangeStatus, setRemiseEnBanqueToChangeStatus] =
    useState()
  const universalTableData = useUniversalTableData(useCahiersDeTransmission)
  const {handleFilterChange} = universalTableData

  const columns = useMemo(
    () => [
      columnHelper.accessor('numero_remise', {
        header: 'Numéro remise',
        id: 'numero_remise',
        cell: info => {
          return (
            <Link
              color="inherit"
              component={RouterLink}
              to={`/remises-en-banque/${info.cell.row.original.remise_en_banque_id}`}
              variant="subtitle2"
            >
              {info.getValue()}
            </Link>
          )
        },
      }),
      columnHelper.accessor('date', {
        header: 'Date création',
        id: 'date',
      }),
      columnHelper.accessor('agence.name', {
        header: 'Agence',
        id: 'agence__name',
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        id: 'type',
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        id: 'status',
      }),
      columnHelper.accessor('last_status_update_date', {
        header: 'Date status',
        id: 'last_status_update_date',
        cell: info => moment(info.getValue()).format('DD/MM/YYYY à HH:mm:ss'),
      }),
      columnHelper.accessor('montant', {
        header: 'Montant',
        id: 'montant',
        cell: info => {
          return (
            <>
              <DeviseLabel>{info.getValue()}</DeviseLabel>
            </>
          )
        },
      }),
      columnHelper.accessor(
        row => {
          return row.last_status_update_user?.display_name
        },
        {
          header: 'Utilisateur',
          id: 'last_status_update_user__display_name',
        },
      ),
      columnHelper.display({
        header: 'Actions',
        enableHiding: false,
        cell: info => {
          return (
            <>
              <PermissionHidder mainRole={'is_superuser'}>
                <Tooltip title="Modifier le statut de la remise" arrow>
                  <IconButton
                    sx={{ml: 1, mr: 1}}
                    size="small"
                    color="primary"
                    onClick={() =>
                      setRemiseEnBanqueToChangeStatus(info.cell.row.original)
                    }
                  >
                    <TransferWithinAStation />
                  </IconButton>
                </Tooltip>
              </PermissionHidder>
            </>
          )
        },
      }),
    ],
    [setRemiseEnBanqueToChangeStatus],
  )

  return (
    <PageTemplate
      title="Cahier de transmission"
      sections={[{title: 'Cahier de transmission', current: true}]}
    >
      <WrappedUniversalTable
        columns={columns}
        name={'cahiers-transmission-list'}
        universalTableData={universalTableData}
        filtersContent={
          <>
            <AutocompleteInput
              label={'Status'}
              options={[
                {label: 'Tous', value: null},
                {label: 'Agence', value: 'Agence'},
                {
                  label: 'Vers Prima',
                  value: 'Vers Prima',
                },
                {label: 'Prima', value: 'Prima'},
                {label: 'Vers Banque', value: 'Vers Banque'},
                {label: 'Banque', value: 'Banque'},
              ]}
              onChange={handleFilterChange('status', 'value')}
            />
            <TypesReglementInput
              remise={true}
              onChange={handleFilterChange('type', 'nom')}
            />
            <BanquesRemiseInput
              onChange={handleFilterChange('banque_remise', 'id')}
            />
            <AgencesInput onChange={handleFilterChange('agence', 'id')} />
          </>
        }
      />
      {!!remiseEnBanqueToChangeStatus && (
        <ChangeStatusDialog
          remiseEnBanque={remiseEnBanqueToChangeStatus}
          onClose={() => setRemiseEnBanqueToChangeStatus()}
          open={!!remiseEnBanqueToChangeStatus}
          onCancel={() => setRemiseEnBanqueToChangeStatus()}
        />
      )}
    </PageTemplate>
  )
}
