import {Switch as MuiSwitch, FormControlLabel} from '@mui/material'
import {useForm, Controller} from '.'

const Switch = ({name, label, ...props}) => {
  const {control, isLoading} = useForm()
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {value, onChange}}) => (
        <FormControlLabel
          control={
            <MuiSwitch
              checked={value}
              onChange={onChange}
              inputProps={{'aria-label': label, 'data-testid': `field-${name}`}}
              disabled={isLoading}
              color="primary"
              edge="start"
            />
          }
          label={label}
          {...props}
        />
      )}
    />
  )
}

export default Switch
