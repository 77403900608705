const fonctions = [
  {
    id: 1,
    url: 'http://localhost:8000/fonctions/1/',
    name: 'Chargée de clientèle',
  },
  {
    id: 2,
    url: 'http://localhost:8000/fonctions/2/',
    name: 'Rédactrive Sinistres',
  },
  {
    id: 3,
    url: 'http://localhost:8000/fonctions/3/',
    name: 'Responsable',
  },
  {
    id: 4,
    url: 'http://localhost:8000/fonctions/4/',
    name: 'Cogérant',
  },
  {
    id: 5,
    url: 'http://localhost:8000/fonctions/5/',
    name: 'Chargé de contrôle',
  },
  {
    id: 6,
    url: 'http://localhost:8000/fonctions/6/',
    name: 'Comptable',
  },
  {
    id: 7,
    url: 'http://localhost:8000/fonctions/7/',
    name: 'Comptable Auxiliaire',
  },
]

export {fonctions}
