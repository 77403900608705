import {useState} from 'react'

import {partialUpdateClient} from 'src/services/crm-api'
import useIsMountedRef from 'src/hooks/useIsMountedRef'
import useSnackbar from 'src/hooks/useSnackbar'

const useSimplePartialUpdateForm = ({promise, id, onError, onSuccess}) => {
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)

  const isError = status === 'error'
  const isLoading = status === 'loading'

  const {showSuccess} = useSnackbar()

  const isMountedRef = useIsMountedRef()

  const save = values => {
    setStatus('loading')
    partialUpdateClient(id, values)
      .then(data => {
        if (isMountedRef.current) {
          setStatus(null)
          showSuccess('Modification effectuée')
          onSuccess?.(data)
        }
      })
      .catch(e => {
        setError(e)
        setStatus('error')
        onError?.(e)
      })
  }
  return {isLoading, isError, error, save}
}

export default useSimplePartialUpdateForm
