const agences = [
  {
    id: 1,
    url: 'http://localhost:8000/agences/1/',
    ville: {
      id: 1,
      url: 'http://localhost:8000/villes/1/',
      name: 'Saint-Denis',
    },
    name: 'Saint-Denis',
    zipcode: '97400',
    cedex: 'cedex',
  },
  {
    id: 2,
    url: 'http://localhost:8000/agences/2/',
    ville: {
      id: 17,
      url: 'http://localhost:8000/villes/17/',
      name: 'Saint-Louis',
    },
    name: 'Saint Louis',
    zipcode: '97450',
    cedex: '',
  },
  {
    id: 3,
    url: 'http://localhost:8000/agences/3/',
    ville: {
      id: 13,
      url: 'http://localhost:8000/villes/13/',
      name: 'Saint-André',
    },
    name: 'Saint André',
    zipcode: '97440',
    cedex: '',
  },
  {
    id: 4,
    url: 'http://localhost:8000/agences/4/',
    ville: {
      id: 18,
      url: 'http://localhost:8000/villes/18/',
      name: 'Saint-Paul',
    },
    name: 'Saint Paul',
    zipcode: '97864',
    cedex: 'cedex',
  },
]
export {agences}
