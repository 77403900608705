import PageTemplate from 'src/components/templates/PageTemplate'
import {useNavigate} from 'react-router'
import {useAgences, useCreateCaisse} from 'src/hooks/api-hooks'

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import AgencesInputControlled from 'src/components/controlled/AgencesInputControlled'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {withStyles} from '@mui/styles'
import AddButton from 'src/components/buttons/AddButton'
import TextInputControlled from 'src/components/controlled/TextInputControlled'
import {Box} from '@mui/system'
import DeviseLabel from 'src/components/DeviseLabel'
import {useQuery} from 'react-query'
import {fetchCaisses} from 'src/services/crm-api'
import OperationsField, {OperationSchema} from './OperationsField'
import {red} from '@mui/material/colors'
import {useSearchParams} from 'react-router-dom'

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell)

const schemaObj = {
  agence_id: yup.mixed().label('Agence').required(),
  operations: yup.array().of(OperationSchema),
}
const schemaObjWithoutAgence = {
  operations: yup.array().of(OperationSchema),
}

const schema = yup.object().shape(schemaObj)
const schemaWithoutAgence = yup.object().shape(schemaObjWithoutAgence)

export default function RemiseEnBanqueCreate() {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const agenceId = searchParams.get('agence')

  console.log(agenceId)

  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm({
    resolver: yupResolver(agenceId ? schemaWithoutAgence : schema),
  })

  const agence = agenceId ?? watch('agence_id')
  const {data: veille} = useQuery(['last-caisse', agence?.id], () =>
    fetchCaisses({agence: agence.id, ordering: '-id', limit: 1}),
  )

  const {mutate, isLoading, error} = useCreateCaisse()
  const totalOperations = watch('operations')?.reduce(
    (acc, item) => (item.type === 'retrait' ? -item.montant : item.montant),
    0,
  )
  const totalRetrait = watch('operations')
    ?.filter(x => x.type === 'retrait')
    .reduce((acc, item) => item.montant + acc, 0)

  const totalRemise = watch('operations')
    ?.filter(x => x.type === 'remise')
    .reduce((acc, item) => item.montant + acc, 0)

  const {data: agences, isLoading: agencesAreLoading} = useAgences()
  const title = agenceId
    ? agencesAreLoading
      ? 'Arreter une caisse'
      : 'Arreter la caisse de ' + agences.find(x => x.id == agenceId).name
    : 'Arreter une caisse'

  const total =
    500 * (watch('billets_500') ?? 0) +
    200 * (watch('billets_200') ?? 0) +
    100 * (watch('billets_100') ?? 0) +
    50 * (watch('billets_050') ?? 0) +
    20 * (watch('billets_020') ?? 0) +
    10 * (watch('billets_010') ?? 0) +
    5 * (watch('billets_005') ?? 0) +
    2 * (watch('pieces_200') ?? 0) +
    1 * (watch('pieces_100') ?? 0) +
    0.5 * (watch('pieces_050') ?? 0) +
    0.2 * (watch('pieces_020') ?? 0) +
    0.1 * (watch('pieces_010') ?? 0) +
    0.05 * (watch('pieces_005') ?? 0) +
    0.02 * (watch('pieces_002') ?? 0) +
    0.01 * (watch('pieces_001') ?? 0)

  const caisseVeille = veille?.results?.[0]?.total ?? 160

  const equilibre = total + totalRetrait - totalRemise - caisseVeille

  console.log(errors)

  return (
    <PageTemplate
      title={title}
      sections={[{title: 'Arreter une caisse', current: true}]}
    >
      <form
        onSubmit={handleSubmit(values => {
          const body = values
          body.agence_id = agenceId ?? body.agence_id?.id
          body.billets_500 =
            body.billets_500 === '' ? 0 : parseInt(body.billets_500)
          body.billets_200 =
            body.billets_200 === '' ? 0 : parseInt(body.billets_200)
          body.billets_100 =
            body.billets_100 === '' ? 0 : parseInt(body.billets_100)
          body.billets_050 =
            body.billets_050 === '' ? 0 : parseInt(body.billets_050)
          body.billets_020 =
            body.billets_020 === '' ? 0 : parseInt(body.billets_020)
          body.billets_010 =
            body.billets_010 === '' ? 0 : parseInt(body.billets_010)
          body.billets_005 =
            body.billets_005 === '' ? 0 : parseInt(body.billets_005)
          body.pieces_200 =
            body.pieces_200 === '' ? 0 : parseInt(body.pieces_200)
          body.pieces_100 =
            body.pieces_100 === '' ? 0 : parseInt(body.pieces_100)
          body.pieces_050 =
            body.pieces_050 === '' ? 0 : parseInt(body.pieces_050)
          body.pieces_020 =
            body.pieces_020 === '' ? 0 : parseInt(body.pieces_020)
          body.pieces_010 =
            body.pieces_010 === '' ? 0 : parseInt(body.pieces_010)
          body.pieces_005 =
            body.pieces_005 === '' ? 0 : parseInt(body.pieces_005)
          body.pieces_002 =
            body.pieces_002 === '' ? 0 : parseInt(body.pieces_002)
          body.pieces_001 =
            body.pieces_001 === '' ? 0 : parseInt(body.pieces_001)

          mutate(body, {
            onSuccess: results => {
              navigate(`/caisses/`)
            },
          })
        })}
      >
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Card sx={{p: 2}}>
              <CardHeader title="Inventaires"></CardHeader>
              {/* <Divider /> */}
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={1}
                        type="number"
                        control={control}
                        name="billets_500"
                        label="Billets de 500 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={8}
                        type="number"
                        control={control}
                        name="pieces_200"
                        label="Pièce de 2 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={2}
                        type="number"
                        control={control}
                        name="billets_200"
                        label="Billets de 200 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={9}
                        type="number"
                        control={control}
                        name="pieces_100"
                        label="Pièce de 1 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={3}
                        type="number"
                        control={control}
                        name="billets_100"
                        label="Billets de 100 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_050"
                        label="Pièce de 0,50 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={4}
                        type="number"
                        control={control}
                        name="billets_050"
                        label="Billets de 50 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_020"
                        label="Pièce de 0,20 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={5}
                        type="number"
                        control={control}
                        name="billets_020"
                        label="Billets de 20 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_010"
                        label="Pièce de 0,10 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={6}
                        type="number"
                        control={control}
                        name="billets_010"
                        label="Billets de 10 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_005"
                        label="Pièce de 0,05 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <TextInputControlled
                        tabIndex={7}
                        type="number"
                        control={control}
                        name="billets_005"
                        label="Billets de 5 €"
                        variant="standard"
                      />
                    </TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_002"
                        label="Pièce de 0,02 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextInputControlled
                        type="number"
                        control={control}
                        name="pieces_001"
                        label="Pièce de 0,01 €"
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <CardHeader title="Opérations"></CardHeader>
              <OperationsField control={control} errors={errors} />
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card>
              <CardContent>
                {Boolean(agenceId) ? null : (
                  <AgencesInputControlled
                    name="agence_id"
                    control={control}
                    error={!!errors.agence_id}
                    helperText={errors.agence_id?.message}
                  />
                )}

                {!!veille && (
                  <>
                    <Box mt={2} mb={2}>
                      <Typography variant="h4">
                        Fond de caisse initial
                      </Typography>
                      <Typography>
                        <DeviseLabel>160</DeviseLabel>
                      </Typography>
                    </Box>
                    <Box mt={2} mb={2}>
                      <Typography variant="h4">
                        Fond de caisse de la veille
                      </Typography>
                      <Typography>
                        <DeviseLabel>{caisseVeille}</DeviseLabel>
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}

                <Box mt={2} mb={2}>
                  <Typography variant="h4">
                    Arreté de la caisse du jour
                  </Typography>
                  <Typography>
                    <DeviseLabel>{total}</DeviseLabel>
                  </Typography>
                </Box>
                <Divider />
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h6"
                    color={equilibre === 0 ? undefined : red[500]}
                  >
                    {equilibre > 0
                      ? "Trop d'argent dans la caisse"
                      : equilibre < 0
                      ? "Manque d'argent dans la caisse"
                      : 'La caisse est équilibrée'}
                  </Typography>
                  <Typography color={equilibre === 0 ? undefined : red[500]}>
                    <DeviseLabel>{Math.abs(equilibre)}</DeviseLabel>
                  </Typography>
                </Box>
                <AddButton loading={isLoading}>Fermer la caisse</AddButton>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </PageTemplate>
  )
}
