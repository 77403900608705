import PageTemplate from 'src/components/templates/PageTemplate'

import {useEcritures} from 'src/hooks/api-hooks'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import moment from 'moment'
import ComptesComptableInput from '../../components/inputs/ComptesComptableInput'
import AutocompleteInput from '../../components/inputs/AutocompleteInput'

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('date', {
    header: 'Date',
    id: 'date',
    cell: info => moment(info.getValue()).format('DD/MM/YYYY'),
  }),
  columnHelper.accessor('export_compta_id', {
    id: 'export_compta_id',
    header: 'Export Compta',
  }),
  columnHelper.accessor('compte_comptable_id', {
    id: 'compte_comptable_id',
    header: 'Compte Comptable',
    enableSorting: false,
  }),
  columnHelper.accessor('libelle', {
    id: 'libelle',
    header: 'Libellé',
  }),
  columnHelper.accessor('commentaire', {
    id: 'commentaire',
    header: 'Commentaire',
  }),
  columnHelper.accessor(row => (row.montant < 0 ? row.montant : ''), {
    id: 'debit',
    header: 'Débit',
    enableSorting: false,
  }),
  columnHelper.accessor(row => (row.montant >= 0 ? row.montant : ''), {
    id: 'credit',
    header: 'Crédit',
    enableSorting: false,
  }),
]

export default function CompteComptableDetails() {
  const universalTableData = useUniversalTableData(useEcritures, {
    enableFiltersFromSearchParams: true,
    defaultRowPerPage: 1000,
    rowsPerPageOptions: [1000, 2000, 5000, 10000],
  })
  const {filters, setSearchParams, searchParamsFilters} = universalTableData
  return (
    <PageTemplate
      title="Ecritures"
      sections={[
        {title: 'Comptes comptable', path: '/comptes-comptable'},
        {title: 'Ecritures', current: true},
      ]}
    >
      <WrappedUniversalTable
        columns={columns}
        name={'ecritures-list'}
        universalTableData={universalTableData}
        filtersContent={
          <>
            <ComptesComptableInput
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id || option === value.id
              }}
              getOptionLabel={option => {
                return Boolean(option.id)
                  ? `${option.id} - ${option.libelle}`
                  : option
              }}
              defaultValue={filters?.compte_comptable ?? undefined}
              onChange={(event, value) => {
                const currentFilters = filters
                if (value?.id) {
                  currentFilters.compte_comptable = value.id
                } else {
                  delete currentFilters.compte_comptable
                }
                setSearchParams(currentFilters)
              }}
            />
            <AutocompleteInput
              label="Export Comptable"
              options={['Exportées', 'Non exportées']}
              onChange={(event, value) => {
                const currentFilters = filters
                if (value) {
                  currentFilters.est_exportee = value === 'Exportées'
                } else {
                  delete currentFilters.est_exportee
                }
                setSearchParams(currentFilters)
              }}
            />
          </>
        }
      />
    </PageTemplate>
  )
}
