import {Controller} from 'react-hook-form'
import AutocompleteInput from '../inputs/AutocompleteInput'

const AutocompleteInputControlled = ({
  label,
  error,
  control,
  name,
  helperText,
  defaultValue = null,
  options,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({field: {value, onChange}}) => (
      <AutocompleteInput
        onChange={(event, item) => {
          onChange(item)
        }}
        value={value}
        options={options}
        label={label}
        error={error}
        helperText={helperText}
        {...props}
      />
    )}
  />
)

export default AutocompleteInputControlled
