import {Autocomplete} from '@mui/material'
import TextInput from './TextInput'

const AutocompleteInput = ({
  label,
  variant,
  error,
  helperText,
  options,
  ...props
}) => {
  return (
    <Autocomplete
      options={options}
      {...props}
      renderInput={params => (
        <TextInput
          {...params}
          margin="dense"
          size="small"
          variant={variant}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
    />
  )
}

export default AutocompleteInput
