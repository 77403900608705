import PrimaryButton from './PrimaryButton'
import AddIcon from '@mui/icons-material/Add'

const AddButton = ({children, ...props}) => (
  <PrimaryButton
    type="submit"
    startIcon={<AddIcon fontSize="small" />}
    {...props}
  >
    {children}
  </PrimaryButton>
)

export default AddButton
