import {Fragment} from 'react'
import {useFieldArray} from 'react-hook-form'
import {Grid, Divider} from '@mui/material'
import * as yup from 'yup'

import {useForm, ClientAutocomplete, LiaisonsField} from 'src/components/Form'
import CloseButton from 'src/components/buttons/CloseButton'
import AddButton from 'src/components/buttons/AddButton'
import ClientAutocompleteInputControlled from 'src/components/controlled/ClientAutocompleteInputControlled'
import LiaisonsInputControlled from 'src/components/controlled/LiaisonsInputControlled'

const LiaisonsClientsSchema = yup.object().shape({
  client_2_id: yup.mixed().required().label('Autre client'),
  lien_id: yup.mixed().required().label('Nature du lien'),
})

const LiaisonsClientsAddField = ({loading, control, errors}) => {
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'liaisons_clients_add',
  })

  return (
    <Grid container spacing={2}>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            <Grid item xs={11}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ClientAutocompleteInputControlled
                    control={control}
                    name={`liaisons_clients_add.${k}.client_2_id`}
                    label="Autre client"
                    error={Boolean(
                      errors?.liaisons_clients_add?.k?.client_2_id,
                    )}
                    disabled={loading}
                    helperText={
                      errors?.liaisons_clients_add?.k?.client_2_id?.message
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <LiaisonsField
                    size="small"
                    name={`liaisons_clients_add[${k}].lien_id`}
                    isError={Boolean(
                      errors?.liaisons_clients_add?.[k]?.lien_id,
                    )}
                    disabled={loading}
                    errorMessage={
                      errors?.liaisons_clients_add?.[k]?.lien_id?.message
                    }
                    defaultValue={item.lien_id}
                  /> */}
                  <LiaisonsInputControlled
                    control={control}
                    name={`liaisons_clients_add.${k}.lien_id`}
                    error={Boolean(errors?.liaisons_clients_add?.[k]?.lien_id)}
                    disabled={loading}
                    helperText={
                      errors?.liaisons_clients_add?.k?.lien_id?.message
                    }
                    // defaultValue={item.lien_id}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CloseButton
                disabled={loading}
                onClick={event => {
                  event.preventDefault()
                  remove(k)
                }}
              />
            </Grid>
            <Divider />
          </Fragment>
        )
      })}

      <Grid item xs={12}>
        <AddButton
          disabled={loading}
          onClick={event => {
            event.preventDefault()
            append({lien_id: null, client_2_id: null})
          }}
        >
          Ajouter un lien
        </AddButton>
      </Grid>
    </Grid>
  )
}

export {LiaisonsClientsAddField as default, LiaisonsClientsSchema}
