import {StrictMode} from 'react'
import {QueryClient, QueryClientProvider} from 'react-query'
import ReactDOM from 'react-dom'
import {HelmetProvider} from 'react-helmet-async'
import {StyledEngineProvider} from '@mui/material'
import {BrowserRouter} from 'react-router-dom'
import {SnackbarProvider} from 'notistack'
import {ThemeProvider} from '@mui/material/styles'
import {createTheme} from 'src/theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {AuthProvider} from 'src/hooks/useAuth'
import {QueryStateProvider} from 'src/hooks/useQueryState'
import DebugProvider from 'src/hooks/useDebug'
import {ReactQueryDevtools} from 'react-query/devtools'
import 'moment/locale/fr'
import {fr} from 'yup-locales'
import {setLocale} from 'yup'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import {LocalizationProvider} from '@mui/lab'

setLocale(fr)

if (
  process.env.REACT_APP_HVA_MOCK === 'true' ||
  process.env.REACT_APP_HVA_MOCK === true
) {
  const {worker} = require('./mocks/browser')
  worker.start()
}

const theme = createTheme()
const queryClient = new QueryClient()
ReactDOM.render(
  <StrictMode>
    <DebugProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <AuthProvider>
              <BrowserRouter>
                <QueryStateProvider>
                  <SnackbarProvider dense maxSnack={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={frLocale}
                    >
                      <ThemeProvider theme={theme}>
                        <App />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </SnackbarProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryStateProvider>
              </BrowserRouter>
            </AuthProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </DebugProvider>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
