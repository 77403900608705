import PageTemplate from 'src/components/templates/PageTemplate'

import {Link as RouterLink} from 'react-router-dom'
import {useDeleteRemiseEnBanque, useRemisesEnBanques} from 'src/hooks/api-hooks'
import {Button, IconButton, Link, Stack, Tooltip} from '@mui/material'
import DeviseLabel from 'src/components/DeviseLabel'
import ChangeStatusDialog from './ChangeStatusDialog'
import {Delete, Download, Money, Payments, Preview} from '@mui/icons-material'
import useModalConfirmation from '../../hooks/useModalConfirmation'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import {useMemo, useState} from 'react'
import TypesReglementInput from '../../components/inputs/TypesReglementInput'
import BanquesRemiseInput from '../../components/inputs/BanquesRemiseInput'
import AgencesInput from '../../components/inputs/AgencesInput'
import PermissionHidder from 'src/components/PermissionHidder'

const columnHelper = createColumnHelper()

export default function RemiseEnBanqueList({typeRemise}) {
  const rightActions = (
    <Stack direction="row" spacing={2}>
      {!Boolean(typeRemise) ||
        (typeRemise === 'Chèque' && (
          <Button
            startIcon={<Payments />}
            variant="contained"
            component={RouterLink}
            to="/remises-en-banque/create?type=Chèque"
          >
            Remettre des chèques
          </Button>
        ))}
      {!Boolean(typeRemise) ||
        (typeRemise === 'Espèces' && (
          <Button
            startIcon={<Money />}
            variant="contained"
            component={RouterLink}
            to="/remises-en-banque/create?type=Espèces"
          >
            Remettre des espèces
          </Button>
        ))}
    </Stack>
  )

  const {
    ConfirmDialogElement,
    args: remiseEnBanqueToDelete,
    setArgs: setRemiseEnBanqueToDelete,
  } = useModalConfirmation({
    onConfirm: args => {
      deleteRemiseEnBanque(remiseEnBanqueToDelete.id)
      setRemiseEnBanqueToDelete()
    },
  })
  const [remiseEnBanqueToChangeStatus, setRemiseEnBanqueToChangeStatus] =
    useState()
  const {mutate: deleteRemiseEnBanque} = useDeleteRemiseEnBanque()

  const universalTableData = useUniversalTableData(useRemisesEnBanques, {
    initialFilters: {type_remise: typeRemise},
  })

  const columns = useMemo(
    () => [
      columnHelper.accessor('numero_remise', {
        id: 'numero_remise',
        header: 'Numero remise',
        cell: info => (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/remises-en-banque/${info.row.original.id}`}
            variant="subtitle2"
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor('type_remise', {
        id: 'type_remise',
        header: 'Type',
      }),
      columnHelper.accessor('agence.name', {
        id: 'agence__name',
        header: 'Agence',
      }),
      columnHelper.accessor('montant', {
        id: 'montant',
        header: 'Montant',
        cell: info => <DeviseLabel>{info.getValue()}</DeviseLabel>,
      }),
      columnHelper.display({
        enableHiding: false,
        header: 'Actions',
        cell: ({row}) => {
          return (
            <>
              <Tooltip title="Voir le bordereau" arrow>
                <Link href={row.original.pdf_preview} target="_blank">
                  <IconButton sx={{ml: 1, mr: 1}} size="small" color="primary">
                    <Preview />
                  </IconButton>
                </Link>
              </Tooltip>

              <Tooltip title="Télecharger le bordereau" arrow>
                <Link href={row.original.pdf} target="_blank">
                  <IconButton sx={{ml: 1, mr: 1}} size="small" color="primary">
                    <Download />
                  </IconButton>
                </Link>
              </Tooltip>
              <PermissionHidder mainRole={'is_superuser'}>
                <Tooltip title="Effacer définitivement la remise" arrow>
                  <IconButton
                    sx={{ml: 1, mr: 1}}
                    size="small"
                    color="primary"
                    onClick={() => setRemiseEnBanqueToDelete(row.original)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </PermissionHidder>
            </>
          )
        },
      }),
    ],
    [],
  )
  return (
    <PageTemplate
      title="Remises en banque"
      sections={[{title: 'Remises en banque', current: true}]}
      rightActions={rightActions}
    >
      <WrappedUniversalTable
        columns={columns}
        universalTableData={universalTableData}
        name={'remise-en-banque-list'}
        filtersContent={
          <>
            {Boolean(typeRemise) ? null : (
              <TypesReglementInput
                remise={true}
                onChange={universalTableData.handleFilterChange(
                  'type_remise',
                  'nom',
                )}
              />
            )}
            <BanquesRemiseInput
              onChange={universalTableData.handleFilterChange(
                'banque_remise',
                'id',
              )}
            />
            <AgencesInput
              onChange={universalTableData.handleFilterChange('agence', 'id')}
            />
          </>
        }
      />

      {Boolean(remiseEnBanqueToDelete) && (
        <ConfirmDialogElement>
          Vous êtes sur le point de supprimer cette remise en banque:
          <br />
          <strong>
            {remiseEnBanqueToDelete.numero_remise} -{' '}
            {remiseEnBanqueToDelete.type_remise} - {remiseEnBanqueToDelete.date}{' '}
            - {remiseEnBanqueToDelete.agence.name} -{' '}
            <DeviseLabel>{remiseEnBanqueToDelete.montant}</DeviseLabel>
          </strong>
        </ConfirmDialogElement>
      )}
      {!!remiseEnBanqueToChangeStatus && (
        <ChangeStatusDialog
          remiseEnBanque={remiseEnBanqueToChangeStatus}
          onClose={() => setRemiseEnBanqueToChangeStatus()}
          open={!!remiseEnBanqueToChangeStatus}
          onCancel={() => setRemiseEnBanqueToChangeStatus()}
        />
      )}
    </PageTemplate>
  )
}
