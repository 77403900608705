import {Controller} from 'react-hook-form'
import CompagnieInput from '../inputs/CompagnieInput'

export default function CompagnieInputControlled({
  control,
  name = 'compagnie',
  defaultValue = 'Prudence Créole',
  error,
  onChange,
  helperText,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {value, onChange: localOnChange}}) => (
        <CompagnieInput
          onChange={(event, item) => {
            localOnChange(item)
            onChange?.(item)
          }}
          value={value}
          margin="dense"
          size="small"
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
