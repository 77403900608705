import {useState, useEffect} from 'react'
import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {fetchBanques} from 'src/services/crm-api'

const BanquesInput = ({
  label = 'Banque',
  error,
  helperText,
  margin,
  size,
  ...props
}) => {
  const [options, setOptions] = useState([])
  const [status, setStatus] = useState('loading')
  const [errorText, setErrorText] = useState()
  const loading = status === 'loading'
  const loadingError = status === 'error'

  useEffect(() => {
    let active = true

    fetchBanques()
      .then(data => {
        if (active) {
          setOptions(data)
          setStatus('loaded')
        }
      })
      .catch(err => {
        setStatus('error')
        setErrorText(err.message)
      })

    return () => {
      active = false
    }
  }, [loading])
  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.nom ?? ''}
      options={options}
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorText : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default BanquesInput
