const typesReglement = [
  {
    id: 1,
    nom: 'Chèque',
    confie: true,
    banque_remise_id: 1,
    banque_remise: {id: 1, nom: 'CEPAC Prime'},
    moyen_remboursement: true,
  },
  {
    id: 2,
    nom: 'Espèces',
    confie: true,
    banque_remise_id: 1,
    banque_remise: {id: 1, nom: 'CEPAC Prime'},
    moyen_remboursement: false,
  },
  {
    id: 3,
    nom: 'Virement',
    confie: true,
    banque_remise_id: 1,
    banque_remise: {id: 1, nom: 'CEPAC Prime'},
    moyen_remboursement: true,
  },
  {
    id: 4,
    nom: 'CB en agence',
    confie: false,
    banque_remise_id: 3,
    banque_remise: {id: 3, nom: 'Compte Compagnie'},
    moyen_remboursement: false,
  },
  {
    id: 5,
    nom: 'CB en ligne',
    confie: false,
    banque_remise_id: 1,
    banque_remise: {id: 3, nom: 'Compte Compagnie'},
    moyen_remboursement: false,
  },
  {
    id: 6,
    nom: 'CB 3 fois',
    confie: false,
    banque_remise_id: 1,
    banque_remise: {id: 3, nom: 'Compte Compagnie'},
    moyen_remboursement: false,
  },
  {
    id: 7,
    confie: true,
    nom: 'Règlement compagnie',
    banque_remise_id: null,
    banque_remise: null,
    moyen_remboursement: false,
  },
  {
    id: 8,
    nom: 'CB 2 fois',
    confie: false,
    banque_remise_id: 1,
    banque_remise: {id: 3, nom: 'Compte Compagnie'},
    moyen_remboursement: false,
  },
  {
    id: 9,
    nom: 'CB 4 fois',
    confie: false,
    banque_remise_id: 1,
    banque_remise: {id: 3, nom: 'Compte Compagnie'},
    moyen_remboursement: false,
  },
]

export {typesReglement}
