import PageTemplate from '../../components/templates/PageTemplate'
import {Container, Grid, Paper} from '@mui/material'
import TextInputControlled from '../../components/controlled/TextInputControlled'
import {useChangeCurrentUserPassword} from '../../hooks/api-hooks'
import {useForm} from 'react-hook-form'
import {LoadingButton} from '@mui/lab'
import {errorResponseToJson} from '../../services/crm-api'

export default function ChangePassword() {
  const {
    control,
    isLoading,
    formState: {errors: formErrors},
    handleSubmit,
  } = useForm()
  const {error, mutate} = useChangeCurrentUserPassword()

  console.log(error)
  const errors = {...formErrors, ...errorResponseToJson(error)}
  return (
    <PageTemplate
      title="Changer de mot de passe"
      sections={[{title: 'Changer de mot de passse', current: true}]}
    >
      <Container component={Paper}>
        <form
          onSubmit={handleSubmit(values => {
            mutate(values)
          })}
        >
          <Grid container spacing={1} padding={2}>
            <Grid item xs={12} md={12}>
              <TextInputControlled
                name={'old_password'}
                label={'Mot de passe actuel'}
                type={'password'}
                control={control}
                error={!!errors?.old_password?.message}
                helperText={errors?.old_password?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputControlled
                name={'password'}
                label={'Mot de passe actuel'}
                type={'password'}
                control={control}
                error={!!errors?.password?.message}
                helperText={errors?.password?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputControlled
                name={'password2'}
                label={'Mot de passe actuel'}
                type={'password'}
                control={control}
                error={!!errors?.password2?.message}
                helperText={errors?.password2?.message}
              />
            </Grid>
            <Grid justifyContent={'flex-end'} display={'flex'} xs={12} p={1}>
              <LoadingButton
                loading={isLoading}
                type={'submit'}
                variant={'contained'}
              >
                Changer
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </PageTemplate>
  )
}
