import {LoadingButton} from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'

const ConfirmDialog = ({
  children,
  title = '',
  loading = false,
  confirmColor = 'error',
  confirmLabel = 'Confirmer',
  onCancel = () => {},
  onConfirm = () => {},
  confirmVariant = 'text',
  open = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus disabled={loading}>
          Annuler
        </Button>
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={onConfirm}
          color={confirmColor}
          variant={confirmVariant}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
