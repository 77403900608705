import {Controller} from 'react-hook-form'
import DeviseInput from '../inputs/DeviseInput'

export default function DeviseInputControlled({
  control,
  name = 'devise',
  defaultValue = null,
  error,
  helperText,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field}) => (
        <DeviseInput
          {...field}
          inputRef={field.ref}
          margin="dense"
          size="small"
          fullWidth
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
