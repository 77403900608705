import {Alert} from '@mui/material'
import {useForm} from './Form'

const AlertError = ({name, readOnly = false, ...props}) => {
  const {errors} = useForm()

  return Boolean(errors?.message) ? (
    <Alert severity="error">{errors?.message}</Alert>
  ) : null
}

export default AlertError
