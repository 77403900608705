import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Person, Edit, Close} from '@mui/icons-material'

import {errorResponseToJson} from 'src/services/crm-api'
import Form, {
  AlertError,
  CiviliteField,
  DatePicker,
  TextField,
} from 'src/components/Form'

import LabelRow from 'src/components/LabelRow'
import CopiableBox from 'src/components/CopiableBox'
import {usePartialEditClient} from 'src/hooks/api-hooks'

const resolver = yupResolver(
  yup.object().shape({
    reagi_id: yup.string().label('id RéAGI').required().max(35),
    civilite: yup.string().label('Civilité').required(),
    nom: yup.string().required().max(50),
  }),
)

const DisplayBody = ({
  client,
  setEditing,
  editing,
  isLoading,
  modificationDisabled,
}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="ID RéAGI">
            <CopiableBox copyString={client?.reagi_id}>
              {client?.reagi_id}
            </CopiableBox>
          </LabelRow>
          <LabelRow label="Civilité">{client?.civilite}</LabelRow>
          <LabelRow label="Nom">{client?.nom}</LabelRow>
          <LabelRow label="Prenom">{client?.prenom}</LabelRow>
          <LabelRow label="Date de naissance">
            {client?.date_naissance}
          </LabelRow>
          <LabelRow label="Nom de naissance">{client?.nom_naissance}</LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={!Boolean(client) || modificationDisabled}
        >
          Modifier
        </Button>
      </CardActions>
    </>
  )
}

const EditBody = ({
  client,
  onSubmit,
  setEditing,
  editing,
  modificationDisabled,
}) => {
  const {isLoading, error, mutate} = usePartialEditClient(client.id)

  const errors = errorResponseToJson(error)

  return (
    <Form
      onSubmit={values => {
        mutate(values)
        setEditing(false)
      }}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID RéAGI">
            <TextField
              size="small"
              name="reagi_id"
              defaultValue={client.reagi_id}
            />
          </LabelRow>
          <LabelRow label="Civilité">
            <CiviliteField defaultValue={client?.civilite} size="small" />
          </LabelRow>
          <LabelRow label="Nom">
            <TextField size="small" name="nom" defaultValue={client.nom} />
          </LabelRow>
          <LabelRow label="Prenom">
            <TextField
              size="small"
              name="prenom"
              defaultValue={client.prenom}
            />
          </LabelRow>
          <LabelRow label="Date de naissance">
            <DatePicker
              size="small"
              name="date_naissance"
              defaultValue={client.date_naissance}
            />
          </LabelRow>
          <LabelRow label="Nom de naissance">
            <TextField
              size="small"
              name="nom_naissance"
              defaultValue={client.nom_naissance}
            />
          </LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="subit"
          disabled={isLoading || modificationDisabled}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ClientParticulierInfoCard = ({
  client,
  modificationDisabled,
  ...props
}) => {
  const [editing, setEditing] = useState(false)

  return (
    <Card {...props}>
      <CardHeader title="Identité du Particulier" avatar={<Person />} />
      <Divider />
      {editing ? (
        <EditBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ClientParticulierInfoCard
