import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material'

import useSnackbar from 'src/hooks/useSnackbar'
import useAsync from 'src/hooks/useAsync'
import AddButton from '../buttons/AddButton'
import EditButton from '../buttons/EditButton'
import PerteEtProfitForm from '../forms/PerteEtProfitForm'
import {createOperation, updateOperation} from 'src/services/crm-api'
import DeleteOperationButton from './DeleteOperationButton'
import {
  useCreateOperation,
  useEditOperation,
  useOperation,
} from 'src/hooks/api-hooks'
import useOperationDialog from './useOperationDialog'

const PerteEtProfitFormDialog = ({
  open = true,
  perteEtProfit,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) => {
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(perteEtProfit?.id)

  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      create(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="perte-et-profit-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="perte-et-profit-dialog">
        {isEditing ? 'Modifier' : 'Ajouter'} une operation diverse
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          'Chargement ...'
        ) : (
          <PerteEtProfitForm
            client={client}
            perteEtProfit={operation}
            onSubmit={handleSubmit}
            id="perte-et-profit-dialog-form"
          />
        )}
      </DialogContent>
      <DialogActions>
        <DeleteOperationButton
          sx={{mr: 'auto'}}
          operationId={perteEtProfit?.id}
          onDelete={handleClose}
        />
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        {isEditing ? (
          <EditButton
            form="perte-et-profit-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Modifier
          </EditButton>
        ) : (
          <AddButton
            form="perte-et-profit-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PerteEtProfitFormDialog
