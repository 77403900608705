import {fetchLiaisons} from 'src/services/crm-api'
import {LoadingAutocomplete} from '.'

const LiaisonsField = props => {
  return (
    <LoadingAutocomplete
      autoFit={true}
      promise={fetchLiaisons}
      transformItems={x => ({text: x.lien, value: x.id})}
      {...props}
    />
  )
}

export default LiaisonsField
