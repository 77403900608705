import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  Divider,
  Box,
  CardActions,
  Button,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material'
import {NavLink as RouterLink} from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

import {Add, Link} from '@mui/icons-material'

import ConfirmDialog from 'src/components/ConfirmDialog'
import CloseButton from 'src/components/buttons/CloseButton'
import ClientAddLiaisonDialog from './ClientAddLiaisonDialog'
import {useDeleteLiaison} from 'src/hooks/api-hooks'

const LiaisonRow = ({
  client,
  modificationDisabled,
  liaison: {id, client_2, client_1, lien},
}) => {
  const [open, setOpen] = useState(false)
  const {isLoading: loading, mutate} = useDeleteLiaison(client.id)

  const isTwo = client.id === client_2.id

  const description = isTwo ? lien.lien_reverse : lien.lien
  const theOther = isTwo ? client_1 : client_2
  const nom = theOther.description

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {description}
          <Button component={RouterLink} to={`/clients/${theOther.id}`}>
            <ArrowRightAltIcon size="small" />
            {nom}
          </Button>
        </Box>
        <Box>
          {!loading && (
            <CloseButton
              onClick={() => {
                setOpen(true)
              }}
            />
          )}
          {loading && <CircularProgress size={20} />}
        </Box>
      </Box>
      <ConfirmDialog
        title="Confirmation de suppression"
        modificationDisabled={modificationDisabled}
        onConfirm={() => {
          mutate(id)
          setOpen(false)
        }}
        onCancel={() => {
          setOpen(false)
        }}
        open={open}
      >
        Vous êtes sur le point de supprimer cette relation:
        <br />
        <strong>
          {description} {nom}
        </strong>
      </ConfirmDialog>
    </>
  )
}

const ClientLiaisonsCard = ({client, modificationDisabled, ...props}) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  return (
    <Card {...props} sx={{mb: 3}}>
      <CardHeader title="Liaisons" avatar={<Link />} />
      <Divider />
      {client?.liaisons_clients?.length > 0 && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                {client?.liaisons_clients?.map(x => {
                  return <LiaisonRow client={client} liaison={x} key={x.id} />
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <CardActions>
        <Box>
          <ClientAddLiaisonDialog
            client={client}
            open={openCreateDialog}
            modificationDisabled={modificationDisabled}
            onClose={() => {
              setOpenCreateDialog(false)
            }}
          />
          <Button
            startIcon={<Add fontSize="small" />}
            color="primary"
            variant="text"
            onClick={() => {
              setOpenCreateDialog(true)
            }}
            disabled={modificationDisabled}
          >
            Ajouter une liaison
          </Button>
        </Box>
      </CardActions>
    </Card>
  )
}

export default ClientLiaisonsCard
