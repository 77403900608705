import useLocalPagination from './useLocalPagination'
import {useMemo, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

export default function useUniversalTableData(
  reactQueryHook,
  {
    enableFiltersFromSearchParams = false,
    defaultRowPerPage,
    rowsPerPageOptions,
    initialFilters = {},
  } = {},
) {
  const {offset, limit, PaginationComponent} = useLocalPagination({
    defaultRowPerPage,
    rowsPerPageOptions,
  })

  const [ordering, setOrdering] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsFilters = useMemo(() => {
    if (enableFiltersFromSearchParams) {
      const result = {}
      for (const [key, value] of searchParams) {
        result[key] = value
      }
      return result
    }
    return {}
  }, [searchParams, enableFiltersFromSearchParams])

  const [filters, setFilters] = useState({})
  const {isLoading, data, isFetching, isError, error} = reactQueryHook({
    ...searchParamsFilters,
    ...initialFilters,
    offset,
    limit,
    ordering,
    ...filters,
  })
  const handleFilterChange = (filterKey, key) => (evt, value) => {
    if (!value) {
      const currentFilters = {...filters}
      delete currentFilters[filterKey]
      return setFilters(currentFilters)
    }
    setFilters({...filters, [filterKey]: key ? value?.[key] : value})
  }
  return {
    offset,
    limit,
    PaginationComponent,
    ordering,
    setOrdering,
    isLoading,
    data,
    error,
    isError,
    isFetching,
    filters,
    setFilters,
    handleFilterChange,
    searchParamsFilters,
    setSearchParams,
  }
}
