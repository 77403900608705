import {rest} from 'msw'
import jsons from './all-json'
import {nanoid} from 'nanoid'

export const baseURL = process.env.REACT_APP_CRM_API_URL
const delay = process.env.NODE_ENV === 'test' ? 0 : 300

export const handlers = [
  rest.get(`${baseURL}/me/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.status(200), ctx.json(jsons.me))
  }),
  // rest.get(`${baseURL}/me/`, async (req, res, ctx) => {
  //   return res(
  //     ctx.status(401),
  //     ctx.json({
  //       detail: 'Given token not valid for any token type',
  //       code: 'token_not_valid',
  //       messages: [
  //         {
  //           token_class: 'AccessToken',
  //           token_type: 'access',
  //           message: 'Token is invalid or expired',
  //         },
  //       ],
  //       status_code: 401,
  //     }),
  //   )
  // }),
  rest.post(`${baseURL}/token/obtain/`, async (req, res, ctx) => {
    const response = {}
    var valid = true
    if (!req.body.password) {
      response.password = 'password is required'
      valid = false
    }
    if (!req.body.email) {
      response.email = 'email is required'
      valid = false
    }

    if (valid) {
      return res(
        ctx.delay(delay),
        ctx.json({
          refresh: 'refresh_token',
          access: 'access_token',
        }),
      )
    } else {
      return res(ctx.delay(delay), ctx.status(400), ctx.json(response))
    }
  }),
  /*
   █████╗  ██████╗ ███████╗███╗   ██╗ ██████╗███████╗███████╗
  ██╔══██╗██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔════╝██╔════╝
  ███████║██║  ███╗█████╗  ██╔██╗ ██║██║     █████╗  ███████╗
  ██╔══██║██║   ██║██╔══╝  ██║╚██╗██║██║     ██╔══╝  ╚════██║
  ██║  ██║╚██████╔╝███████╗██║ ╚████║╚██████╗███████╗███████║
  ╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚═╝  ╚═══╝ ╚═════╝╚══════╝╚══════╝
                                                             
  */
  rest.get(`${baseURL}/agences/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.agences))
  }),
  /*
  ███████╗ ██████╗ ███╗   ██╗ ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
  ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
  █████╗  ██║   ██║██╔██╗ ██║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
  ██╔══╝  ██║   ██║██║╚██╗██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
  ██║     ╚██████╔╝██║ ╚████║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
  ╚═╝      ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
                                                                            
  */
  rest.get(`${baseURL}/fonctions/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.fonctions))
  }),
  /*
  ███████╗███████╗██████╗ ██╗   ██╗██╗ ██████╗███████╗███████╗
  ██╔════╝██╔════╝██╔══██╗██║   ██║██║██╔════╝██╔════╝██╔════╝
  ███████╗█████╗  ██████╔╝██║   ██║██║██║     █████╗  ███████╗
  ╚════██║██╔══╝  ██╔══██╗╚██╗ ██╔╝██║██║     ██╔══╝  ╚════██║
  ███████║███████╗██║  ██║ ╚████╔╝ ██║╚██████╗███████╗███████║
  ╚══════╝╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝╚══════╝╚══════╝
                                                              
  */
  rest.get(`${baseURL}/services/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.services))
  }),
  /*
  ██████╗ ██████╗  ██████╗ ██████╗ ██╗   ██╗██╗████████╗███████╗
  ██╔══██╗██╔══██╗██╔═══██╗██╔══██╗██║   ██║██║╚══██╔══╝██╔════╝
  ██████╔╝██████╔╝██║   ██║██║  ██║██║   ██║██║   ██║   ███████╗
  ██╔═══╝ ██╔══██╗██║   ██║██║  ██║██║   ██║██║   ██║   ╚════██║
  ██║     ██║  ██║╚██████╔╝██████╔╝╚██████╔╝██║   ██║   ███████║
  ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝   ╚═╝   ╚══════╝
  */
  rest.get(`${baseURL}/produits/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.produits))
  }),
  /*
  ██╗   ██╗███████╗███████╗██████╗ ███████╗     ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝    ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║███████╗█████╗  ██████╔╝███████╗    ██║     ██████╔╝█████╗  ███████║   ██║   █████╗  
  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║    ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝  
  ╚██████╔╝███████║███████╗██║  ██║███████║    ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝     ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  */
  rest.post(`${baseURL}/users/`, async (req, res, ctx) => {
    return res(
      ctx.delay(delay),
      ctx.json({
        id: 1337,
        ...req.body,
      }),
    )
  }),

  /*
  ██╗   ██╗███████╗███████╗██████╗ ███████╗
  ██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
  ██║   ██║███████╗█████╗  ██████╔╝███████╗
  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
  ╚██████╔╝███████║███████╗██║  ██║███████║
   ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝
  */
  rest.get(`${baseURL}/users/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.usersList))
  }),
  rest.get(`${baseURL}/users/:id`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.usersDetails))
  }),
  rest.get(`${baseURL}/users/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.usersEdit))
  }),
  rest.put(`${baseURL}/users/:id/changepassword/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json({status: 'ok'}))
  }),
  rest.put(`${baseURL}/users/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(req.body))
  }),

  /*
  ██╗     ██╗ █████╗ ██╗███████╗ ██████╗ ███╗   ██╗███████╗
  ██║     ██║██╔══██╗██║██╔════╝██╔═══██╗████╗  ██║██╔════╝
  ██║     ██║███████║██║███████╗██║   ██║██╔██╗ ██║███████╗
  ██║     ██║██╔══██║██║╚════██║██║   ██║██║╚██╗██║╚════██║
  ███████╗██║██║  ██║██║███████║╚██████╔╝██║ ╚████║███████║
  ╚══════╝╚═╝╚═╝  ╚═╝╚═╝╚══════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
  */
  rest.get(`${baseURL}/clients/liaisons/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.liaisons))
  }),
  rest.post(`${baseURL}/clients/:id/ajout-liaisons/`, async (req, res, ctx) => {
    const {lien_id, client_2_id} = req.body
    // Get the correct liaison
    const lien = jsons.liaisons.find(x => x.id === parseInt(lien_id))
    const client_1 = {
      id: 1,
      type: 'Personne morale',
      denomination_sociale: 'Acme',
    }
    const client_2 = jsons.clientsList['results'].find(x => {
      return x.id === parseInt(client_2_id)
    })
    // Get the correct client
    return res(
      ctx.delay(delay),
      ctx.json({
        id: nanoid(),
        client_2,
        client_1,
        lien,
      }),
    )
  }),
  rest.delete(
    `${baseURL}/clients/enlever-liaisons/:id/`,
    async (req, res, ctx) => {
      return res(ctx.delay(delay), ctx.json({ok: 'ok'}))
    },
  ),

  /*
      ██████╗██╗     ██╗███████╗███╗   ██╗████████╗
     ██╔════╝██║     ██║██╔════╝████╗  ██║╚══██╔══╝
     ██║     ██║     ██║█████╗  ██╔██╗ ██║   ██║   
     ██║     ██║     ██║██╔══╝  ██║╚██╗██║   ██║   
     ╚██████╗███████╗██║███████╗██║ ╚████║   ██║   
      ╚═════╝╚══════╝╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
  */
  rest.get(`${baseURL}/clients/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.clientsList))
  }),
  rest.get(`${baseURL}/clients/:id/`, async (req, res, ctx) => {
    const client = jsons.clientsList['results'].find(x => {
      return x.id === parseInt(req.params.id)
    })
    return res(ctx.delay(delay), ctx.json(client))
  }),
  rest.put(`${baseURL}/clients/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(req.body))
  }),
  rest.patch(`${baseURL}/clients/:id/`, async (req, res, ctx) => {
    const newClient = {
      ...jsons.clientsList['results'].find(x => {
        return x.id === parseInt(req.params.id)
      }),
      ...req.body,
    }
    return res(ctx.delay(delay), ctx.json(newClient))
  }),
  rest.post(`${baseURL}/clients/`, async (req, res, ctx) => {
    return res(
      ctx.delay(delay),
      ctx.json({
        id: 1,
        ...req.body,
      }),
    )
  }),

  /*
   ██████╗ ██████╗ ███╗   ██╗████████╗██████╗  █████╗ ████████╗███████╗
  ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██║   ██║██╔██╗ ██║   ██║   ██████╔╝███████║   ██║   ███████╗
  ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██╗██╔══██║   ██║   ╚════██║
  ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║██║  ██║   ██║   ███████║
   ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  */
  rest.get(`${baseURL}/contrats/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.contratsList))
  }),
  rest.post(`${baseURL}/contrats/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json({id: 1, ...req.body}))
  }),
  rest.get(`${baseURL}/contrats/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.contratDetails))
  }),
  rest.put(`${baseURL}/contrats/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(req.body))
  }),
  /*
  ███╗   ███╗ ██████╗ ████████╗██╗███████╗███████╗    ██████╗ ███████╗███████╗██╗██╗     ██╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
  ████╗ ████║██╔═══██╗╚══██╔══╝██║██╔════╝██╔════╝    ██╔══██╗██╔════╝██╔════╝██║██║     ██║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  ██╔████╔██║██║   ██║   ██║   ██║█████╗  ███████╗    ██████╔╝█████╗  ███████╗██║██║     ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██║╚██╔╝██║██║   ██║   ██║   ██║██╔══╝  ╚════██║    ██╔══██╗██╔══╝  ╚════██║██║██║     ██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
  ██║ ╚═╝ ██║╚██████╔╝   ██║   ██║██║     ███████║    ██║  ██║███████╗███████║██║███████╗██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
  ╚═╝     ╚═╝ ╚═════╝    ╚═╝   ╚═╝╚═╝     ╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝╚══════╝╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
  */
  rest.get(`${baseURL}/motifs-resiliation/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.motifsResiliations))
  }),
  /*
  ██████╗  █████╗ ███╗   ██╗ ██████╗ ██╗   ██╗███████╗███████╗
  ██╔══██╗██╔══██╗████╗  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝
  ██████╔╝███████║██╔██╗ ██║██║   ██║██║   ██║█████╗  ███████╗
  ██╔══██╗██╔══██║██║╚██╗██║██║▄▄ ██║██║   ██║██╔══╝  ╚════██║
  ██████╔╝██║  ██║██║ ╚████║╚██████╔╝╚██████╔╝███████╗███████║
  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝ ╚══▀▀═╝  ╚═════╝ ╚══════╝╚══════╝
  */
  rest.get(`${baseURL}/banques/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.banques))
  }),
  /*
  ██████╗  █████╗ ███╗   ██╗ ██████╗ ██╗   ██╗███████╗███████╗    ██████╗ ███████╗███╗   ███╗██╗███████╗███████╗
  ██╔══██╗██╔══██╗████╗  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝    ██╔══██╗██╔════╝████╗ ████║██║██╔════╝██╔════╝
  ██████╔╝███████║██╔██╗ ██║██║   ██║██║   ██║█████╗  ███████╗    ██████╔╝█████╗  ██╔████╔██║██║███████╗█████╗  
  ██╔══██╗██╔══██║██║╚██╗██║██║▄▄ ██║██║   ██║██╔══╝  ╚════██║    ██╔══██╗██╔══╝  ██║╚██╔╝██║██║╚════██║██╔══╝  
  ██████╔╝██║  ██║██║ ╚████║╚██████╔╝╚██████╔╝███████╗███████║    ██║  ██║███████╗██║ ╚═╝ ██║██║███████║███████╗
  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝ ╚══▀▀═╝  ╚═════╝ ╚══════╝╚══════╝    ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚═╝╚══════╝╚══════╝
  */
  rest.get(`${baseURL}/banques-remise/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.banquesRemise))
  }),
  /*
  ███████╗████████╗ █████╗ ████████╗███████╗    ██████╗ ██████╗ ███████╗ ██████╗ ██████╗ ███╗   ██╗████████╗███████╗███╗   ██╗████████╗██╗███████╗██╗   ██╗██╗  ██╗
  ██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██╔════╝    ██╔══██╗██╔══██╗██╔════╝██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔════╝████╗  ██║╚══██╔══╝██║██╔════╝██║   ██║╚██╗██╔╝
  █████╗     ██║   ███████║   ██║   ███████╗    ██████╔╝██████╔╝█████╗  ██║     ██║   ██║██╔██╗ ██║   ██║   █████╗  ██╔██╗ ██║   ██║   ██║█████╗  ██║   ██║ ╚███╔╝ 
  ██╔══╝     ██║   ██╔══██║   ██║   ╚════██║    ██╔═══╝ ██╔══██╗██╔══╝  ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══╝  ██║╚██╗██║   ██║   ██║██╔══╝  ██║   ██║ ██╔██╗ 
  ███████╗   ██║   ██║  ██║   ██║   ███████║    ██║     ██║  ██║███████╗╚██████╗╚██████╔╝██║ ╚████║   ██║   ███████╗██║ ╚████║   ██║   ██║███████╗╚██████╔╝██╔╝ ██╗
  ╚══════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝     ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚═╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝
                                                                                                                                                                   
  */
  rest.get(`${baseURL}/etats-precontentieux/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.etatsPrecontentieux))
  }),
  /*
  ████████╗██╗   ██╗██████╗ ███████╗███████╗     █████╗ ██╗   ██╗███████╗███╗   ██╗ █████╗ ███╗   ██╗████████╗
  ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝    ██╔══██╗██║   ██║██╔════╝████╗  ██║██╔══██╗████╗  ██║╚══██╔══╝
     ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗    ███████║██║   ██║█████╗  ██╔██╗ ██║███████║██╔██╗ ██║   ██║   
     ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║    ██╔══██║╚██╗ ██╔╝██╔══╝  ██║╚██╗██║██╔══██║██║╚██╗██║   ██║   
     ██║      ██║   ██║     ███████╗███████║    ██║  ██║ ╚████╔╝ ███████╗██║ ╚████║██║  ██║██║ ╚████║   ██║   
     ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝    ╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝   
  */
  rest.get(`${baseURL}/types-avenant/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.typesAvenant))
  }),
  /*
  ████████╗██╗   ██╗██████╗ ███████╗███████╗    ██████╗ ███████╗ ██████╗ ██╗     ███████╗███╗   ███╗███████╗███╗   ██╗████████╗
  ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔════╝    ██╔══██╗██╔════╝██╔════╝ ██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
     ██║    ╚████╔╝ ██████╔╝█████╗  ███████╗    ██████╔╝█████╗  ██║  ███╗██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║   ██║   
     ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ╚════██║    ██╔══██╗██╔══╝  ██║   ██║██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║   
     ██║      ██║   ██║     ███████╗███████║    ██║  ██║███████╗╚██████╔╝███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║   ██║   
     ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚══════╝    ╚═╝  ╚═╝╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝   
  */
  rest.get(`${baseURL}/types-reglement/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.typesReglement))
  }),
  /*
   ██████╗ ██╗   ██╗██╗████████╗████████╗ █████╗ ███╗   ██╗ ██████╗███████╗███████╗
  ██╔═══██╗██║   ██║██║╚══██╔══╝╚══██╔══╝██╔══██╗████╗  ██║██╔════╝██╔════╝██╔════╝
  ██║   ██║██║   ██║██║   ██║      ██║   ███████║██╔██╗ ██║██║     █████╗  ███████╗
  ██║▄▄ ██║██║   ██║██║   ██║      ██║   ██╔══██║██║╚██╗██║██║     ██╔══╝  ╚════██║
  ╚██████╔╝╚██████╔╝██║   ██║      ██║   ██║  ██║██║ ╚████║╚██████╗███████╗███████║
   ╚══▀▀═╝  ╚═════╝ ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝╚══════╝╚══════╝
  */
  rest.get(`${baseURL}/clients/:id/quittances/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.quittances))
  }),
  rest.post(`${baseURL}/quittances/`, async (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.json({...req.body, id: Math.floor(Math.random() * 100 + 10000)}),
    )
  }),
  rest.put(`${baseURL}/quittances/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.json(req.body))
  }),
  /*
  ███████╗ ██████╗██████╗ ██╗████████╗██╗   ██╗██████╗ ███████╗███████╗
  ██╔════╝██╔════╝██╔══██╗██║╚══██╔══╝██║   ██║██╔══██╗██╔════╝██╔════╝
  █████╗  ██║     ██████╔╝██║   ██║   ██║   ██║██████╔╝█████╗  ███████╗
  ██╔══╝  ██║     ██╔══██╗██║   ██║   ██║   ██║██╔══██╗██╔══╝  ╚════██║
  ███████╗╚██████╗██║  ██║██║   ██║   ╚██████╔╝██║  ██║███████╗███████║
  ╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
  */
  rest.get(`${baseURL}/clients/:id/ecritures/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json(jsons.ecritures))
  }),
  rest.post(`${baseURL}/ecritures/`, async (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.json({...req.body, id: Math.floor(Math.random() * 100 + 10000)}),
    )
  }),
  rest.put(`${baseURL}/ecritures/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(1000), ctx.json(req.body))
  }),
  rest.delete(`${baseURL}/ecritures/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json({ok: 'ok'}))
  }),
  /*
   █████╗ ███████╗███████╗███████╗ ██████╗████████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
  ██╔══██╗██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  ███████║█████╗  █████╗  █████╗  ██║        ██║   ███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██╔══██║██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
  ██║  ██║██║     ██║     ███████╗╚██████╗   ██║   ██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
  ╚═╝  ╚═╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝
  */
  rest.post(`${baseURL}/affectations/`, async (req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.json({
        ...req.body,
        id: Math.floor(Math.random() * 100 + 10000),
        lettrage: (Math.random() + 1).toString(36).substring(2, 5),
      }),
    )
  }),
  rest.delete(`${baseURL}/affectations/:id/`, async (req, res, ctx) => {
    return res(ctx.delay(delay), ctx.json({ok: 'ok'}))
  }),
]
