export default function SizeFormatter({sizeInBytes}) {
  if (!sizeInBytes) return ''
  const sizeInK = sizeInBytes / 1024
  if (sizeInK < 1024) {
    return `${Math.round(sizeInK)} Ko`
  }
  const sizeInM = sizeInK / 1024
  if (sizeInM < 1024) {
    return `${Math.round(sizeInM)} Mo`
  }

  return `${Math.toFixed(sizeInM / 1024, 2)} Go`
}
