const motifsResiliations = [
  {
    id: 1,
    nom: 'Dénonciation à échéance du souscripteur',
  },
  {
    id: 2,
    nom: 'Changement de domicile',
  },
  {
    id: 3,
    nom: 'Changement de situation matrimoniale',
  },
  {
    id: 4,
    nom: 'Changement de régime matrimonial',
  },
  {
    id: 5,
    nom: 'Changement de profession',
  },
  {
    id: 6,
    nom: "Cessation d'activité",
  },
  {
    id: 7,
    nom: 'Retraite professionnelle',
  },
  {
    id: 8,
    nom: 'Transfert de propriété',
  },
  {
    id: 9,
    nom: 'Disparition du bien',
  },
  {
    id: 10,
    nom: "Par l'Assureur suite à sinistre",
  },
  {
    id: 11,
    nom: 'Résiliation par la compagnie',
  },
  {
    id: 12,
    nom: 'Décès du souscripteur',
  },
  {
    id: 13,
    nom: 'Aggravation de risque (L113-4)',
  },
  {
    id: 14,
    nom: 'Surveillance de portefeuille',
  },
  {
    id: 15,
    nom: 'Loi Hamon',
  },
]

export {motifsResiliations}
