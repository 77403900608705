import NumberFormat from 'react-number-format'

export default function DeviseLabel({
  children,
  hideSymbol = false,
  colored = false,
  ...props
}) {
  return (
    <span
      style={{
        color: colored ? (children >= 0 ? 'green' : 'red') : 'inherit',
      }}
    >
      <NumberFormat
        value={parseFloat(children)}
        fixedDecimalScale={true}
        decimalScale={2}
        thousandsGroupStyle="thousand"
        decimalSeparator=","
        thousandSeparator=" "
        displayType={'text'}
        {...props}
      />
      {!hideSymbol && ' €'}
    </span>
  )
}
