import {TableRow, TableCell} from '@mui/material'
import CardSubtitle from 'src/components/labels/CardSubtitle'

const LabelRow = ({
  children,
  label,
  labelCellProps,
  elementCellProps,
  ...props
}) => {
  return (
    <TableRow {...props}>
      <TableCell {...labelCellProps}>
        <CardSubtitle>{label}</CardSubtitle>
      </TableCell>
      <TableCell {...elementCellProps}>{children}</TableCell>
    </TableRow>
  )
}

export default LabelRow
