import {Controller} from 'react-hook-form'
import TextInput from '../inputs/TextInput'

export default function TextInputControlled({
  control,
  error,
  helperText,
  name,
  defaultValue = '',
  label,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {ref, ...field}}) => (
        <TextInput
          {...field}
          inputRef={ref}
          error={error}
          helperText={helperText}
          label={label}
          {...props}
        />
      )}
    />
  )
}
