import PageTemplate from 'src/components/templates/PageTemplate'
import AddButton from 'src/components/buttons/AddButton'
import {createColumnHelper} from '@tanstack/react-table'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {useResetPassword, useUsers} from '../../hooks/api-hooks'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {Box, IconButton, Link} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import {Edit, Send} from '@mui/icons-material'
import AutocompleteInput from '../../components/inputs/AutocompleteInput'
import ConfirmDialog from '../../components/ConfirmDialog'
import {useMemo, useState} from 'react'

const userTypes = [
  {
    label: 'Tous',
    value: {
      is_staff: null,
      is_superuser: null,
    },
  },
  {
    label: 'Utilisateurs',
    value: {
      is_staff: true,
      is_superuser: false,
    },
  },
  {
    label: 'Lecture seule',
    value: {
      is_staff: false,
      is_superuser: false,
    },
  },
  {
    label: 'Admin',
    value: {
      is_staff: null,
      is_superuser: true,
    },
  },
]

const columnHelper = createColumnHelper()

const rightActions = (
  <AddButton to="/users/create">Ajouter un utilisateur</AddButton>
)

const UserList = () => {
  const universalTableData = useUniversalTableData(useUsers)
  const {handleFilterChange, setFilters} = universalTableData
  const [userToReset, setUserToReset] = useState()
  const {isLoading: userResetLoading, mutate} = useResetPassword(
    userToReset?.id,
  )
  const columns = useMemo(
    () => [
      columnHelper.accessor('display_name', {
        id: 'lastname',
        header: 'Nom',
        cell: info => (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/users/${info.cell.row.original.id}`}
            variant="subtitle2"
            data-testid="cell-field-nom"
          >
            {info.getValue()}
          </Link>
        ),
      }),
      columnHelper.accessor(
        row =>
          row.is_superuser
            ? 'Admin'
            : row.is_staff
            ? 'Utilisateurs'
            : 'Lecture seule',
        {
          header: 'Type',
          id: 'type',
          enableSorting: false,
        },
      ),
      columnHelper.accessor('fonction', {
        id: 'fonction__name',
        header: 'Fonction',
        cell: info =>
          !!info.cell.row.original.fonction
            ? info.cell.row.original.fonction.name
            : '',
      }),
      columnHelper.accessor('agence.name', {
        id: 'agence__name',
        header: 'Agence',
      }),
      columnHelper.display({
        header: ' ',
        enableHiding: false,
        cell: ({row}) => {
          return (
            <Box sx={{textAlign: 'right'}}>
              <IconButton
                size="small"
                sx={{fontSize: 15, ml: 2}}
                onClick={() => setUserToReset(row.original)}
              >
                <Send fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                sx={{fontSize: 15}}
                component={RouterLink}
                to={`/users/${row.original.id}/edit`}
              >
                <Edit fontSize="inherit" />
              </IconButton>
            </Box>
          )
        },
      }),
    ],
    [setUserToReset, userToReset],
  )

  return (
    <PageTemplate
      title="Utilisateurs"
      sections={[{title: 'Utilisateurs', current: true}]}
      data-testid="page-users-list"
      rightActions={rightActions}
    >
      <WrappedUniversalTable
        universalTableData={universalTableData}
        name={'users-list'}
        columns={columns}
        filtersContent={
          <AutocompleteInput
            options={userTypes}
            label={'Type'}
            onChange={(_, value) => setFilters(value?.value)}
          />
        }
      />
      {!!userToReset && (
        <ConfirmDialog
          title="Confirmation"
          onConfirm={() => {
            mutate(userToReset.id)
            setUserToReset()
          }}
          onCancel={() => {
            setUserToReset()
          }}
          open={!!userToReset}
          confirmVariant={'contained'}
          loading={userResetLoading}
        >
          Vous êtes sur le point de réinitialiser le mot de passe de{' '}
          {userToReset?.lastname} {userToReset?.firstname}. <br />
          Voulez-vous continuer ?
        </ConfirmDialog>
      )}
    </PageTemplate>
  )
}

export default UserList
