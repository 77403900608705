import {Controller} from 'react-hook-form'
import TypeClientInput from '../inputs/TypeClientInput'

export default function TypeClientInputControlled({
  control,
  error,
  name = 'type',
  helperText,
  defaultValue = null,
  ...props
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({field: {value, onChange}}) => (
        <TypeClientInput
          onChange={(event, item) => {
            onChange(item)
          }}
          value={value}
          error={error}
          helperText={helperText}
          margin="dense"
          size="small"
          {...props}
        />
      )}
    />
  )
}
