import {useDrag} from 'react-dnd'
import {Box} from '@mui/material'
import {Article} from '@mui/icons-material'
import {DraggableTypes} from './FolderItem'

export default function FileItem({file, ...props}) {
  const [{isDragging}, drag] = useDrag(() => ({
    type: DraggableTypes.FILE,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => file,
    previewOptions: {
      anchorX: 0,
      offsetY: 0,
    },
  }))
  return (
    <Box
      ref={drag}
      display={'flex'}
      alignItems={'center'}
      style={{
        opacity: isDragging ? 0.1 : 1,
        cursor: isDragging ? 'move' : undefined,
      }}
      {...props}
    >
      <Article sx={{mr: 1}} />
      {file.name}
    </Box>
  )
}
