import {Box} from '@mui/material'
import {FileCopy} from '@mui/icons-material'
import useSnackbar from 'src/hooks/useSnackbar'

const CopiableBox = ({children, copyString, position = 'right', ...props}) => {
  const {toast} = useSnackbar()
  return (
    <Box
      onClick={() => {
        navigator.clipboard.writeText(copyString)
        toast('Copié dans le presse papier', {
          autoHideDuration: 1000,
        })
      }}
      sx={{
        cursor: 'pointer',
      }}
      alignItems="center"
      display="flex"
      {...props}
    >
      {position === 'left' ? (
        <FileCopy sx={{fontSize: 13, marginLeft: '4px'}} />
      ) : null}
      {children}
      {position === 'right' ? (
        <FileCopy sx={{fontSize: 13, marginLeft: '4px'}} />
      ) : null}
    </Box>
  )
}

export default CopiableBox
