import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import {Add, Article, Delete, Folder} from '@mui/icons-material'
import {useCreateFolder, useDeleteFolder} from '../../hooks/api-hooks'
import ConfirmDialog from '../ConfirmDialog'
import {useState} from 'react'
import TextInput from '../inputs/TextInput'
import AddDocumentFilesDialog from '../dialogs/AddDocumentFilesDialog'

export default function PreviewFolder({folder, onDelete = () => {}}) {
  const [modal, setModal] = useState()
  const [newFolderName, setNewFolderName] = useState('')
  const confirmationOpen = modal === 'delete'
  const addOpen = modal === 'add'
  const addFiles = modal === 'files'
  const {mutate: deleteFolder, isLoading: isLoadingDelete} = useDeleteFolder()
  const {mutate: createFolder, isLoading: isLoadingAdd} = useCreateFolder()

  const isLoading = isLoadingDelete || isLoadingAdd

  const handleAdd = () => {
    createFolder(
      {name: newFolderName, parent_folder_id: folder.id},
      {onSuccess: setModal(undefined)},
    )
  }

  return (
    <Box
      borderLeft={'1px solid black'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box p={1}>
        <Typography variant={'subtitle1'}>{folder.name}</Typography>
      </Box>
      <Box p={1}>
        <LoadingButton
          variant={'contained'}
          startIcon={<Article />}
          fullWidth={true}
          loading={isLoading}
          onClick={() => {
            setModal('files')
          }}
          sx={{mb: 1}}
        >
          Ajouter fichiers
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          startIcon={<Add />}
          fullWidth={true}
          loading={isLoading}
          onClick={() => {
            setNewFolderName('')
            setModal('add')
          }}
          sx={{mb: 1}}
        >
          Ajouter répértoire
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          startIcon={<Folder />}
          fullWidth={true}
          loading={isLoading}
          onClick={() => {
            setNewFolderName('')
            setModal('add')
          }}
          sx={{mb: 1}}
        >
          Répértoire
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          color={'error'}
          startIcon={<Delete />}
          fullWidth={true}
          loading={isLoading}
          disabled={(folder.children ?? []).length !== 0}
          onClick={() => setModal('delete')}
        >
          Supprimer
        </LoadingButton>
        {confirmationOpen ? (
          <ConfirmDialog
            loading={isLoading}
            title="Confirmation de suppression"
            onConfirm={() => {
              deleteFolder(folder.id, {
                onSuccess: () => {
                  onDelete(folder)
                },
                onError: error => {
                  const non_fields_errors =
                    error.response?.data?.non_field_errors
                  if (Boolean(non_fields_errors)) {
                    if (Array.isArray(non_fields_errors)) {
                      alert(non_fields_errors[0])
                    } else {
                      alert(non_fields_errors)
                    }
                  }
                },
              })
              setModal(undefined)
            }}
            onCancel={() => {
              setModal(undefined)
            }}
            open={confirmationOpen}
          >
            Vous êtes sur le point de supprimer le repertoire{' '}
            <b>{folder.path}</b>
          </ConfirmDialog>
        ) : undefined}
        {addOpen ? (
          <Dialog open={addOpen} onClose={() => setModal(undefined)}>
            <DialogTitle>
              Ajouter un répértoire dans <b>{folder.name}</b>
            </DialogTitle>
            <DialogContent>
              <TextInput
                autoFocus
                label="Nom du répertoire"
                fullWidth
                value={newFolderName}
                onChange={value =>
                  setNewFolderName(value.nativeEvent.target.value)
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => setModal(undefined)}
              >
                Annuler
              </Button>
              <LoadingButton
                variant={'contained'}
                onClick={handleAdd}
                startIcon={<Add />}
                isLoading={isLoading}
                disabled={newFolderName === ''}
              >
                Ajouter
              </LoadingButton>
            </DialogActions>
          </Dialog>
        ) : undefined}
        {addFiles ? (
          <AddDocumentFilesDialog
            open={addFiles}
            folderId={folder.id}
            handleClose={() => {
              setModal(undefined)
            }}
          />
        ) : undefined}
      </Box>
    </Box>
  )
}
