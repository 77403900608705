import {useRef} from 'react'
import PageTemplate from 'src/components/templates/PageTemplate'
import {Box, Card, CardContent, Grid, IconButton, Link} from '@mui/material'
import {useContrats} from '../../hooks/api-hooks'
import useUniversalTableData from '../../hooks/useUniversalTableData'

import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import CopiableBox from '../../components/CopiableBox'
import {Edit} from '@mui/icons-material'
import {Link as RouterLink} from 'react-router-dom'
import TextInput from 'src/components/inputs/TextInput'

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('reagi_id', {
    id: 'reagi_id',
    header: 'ID',
    enableHiding: false,
    cell: ({cell: {row}}) => {
      const item = row.original
      return (
        <>
          <Link
            color="inherit"
            component={RouterLink}
            to={`/contrats/${item.id}`}
            variant="subtitle2"
            data-testid="cell-field-nom"
          >
            {item.reagi_id}
          </Link>{' '}
          <CopiableBox
            copyString={item.reagi_id}
            display={'inline'}
            position={'left'}
          >
            Copier
          </CopiableBox>
        </>
      )
    },
  }),
  columnHelper.accessor('produit.compagnie', {
    id: 'produit.compagnie',
    header: 'Compagnie',
  }),
  columnHelper.accessor('produit.code', {id: 'produit__code', header: 'Code'}),
  columnHelper.accessor('produit.nom', {id: 'produit__nom', header: 'Nom'}),
  columnHelper.accessor('client.description', {
    id: 'client__description',
    header: 'Client',
    cell: info => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/clients/${info.cell.row.original.client.id}`}
        variant="subtitle2"
        data-testid="cell-field-nom"
      >
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.display({
    header: ' ',
    enableHiding: false,
    cell: ({row}) => {
      return (
        <Box sx={{textAlign: 'right'}}>
          <IconButton
            size="small"
            sx={{fontSize: 15}}
            component={RouterLink}
            to={`/contrats/${row.original.id}/edit`}
          >
            <Edit fontSize="inherit" />
          </IconButton>
        </Box>
      )
    },
  }),
]

const ContratList = () => {
  const universalTableData = useUniversalTableData(useContrats)
  const {filters, setFilters} = universalTableData
  const searchTimer = useRef()

  const handleSearch = event => {
    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      const {value: search} = event.target
      // TODO : Optimisation perf recherche - bug de reinitialisation non corrigé (voir code liste clients)
      setFilters({search})
    }, 300)
  }

  return (
    <PageTemplate
      title="Contrats"
      sections={[{title: 'Contrats', current: true}]}
      data-testid="page-contrats-list"
    >
      <Grid
        container
        className="box-list-search"
        sx={{
          position: !!filters['search'] ? 'sticky' : 'block',
          top: '64px',
          alignSelf: 'flex-start',
          zIndex: '999',
        }}
      >
        <Grid item>
          <Card>
            <CardContent
              sx={{padding: '10px', paddingBottom: '10px !important '}}
            >
              <TextInput
                label="Recherche"
                sx={{width: '300px'}}
                onChange={handleSearch}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <WrappedUniversalTable
        universalTableData={universalTableData}
        columns={columns}
        name={'contrats-list'}
      />
    </PageTemplate>
  )
}

export default ContratList
