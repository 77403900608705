import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import {
  useCreateFiles,
  useFolder,
  usePartialEditFile,
} from '../../hooks/api-hooks'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import SizeFormatter from '../SizeFormatter'
import {useMemo, useState} from 'react'
import FolderItem from './FolderItem'
import FileItem from './FileItem'
import PreviewFile from './PreviewFile'
import PreviewFolder from './PreviewFolder'
import LoadingCard from '../LoadingCard'
import {DraggableColumnHeader} from '../UniversalTable'

const columnHelper = createColumnHelper()

const FileManagerTable = ({
  data,
  onFolderDrop = () => {},
  isLoading = false,
  loadingText = '',
}) => {
  const [sorting, setSorting] = useState([])

  const [expanded, setExpanded] = useState({
    [data[0].path]: true,
  })

  const [preview, setPreview] = useState()
  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: 'Nom',
        accessor: 'name',
        cell: ({row, getValue}) => (
          <Box pl={1 * row.depth}>
            <Box alignItems={'center'} display={'flex'}>
              {row.original.type === 'folder' && row.getCanExpand() ? (
                <FolderItem
                  folder={row.original}
                  toggleExpandedHandler={row.getToggleExpandedHandler()}
                  name={getValue()}
                  isOpened={row.getIsExpanded()}
                  onDrop={onFolderDrop}
                />
              ) : undefined}
              {row.original.type === 'file' ? (
                <FileItem file={row.original} />
              ) : undefined}
              {row.original === 'empty' ? <div>Vide</div> : undefined}
            </Box>
          </Box>
        ),
      },
      columnHelper.accessor('type', {
        id: 'type',
        header: 'Type',
        cell: info => {
          if (info.getValue() === 'folder') {
            return 'Dossier'
          }
          if (info.getValue() === 'file') {
            return 'Fichier'
          }
          return ''
        },
      }),
      columnHelper.accessor('size', {
        id: 'size',
        header: 'Taille',
        cell: info => <SizeFormatter sizeInBytes={info.renderValue()} />,
      }),
    ],
    [],
  )
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id),
  )
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnOrder,
      expanded,
    },
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: row => row.path,
    getSubRows: row => {
      if (row.type === 'folder') {
        return row.children?.length > 0 ? row.children : ['empty']
      } else return null
    },
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })
  let clickTimer
  const handleClick = (e, row) => {
    clearTimeout(clickTimer)
    if (e.detail === 1) {
      clickTimer = setTimeout(() => {
        if (row.original.type === 'file' || row.original.type == 'folder') {
          setPreview(preview === row.original ? undefined : row.original)
        }
      }, 150)
    }
    if (e.detail === 2) {
      if (row.original.type === 'file') {
        if (Boolean(row?.original?.file_infos)) {
          window.location.href = row.original.file_infos
        }
      }
    }
  }

  return (
    <Paper
      sx={{width: '100%', overflow: 'hidden', minHeight: 500, display: 'flex'}}
    >
      <Grid container p={0}>
        <Grid item md={Boolean(preview) ? 10 : 12} position={'relative'}>
          <TableContainer>
            <Table
              stickyheader
              aria-label="sticky table"
              size={'small'}
              style={{
                tableLayout: 'auto',
              }}
              width={table.getCenterTotalSize()}
            >
              <TableHead>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        last={index === headerGroup.headers.length - 1}
                      />
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map(row => (
                  <TableRow
                    hover
                    key={row.id}
                    selected={row.original === preview}
                    style={{cursor: 'pointer'}}
                    onClick={e => handleClick(e, row)}
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell
                        key={cell.id}
                        width={cell.column.getSize()}
                        style={{width: cell.column.getSize()}}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoading ? (
            <>
              <Box
                {...{
                  display: 'flex',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                style={{
                  backgroundColor: 'white',
                  opacity: 0.8,
                }}
              />
              <Box
                {...{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  flexDirection: 'column',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                style={{
                  zIndex: 10,
                }}
              >
                <CircularProgress sx={{mb: 1}} />
                <Typography variant={'subtitle1'}>{loadingText}</Typography>
              </Box>
            </>
          ) : undefined}
        </Grid>
        <Grid item md={Boolean(preview) ? 2 : 0}>
          {Boolean(preview) && preview.type == 'file' ? (
            <PreviewFile file={preview} onClose={() => setPreview(null)} />
          ) : undefined}
          {Boolean(preview) && preview.type == 'folder' ? (
            <PreviewFolder
              folder={preview}
              onDelete={() => {
                setPreview(null)
              }}
            />
          ) : undefined}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default function Filemanager({rootFolder = 'root'}) {
  const {data, isFetching} = useFolder(rootFolder, {
    trashed: false,
  })
  const {mutate, isLoading} = useCreateFiles({
    onUploadProgress: progressEvent => {
      console.log(progressEvent.loaded / progressEvent.total)
    },
  })

  const {mutate: mutateEditFile, isLoading: isLoadingMoveFile} =
    usePartialEditFile()

  const saveFiles = (files, folderId) => {
    mutate({files, folderId})
  }

  const moveFile = (file, folderId) => {
    if (folderId !== file.parent_folder_id) {
      mutateEditFile({
        id: file.id,
        body: {parent_folder_id: folderId},
      })
    }
  }

  const handleFolderDrop = (folderId, droppedObj) => {
    if (Boolean(droppedObj.dataTransfer)) {
      saveFiles(droppedObj.files, folderId)
    }
    if (droppedObj.type === 'file') {
      moveFile(droppedObj, folderId)
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {data ? (
        <FileManagerTable
          data={[data]}
          onFolderDrop={handleFolderDrop}
          isLoading={isFetching || isLoading}
          loadingText={
            isLoading
              ? 'Téléversement en cours'
              : 'Rafraichissement des données'
          }
        />
      ) : (
        <LoadingCard />
      )}
    </DndProvider>
  )
}
