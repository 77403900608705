import PrimaryButton from './PrimaryButton'
import CreateIcon from '@mui/icons-material/Create'

const EditButton = ({children, ...props}) => (
  <PrimaryButton
    type="submit"
    startIcon={<CreateIcon fontSize="small" />}
    {...props}
  >
    {children}
  </PrimaryButton>
)

export default EditButton
