import {useReducer} from 'react'

export default function useFilters(defaultValue = {}) {
  const [filters, setLocalFilters] = useReducer(
    (o, n) => ({...o, ...n}),
    defaultValue,
  )

  const setFilters = newFilters => {
    const currentFilters = filters

    for (const [key, value] of Object.entries(newFilters)) {
      if (value === undefined) {
        delete currentFilters[key]
      } else {
        currentFilters[key] = value
      }
    }
    setLocalFilters(currentFilters)
  }

  return {filters, setFilters}
}
