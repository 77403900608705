import {Controller} from 'react-hook-form'
import UsersInput from '../inputs/UsersInput'

export default function UsersInputControlled({
  control,
  name = 'referent_id',
  defaultValue = null,
  error,
  helperText,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {value, onChange}}) => (
        <UsersInput
          onChange={(event, item) => {
            onChange(item)
          }}
          value={value}
          margin="dense"
          size="small"
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
