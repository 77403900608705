import PageTemplate from 'src/components/templates/PageTemplate'
import {
  useCreateGroupeClient,
  useDeleteGroupeClient,
  useEditGroupeClient,
  useGroupeClients,
} from 'src/hooks/api-hooks'
import {createColumnHelper} from '@tanstack/react-table'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@mui/material'
import {Add, Delete, Edit} from '@mui/icons-material'
import {useMemo, useState} from 'react'
import AddButton from '../../components/buttons/AddButton'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInputControlled from '../../components/controlled/TextInputControlled'
import ConfirmDialog from '../../components/ConfirmDialog'
import DeviseLabel from '../../components/DeviseLabel'
import EditButton from '../../components/buttons/EditButton'

const columnHelper = createColumnHelper()

const schema = yup.object().shape({
  nom: yup.string().label('Nom').required(),
})

const GroupeClientList = () => {
  const universalTableData = useUniversalTableData(useGroupeClients)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteObj, setDeleteObj] = useState()
  const [editObj, setEditObj] = useState()
  const rightActions = (
    <Button
      variant={'contained'}
      startIcon={<Add />}
      onClick={() => {
        setDialogOpen(true)
      }}
    >
      Ajouter un groupe
    </Button>
  )

  const handleClose = () => {
    reset()
    setDialogOpen(false)
  }

  const {
    mutate: create,
    isLoading: isCreateLoading,
    errors: createErrors,
  } = useCreateGroupeClient()
  const {
    mutate: update,
    isLoading: isUpdateLoading,
    errors: updateErrors,
  } = useEditGroupeClient(editObj?.id)
  const isLoading = isCreateLoading || isUpdateLoading
  const errors = {...createErrors, ...updateErrors}

  const {mutate: remove} = useDeleteGroupeClient()
  const {
    control,
    handleSubmit,
    formState: {errors: formErrors},
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return editObj
    }, [editObj]),
  })

  const isEditing = !!editObj

  const allErrors = {...errors, ...formErrors}

  const columns = useMemo(
    () => [
      columnHelper.accessor('nom', {
        id: 'nom',
        header: 'Nom',
        enableSorting: false,
      }),
      columnHelper.accessor('clients', {
        id: 'clients',
        header: 'Nombre de clients',
        cell: info => info.getValue().length,
        enableSorting: false,
      }),
      columnHelper.display({
        header: ' ',
        enableHiding: false,
        cell: ({row}) => {
          return (
            <Box sx={{textAlign: 'right'}}>
              <IconButton
                onClick={() => {
                  setEditObj(row.original)
                  setDialogOpen(true)
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => setDeleteObj(row.original)}
                sx={{
                  visibility:
                    row.original.clients.length == 0 ? 'visible' : 'hidden',
                }}
              >
                <Delete color={'error'} />
              </IconButton>
            </Box>
          )
        },
      }),
    ],
    [deleteObj, setDeleteObj, setEditObj, editObj],
  )

  return (
    <PageTemplate
      title="Groupes"
      sections={[{title: 'Groupes', current: true}]}
      rightActions={rightActions}
    >
      <WrappedUniversalTable
        dataAccessor={null}
        columns={columns}
        universalTableData={universalTableData}
        name={'groupe-list'}
      />
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {isEditing ? 'Modifier' : 'Ajouter'} un groupe
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form
            onSubmit={handleSubmit(values => {
              if (isEditing) {
                update(values, {
                  onSuccess: () => {
                    setEditObj()
                    setDialogOpen(false)
                    reset()
                  },
                })
              } else {
                create(values, {
                  onSuccess: () => {
                    setDialogOpen(false)
                    reset()
                  },
                })
              }
            })}
            id={'groupe-add-form'}
          >
            <TextInputControlled
              control={control}
              name="nom"
              label="Nom"
              error={!!allErrors?.nom?.message}
              helperText={allErrors?.nom?.message}
              size="small"
              defaultValue={editObj?.nom}
              inputRef={input => input && input.focus()}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isLoading}>
            Annuler
          </Button>
          {isEditing ? (
            <EditButton
              form="groupe-add-form"
              loading={isLoading}
              loadingPosition="start"
              type="submit"
            >
              Modifier
            </EditButton>
          ) : (
            <AddButton
              form="groupe-add-form"
              loading={isLoading}
              loadingPosition="start"
              type="submit"
            >
              Ajouter
            </AddButton>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="Confirmation de suppression"
        onConfirm={() => {
          remove(deleteObj.id)
          setDeleteObj(undefined)
        }}
        onCancel={() => {
          setDeleteObj(undefined)
        }}
        open={!!deleteObj}
      >
        Vous êtes sur le point de supprimer ce groupe:
        <br />
        <strong>{}</strong>
      </ConfirmDialog>
    </PageTemplate>
  )
}

export default GroupeClientList
