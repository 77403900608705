import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {useTypesReglement} from 'src/hooks/api-hooks'

const TypesReglementInput = ({
  label = "Type d'encaissement",
  error,
  remboursement = false,
  helperText,
  margin = 'dense',
  size = 'small',
  remise = false,
  ...props
}) => {
  const {
    isLoading: loading,
    data,
    isError: loadingError,
    error: errorException,
  } = useTypesReglement()

  const options = data?.sort((a, b) => {
    if (a.nom < b.nom) {
      return -1
    }
    if (a.nom > b.nom) {
      return 1
    }
    return 0
  })

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.nom ?? ''}
      options={
        (remise
          ? options?.filter(x => x.nom === 'Chèque' || x.nom === 'Espèces')
          : remboursement
          ? options?.filter(x => x.moyen_remboursement)
          : options) ?? []
      }
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorException.message : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default TypesReglementInput
