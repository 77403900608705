const {Card, Box, CircularProgress} = require('@mui/material')

const LoadingCard = () => {
  return (
    <Card data-testid="loading">
      <Box
        sx={{
          p: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 300,
        }}
      >
        <CircularProgress />
      </Box>
    </Card>
  )
}

export default LoadingCard
