import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {Article, Folder} from '@mui/icons-material'

export default function FolderList({
  items,
  onClickOnItem,
  simple = false,
  minWidth,
}) {
  const sorted = items?.sort((a, b) => {
    if (a.type === 'folder' && b.type === 'file') {
      return -1
    }
    if (a.type === 'file' && b.type === 'folder') {
      return 1
    }
    return a.name.localeCompare(b.name)
  })
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth}} aria-label="simple table" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            {!simple && <TableCell>Chemin</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(sorted?.length > 0) ? (
            sorted?.sort()?.map(x => (
              <TableRow
                hover
                style={{cursor: 'pointer'}}
                key={x.path}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                onClick={() => onClickOnItem?.(x)}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {x.type === 'folder' && <Folder style={{marginRight: 8}} />}
                  {x.type === 'file' && <Article style={{marginRight: 8}} />}
                  {x.name}
                </TableCell>
                {!simple && (
                  <TableCell component="th" scope="row">
                    {x.path}
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow
              hover
              style={{cursor: 'pointer'}}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell
                component="th"
                scope="row"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Aucun contenu
              </TableCell>
              {!simple && <TableCell component="th" scope="row"></TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
