import {useEffect} from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Box, Divider, Drawer, Hidden} from '@mui/material'
import PeopleIcon from '@mui/icons-material/People'
import EuroIcon from '@mui/icons-material/Euro'
import DescriptionIcon from '@mui/icons-material/Description'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

import Logo from 'src/components/Logo'
import NavSection from 'src/components/NavSection'
import Scrollbar from 'src/components/Scrollbar'
import {
  AccountBalance,
  CurrencyExchange,
  Delete,
  Download,
  FactCheck,
  Folder,
  FolderCopy,
  LocalShipping,
  Person,
  Search,
} from '@mui/icons-material'
import useAuth from '../../../hooks/useAuth'

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Clients',
        path: '/',
        icon: <Person fontSize="small" />,
      },
      {
        title: 'Groupes',
        path: '/groupes',
        icon: <PeopleIcon fontSize="small" />,
      },
      {
        title: 'Contrats',
        path: '/contrats',
        icon: <DescriptionIcon fontSize="small" />,
      },
      {
        title: 'Gestionnaire de fichiers',
        icon: <FolderCopy fontSize="small" />,
        conditions: {
          has_geid_access: true,
        },
        children: [
          {
            title: 'Parcourir',
            path: '/gestionnaire-fichiers',
            icon: <Folder fontSize="small" />,
          },
          {
            title: 'Rechercher',
            path: '/gestionnaire-fichiers/rechercher',
            icon: <Search fontSize="small" />,
          },
          {
            title: 'Corbeille',
            path: '/gestionnaire-fichiers/corbeille',
            icon: <Delete fontSize="small" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Comptabilité',
    items: [
      {
        title: 'Remises en banque',
        icon: <AccountBalance fontSize="small" />,
        children: [
          {
            title: 'Chèque',
            path: '/remises-en-banque/cheque',
            icon: <AccountBalance fontSize="small" />,
          },
          {
            title: 'Espèces',
            path: '/remises-en-banque/especes',
            icon: <AccountBalance fontSize="small" />,
          },
        ],
      },
      {
        title: 'Cahiers de transmission',
        path: '/cahiers-de-transmission',
        icon: <LocalShipping fontSize="small" />,
      },
      {
        title: 'Envois de fonds',
        path: '/envois-de-fonds',
        icon: <CurrencyExchange fontSize="small" />,
      },
      {
        title: 'Caisses',
        path: '/caisses',
        icon: <EuroIcon fontSize="small" />,
      },
      {
        title: 'Comptes comptable',
        path: '/comptes-comptable',
        icon: <FactCheck fontSize="small" />,
      },
      {
        title: 'Exports comptable',
        path: '/exports-compta',
        icon: <Download fontSize="small" />,
      },
    ],
  },
  {
    title: 'Administration',
    items: [
      {
        title: 'Utilisateurs',
        path: '/users',
        icon: <SupervisedUserCircleIcon fontSize="small" />,
      },
    ],
    conditions: {
      is_superuser: true,
    },
  },
]

function isItemPermissionOk(item, user) {
  const {conditions} = item
  let success = true
  if (!!conditions) {
    const keys = Object.keys(conditions)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (user?.[key] !== conditions[key]) {
        success = false
      }
    }
  }
  return success
}

function filterItems(items, user) {
  if (!items) {
    return
  }
  const filteredItems = items.filter(x => isItemPermissionOk(x, user))
  return filteredItems.map(x => ({
    ...x,
    children: filterItems(x.children, user),
  }))
}

function filterSections(section, user) {
  return sections.filter(section => isItemPermissionOk(section, user))
}

const DashboardSidebar = props => {
  const {onMobileClose, openMobile} = props
  const location = useLocation()
  const {user} = useAuth()

  const filteredSections = filterSections(sections, user).map(x => ({
    ...x,
    items: filterItems(x.items, user),
  }))

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      data-testid="dashboard-left"
    >
      <Scrollbar options={{suppressScrollX: true}}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box sx={{p: 2}}>
          {filteredSections.map(section => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{p: 2}}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{mt: 2}}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default DashboardSidebar
