import PageTemplate from 'src/components/templates/PageTemplate'
import {useComptesComptable} from 'src/hooks/api-hooks'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import {Box} from '@mui/system'
import CopiableBox from '../../components/CopiableBox'
import {Link} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('id', {
    id: 'id',
    header: 'ID',
    cell: info => (
      <Box display="flex" alignItems="center">
        <Box sx={{mr: 1}}>
          <CopiableBox copyString={info.getValue()}>
            {info.getValue()}
          </CopiableBox>
        </Box>
      </Box>
    ),
  }),
  columnHelper.accessor('libelle', {
    id: 'libelle',
    header: 'Libellé',
    cell: info => (
      <Link
        color="inherit"
        component={RouterLink}
        to={`/comptes-comptable/ecritures?compte_comptable=${info.row.original.id}`}
        variant="subtitle2"
      >
        {info.getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor('solde', {
    id: 'solde',
    header: 'Solde',
  }),
]

export default function CompteComptableList() {
  const universalTableData = useUniversalTableData(useComptesComptable)

  return (
    <PageTemplate
      title="Comptes comptable"
      sections={[{title: 'Comptes comptable', current: true}]}
    >
      <WrappedUniversalTable
        name={'comptes-comptable-list'}
        columns={columns}
        universalTableData={universalTableData}
        dataAccessor={false}
      />
    </PageTemplate>
  )
}
