import PageTemplate from '../../components/templates/PageTemplate'
import useFilters from '../../hooks/useFilters'
import {useFiles} from '../../hooks/api-hooks'
import useLocalPagination from '../../hooks/useLocalPagination'
import {useMemo, useState} from 'react'
import {Box, Grid, Paper, Typography} from '@mui/material'
import SizeFormatter from '../../components/SizeFormatter'
import {createColumnHelper} from '@tanstack/react-table'
import LoadingCard from '../../components/LoadingCard'
import PreviewFile from '../../components/Filemanager/PreviewFile'
import TextInput from '../../components/inputs/TextInput'
import {StandardTableComponent} from '../../components/StandardTableComponent'
import moment from 'moment'

const columnHelper = createColumnHelper()

export default function GestionnaireDeFichiersTrash() {
  const {offset, limit, PaginationComponent} = useLocalPagination({})
  const [ordering, setOrdering] = useState('-id')
  const {filters, setFilters} = useFilters()
  const {isLoading, data, isFetching} = useFiles({
    offset,
    limit,
    ordering,
    ...filters,
    is_trashed: true,
  })
  const [preview, setPreview] = useState()

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Nom',
        cell: info => (
          <div
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setPreview(
                preview === info.row.original ? undefined : info.row.original,
              )
            }}
          >
            <b>{info.renderValue()}</b>
          </div>
        ),
      }),
      columnHelper.accessor('parent_folder.path', {
        id: 'path',
        header: 'Localisation',
      }),
      columnHelper.accessor('size', {
        id: 'size',
        header: 'Taille',
        cell: info => <SizeFormatter sizeInBytes={info.renderValue()} />,
      }),
      columnHelper.accessor('trash_date', {
        id: 'trash_date',
        header: 'Date de mise à la corbeille',
        cell: ({value}) => moment(value).format('DD/MM/YYYY à HH:mm:ss'),
      }),
    ],
    [preview],
  )

  return (
    <PageTemplate
      title="Corbeille"
      sections={[
        {title: 'Gestionnaire de fichiers', path: '/gestionnaire-fichiers'},
        {title: 'Corbeille', current: true},
      ]}
    >
      <Box component={Paper} p={1}>
        <Typography variant={'overline'}>Paramètre de recherche</Typography>
        <Grid container>
          <Grid item md={6} xs={12} p={1}>
            <TextInput
              value={filters?.search || null}
              onChange={event => {
                setFilters({search: event.nativeEvent.target.value})
              }}
              label={'Votre recherche'}
            />
          </Grid>
        </Grid>
      </Box>
      {isLoading ? (
        <LoadingCard />
      ) : (
        <>
          <PaginationComponent count={data?.count} isLoading={isLoading} />
          <Paper
            sx={{
              width: '100%',
              overflow: 'hidden',
              minHeight: 500,
              display: 'flex',
            }}
          >
            <Grid container p={0}>
              <Grid item md={Boolean(preview) ? 10 : 12} position={'relative'}>
                <StandardTableComponent
                  data={data?.results ?? []}
                  columns={columns}
                  setOrdering={setOrdering}
                  ordering={ordering}
                />
              </Grid>
              <Grid item md={Boolean(preview) ? 2 : 0}>
                {Boolean(preview) && preview.type == 'file' ? (
                  <PreviewFile
                    file={preview}
                    onClose={() => setPreview(null)}
                  />
                ) : undefined}
              </Grid>
            </Grid>
          </Paper>
          <PaginationComponent count={data?.count} isLoading={isLoading} />
        </>
      )}
    </PageTemplate>
  )
}
