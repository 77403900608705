import {IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const CloseButton = props => (
  <IconButton
    color="primary"
    aria-label={`Fermer`}
    component="span"
    size="small"
    {...props}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
)

export default CloseButton
