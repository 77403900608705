import {TextField as MuiTextField} from '@mui/material'
import {Controller, useForm} from './Form'
const DatePicker = ({name, label, size, isError, errorMessage, ...props}) => {
  const {control, isLoading, errors} = useForm()
  return (
    <Controller
      control={control}
      name={name}
      {...props}
      render={({field}) => {
        return (
          <MuiTextField
            label={label}
            id={`${name}-field`}
            helperText={errors[name]?.message || errorMessage}
            disabled={isLoading}
            variant="outlined"
            fullWidth
            error={Boolean(errors[name]) || isError}
            {...props}
            {...field}
            value={field.value ?? ''}
          />
        )
      }}
    />
  )
}

export default DatePicker
