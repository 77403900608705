const typesAvenant = [
  {
    id: 1,
    nom: 'Affaire Nouvelle',
  },
  {
    id: 2,
    nom: 'Modification',
  },
  {
    id: 3,
    nom: 'Renouvellement',
  },
  {
    id: 4,
    nom: 'Résiliation',
  },
  {
    id: 5,
    nom: 'Régularisation',
  },
]

export {typesAvenant}
