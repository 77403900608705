import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material'

import useSnackbar from 'src/hooks/useSnackbar'
import useAsync from 'src/hooks/useAsync'
import AddButton from '../buttons/AddButton'
import EditButton from '../buttons/EditButton'
import {createOperation, updateOperation} from 'src/services/crm-api'
import RemboursementForm from '../forms/RemboursementForm'
import DeleteOperationButton from './DeleteOperationButton'
import useOperationDialog from './useOperationDialog'

const RemboursementFormDialog = ({
  open = true,
  remboursement,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) => {
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(remboursement?.id)
  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      create(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="remboursement-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="remboursement-dialog">
        {isEditing ? 'Modifier' : 'Ajouter'} un remboursement
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          'Chargement ...'
        ) : (
          <RemboursementForm
            client={client}
            remboursement={operation}
            onSubmit={handleSubmit}
            id="remboursement-dialog-form"
          />
        )}
      </DialogContent>
      <DialogActions>
        <DeleteOperationButton
          sx={{mr: 'auto'}}
          operationId={remboursement?.id}
          onDelete={handleClose}
        />
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        {isEditing ? (
          <EditButton
            form="remboursement-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Modifier
          </EditButton>
        ) : (
          <AddButton
            form="remboursement-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default RemboursementFormDialog
