import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
  Box,
  TableRow,
  TableCell,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Person, Edit, Phone, Mail, Close} from '@mui/icons-material'

import Form, {AlertError, TextField} from 'src/components/Form'
import LabelRow from 'src/components/LabelRow'
import {errorResponseToJson} from 'src/services/crm-api'
import FormeJuridiqueField from 'src/components/Form/FormeJuridiqueField'
import InterlocuteursField, {
  InterlocuteurSchema,
} from 'src/components/forms/ClientForm/InterlocuteursField'
import CardSubtitle from 'src/components/labels/CardSubtitle'
import CopiableBox from 'src/components/CopiableBox'
import {usePartialEditClient} from 'src/hooks/api-hooks'

const resolver = yupResolver(
  yup.object().shape({
    reagi_id: yup.string().label('id RéAGI').required().max(35),
    forme_juridique: yup.string().label('Forme juridique').required(),
    denomination_sociale: yup
      .string()
      .label('Dénomination sociale')
      .required()
      .max(50),
    interlocuteurs: yup.array().of(InterlocuteurSchema),
  }),
)

const DisplayBody = ({
  client,
  setEditing,
  editing,
  isLoading,
  modificationDisabled,
}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="ID RéAGI">
            <CopiableBox copyString={client?.reagi_id}>
              {client?.reagi_id}
            </CopiableBox>
          </LabelRow>
          <LabelRow label="SIRET">{client?.siret}</LabelRow>
          <LabelRow label="Forme juridique">{client?.forme_juridique}</LabelRow>
          <LabelRow label="Dénomination sociale">
            {client?.denomination_sociale}
          </LabelRow>
          <LabelRow
            label="Interlocuteurs"
            labelCellProps={{
              sx: {
                verticalAlign: 'top',
              },
            }}
          >
            {client?.interlocuteurs?.map(x => {
              return (
                <Box sx={{mb: 2}} key={x.nom}>
                  <Box>
                    {x.nom} {Boolean(x.fonction) && <>({x.fonction})</>}
                  </Box>
                  {Boolean(x.telephone) && (
                    <Box display="flex" alignItems="center">
                      <Phone sx={{fontSize: 12, mr: 1}} />
                      {x.telephone}
                    </Box>
                  )}
                  {Boolean(x.email) && (
                    <Box display="flex" alignItems="center">
                      <Mail sx={{fontSize: 12, mr: 1}} />
                      <div>{x.email}</div>
                    </Box>
                  )}
                </Box>
              )
            })}
          </LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={!Boolean(client) || modificationDisabled}
        >
          Modifier
        </Button>
      </CardActions>
    </>
  )
}

const EditBody = ({
  client,
  onSubmit,
  setEditing,
  editing,
  modificationDisabled,
}) => {
  const {isLoading, error, mutate} = usePartialEditClient(client.id)

  const errors = errorResponseToJson(error)
  const defaultValue = {
    reagi_id: client.reagi_id,
    siret: client.siret,
    forme_juridique: client.forme_juridique,
    denomination_sociale: client.denomination_sociale,
    interlocuteurs: client.interlocuteurs,
  }
  return (
    <Form
      onSubmit={values => {
        mutate(values)
        setEditing(false)
      }}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID RéAGI">
            <TextField size="small" name="reagi_id" />
          </LabelRow>
          <LabelRow label="SIRET">
            <TextField size="small" name="siret" />
          </LabelRow>
          <LabelRow label="Forme juridique">
            <FormeJuridiqueField label="Forme juridique" size="small" />
          </LabelRow>
          <LabelRow label="Dénomination sociale">
            <TextField size="small" name="denomination_sociale" />
          </LabelRow>
          {/* <TableRow>
            <TableCell colSpan={2}>
              <CardSubtitle>Interlocuteurs</CardSubtitle>
              <InterlocuteursField />
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading || modificationDisabled}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ClientEntrepriseInfoCard = ({client, modificationDisabled, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Identité de l'entreprise" avatar={<Person />} />
      <Divider />
      {editing ? (
        <EditBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ClientEntrepriseInfoCard
