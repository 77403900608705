import axios from 'axios'

export const baseURL = process.env.REACT_APP_CRM_API_URL
axios.defaults.baseURL = baseURL
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

export const errorMessage = error => {
  return (
    'Une erreur est survenue: ' +
    (error?.response?.data?.detail ?? error?.message)
  )
}

const recursiveParseArray = data => {
  let results = {}
  Object.keys(data).forEach(x => {
    if (Array.isArray(data[x])) {
      results[x] = {message: data[x].join(' ')}
    } else if (typeof data[x] === 'object') {
      results[x] = recursiveParseArray(data[x])
    }
  })
  return results
}

export const errorResponseToJson = error => {
  if (!error) return
  if (error.response?.status === 400 && Boolean(error.response?.data)) {
    // This is a validation error
    let results = recursiveParseArray(error.response.data)
    if (error.response.data.message) {
      results.message = error.response.data.message
    }
    return results
  }
  return {
    message: errorMessage(error),
  }
}

export const login = (email, password) => {
  return axiosInstance.post('/login/', {
    email,
    password,
  })
}

export const logout = () => {
  return axiosInstance.post('/logout/')
}

// User
export const fetchUsers = params => {
  return axiosInstance.get('/users/', {params}).then(response => response.data)
}

export const fetchUsersList = params => {
  return axiosInstance
    .get('/users-list/', {params})
    .then(response => response.data)
}

export const createUser = body => {
  return axiosInstance.post('/users/', body).then(response => response.data)
}

export const fetchUser = id => {
  return axiosInstance.get(`/users/${id}/`).then(response => response.data)
}

export const updatePassword = (id, password) => {
  return axiosInstance
    .put(`/users/${id}/changepassword/`, {password})
    .then(response => response.data)
}

export const changeCurrentUserPassword = body => {
  return axiosInstance
    .put(`/changepassword/`, body)
    .then(response => response.data)
}

export const updateUser = (id, body) => {
  return axiosInstance
    .put(`/users/${id}/`, body)
    .then(response => response.data)
}

// Agence
export const fetchAgences = params => {
  return axiosInstance
    .get('/agences/', {params})
    .then(response => response.data)
}

// Compte
export const fetchComptesComptableSimple = params => {
  return axiosInstance
    .get('/comptes-comptable/simple/', {params})
    .then(response => response.data)
}

export const fetchCompteComptable = id => {
  return axiosInstance
    .get(`/comptes-comptable/${id}/`)
    .then(response => response.data)
}

export const fetchComptesComptable = params => {
  return axiosInstance
    .get('/comptes-comptable/', {params})
    .then(response => response.data)
}

// ExportsCompta
export const fetchExportsCompta = params => {
  return axiosInstance
    .get('/exports-compta', {params})
    .then(response => response.data)
}

export const fetchExportsComptaPreview = params => {
  return axiosInstance
    .get('/exports-compta/preview/', {params})
    .then(response => response.data)
}

export const createExportCompta = body => {
  return axiosInstance
    .post('/exports-compta/', body)
    .then(response => response.data)
}

export const deleteExportCompta = id => {
  return axiosInstance
    .delete(`/exports-compta/${id}/`)
    .then(response => response.data)
}

// Ecritures
export const fetchEcritures = params => {
  return axiosInstance
    .get('/ecritures/', {params})
    .then(response => response.data)
}

// Produits
export const fetchProduits = params => {
  return axiosInstance
    .get('/produits/', {params})
    .then(response => response.data)
}

// Marques auto
export const fetchMarquesAuto = params => {
  return axiosInstance
    .get('/marques-auto/', {params})
    .then(response => response.data)
}

// Repreneurs
export const fetchRepreneurs = params => {
  return axiosInstance
    .get('/repreneurs/', {params})
    .then(response => response.data)
}

// TypeImmeuble
export const fetchTypesImmeuble = params => {
  return axiosInstance
    .get('/types-immeuble/', {params})
    .then(response => response.data)
}

// MotifsResiliation
export const fetchMotifsResiliation = params => {
  return axiosInstance
    .get('/motifs-resiliation/', {params})
    .then(response => response.data)
}

// Fonction
export const fetchFonctions = params => {
  return axiosInstance.get('/fonctions/', {params})
}

// Services
export const fetchServices = params => {
  return axiosInstance.get('/services/', {params})
}

// Me
export const fetchMe = () => {
  return axiosInstance.get('/me/')
}

// Settings
export const fetchMeSettings = () => {
  return axiosInstance.get('/me/settings/')
}

export const createMeSettings = body => {
  return axiosInstance
    .post('/me/settings/', body)
    .then(response => response.data)
}

export const fetchOneMeSettings = name => {
  return axiosInstance
    .get(`/me/settings/${name}/`)
    .then(response => response.data)
}

export const deleteMeSettings = name => {
  return axiosInstance
    .delete(`/me/settings/${name}/`)
    .then(response => response.data)
}

export const saveOrCreateMeSettings = (name, body) => {
  return axiosInstance
    .patch(`/me/settings/${name}/`, body)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 404) {
        return createMeSettings({...body, name})
      }
      throw error
    })
}

// Client
export const fetchClients = params => {
  return axiosInstance
    .get('/clients/', {params})
    .then(response => response.data)
}

export const searchClients = (search, params) => {
  return fetchClients({...params, search})
}

export const createClient = body => {
  return axiosInstance.post('/clients/', body).then(response => response.data)
}

export const fetchClient = id => {
  return axiosInstance.get(`/clients/${id}/`).then(response => response.data)
}

export const updateClient = (id, body) => {
  return axiosInstance
    .put(`/clients/${id}/`, body)
    .then(response => response.data)
}

export const deleteClient = id => {
  return axiosInstance.delete(`/clients/${id}/`).then(response => response.data)
}

export const partialUpdateClient = (id, body) => {
  return axiosInstance
    .patch(`/clients/${id}/`, body)
    .then(response => response.data)
}

export const fetchLiaisons = () => {
  return axiosInstance.get(`/clients/liaisons/`).then(response => response.data)
}

export const addLiaisonToClient = (id, body) => {
  return axiosInstance
    .post(`/clients/${id}/ajout-liaisons/`, body)
    .then(response => response.data)
}

export const deleteLiaison = id => {
  return axiosInstance
    .delete(`/clients/enlever-liaisons/${id}/`)
    .then(response => response.data)
}

// Contrat
export const fetchContrats = params => {
  return axiosInstance
    .get('/contrats/', {params})
    .then(response => response.data)
}

// Contrat
export const fetchDevis = params => {
  return axiosInstance.get('/devis/', {params}).then(response => response.data)
}

export const fetchOneDevis = id => {
  return axiosInstance.get(`/devis/${id}/`).then(response => response.data)
}

export const createContrat = body => {
  return axiosInstance.post('/contrats/', body).then(response => response.data)
}

export const fetchContrat = id => {
  return axiosInstance.get(`/contrats/${id}/`).then(response => response.data)
}

export const updateContrat = (id, body) => {
  return axiosInstance
    .put(`/contrats/${id}/`, body)
    .then(response => response.data)
}

export const partialUpdateContrat = (id, body) => {
  return axiosInstance
    .patch(`/contrats/${id}/`, body)
    .then(response => response.data)
}

export const deleteContrat = id => {
  return axiosInstance
    .delete(`/contrats/${id}/`)
    .then(response => response.data)
}

// quittances
export const fetchQuittancesForClient = (clientId, params) => {
  return axiosInstance
    .get(`/clients/${clientId}/quittances/`, {params})
    .then(response => response.data)
}

export const createQuittance = body => {
  return axiosInstance
    .post('/operations/', body)
    .then(response => response.data)
}

export const updateQuittance = (id, body) => {
  return axiosInstance
    .put(`/operations/${id}/`, body)
    .then(response => response.data)
}

// operations
export const fetchQuittances = params => {
  return axiosInstance
    .get('/quittances/', {params})
    .then(response => response.data)
}

export const fetchOperationsForClient = (clientId, params) => {
  return axiosInstance
    .get(`/clients/${clientId}/operations/`, {params})
    .then(response => response.data)
}

export const fetchOperation = id => {
  return axiosInstance.get(`/operations/${id}/`).then(response => response.data)
}

export const createOperation = body => {
  return axiosInstance
    .post('/operations/', body)
    .then(response => response.data)
}

export const updateOperation = (id, body) => {
  return axiosInstance
    .put(`/operations/${id}/`, body)
    .then(response => response.data)
}

export const partialUpdateOperation = (id, body) => {
  return axiosInstance
    .patch(`/operations/${id}/`, body)
    .then(response => response.data)
}

export const deleteOperation = id => {
  return axiosInstance
    .delete(`/operations/${id}/`)
    .then(response => response.data)
}

export const ristourneQuittance = (id, params) => {
  return axiosInstance.post(`/operations/quittances/${id}/ristourne/`, {params})
}

// reglements
export const fetchReglements = params => {
  return axiosInstance
    .get(`/operations/reglements/`, {params})
    .then(response => response.data)
}

export const rejetReglement = (id, params) => {
  return axiosInstance.post(`/operations/reglements/${id}/rejet/`, {params})
}

// affectations
export const createAffectation = body => {
  return axiosInstance
    .post('/affectations/', body)
    .then(response => response.data)
}

export const deleteAffectation = id => {
  return axiosInstance
    .delete(`/affectations/${id}/`)
    .then(response => response.data)
}

// banques
export const fetchBanques = params => {
  return axiosInstance
    .get(`/banques/`, {params})
    .then(response => response.data)
}

// banques remise
export const fetchBanquesRemise = params => {
  return axiosInstance
    .get(`/banques-remise/`, {params})
    .then(response => response.data)
}

// etats precontentieux
export const fetchEtatsPrecontentieux = params => {
  return axiosInstance
    .get(`/etats-precontentieux/`, {params})
    .then(response => response.data)
}

// types avenant
export const fetchTypesAvenant = params => {
  return axiosInstance
    .get(`/types-avenant/`, {params})
    .then(response => response.data)
}

// types reglement
export const fetchTypeReglement = params => {
  return axiosInstance
    .get(`/types-reglement/`, {params})
    .then(response => response.data)
}

// cahiers de transmission
export const fetchCahiersDeTransmission = params => {
  return axiosInstance
    .get('/cahiers-de-transmission/', {params})
    .then(response => response.data)
}

export const statusCahierDeTransmission = (id, status) => {
  return axiosInstance
    .post(`/cahiers-de-transmission/${id}/status/`, {status})
    .then(response => response.data)
}

// envoi de fond

export const previewEnvoiDeFond = body => {
  return axiosInstance
    .post('/envois-de-fond/preview/', body)
    .then(response => response.data)
}

export const createEnvoiDeFond = body => {
  return axiosInstance
    .post('/envois-de-fond/', body)
    .then(response => response.data)
}

export const fetchEnvoiDeFond = id => {
  return axiosInstance
    .get(`/envois-de-fond/${id}/`)
    .then(response => response.data)
}

export const fetchEnvoisDeFonds = params => {
  return axiosInstance
    .get(`/envois-de-fond/`, {params})
    .then(response => response.data)
}

export const deleteEnvoiDeFond = id => {
  return axiosInstance
    .delete(`/envois-de-fond/${id}/`)
    .then(response => response.data)
}

// envoidefondexport
export const fetchEnvoisDeFondExport = params => {
  return axiosInstance
    .get('/envois-de-fond-export/', {params})
    .then(response => response.data)
}

// remises en banque
export const createRemiseEnBanque = body => {
  return axiosInstance
    .post('/remises-en-banque/', body)
    .then(response => response.data)
}

export const fetchRemisesEnBanque = params => {
  return axiosInstance
    .get('/remises-en-banque/', {params})
    .then(response => response.data)
}

export const fetchRemiseEnBanque = id => {
  return axiosInstance
    .get(`/remises-en-banque/${id}/`)
    .then(response => response.data)
}

export const deleteRemiseEnBanque = id => {
  return axiosInstance
    .delete(`/remises-en-banque/${id}/`)
    .then(response => response.data)
}

// caisses
export const createCaisse = body => {
  return axiosInstance.post('/caisses/', body).then(response => response.data)
}

export const fetchCaisses = params => {
  return axiosInstance
    .get('/caisses/', {params})
    .then(response => response.data)
}

export const fetchCaisse = id => {
  return axiosInstance.get(`/caisses/${id}/`).then(response => response.data)
}

export const deleteCaisse = id => {
  return axiosInstance.delete(`/caisses/${id}/`).then(response => response.data)
}

// DocumentFile
export const fetchFolder = (path = '', params = {}) => {
  return axiosInstance
    .get(`/folders/${encodeURI(path)}/path/`, {
      params: {...params},
    })
    .then(response => response.data)
}

export const createFolder = (body, params = {}) => {
  return axiosInstance
    .post('/folders/', body, {
      params: {...params},
    })
    .then(response => response.data)
}

export const deleteFolder = id => {
  return axiosInstance.delete(`/folders/${id}/`).then(response => response.data)
}

export const fetchFiles = (params = {}) => {
  return axiosInstance
    .get(`/files/`, {
      params: {...params},
    })
    .then(response => response.data)
}

export const fetchFile = (id, params = {}) => {
  return axiosInstance
    .get(`/files/${id}/`, {
      params: {...params},
    })
    .then(response => response.data)
}

export const createFiles = (files, parent_folder_id = 1, params = {}) => {
  let formData = new FormData()
  for (var i = 0; i < files.length; i++) {
    let file = files[i]
    formData.append('files_infos', file, file.name)
  }
  formData.append('parent_folder_id', parent_folder_id)

  return axiosInstance.post('/files/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...params,
  })
}

export const updateFile = (id, body, params = {}) => {
  return axiosInstance
    .patch(`/files/${id}/`, body, params)
    .then(response => response.data)
}

export const deleteFile = id => {
  return axiosInstance.delete(`/files/${id}/`).then(response => response.data)
}

// Fusion
export const fetchFusions = params => {
  return axiosInstance
    .get('/fusions/', {params})
    .then(response => response.data)
}

export const createFusion = (id, body) => {
  return axiosInstance
    .post(`/fusions/${id}/save/`, body)
    .then(response => response.data)
}

export const generateFusionUrl = (id, variables) => {
  const encoded_variables = btoa(encodeURIComponent(JSON.stringify(variables)))
  return `${baseURL}/fusions/${id}/generate/?variables=${encoded_variables}`
}

export const generateFusionPreviewUrl = (id, variables) => {
  const encoded_variables = btoa(encodeURIComponent(JSON.stringify(variables)))
  return `${baseURL}/fusions/${id}/preview/?variables=${encoded_variables}`
}

// GroupeClient
export const fetchGroupeClients = params => {
  return axiosInstance
    .get('/clients/groupe-client/', {params})
    .then(response => response.data)
}

export const createGroupeClient = body => {
  return axiosInstance
    .post('/clients/groupe-client/', body)
    .then(response => response.data)
}

export const fetchGroupeClient = id => {
  return axiosInstance
    .get(`/clients/groupe-client/${id}/`)
    .then(response => response.data)
}

export const deleteGroupeClient = id => {
  return axiosInstance
    .delete(`/clients/groupe-client/${id}/`)
    .then(response => response.data)
}

export const updateGroupeClient = (id, body) => {
  return axiosInstance
    .put(`/clients/groupe-client/${id}/`, body)
    .then(response => response.data)
}

export const partialUpdateGroupeClient = (id, body) => {
  return axiosInstance
    .patch(`/clients/groupe-client/${id}/`, body)
    .then(response => response.data)
}

export const resetPassword = id => {
  return axiosInstance.post(`/users/${id}/resetpassword/`)
}

// utility methods
export const clientDisplayName = client => {
  if (!client) {
    return ''
  }
  return client.type === 'Personne morale'
    ? client.denomination_sociale
    : `${client.nom} ${client.prenom}`
}

export const produitLabel = produit => {
  if (!produit) {
    return ''
  }
  return `${produit.code}-${produit.nom}`
}

export const convertReactTableSortingToOrdering = data => {
  return data
    .map(x => {
      return (x.desc ? '-' : '') + x.id
    })
    .join(',')
}
