import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
} from '@mui/material'
import {Edit, Phone, Close} from '@mui/icons-material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'

import Form, {AlertError, TextField} from 'src/components/Form'
import {errorResponseToJson} from 'src/services/crm-api'
import LabelRow from 'src/components/LabelRow'
import {usePartialEditClient} from 'src/hooks/api-hooks'

const resolver = yupResolver(
  yup.object().shape({
    email_prioritaire: yup.string().label('Email prioritaire').email(),
    email_secondaire: yup.string().label('Email secondaire').email(),
  }),
)

const DisplayBody = ({
  client,
  setEditing,
  modificationDisabled,
  editing,
  isLoading,
}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="Téléphone prioritaire">
            {client?.telephone_prioritaire}
          </LabelRow>
          <LabelRow label="Téléphone secondaire">
            {client?.telephone_secondaire}
          </LabelRow>
          <LabelRow label="Email prioritaire">
            {client?.email_prioritaire}
          </LabelRow>
          <LabelRow label="Email secondaire">
            {client?.email_secondaire}
          </LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={!Boolean(client) || modificationDisabled}
        >
          Modifier
        </Button>
      </CardActions>
    </>
  )
}

const EditBody = ({
  client,
  onSubmit,
  setEditing,
  editing,
  modificationDisabled,
}) => {
  const {isLoading, error, mutate} = usePartialEditClient(client.id)
  const errors = errorResponseToJson(error)
  const defaultValue = {
    telephone_prioritaire: client.telephone_prioritaire,
    telephone_secondaire: client.telephone_secondaire,
    email_prioritaire: client.email_prioritaire,
    email_secondaire: client.email_secondaire,
  }

  return (
    <Form
      onSubmit={values => {
        mutate(values)
        setEditing(false)
      }}
      errors={errors}
      loading={isLoading}
      defaultValues={defaultValue}
      resolver={resolver}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="Téléphone prioritaire">
            <TextField size="small" name="telephone_prioritaire" />
          </LabelRow>
          <LabelRow label="Téléphone secondaire">
            <TextField size="small" name="telephone_secondaire" />
          </LabelRow>
          <LabelRow label="Email prioritaire">
            <TextField size="small" name="email_prioritaire" />
          </LabelRow>
          <LabelRow label="Email secondaire">
            <TextField size="small" name="email_secondaire" />
          </LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading || modificationDisabled}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ClientPhoneCard = ({client, modificationDisabled, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Contact" avatar={<Phone />} />
      <Divider />
      {editing ? (
        <EditBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          client={client}
          modificationDisabled={modificationDisabled}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ClientPhoneCard
