import {useRef} from 'react'
import PageTemplate from 'src/components/templates/PageTemplate'
import AddButton from 'src/components/buttons/AddButton'
import TextInput from 'src/components/inputs/TextInput'

import {useClients} from 'src/hooks/api-hooks'
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material'
import {createColumnHelper} from '@tanstack/react-table'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {Link as RouterLink} from 'react-router-dom'
import {Edit, ErrorOutline, Warning} from '@mui/icons-material'
import CopiableBox from '../../components/CopiableBox'
import {orange, red} from '@mui/material/colors'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import GroupeClientInput from '../../components/inputs/GroupeClientInput'
import DeviseLabel from '../../components/DeviseLabel'

const rightActions = (
  <AddButton to="/clients/create">Ajouter un client</AddButton>
)

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('reagi_id', {
    id: 'reagi_id',
    header: 'ID',
    enableHiding: false,
    cell: ({cell: {row}}) => {
      const item = row.original
      return (
        <Box display="flex" alignItems="center">
          <Box sx={{mr: 1}}>
            <CopiableBox copyString={item.reagi_id}>
              {item.reagi_id}
            </CopiableBox>
          </Box>
          {Boolean(item.important) && (
            <Box sx={{mr: 1}}>
              <Tooltip
                title={item.important}
                aria-label={`tooltip-important-${item.id}`}
              >
                <Warning fontSize="small" style={{color: red[500]}} />
              </Tooltip>
            </Box>
          )}

          {Boolean(item.commentaire) && (
            <Box>
              <Tooltip
                title={item.commentaire}
                aria-label={`tooltip-commentaire-${item.id}`}
              >
                <ErrorOutline fontSize="small" style={{color: orange[500]}} />
              </Tooltip>
            </Box>
          )}
        </Box>
      )
    },
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: 'Type',
  }),
  columnHelper.accessor('description', {
    id: 'description_order',
    header: 'Nom',
    cell: ({cell: {row}}) => {
      const item = row.original
      return (
        <Link
          color="inherit"
          component={RouterLink}
          to={`/clients/${item.id}`}
          variant="subtitle2"
          data-testid="cell-field-nom"
        >
          {item.type == 'Personne physique'
            ? `${item.civilite} ${item.nom} ${item.prenom}`
            : `${item.description}`}
        </Link>
      )
    },
  }),
  columnHelper.accessor('adresse_principale_ville', {
    id: 'adresse_principale_ville',
    header: 'Ville',
    enableSorting: false,
  }),
  columnHelper.accessor('adresse_principale_code_postal', {
    id: 'adresse_principale_code_postal',
    header: 'Code postal',
    enableSorting: false,
  }),
  columnHelper.accessor('contrats_count', {
    id: 'contrats_count',
    enableSorting: false,
    header: 'Contrats',
    textAlign: 'center',
    context: {
      textAlign: 'center',
    },
    cell: info => (
      <Box sx={{textAlign: 'left'}}>
        <Chip label={info.getValue()} />
      </Box>
    ),
  }),
  columnHelper.accessor('solde', {
    id: 'solde',
    header: 'Solde',
    cell: info => <DeviseLabel colored={true}>{info.getValue()}</DeviseLabel>,
    enableSorting: false,
  }),
  columnHelper.display({
    header: ' ',
    enableHiding: false,
    cell: ({row}) => {
      return (
        <Box sx={{textAlign: 'right'}}>
          <IconButton
            size="small"
            sx={{fontSize: 15}}
            component={RouterLink}
            to={`/clients/${row.original.id}/edit`}
          >
            <Edit fontSize="inherit" />
          </IconButton>
        </Box>
      )
    },
  }),
]

const ClientList = () => {
  const universalTableData = useUniversalTableData(useClients)
  const {filters, setFilters} = universalTableData
  const searchTimer = useRef()

  const handleSearch = event => {
    clearTimeout(searchTimer.current)
    searchTimer.current = setTimeout(() => {
      const {value: search} = event.target
      // Buggy !! Strange case with setFilters({})
      // if (search === '' || !Boolean(search)) {
      //   const currentFilters = filters
      //   delete filters['search']
      //   setFilters(currentFilters)
      //   return
      // }
      setFilters({search})
    }, 300)
  }

  return (
    <PageTemplate
      title="Clients"
      sections={[{title: 'Clients', current: true}]}
      data-testid="page-clients-list"
      rightActions={rightActions}
    >
      <Grid
        container
        className="box-list-search"
        sx={{
          position: !!filters['search'] ? 'sticky' : 'block',
          top: '64px',
          alignSelf: 'flex-start',
          zIndex: '999',
        }}
      >
        <Grid item>
          <Card>
            <CardContent
              sx={{padding: '10px', paddingBottom: '10px !important '}}
            >
              <TextInput
                label="Recherche"
                sx={{width: '300px'}}
                onChange={handleSearch}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <WrappedUniversalTable
        columns={columns}
        universalTableData={universalTableData}
        name={'client-list'}
        filtersContent={
          <>
            <GroupeClientInput
              onChange={(event, value) => {
                setFilters({groupe: value?.id})
              }}
            />
          </>
        }
      />
    </PageTemplate>
  )
}

export default ClientList
