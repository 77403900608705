import PageTemplate from 'src/components/templates/PageTemplate'
import LoadingCard from 'src/components/LoadingCard'
import {useCompteComptable, useEcritures} from 'src/hooks/api-hooks'
import {useParams} from 'react-router-dom'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import moment from 'moment'

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor('date', {
    header: 'Date',
    id: 'date',
    cell: info => moment(info.getValue()).format('DD/MM/YYYY'),
  }),
  columnHelper.accessor('export_compta_id', {
    id: 'export_compta_id',
    header: 'Export Compta',
  }),
  columnHelper.accessor('compte_comptable_id', {
    id: 'compte_comptable_id',
    header: 'Compte Comptable',
    enableSorting: false,
  }),
  columnHelper.accessor('libelle', {
    id: 'libelle',
    header: 'Libellé',
  }),
  columnHelper.accessor('commentaire', {
    id: 'commentaire',
    header: 'Commentaire',
  }),
  columnHelper.accessor(row => (row.montant < 0 ? row.montant : ''), {
    id: 'debit',
    header: 'Débit',
    enableSorting: false,
  }),
  columnHelper.accessor(row => (row.montant >= 0 ? row.montant : ''), {
    id: 'credit',
    header: 'Crédit',
    enableSorting: false,
  }),
]

export default function CompteComptableDetails() {
  const {id} = useParams()
  const {data: compteComptable, isLoading} = useCompteComptable(id)

  const universalTableData = useUniversalTableData(useEcritures, {
    initialFilters: {
      compte_comptable: id,
    },
  })

  const title = compteComptable
    ? `Écritures de ${compteComptable.id} - ${compteComptable.libelle}`
    : "Écritures d'un compte comptable"

  return (
    <PageTemplate
      title={title}
      sections={[
        {title: 'Comptes comptable', path: '/comptes-comptable'},
        {title: 'Consultation', current: true},
      ]}
    >
      {isLoading ? (
        <LoadingCard />
      ) : (
        <WrappedUniversalTable
          name={'compte-comptable-details'}
          columns={columns}
          universalTableData={universalTableData}
        />
      )}
    </PageTemplate>
  )
}
