import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material'

import AddButton from '../buttons/AddButton'
import EditButton from '../buttons/EditButton'
import QuittanceForm from '../forms/QuittanceForm'
import useOperationDialog from './useOperationDialog'
import DeleteOperationButton from './DeleteOperationButton'
import RistourneQuittanceButton from './RistourneQuittanceButton'

const QuittanceFormDialog = ({
  open = true,
  handleClose = () => {},
  onSubmit,
  client,
  quittance,
  isRistourne = false,
  ...props
}) => {
  const contrats = client.contrats
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(quittance?.id)

  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      create(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="quittance-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="quittance-dialog">
        {isEditing ? 'Modifier' : 'Ajouter'} une{' '}
        {isRistourne ? 'ristourne' : 'quittance'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading ? (
          'Chargement ...'
        ) : (
          <QuittanceForm
            quittance={operation}
            contrats={contrats}
            onSubmit={handleSubmit}
            isRistourne={isRistourne}
            id="quittance-dialog-form"
          />
        )}
      </DialogContent>
      <DialogActions>
        <DeleteOperationButton
          sx={{mr: 'auto'}}
          operationId={quittance?.id}
          onDelete={handleClose}
        />
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        <RistourneQuittanceButton operation={operation} onRejet={handleClose} />
        {isEditing ? (
          <EditButton
            form="quittance-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Modifier
          </EditButton>
        ) : (
          <AddButton
            form="quittance-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default QuittanceFormDialog
