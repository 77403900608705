import {
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  TableBody,
  Grid,
  Typography,
} from '@mui/material'
import {forwardRef, useEffect, useMemo, useRef} from 'react'
import {useTable, useSortBy, useRowSelect} from 'react-table'
import moment from 'moment'
import DeviseLabel from 'src/components/DeviseLabel'

const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  )
})

export default function EspecesTable({
  reglements,
  onSelectionChange = () => {},
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        sortType: 'datetime',
        Cell: ({value}) => {
          return moment(value).format('DD/MM/YYYY')
        },
      },
      {
        Header: 'Nom',
        accessor: 'client.description',
      },
      {
        Header: 'Montant',
        accessor: 'montant',
        Cell: ({value}) => {
          return (
            <>
              <DeviseLabel>{value}</DeviseLabel>
            </>
          )
        },
      },
    ],
    [],
  )
  const data = useMemo(() => {
    return (
      reglements?.map(x => ({
        ...x,
        date: moment(x.date, 'DD/MM/YYYY').toDate(),
      })) ?? []
    )
  }, [reglements])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'Date',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({getToggleAllRowsSelectedProps}) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({row}) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    },
  )

  const currentSelectedFlatRowsRef = useRef(selectedFlatRows)

  useEffect(() => {
    if (
      selectedFlatRows.length === 0 &&
      currentSelectedFlatRowsRef.current.length === 0
    ) {
      return
    }
    if (currentSelectedFlatRowsRef.current !== selectedFlatRows) {
      currentSelectedFlatRowsRef.current = selectedFlatRows
      onSelectionChange(selectedFlatRows.map(x => x.original))
    }
  }, [onSelectionChange, selectedFlatRows])

  return (
    <>
      <Table {...getTableProps()} size="small">
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column =>
                column.canSort ? (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <TableSortLabel
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      {column.render('Header')}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                  </TableCell>
                ),
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
