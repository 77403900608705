import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Euro, Edit, Close} from '@mui/icons-material'

import Form, {AlertError} from 'src/components/Form'
import LabelRow from 'src/components/LabelRow'
import useSimplePartialUpdateForm from 'src/hooks/useSimplePartialUpdateForm'
import {errorResponseToJson, partialUpdateContrat} from 'src/services/crm-api'

const resolver = yupResolver(yup.object().shape({}))

const DisplayBody = ({contrat, setEditing, editing, isLoading}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="Fractionnement">
            {contrat?.prime_fractionnement}
          </LabelRow>
          <LabelRow label="Type">{contrat?.prime_type}</LabelRow>
        </TableBody>
      </Table>
    </>
  )
}

const EditBody = ({contrat, onSubmit, setEditing, editing, setContrat}) => {
  const {isLoading, error, save} = useSimplePartialUpdateForm({
    promise: partialUpdateContrat,
    id: contrat.id,
    onSuccess: data => {
      setContrat(data)
      setEditing(false)
    },
  })

  const errors = errorResponseToJson(error)
  const defaultValue = {}
  return (
    <Form
      onSubmit={save}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ContratPrimeCard = ({contrat, setContrat, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Prime" avatar={<Euro />} />
      <Divider />
      {editing ? (
        <EditBody
          contrat={contrat}
          setContrat={setContrat}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          contrat={contrat}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ContratPrimeCard
