import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import useAsync from 'src/hooks/useAsync'
import {errorResponseToJson, updatePassword} from 'src/services/crm-api'
import Form, {TextField} from 'src/components/Form'
import useSnackbar from 'src/hooks/useSnackbar'

const schema = yup.object().shape({
  password: yup.string().label('Mot de passe').required().min(5).max(35),
})

const defaultValues = {password: ''}

const ChangePasswordDialog = ({user, open, setOpen, ...props}) => {
  const {showError, showSuccess} = useSnackbar()

  const {isLoading, error, run} = useAsync()

  const onSubmit = values => {
    run(
      updatePassword(user.id, values.password).then(() => {
        setOpen(false)
        showSuccess('Mot de passe changé')
      }),
    ).catch(error => {
      showError(error)
    })
  }
  const errors = errorResponseToJson(error)
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <Form
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        resolver={yupResolver(schema)}
        errors={errors}
      >
        <DialogTitle id="alert-dialog-title">
          Changement de mot de passe
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Mot de passe"
            name="password"
            type="password"
            disabled={isLoading}
            sx={{mt: 2}}
          />
          {/* <TextField
            autoFocus
            error={allErrors.password}
            helperText={allErrors.password?.message}
            disabled={isLoading}
            label="Nouveau mot de passe"
            type="password"
            name="password"
            inputRef={inputRef}
            onBlur={onBlur}
            fullWidth
            inputProps={{
              'data-testid': 'field-password',
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false)
            }}
            type="button"
            color="primary"
            disabled={isLoading}
          >
            Annuler
          </Button>
          <Button color="primary" autoFocus type="submit" disabled={isLoading}>
            Changer
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ChangePasswordDialog
