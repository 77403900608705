import {useContext, useState, createContext, useEffect, useMemo} from 'react'
import {
  axiosInstance,
  fetchMe,
  login as loginAPI,
  logout as logoutAPI,
} from 'src/services/crm-api'
import useIsMountedRef from './useIsMountedRef'

const authContext = createContext()

export function AuthProvider({children}) {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      <WithAxios>{children}</WithAxios>
    </authContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(authContext)
  if (!context) {
    throw new Error('useAuth must be used withing <AuthProvider />.')
  }
  return useContext(authContext)
}

function useProvideAuth() {
  const [status, setStatus] = useState('initializing')
  const [user, setUser] = useState(null)

  const isMountedRef = useIsMountedRef()

  const isAuthenticated = user && status === 'success'
  const isInitialized = status === 'initialized' || status === 'success'

  useEffect(() => {
    fetchMe()
      .then(response => {
        if (isMountedRef.current) {
          setUser(response.data)
          setStatus('success')
        }
      })
      .catch(error => {
        if (isMountedRef.current) {
          setStatus('initialized')
        }
      })
  }, [isMountedRef])

  const logout = async () => {
    return logoutAPI().then(() => {
      setUser(null)
    })
  }

  const login = async (email, password) => {
    return loginAPI(email, password).then(() => {
      return fetchMe()
        .then(response => {
          if (isMountedRef.current) {
            setUser(response.data)
            setStatus('success')
          }
          return response.data
        })
        .catch(error => {
          if (isMountedRef.current) {
            setStatus('initialized')
          }
        })
    })
  }
  return {
    login,
    logout,
    isAuthenticated,
    isInitialized,
    user,
    setUser,
    status,
  }
}

const WithAxios = ({children}) => {
  const {setUser} = useAuth()

  const dealWithinitialized = response => response
  const dealWithError = useMemo(() => {
    return async function (error) {
      if (
        error.response?.status === 401 &&
        error.response?.headers?.['www-authenticate'].toLowerCase() ===
          'session'
      ) {
        setUser(null)
      }

      return Promise.reject(error)
    }
  }, [setUser])

  useEffect(() => {
    const interceptors = axiosInstance.interceptors.response.use(
      dealWithinitialized,
      dealWithError,
    )
    return () => {
      axiosInstance.interceptors.response.eject(interceptors)
    }
  }, [dealWithError])

  return children
}

export default useAuth
