import {useEffect, useState} from 'react'
import {Autocomplete, CircularProgress} from '@mui/material'
import {searchClients} from 'src/services/crm-api'
import TextInput from './TextInput'

const ClientAutocompleteInput = ({
  label = 'Client',
  error,
  helperText,
  localOptions,
  ...props
}) => {
  const [options, setOptions] = useState([])
  const [status, setStatus] = useState('loading')
  const [errorText, setErrorText] = useState()
  const [query, setQuery] = useState('')
  const loading = status === 'loading'
  const loadingError = status === 'error'

  useEffect(() => {
    let active = true

    if (query === '') {
      return setStatus('none')
    }

    setStatus('loading')
    searchClients(query)
      .then(data => {
        if (active) {
          setOptions(data.results)
          setStatus('loaded')
        }
      })
      .catch(err => {
        setStatus('error')
        setErrorText(err.message)
      })

    return () => {
      active = false
    }
  }, [query])

  const allOptions = [
    ...localOptions.map(x => ({...x, isLocal: true})),
    ...options,
  ]

  return (
    <Autocomplete
      autoFit={true}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => {
        return option.description ?? ''
      }}
      options={allOptions}
      loading={loading}
      onInputChange={event => {
        setQuery(event?.target?.value || '')
      }}
      filterOptions={(options, {inputValue}) => {
        return options.filter(
          x => !x.isLocal || (x.isLocal && x.description.includes(inputValue)),
        )
      }}
      renderOption={(props, option, {selected}) => {
        return (
          <li {...props} style={{color: option.isLocal ? 'green' : 'inherit'}}>
            {option.isLocal
              ? option.description ?? ''
              : `${option.reagi_id} - ${option.description} (${option.type})`}
          </li>
        )
      }}
      {...props}
      renderInput={params => (
        <TextInput
          {...params}
          label={label}
          helperText={loadingError ? errorText : helperText}
          error={error || loadingError}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default ClientAutocompleteInput
