import {Grid, Typography} from '@mui/material'

import {
  ClientAddressCard,
  ClientInfoCard,
  ClientPhoneCard,
  ClientLiaisonsCard,
} from '.'
import ClientActivityCard from './ClientActivityCard'

const ClientDetailsInfo = ({client = {}, modificationDisabled}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <ClientInfoCard
          client={client}
          modificationDisabled={modificationDisabled}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ClientPhoneCard
          client={client}
          modificationDisabled={modificationDisabled}
          sx={{mb: 2}}
        />
        <ClientAddressCard
          client={client}
          modificationDisabled={modificationDisabled}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ClientLiaisonsCard
          client={client}
          modificationDisabled={modificationDisabled}
        />

        {/*<ClientActivityCard />*/}
      </Grid>
    </Grid>
  )
}

export default ClientDetailsInfo
