import {useTheme} from '@mui/system'
import {useDrop} from 'react-dnd'
import {Box} from '@mui/material'
import {Folder, FolderOpen, AddIcCall} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const DraggableTypes = {
  FILE: 'file',
}

export default function FolderItem({
  folder,
  toggleExpandedHandler,
  isOpened,
  onDrop = () => {},
}) {
  const theme = useTheme()
  const [{isOver, isOverCurrent, canDrop}, drop] = useDrop(
    () => ({
      accept: [DraggableTypes.FILE, '__NATIVE_FILE__'],
      drop: droppedObj => onDrop(folder.id, droppedObj),
      canDrop: (item, monitor) => {
        if (item.type === 'file') {
          return monitor.getItem().parent_folder_id !== folder.id
        }
        return true
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        isOverCurrent: monitor.isOver({shallow: true}),
        canDrop: monitor.canDrop(),
      }),
    }),
    [],
  )

  const overStyle = {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  }
  return (
    <Box
      ref={drop}
      display={'flex'}
      alignItems={'center'}
      {...{
        onClick: () => {
          toggleExpandedHandler()          
        },
      }}
      style={isOverCurrent && canDrop ? overStyle : undefined}
    >
      {isOpened ?  <><RemoveIcon /><FolderOpen sx={{mr: 1}} /></> : <><AddIcon /><Folder sx={{mr: 1}} /></>}{' '}
      {folder.name}
    </Box>
  )
}
