import PageTemplate from 'src/components/templates/PageTemplate'
import AddButton from 'src/components/buttons/AddButton'
import {useMemo, useState} from 'react'
import {useCaisses, useDeleteCaisse} from 'src/hooks/api-hooks'
import {IconButton, Link} from '@mui/material'
import AgencesInput from 'src/components/inputs/AgencesInput'
import ConfirmDialog from 'src/components/ConfirmDialog'
import DeviseLabel from 'src/components/DeviseLabel'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {Link as RouterLink} from 'react-router-dom'
import {Delete} from '@mui/icons-material'
import {createColumnHelper} from '@tanstack/react-table'
import PermissionHidder from 'src/components/PermissionHidder'

const rightActions = (
  <AddButton to="/caisses/create">Rajouter une fermeture de caisse</AddButton>
)

const columnHelper = createColumnHelper()
export default function CaisseList() {
  const [caisseToDelete, setCaisseToDelete] = useState()
  const {mutate: deleteCaisse} = useDeleteCaisse()
  const universalTableData = useUniversalTableData(useCaisses, {
    name: 'caisse-list',
  })
  const {handleFilterChange} = universalTableData

  const columns = useMemo(() => [
    columnHelper.accessor('id', {
      header: 'ID',
      id: 'id',
      accessor: 'id',
      cell: info => {
        return (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/caisses/${info.getValue()}`}
            variant="subtitle2"
          >
            {info.getValue()}
          </Link>
        )
      },
    }),
    columnHelper.accessor('date', {
      id: 'date',
      header: 'Date',
    }),
    columnHelper.accessor('agence.name', {
      id: 'agence__name',
      header: 'Agence',
    }),
    columnHelper.accessor('total', {
      id: 'total',
      header: 'Total',
      cell: info => <DeviseLabel>{info.getValue()}</DeviseLabel>,
    }),
    columnHelper.accessor(
      ({arretee_par: {lastname, firstname}}) => `${lastname} ${firstname}`,
      {
        id: 'arretee_par__lastname',
        header: 'Fermée par',
      },
    ),
    columnHelper.display({
      header: 'Actions',
      enableHiding: false,
      cell: ({row}) => (
        <PermissionHidder mainRole={'is_superuser'}>
          <IconButton
            size="small"
            color="primary"
            sx={{ml: 2, mr: 2}}
            onClick={() => setCaisseToDelete(row.original)}
          >
            <Delete />
          </IconButton>
        </PermissionHidder>
      ),
    }),
  ])

  return (
    <PageTemplate
      title="Fermeture de caisse"
      sections={[{title: 'List des fermetures de caisse', current: true}]}
      rightActions={rightActions}
    >
      <WrappedUniversalTable
        columns={columns}
        universalTableData={universalTableData}
        name={'caisses-list'}
        filtersContent={
          <AgencesInput onChange={handleFilterChange('agence', 'id')} />
        }
      />
      {!!caisseToDelete && (
        <ConfirmDialog
          title="Confirmation de suppression"
          onConfirm={() => {
            deleteCaisse(caisseToDelete.id)
            setCaisseToDelete()
          }}
          onCancel={() => {
            setCaisseToDelete()
          }}
          open={!!caisseToDelete}
        >
          Vous êtes sur le point de supprimer cette caisse:
          <br />
          <strong>
            {caisseToDelete.id} - {caisseToDelete.date} -{' '}
            {caisseToDelete.agence.name} -{' '}
            <DeviseLabel>{caisseToDelete.total}</DeviseLabel>
          </strong>
        </ConfirmDialog>
      )}
    </PageTemplate>
  )
}
