import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import moment from 'moment'
import {Download} from '@mui/icons-material'
import {Controller, useForm} from 'react-hook-form'
import * as yup from 'yup'
import {DesktopDatePicker, LoadingButton} from '@mui/lab'
import {yupResolver} from '@hookform/resolvers/yup'
import CompagnieInputControlled from '../controlled/CompagnieInputControlled'
import {useState} from 'react'
import {useEnvoisDeFondExport} from '../../hooks/api-hooks'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  compagnie: yup.mixed().label('Compagnie').required(),
  date_fin: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date de fin')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
})

const EnvoiDeFondExportDialog = ({
  open = true,
  dette,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  })
  const [filters, setFilters] = useState()
  const {isLoading, data: preview} = useEnvoisDeFondExport(
    {
      ...filters,
    },
    {},
  )
  const allErrors = errors
  const [uiState, setUiState] = useState()
  const isPreviewState = uiState === 'preview'
  const isFinishedState = uiState === 'finished'

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        body.date_fin = moment(values.date_fin).format('DD/MM/YYYY')
        setFilters({...body})
        setUiState('preview')
      })}
      {...props}
      id={'envoi-de-fond-export-dialog'}
    >
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason)
          }
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Export des données pour envoi de fond</DialogTitle>
        <Divider />
        <DialogContent>
          {isFinishedState && !isLoading ? (
            <Table size={'small'}>
              <TableRow>
                <TableCell>Quittances soldées</TableCell>
                <TableCell>{preview.nb_quittances}</TableCell>
                <TableCell>
                  <Link href={preview.quittances_url}>
                    <Button startIcon={<Download />}>Télécharger</Button>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ristournes soldées</TableCell>
                <TableCell>{preview.nb_ristournes}</TableCell>{' '}
                <TableCell>
                  <Link href={preview.ristournes_url}>
                    <Button startIcon={<Download />}>Télécharger</Button>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Écritures partielles</TableCell>
                <TableCell>{preview.nb_partielles}</TableCell>{' '}
                <TableCell>
                  <Link href={preview.partielles_url}>
                    <Button startIcon={<Download />}>Télécharger</Button>
                  </Link>
                </TableCell>
              </TableRow>
            </Table>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="date_fin"
                  control={control}
                  defaultValue={moment(today).format('MM/DD/YYYY')}
                  render={({field}) => (
                    <DesktopDatePicker
                      {...field}
                      onChange={(...args) => {
                        field.onChange(args)
                        setUiState('initial')
                      }}
                      label="Date de fin"
                      inputFormat="dd/MM/yyyy"
                      renderInput={params => (
                        <TextField
                          {...params}
                          disabled={isLoading}
                          margin="dense"
                          size="small"
                          fullWidth
                          error={!!allErrors?.date_fin?.message}
                          helperText={allErrors?.date_fin?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CompagnieInputControlled
                  control={control}
                  onChange={() => {
                    setUiState('initial')
                  }}
                  error={!!allErrors?.compagnie?.message}
                  helperText={allErrors?.compagnie?.message}
                />
              </Grid>
              {isPreviewState && preview ? (
                <Grid item xs={12} sx={{mt: 3}}>
                  <Typography color="textPrimary" variant="subtitle2">
                    Prévisualisation des données
                  </Typography>
                  <Table size={'small'}>
                    <TableRow>
                      <TableCell>Quittances soldées</TableCell>
                      <TableCell>{preview.nb_quittances}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ristournes soldées</TableCell>
                      <TableCell>{preview.nb_ristournes}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Écritures partielles</TableCell>
                      <TableCell>{preview.nb_partielles}</TableCell>
                    </TableRow>
                  </Table>
                </Grid>
              ) : null}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          {isPreviewState ? (
            isFinishedState ? null : (
              <Button
                loading={isLoading}
                startIcon={<Download fontSize="small" />}
                variant="contained"
                onClick={() => setUiState('finished')}
              >
                Télécharger
              </Button>
            )
          ) : (
            <LoadingButton
              loading={isLoading}
              type={'submit'}
              form="envoi-de-fond-export-dialog"
              startIcon={<Download fontSize="small" />}
              variant="contained"
            >
              Prévisualiser pour envoi
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default EnvoiDeFondExportDialog
