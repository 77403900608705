import {Download} from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import {useParams} from 'react-router'
import DeviseLabel from 'src/components/DeviseLabel'
import ErrorCard from 'src/components/ErrorCard'
import LoadingCard from 'src/components/LoadingCard'
import PageTemplate from 'src/components/templates/PageTemplate'
import {useCahiersDeTransmission, useRemiseEnBanque} from 'src/hooks/api-hooks'
import RemiseEnBanqueDetailsTable from './RemiseEnBanqueDetailsTable'
import CahierDeTransmissionStatusIcon from './CahierDeTransmissionStatusIcon'

export default function RemiseEnBanqueDetails() {
  const {id} = useParams()
  const {
    isLoading,
    data: remiseEnBanque,
    isError,
    error,
  } = useRemiseEnBanque(parseInt(id))

  const {isLoading: trackerIsLoading, data: trackers} =
    useCahiersDeTransmission({remise_en_banque: id})

  const isCheque = remiseEnBanque?.type_remise === 'Chèque'
  const isEspeces = !isCheque
  return (
    <PageTemplate
      title={
        remiseEnBanque
          ? `Remise en banque ${remiseEnBanque.type_remise} n° ${remiseEnBanque.numero_remise}`
          : 'Remises en banque chargement ...'
      }
      sections={[
        {
          title: 'Remises en banque',
          path: `/remises-en-banque/${isCheque ? 'cheque' : 'especes'}`,
        },
        {title: 'Consultation', current: true},
      ]}
    >
      {isError && <ErrorCard error={error} />}
      {isLoading && <LoadingCard />}

      {remiseEnBanque && (
        <>
          <Link href={remiseEnBanque.pdf} target="_blank">
            <Button sx={{mb: 2}} variant="contained" startIcon={<Download />}>
              Télécharger le bordereau
            </Button>
          </Link>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Titulaire du compte</TableCell>
                  <TableCell>Agence</TableCell>
                  {isCheque && <TableCell>Nombre de chèque</TableCell>}
                  {isEspeces && <TableCell>Montant billets</TableCell>}
                  {isEspeces && <TableCell>Montant pièces</TableCell>}
                  <TableCell>Montant</TableCell>
                  <TableCell>Date d'émission</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{remiseEnBanque.banque_remise.nom}</TableCell>
                  <TableCell>{remiseEnBanque.agence.name}</TableCell>
                  {isCheque && (
                    <TableCell>{remiseEnBanque.nombre_cheques}</TableCell>
                  )}
                  {isEspeces && (
                    <TableCell>
                      <DeviseLabel>
                        {remiseEnBanque.montant_billets}
                      </DeviseLabel>
                    </TableCell>
                  )}
                  {isEspeces && (
                    <TableCell>
                      <DeviseLabel>{remiseEnBanque.montant_pieces}</DeviseLabel>
                    </TableCell>
                  )}
                  <TableCell>
                    <DeviseLabel>{remiseEnBanque.montant}</DeviseLabel>
                  </TableCell>
                  <TableCell>{remiseEnBanque.date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2} sx={{mt: 1}}>
            <Grid item md={6} xs={12}>
              <TableContainer component={Paper}>
                <RemiseEnBanqueDetailsTable
                  reglements={remiseEnBanque?.reglements}
                />
              </TableContainer>
            </Grid>
            <Grid item md={6} xs={12}>
              {trackers?.results?.map(x => (
                <Card key={x.id} sx={{mb: 2}}>
                  <CardHeader
                    title={`Suivi de la transmission ${x.type} ${
                      x.bordereau ? `(#${x.bordereau})` : ''
                    }`}
                  />
                  <Divider />
                  <CardContent>
                    <CahierDeTransmissionStatusIcon cahierDeTransmission={x} />
                    <Typography variant="subtitle2">Historique</Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Situation</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Utilisateurs</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {x.changements_statut?.map(x => (
                          <TableRow>
                            <TableCell>{x.status}</TableCell>
                            <TableCell>
                              {moment(x.date).format('DD/MM/YYYY à HH:mm:ss')}
                            </TableCell>
                            <TableCell>{x.user?.name ?? ''}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </PageTemplate>
  )
}
