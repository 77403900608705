import {Autocomplete} from 'src/components/Form'

const civilites = [
  'Monsieur',
  'Madame',
  'Mademoiselle',
  'Docteur',
  'Maître',
].map(x => ({text: x, value: x}))

const CiviliteField = props => (
  <Autocomplete items={civilites} name="civilite" {...props} />
)

export default CiviliteField
