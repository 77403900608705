import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Box, Button, Card, Grid, Typography} from '@mui/material'

import Form, {TextField, Select, Switch, AlertError} from 'src/components/Form'

const updateSchema = yup.object().shape({
  firstname: yup.string().label('Prénom').required().max(35),
  lastname: yup.string().label('Nom de famille').required().max(35),
  agence_id: yup.string().label('agence').required(),
})

const createSchema = yup.object().shape({
  firstname: yup.string().label('Prénom').required().max(35),
  lastname: yup.string().label('Nom de famille').required().max(35),
  email: yup.string().email().label('Adresse email').required().max(35),
  password: yup.string().label('Mot de passe').required().min(5),
  agence_id: yup.string().label('Agence').required(),
})

const defaultValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  agence_id: '',
  fonction_id: '',
  service_id: '',
  is_active: false,
  is_staff: false,
  is_superuser: false,
}

const UserForm = ({
  onSubmit,
  loading = false,
  errors = {},
  isEditForm = false,
  agences = [],
  fonctions = [],
  services = [],
  user,
  ...props
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      errors={errors}
      resolver={yupResolver(isEditForm ? updateSchema : createSchema)}
      isLoading={loading}
      values={user}
    >
      <Card>
        <Box sx={{p: 3}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AlertError />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                name="email"
                type="email"
                label="Adresse email"
                readOnly={isEditForm}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {!Boolean(isEditForm) && (
                <TextField
                  type="password"
                  fullWidth
                  label="Mot de passe"
                  name="password"
                />
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth label="Prénom" name="firstname" />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField fullWidth label="Nom de famille" name="lastname" />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select name={'agence_id'} label={'Agence'} items={agences} />
            </Grid>

            <Grid item md={6} xs={12}>
              <Select
                name={'fonction_id'}
                label={'Fonction'}
                items={fonctions}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select name={'service_id'} label={'Service'} items={services} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="subtitle2">
                Utilisateur actif
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Les utilisateurs peuvent se connecter et se service du
                Back-Office
              </Typography>

              <Switch name="is_active" label="Utilisateur actif" />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="subtitle2">
                Utilisateur standard
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Les utilisateurs ont les droits d'écritures
              </Typography>
              <Switch name="is_staff" label="Utilisateur standard" />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="subtitle2">
                Super utilisateur
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Les super utilisateurs ont plus de droit notamment la gestion
                utilisateur
              </Typography>

              <Switch name="is_superuser" label="Super utilisateur" />
            </Grid>
          </Grid>

          <Box sx={{mt: 2}} display="flex" justifyContent="space-between">
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {isEditForm ? 'Modifier ' : 'Créer '}
            </Button>
          </Box>
        </Box>
      </Card>
    </Form>
  )
}

export default UserForm
