import {Fragment} from 'react'
import {useFieldArray} from 'react-hook-form'
import {Grid, Divider} from '@mui/material'
import * as yup from 'yup'

import CloseButton from 'src/components/buttons/CloseButton'
import AddButton from 'src/components/buttons/AddButton'
import TextInputControlled from 'src/components/controlled/TextInputControlled'

const InterlocuteurSchema = yup.object().shape(
  {
    nom: yup.string().required().label('Nom'),
    fonction: yup.string().label('Fonction'),
    telephone: yup.string().label('Téléphone'),
    email: yup.string().email().label('Email'),
  },
  ['telephone', 'email'],
)

const InterlocuteursField = ({control, errors}) => {
  // https://codesandbox.io/s/react-hook-form-usefieldarray-nested-arrays-m8w6j?file=/src/nestedFieldArray.js:471-480
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'interlocuteurs',
  })

  return (
    <Grid container spacing={2}>
      {fields.map((item, index) => {
        return (
          <Fragment key={item.id}>
            <Grid item xs={11} sx={{marginBottom: 1, marginTop: 1}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextInputControlled
                    control={control}
                    name={`interlocuteurs.${index}.nom`}
                    error={!!errors?.interlocuteurs?.[index]?.nom}
                    helperText={errors?.interlocuteurs?.[index]?.nom?.message}
                    label="Nom"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputControlled
                    control={control}
                    name={`interlocuteurs.${index}.fonction`}
                    error={!!errors?.interlocuteurs?.[index]?.fonction}
                    helperText={
                      errors?.interlocuteurs?.[index]?.fonction?.message
                    }
                    label="Fonction"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputControlled
                    control={control}
                    name={`interlocuteurs.${index}.telephone`}
                    error={!!errors?.interlocuteurs?.[index]?.telephone}
                    helperText={
                      errors?.interlocuteurs?.[index]?.telephone?.message
                    }
                    label="Téléphone"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputControlled
                    control={control}
                    name={`interlocuteurs.${index}.email`}
                    error={!!errors?.interlocuteurs?.[index]?.email}
                    helperText={errors?.interlocuteurs?.[index]?.email?.message}
                    label="Email"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CloseButton
                onClick={event => {
                  event.preventDefault()
                  remove(index)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        )
      })}

      <Grid item xs={12} display="flex" justifyContent="center">
        <AddButton
          size="small"
          onClick={event => {
            event.preventDefault()
            append({nom: '', fonction: '', telephone: '', email: ''})
          }}
        >
          Ajouter un interlocuteur
        </AddButton>
      </Grid>
    </Grid>
  )
}

export {InterlocuteursField as default, InterlocuteurSchema}
