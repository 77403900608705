import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@mui/material'

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import moment from 'moment'
import {useForm} from 'react-hook-form'

import AddButton from 'src/components/buttons/AddButton'

import InterlocuteursField, {InterlocuteurSchema} from './InterlocuteursField'
import LiaisonsClientsAddField, {
  LiaisonsClientsSchema,
} from './LiaisonsClientsAddField'
import TextInputControlled from 'src/components/controlled/TextInputControlled'
import TypeClientInputControlled from 'src/components/controlled/TypeClientInputControlled'
import CiviliteInputControlled from 'src/components/controlled/CiviliteInputControlled'
import DatePickerInputControlled from 'src/components/controlled/DatePickerInputControlled'
import FormeJuridiqueInputControlled from 'src/components/controlled/FormeJuridiqueInputControlled'
import CountryInputControlled from 'src/components/controlled/CountryInputControlled'
import EditButton from 'src/components/buttons/EditButton'
import GroupeClientInputControlled from '../../controlled/GroupeClientInputControlled'
import PermissionHidder from 'src/components/PermissionHidder'
import ConfirmDialog from 'src/components/ConfirmDialog'
import useSnackbar from 'src/hooks/useSnackbar'
import { useNavigate } from 'react-router-dom'
import { useDeleteClient, useDeleteGroupeClient } from 'src/hooks/api-hooks'
import { useState } from 'react'
import { DeleteForever } from '@mui/icons-material'

const schemaObj = {
  type: yup.mixed().label('Type').required(),
  // particulier
  civilite: yup
    .mixed()
    .label('Civilité')
    .when('type', (type, schema) =>
      type === 'Personne physique' ? schema.required() : schema,
    ),
  nom: yup
    .string()
    .max(50)
    .label('Nom')
    .when('type', (type, schema) =>
      type === 'Personne physique' ? schema.required() : schema,
    ),

  // entreprise
  siret: yup.string().label('SIRET').max(30),
  // .when('type', (type, schema) =>
  //   type === 'Personne morale' ? schema.required() : schema,
  // )
  repreneur: yup.string().label('Repreneur').max(150),
  forme_juridique: yup.mixed().label('Forme juridique'),
  denomination_sociale: yup
    .string()
    .label('Dénomination Sociale')
    .max(50)
    .when('type', (type, schema) =>
      type === 'Personne morale' ? schema.required() : schema,
    ),
  interlocuteurs: yup
    .array()
    .when('type', (type, schema) =>
      type === 'Personne morale' ? schema.of(InterlocuteurSchema) : schema,
    ),

  reagi_id: yup.string().label('id RéAGI').required().max(35),
  adresse_principale_1: yup.string().label("L'adresse").required().max(50),
  adresse_principale_code_postal: yup
    .string()
    .label('Code postal')
    .required()
    .max(10),
  adresse_principale_ville: yup.string().label('Ville').required().max(30),
  adresse_principale_pays: yup.string().label('Pays').required().max(30),
  email_prioritaire: yup.string().email().label('Email prioritaire'),
  email_secondaire: yup.string().email().label('Email secondaire'),
  liaisons_clients_add: yup.array().of(LiaisonsClientsSchema),
}

const schema = yup.object().shape(schemaObj)

const ClientForm = ({
  onSubmit,
  isLoading: loading = false,
  isError = false,
  errors,
  client,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors: formErrors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...client,
      date_naissance: Boolean(client?.date_naissance)
        ? moment(client.date_naissance, 'DD/MM/YYYY')
        : undefined,
    },
  })

  const isEnterprise = watch('type') === 'Personne morale'
  const isParticulier = watch('type') === 'Personne physique'

  const editing = Boolean(client)
  const allErrors = {...errors, ...formErrors}

  const [deleteDialog, setDeleteDialog] = useState(false)
  const {isLoading: deleteLoading, mutate} = useDeleteClient()
  const navigate = useNavigate()

  const {showSuccess, ShowError} = useSnackbar()
  const handleDelete = () => {  
    mutate(client.id, 
        {
          onSuccess: () => {
            setDeleteDialog(false)
            navigate('/clients')
          }, 
          onError: () => setDeleteDialog(false)
        },
      )    
  }


  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {...values}
        if (body.type === 'Personne physique') {
          delete body['forme_juridique']
          delete body['denomination_sociale']
          delete body['siret']
          delete body['interlocuteurs']
        }
        if (body.type === 'Personne morale') {
          delete body['civilite']
          delete body['nom']
          delete body['prenom']
          delete body['date_naissance']
          delete body['nom_naissance']
        }
        if (body.date_naissance) {
          body.date_naissance = moment(values.date_naissance).format(
            'DD/MM/YYYY',
          )
        }
        body.groupe_id = values.groupe?.id
        if (body.liaisons_clients_add) {
          body.liaisons_clients_add = body.liaisons_clients_add.map(x => ({
            client_2_id: x.client_2_id?.id,
            lien_id: x.lien_id?.id,
          }))
        }
        onSubmit?.(body)
      })}
    >
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} xs={12}>
          {/* Identité */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Identité" />
              <CardContent sx={{pt: 0}}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className={'form-grid-dense'}
                    sx={{paddingTop: '0px'}}
                  >
                    <TextInputControlled
                      control={control}
                      name="reagi_id"
                      label="Id RéAGI"
                      error={!!allErrors?.reagi_id?.message}
                      helperText={allErrors?.reagi_id?.message}
                      size="small"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TypeClientInputControlled
                      control={control}
                      error={!!allErrors?.type?.message}
                      helperText={allErrors?.type?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isParticulier ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <CiviliteInputControlled
                      control={control}
                      error={!!allErrors?.civilite?.message}
                      helperText={allErrors?.civilite?.message}
                    />
                  </Grid>
                  <Hidden mdDown>
                    <Grid
                      item
                      md={6}
                      display={isParticulier ? 'block' : 'none'}
                      className={'form-grid-dense'}
                    />
                  </Hidden>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isParticulier ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <TextInputControlled
                      control={control}
                      name="nom"
                      label="Nom"
                      error={!!allErrors?.nom?.message}
                      helperText={allErrors?.nom?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isParticulier ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <TextInputControlled
                      control={control}
                      name="prenom"
                      label="Prénom"
                      error={!!allErrors?.prenom?.message}
                      helperText={allErrors?.prenom?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isParticulier ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <TextInputControlled
                      control={control}
                      name="nom_naissance"
                      label="Nom de naissance"
                      error={!!allErrors?.nom_naissance?.message}
                      helperText={allErrors?.nom_naissance?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isParticulier ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <DatePickerInputControlled
                      name="date_naissance"
                      control={control}
                      label="Date de naissance"
                      error={!!allErrors?.date_naissance?.message}
                      helperText={allErrors?.date_naissance?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isEnterprise ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <TextInputControlled
                      control={control}
                      name="siret"
                      label="SIRET"
                      error={!!allErrors?.siret?.message}
                      helperText={allErrors?.siret?.message}
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isEnterprise ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <FormeJuridiqueInputControlled
                      control={control}
                      defaultValue={''}
                      error={!!allErrors?.forme_juridique?.message}
                      helperText={allErrors?.forme_juridique?.message}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    display={isEnterprise ? 'block' : 'none'}
                    className={'form-grid-dense'}
                  >
                    <TextInputControlled
                      control={control}
                      name="denomination_sociale"
                      label="Dénomination sociale"
                      error={!!allErrors?.denomination_sociale?.message}
                      helperText={allErrors?.denomination_sociale?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <GroupeClientInputControlled
                      control={control}
                      error={!!allErrors?.groupe?.message}
                      helperText={allErrors?.groupe?.message}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {/* Interlocuteurs */}
          <Box sx={{mb: 3}} display={isEnterprise ? 'block' : 'none'}>
            <Card>
              <CardHeader title="Interlocuteurs" />
              <CardContent>
                <InterlocuteursField control={control} errors={allErrors} />
              </CardContent>
            </Card>
          </Box>
          {/* Adresses */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Adresses" sx={{pb: 0}} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle2">
                      Adresse principale :
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_1"
                      label="Numéro et libellé de la voie"
                      error={!!allErrors?.adresse_principale_1?.message}
                      helperText={allErrors?.adresse_principale_1?.message}
                    />
                  </Grid>

                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_2"
                      label="Résidence / Bâtiment / Entrée / Immeuble"
                      error={!!allErrors?.adresse_principale_2?.message}
                      helperText={allErrors?.adresse_principale_2?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_3"
                      label="No appartement / Boîte aux lettres / Étage / Couloir"
                      error={!!allErrors?.adresse_principale_3?.message}
                      helperText={allErrors?.adresse_principale_3?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_4"
                      label="Lieudit ou service particulier de distribution"
                      error={!!allErrors?.adresse_principale_4?.message}
                      helperText={allErrors?.adresse_principale_4?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_code_postal"
                      label="Code Postal"
                      error={
                        !!allErrors?.adresse_principale_code_postal?.message
                      }
                      helperText={
                        allErrors?.adresse_principale_code_postal?.message
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_principale_ville"
                      label="Ville"
                      error={!!allErrors?.adresse_principale_ville?.message}
                      helperText={allErrors?.adresse_principale_ville?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <CountryInputControlled
                      control={control}
                      name="adresse_principale_pays"
                      error={!!allErrors?.adresse_principale_pays?.message}
                      helperText={allErrors?.adresse_principale_pays?.message}
                    />
                  </Grid>
                  <Divider />
                  <Grid item xs={12}>
                    <Typography color="textPrimary" variant="subtitle2">
                      Adresse secondaire :
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_1"
                      label="Numéro et libellé de la voie"
                      error={!!allErrors?.adresse_secondaire_1?.message}
                      helperText={allErrors?.adresse_secondaire_1?.message}
                    />
                  </Grid>

                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_2"
                      label="Résidence / Bâtiment / Entrée / Immeuble"
                      error={!!allErrors?.adresse_secondaire_2?.message}
                      helperText={allErrors?.adresse_secondaire_2?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_3"
                      label="No appartement / Boîte aux lettres / Étage / Couloir"
                      error={!!allErrors?.adresse_secondaire_3?.message}
                      helperText={allErrors?.adresse_secondaire_3?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_4"
                      label="Lieudit ou service particulier de distribution"
                      error={!!allErrors?.adresse_secondaire_4?.message}
                      helperText={allErrors?.adresse_secondaire_4?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_code_postal"
                      label="Code Postal"
                      error={
                        !!allErrors?.adresse_secondaire_code_postal?.message
                      }
                      helperText={
                        allErrors?.adresse_secondaire_code_postal?.message
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="adresse_secondaire_ville"
                      label="Ville"
                      error={!!allErrors?.adresse_secondaire_ville?.message}
                      helperText={allErrors?.adresse_secondaire_ville?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <CountryInputControlled
                      control={control}
                      name="adresse_secondaire_pays"
                      error={!!allErrors?.adresse_secondaire_pays?.message}
                      helperText={allErrors?.adresse_secondaire_pays?.message}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {/* Tel */}
          <Box sx={{mb: 3}}>
            <Card>
              <CardHeader title="Coordonnées" />
              <CardContent sx={{pt: 0}}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="telephone_prioritaire"
                      label="Téléphone prioritaire"
                      error={!!allErrors?.telephone_prioritaire?.message}
                      helperText={allErrors?.telephone_prioritaire?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="telephone_secondaire"
                      label="Téléphone secondaire"
                      error={!!allErrors?.telephone_secondaire?.message}
                      helperText={allErrors?.telephone_secondaire?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="email_prioritaire"
                      label="Email prioritaire"
                      error={!!allErrors?.email_prioritaire?.message}
                      helperText={allErrors?.email_prioritaire?.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={'form-grid-dense'}>
                    <TextInputControlled
                      control={control}
                      name="email_secondaire"
                      label="Email secondaire"
                      error={!!allErrors?.email_secondaire?.message}
                      helperText={allErrors?.email_secondaire?.message}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Card>
            <CardHeader title="Notes" sx={{pb: 0}} />
            <CardContent sx={{py: 0}}>
              <Box sx={{mb: 2}}>
                <TextInputControlled
                  control={control}
                  name="commentaire"
                  label="Commentaire"
                  error={!!allErrors?.commentaire?.message}
                  helperText={allErrors?.commentaire?.message}
                  rows={3}
                  multiline={true}
                />
              </Box>
              <Box>
                <TextInputControlled
                  control={control}
                  name="important"
                  label="Important"
                  error={!!allErrors?.important?.message}
                  helperText={allErrors?.important?.message}
                  rows={3}
                  multiline={true}
                />
              </Box>
            </CardContent>
          </Card>
          {/* Liaisons */}
          {!editing && (
            <Box sx={{mb: 3, mt: 3}}>
              <Card>
                <CardHeader title="Liaisons" sx={{pb: 0}} />
                <CardContent>
                  <LiaisonsClientsAddField
                    loading={loading}
                    control={control}
                  />
                </CardContent>
              </Card>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 3,
            }}
          >
            {editing ? (
              <>
                <EditButton>Modifier</EditButton>
                <PermissionHidder mainRole={'is_superuser'}>
                    <Button
                    onClick={() => setDeleteDialog(true)}
                    startIcon={<DeleteForever />}
                    variant={'contained'}
                    color='error'
                    sx={{m: 1}}                    
                  >
                    Supprimer
                  </Button>                
                  <ConfirmDialog
                    title={`Voulez-vous vraiment supprimer le client ?`}
                    loading={deleteLoading}
                    open={deleteDialog}
                    onCancel={() => setDeleteDialog(false)}
                    onConfirm={handleDelete}
                  />
                </PermissionHidder>
              </>
            ) : (
              <AddButton>Ajouter</AddButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default ClientForm
