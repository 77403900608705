import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {useGroupeClients} from 'src/hooks/api-hooks'

export default function GroupeClientInput({
  label = 'Groupe',
  error,
  helperText,
  margin = 'dense',
  size = 'small',
  ...props
}) {
  const {
    isLoading: loading,
    data: options,
    isError: loadingError,
    error: errorException,
  } = useGroupeClients()

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.nom ?? ''}
      options={options ?? []}
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorException.message : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
