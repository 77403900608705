import {Helmet} from 'react-helmet-async'
import {Box, Container} from '@mui/material'

import Header, {Title, Breadcrumbs} from '../Header'

const PageTemplate = ({
  title,
  sections,
  rightActions,
  topActions,
  children,
  ...props
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          // minHeight: '100%',
          py: 5,
        }}
        {...props}
      >
        <Container maxWidth="xl" sx={{minHeight: '100%'}}>
          <Header sections={sections} title={title} rightActions={rightActions}>
            <Breadcrumbs />
            <Title data-testid="page-title" mt={2} />
            <Box mt={2}>{topActions}</Box>
          </Header>
          <Box sx={{mt: 3}}>{children}</Box>
        </Container>
      </Box>
    </>
  )
}

export default PageTemplate
