import AutocompleteInput from './AutocompleteInput'

const compagnies = ['Prudence Créole', 'Autre']

export default function CompagnieInput({
  label = 'Compagnie',
  error,
  helperText,
  ...props
}) {
  return (
    <AutocompleteInput
      options={compagnies}
      label={label}
      error={error}
      helperText={helperText}
      {...props}
    />
  )
}
