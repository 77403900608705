import {createContext, useContext} from 'react'

const DebugContext = createContext()
DebugContext.displayName = 'DebugContext'

const useDebug = () => {
  const context = useContext(DebugContext)
  if (!context) {
    throw new Error('useDebug must be used within <Form />')
  }
  return context
}

const DebugProvider = ({children}) => {
  return <DebugContext.Provider>{children}</DebugContext.Provider>
}

export {DebugProvider as default, useDebug}
