import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'
import {FusionParam} from './index'
import {useForm} from 'react-hook-form'
import {useEffect, useMemo, useState} from 'react'
import FusionsInput from '../inputs/FusionsInput'
import moment from 'moment'
import {
  generateFusionPreviewUrl,
  generateFusionUrl,
} from '../../services/crm-api'
import {ArrowBack, Download, Save} from '@mui/icons-material'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack'
import {useCreateFusion} from '../../hooks/api-hooks'
import {LoadingButton} from '@mui/lab'
import BrowseFolder from '../Filemanager/BrowseFolder'
import TextInput from '../inputs/TextInput'

export default function FusionDialog({
  open,
  type,
  initialVariables = {},
  client,
  onCancel = () => {},
  title = 'Création de document',
}) {
  const [currentFusion, setCurrentFusion] = useState()
  const [url, setUrl] = useState()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [currentZoomLevel, setZoomLevel] = useState(1.2)
  const {mutate, isLoading, data} = useCreateFusion(currentFusion?.id)
  const [isFolderStep, setIsFolderStep] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(1)
  const [currentName, setCurrentName] = useState()

  const {control, handleSubmit} = useForm()

  useEffect(() => setCurrentName(currentFusion?.name), [currentFusion])

  const handleCancel = (...args) => {
    onCancel(args)
    setIsFolderStep(false)
    setCurrentFolder(1)
    setUrl(undefined)
    setCurrentFusion(undefined)
  }

  var dialogContent = null
  var dialogActions = null

  const initialStep = !Boolean(url) && !Boolean(currentFusion)
  const isPreviewStep = Boolean(url)
  const isFillingStep = Boolean(currentFusion)

  const filePreview = useMemo(
    () => (
      <Document
        loading={'Chargement de la prévisualisation'}
        file={{
          url: url?.preview,
          withCredentials: true,
        }}
        onLoadSuccess={({numPages}) => {
          setNumPages(numPages)
        }}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map(page => (
            <Page pageNumber={page} />
          ))}
      </Document>
    ),
    [url],
  )

  if (isFolderStep) {
    dialogContent = (
      <BrowseFolder
        rootPath={client?.reagi_id ?? ''}
        onFolderChange={folder => setCurrentFolder(folder?.id ?? 1)}
      />
    )
    dialogActions = (
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        width={'100%'}
        alignItems={'center'}
      >
        <Button
          startIcon={<ArrowBack />}
          disabled={isLoading}
          onClick={() => setIsFolderStep(false)}
        >
          Retour
        </Button>
        <TextInput
          value={currentName}
          onChange={e => setCurrentName(e.target.value)}
          fullWidth={false}
          label={'Nom du fichier'}
          style={{flex: 1, marginLeft: 20, marginRight: 20}}
        />
        <LoadingButton
          disabled={isLoading || !currentName || currentName === ''}
          loading={isLoading}
          variant={'contained'}
          startIcon={<Save />}
          onClick={() =>
            mutate(
              {
                variables: url?.variables,
                parent_folder: currentFolder,
                name: currentName,
              },
              {onSuccess: handleCancel},
            )
          }
        >
          Sauvegarder sur le serveur
        </LoadingButton>
      </Box>
    )
  } else if (isPreviewStep) {
    dialogContent = (
      <Box display={'flex'}>
        <Box
          height={'60vh'}
          overflow={'scroll'}
          border={'1px solid black'}
          display={'flex'}
          justifyContent={'center'}
          backgroundColor={'gray'}
          flex={1}
          padding={1}
        >
          {filePreview}
        </Box>
      </Box>
    )
    dialogActions = (
      <>
        <Button
          startIcon={<ArrowBack />}
          disabled={isLoading}
          onClick={() => setUrl(undefined)}
        >
          Retour
        </Button>
        <Link component={Button} startIcon={<Download />} href={url.download}>
          Télécharger document
        </Link>
        <LoadingButton
          disabled={isLoading}
          loading={isLoading}
          variant={'contained'}
          startIcon={<Save />}
          onClick={() => setIsFolderStep(true)}
        >
          Sauvegarder sur le serveur
        </LoadingButton>
      </>
    )
  } else if (isFillingStep) {
    dialogContent = (
      <>
        <Typography mb={2}>
          Les variantes suivantes sont à saisies libres. <br />
          Il sera possible de prévisualiser sur l'étape d'après le document
          avant validation.
        </Typography>
        {currentFusion.params?.map(x => (
          <FusionParam
            param={x}
            type={x.widget}
            label={x.name}
            name={x.var_name}
            description={x.description}
            control={control}
            key={x.var_name}
          />
        ))}
      </>
    )
    dialogActions = (
      <>
        <Button
          onClick={() => setCurrentFusion(null)}
          color="primary"
          autoFocus
          disabled={isLoading}
        >
          Retour
        </Button>
        <Button type={'submit'} loading={isLoading} disabled={isLoading}>
          Prévisualiser
        </Button>
      </>
    )
  } else if (initialStep) {
    dialogContent = (
      <>
        <Typography>
          Sélectionner le patron de conception qui servira de base à votre
          document.
        </Typography>
        <FusionsInput
          type={type}
          onChange={(event, value) => setCurrentFusion(value)}
        />
      </>
    )
    dialogActions = (
      <>
        <Button onClick={handleCancel} color="primary" autoFocus>
          Annuler
        </Button>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth={'md'}
      fullWidth
      scroll={'paper'}
    >
      <form
        onSubmit={handleSubmit(body => {
          const params = {
            ...initialVariables,
            ...body,
          }
          Object.keys(params).forEach(key => {
            if (params[key] instanceof Date) {
              params[key] = moment(params[key]).format('DD/MM/YYYY')
            }
          })
          setUrl({
            preview: generateFusionPreviewUrl(currentFusion.id, params),
            download: generateFusionUrl(currentFusion.id, params),
            variables: params,
          })
        })}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {dialogContent}
        </DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </form>
    </Dialog>
  )
}
