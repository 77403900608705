import {useParams} from 'react-router'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
} from '@mui/material'
import EditButton from 'src/components/buttons/EditButton'
import PageTemplate from 'src/components/templates/PageTemplate'
import ClientDetailsInfo from './ClientDetailsInfo'
import AddButton from 'src/components/buttons/AddButton'
import {useQueryStateSimpleParam} from 'src/hooks/useQueryState'
import ClientDetailsQuittanceTabs from './ClientDetailsQuittanceTab'
import ClientDetailsCompteTab from './ClientDetailsCompteTab'
import {useClient} from 'src/hooks/api-hooks'
import ClientDetailsDocuments from './ClientDetailsDocuments'
import {useState} from 'react'
import AddDocumentFilesDialog from '../../../components/dialogs/AddDocumentFilesDialog'
import {Engineering, UploadFile} from '@mui/icons-material'
import FusionDialog from '../../../components/FusionDialog'
import ClientDetailsContratTab from './ClientDetailsContratTab'
import useAuth from '../../../hooks/useAuth'

const tabs = [
  {label: 'Identité', value: 'infos'},
  {label: 'Contrats', value: 'contrats'},
  {label: 'Compte', value: 'compte'},
  {label: 'Quittances', value: 'quittances'},
  // {label: 'Sinistres', value: 'sinistres'},
]

const ClientDetails = () => {
  const {id} = useParams()
  const {isLoading, data: client, isFetching} = useClient(id)
  const [currentDialog, setCurrentDialog] = useState()
  const {user} = useAuth()

  const filteredTabs = user.has_geid_access
    ? [...tabs, {label: 'Documents', value: 'documents'}]
    : tabs

  const rightActions = () => {
    if (currentTab === 'infos') {
      return (
        <>
          {user.has_fusion_access && (
            <Button
              onClick={() => setCurrentDialog('fusion')}
              startIcon={<Engineering />}
              variant={'contained'}
              style={{marginRight: 10}}
            >
              Créer une fusion
            </Button>
          )}
          <FusionDialog
            open={currentDialog === 'fusion'}
            onCancel={() => setCurrentDialog(undefined)}
            type={'Client'}
            client={client}
            initialVariables={{client: client?.id}}
          />
          <EditButton to={`/clients/${id}/edit`}>Modifier</EditButton>
        </>
      )
    }

    if (currentTab === 'contrats') {
      return (
        <>
          <AddButton to={`/clients/${id}/create-contrat`}>
            Ajouter contrat
          </AddButton>
          <AddButton to={`/clients/${id}/create-devis`}>
            Ajouter un devis
          </AddButton>
        </>
      )
    }

    if (currentTab === 'documents') {
      return (
        <>
          {user.has_fusion_access && (
            <Button
              onClick={() => setCurrentDialog('fusion')}
              startIcon={<Engineering />}
              variant={'contained'}
              style={{marginRight: 10}}
            >
              Créer une fusion
            </Button>
          )}
          <FusionDialog
            open={currentDialog === 'fusion'}
            onCancel={() => setCurrentDialog(undefined)}
            type={'Client'}
            client={client}
            initialVariables={{client: client?.id}}
          />
          <Button
            startIcon={<UploadFile />}
            variant={'contained'}
            onClick={() => setCurrentDialog('add-document')}
          >
            Ajouter un document
          </Button>
          {currentDialog === 'add-document' && Boolean(client) ? (
            <AddDocumentFilesDialog
              handleClose={() => setCurrentDialog(undefined)}
              folderId={client.folder_id}
            />
          ) : undefined}
        </>
      )
    }
  }

  const [currentTab, setCurrentTab] = useQueryStateSimpleParam(
    'currentTab',
    filteredTabs[0].value,
  )

  const title = client
    ? `Fiche client de ${client.description}`
    : "Consultation d'une fiche client"

  const inlineModificationDisabled = isFetching

  return (
    <PageTemplate
      title={title}
      sections={[
        {title: 'Clients', path: '/clients'},
        {title: 'Consultation', current: true},
      ]}
      rightActions={rightActions()}
      data-testid="page-clients-details"
    >
      <Box>
        <Tabs
          indicatorColor="primary"
          onChange={(event, value) => {
            setCurrentTab(value)
          }}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {filteredTabs.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={isLoading}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      {Boolean(isLoading) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: 300}}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{mt: 3}}>
          {Boolean(client?.important) ? (
            <Alert severity="error" sx={{mb: 2}}>
              <AlertTitle>Important</AlertTitle>
              {client?.important}
            </Alert>
          ) : (
            ''
          )}
          {Boolean(client?.commentaire) ? (
            <Alert severity="success" sx={{mb: 2}}>
              <AlertTitle>Commentaire</AlertTitle>
              {client?.commentaire}
            </Alert>
          ) : (
            ''
          )}
          {currentTab === 'infos' && (
            <ClientDetailsInfo
              client={client}
              modificationDisabled={inlineModificationDisabled}
            />
          )}
          {currentTab === 'contrats' && (
            <ClientDetailsContratTab client={client} />
          )}
          {currentTab === 'quittances' && Boolean(client) && (
            <ClientDetailsQuittanceTabs client={client} />
          )}
          {currentTab === 'compte' && Boolean(client) && (
            <ClientDetailsCompteTab client={client} />
          )}

          {currentTab === 'documents' && (
            <ClientDetailsDocuments client={client} />
          )}
        </Box>
      )}
    </PageTemplate>
  )
}

export default ClientDetails
