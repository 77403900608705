const services = [
  {
    id: 1,
    url: 'http://localhost:8000/services/1/',
    name: 'Production',
  },
  {
    id: 2,
    url: 'http://localhost:8000/services/2/',
    name: 'Comptabilité',
  },
  {
    id: 3,
    url: 'http://localhost:8000/services/3/',
    name: 'Comptabilité Auxiliaire',
  },
  {
    id: 4,
    url: 'http://localhost:8000/services/4/',
    name: 'Sinistres',
  },
  {
    id: 5,
    url: 'http://localhost:8000/services/5/',
    name: 'Réclamations',
  },
]

export {services}
