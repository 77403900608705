import {Controller} from 'react-hook-form'
import ComptesComptableInput from '../inputs/ComptesComptableInput'

export default function ComptesComptableInputControlled({
  control,
  name = 'compte_comptable',
  defaultValue = null,
  error,
  helperText,
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {value, onChange}}) => (
        <ComptesComptableInput
          onChange={(event, item) => {
            onChange(item)
          }}
          value={value}
          margin="dense"
          size="small"
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
