import {DesktopDatePicker} from '@mui/lab'
import TextInput from './TextInput'

export default function DayMonthInput({
  label,
  inputFormat = 'dd/MM',
  error,
  helperText,
  ...props
}) {
  return (
    <DesktopDatePicker
      views={['day', 'month']}
      label={label}
      inputFormat={inputFormat}
      renderInput={params => (
        <TextInput {...params} error={error} helperText={helperText} />
      )}
      {...props}
    />
  )
}
