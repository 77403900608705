import {useSortBy, useTable} from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import {useMemo} from 'react'

// TODO UniversalTable should replace StandardTable
export default function StandardTable({columns, items, ordering, setOrdering}) {
  const data = useMemo(() => {
    return items ?? []
  }, [items])

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        columns,
        data,
        manualSortBy: true,
      },
      useSortBy,
    )

  const sortHandler = column => {
    const key = column.id
    if (key === ordering) {
      setOrdering('-' + key)
    } else {
      setOrdering(key)
    }
  }
  return (
    <Table {...getTableProps()} size="small">
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column =>
              column.canSort ? (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                    onClick={() => sortHandler(column)}
                  >
                    {column.render('Header')}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                </TableCell>
              ),
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <TableCell {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
