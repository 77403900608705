import * as clients from './jsons/clients'
import {agences} from './jsons/agences'
import {services} from './jsons/services'
import {fonctions} from './jsons/fonctions'
import {produits} from './jsons/produits'
import {liaisons} from './jsons/liaisons'
import {motifsResiliations} from './jsons/motifs-resiliation'
import {banques} from './jsons/banques'
import {banquesRemise} from './jsons/banques-remise'
import {etatsPrecontentieux} from './jsons/etats-precontentieux'
import {typesAvenant} from './jsons/types-avenant'
import {typesReglement} from './jsons/types-reglement'
import {quittances} from './jsons/quittances'
import {ecritures} from './jsons/ecritures'

const allJsons = {
  banques,
  banquesRemise,
  etatsPrecontentieux,
  typesAvenant,
  typesReglement,
  quittances,
  ecritures,
  me: {
    id: 1,
    lastname: 'Tout puissant',
    firstname: 'Bruce',
    date_joined: '2021-03-26T21:03:20.635624Z',
    email: 'admin@admin.com',
    is_active: true,
    is_staff: true,
    is_superuser: true,
    agence: {
      id: 1,
      name: 'Saint-Denis',
      zipcode: '97400',
      cedex: 'cedex',
      ville: 1,
    },
    fonction: null,
    service: null,
    url: 'http://localhost:8000/users/1/',
    agence_id: 1,
    fonction_id: null,
    service_id: null,
  },
  produits,
  agences,
  fonctions,
  services,
  /*
  ██╗   ██╗███████╗███████╗██████╗ ███████╗
  ██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
  ██║   ██║███████╗█████╗  ██████╔╝███████╗
  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
  ╚██████╔╝███████║███████╗██║  ██║███████║
   ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝
  */
  usersEdit: {
    id: 1,
    email: 'test@test.com',
    firstname: 'Alain',
    lastname: 'Dupond',
    agence_id: '1',
    service_id: '2',
    fonction_id: '3',
    is_active: true,
    is_staff: true,
    is_superuser: true,
  },
  usersList: {
    count: 23,
    next: null,
    previous: null,
    results: [
      {
        id: 2,
        lastname: 'Dumas',
        firstname: 'Josette',
        date_joined: '2021-05-31T12:29:29.927188Z',
        email: 'josette.dumas7308@example.org',
        is_active: true,
        is_staff: false,
        is_superuser: false,
        agence: 1,
        fonction: 1,
        service: 1,
        url: 'http://localhost:8000/users/2/',
        agence_id: 1,
        fonction_id: 1,
        service_id: 1,
      },
      {
        id: 3,
        lastname: 'Dupuy',
        firstname: 'Yves',
        date_joined: '2021-05-31T12:29:29.933037Z',
        email: 'yves.dupuy8186@example.org',
        is_active: true,
        is_staff: false,
        is_superuser: false,
        agence: 2,
        fonction: 2,
        service: 2,
        url: 'http://localhost:8000/users/3/',
        agence_id: 2,
        fonction_id: 2,
        service_id: 2,
      },
      {
        id: 4,
        lastname: 'Poirier',
        firstname: 'Guillaume',
        date_joined: '2021-05-31T12:29:29.936438Z',
        email: 'guillaume.poirier2720@example.org',
        is_active: true,
        is_staff: false,
        is_superuser: false,
        agence: 3,
        fonction: 3,
        service: 3,
        url: 'http://localhost:8000/users/4/',
        agence_id: 3,
        fonction_id: 3,
        service_id: 3,
      },
      {
        id: 5,
        lastname: 'Lemaitre',
        firstname: 'Julien',
        date_joined: '2021-05-31T12:29:29.940939Z',
        email: 'julien.lemaitre4795@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 4,
        fonction: 4,
        service: 4,
        url: 'http://localhost:8000/users/5/',
        agence_id: 4,
        fonction_id: 4,
        service_id: 4,
      },
      {
        id: 6,
        lastname: 'Bernard',
        firstname: 'Valentine',
        date_joined: '2021-05-31T12:29:29.944735Z',
        email: 'valentine.bernard5364@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 1,
        fonction: 5,
        service: 5,
        url: 'http://localhost:8000/users/6/',
        agence_id: 1,
        fonction_id: 5,
        service_id: 5,
      },
      {
        id: 7,
        lastname: 'Gaudin',
        firstname: 'Louise',
        date_joined: '2021-05-31T12:29:29.948937Z',
        email: 'louise.gaudin843@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 2,
        fonction: 6,
        service: 1,
        url: 'http://localhost:8000/users/7/',
        agence_id: 2,
        fonction_id: 6,
        service_id: 1,
      },
      {
        id: 8,
        lastname: 'Roger',
        firstname: 'Élisabeth',
        date_joined: '2021-05-31T12:29:29.953066Z',
        email: 'élisabeth.roger1234@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 3,
        fonction: 7,
        service: 2,
        url: 'http://localhost:8000/users/8/',
        agence_id: 3,
        fonction_id: 7,
        service_id: 2,
      },
      {
        id: 9,
        lastname: 'Goncalves',
        firstname: 'Augustin',
        date_joined: '2021-05-31T12:29:29.957709Z',
        email: 'augustin.goncalves989@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 4,
        fonction: 1,
        service: 3,
        url: 'http://localhost:8000/users/9/',
        agence_id: 4,
        fonction_id: 1,
        service_id: 3,
      },
      {
        id: 10,
        lastname: 'Barthelemy',
        firstname: 'Maurice',
        date_joined: '2021-05-31T12:29:29.961342Z',
        email: 'maurice.barthelemy2814@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 1,
        fonction: 2,
        service: 4,
        url: 'http://localhost:8000/users/10/',
        agence_id: 1,
        fonction_id: 2,
        service_id: 4,
      },
      {
        id: 11,
        lastname: 'Millet',
        firstname: 'Caroline',
        date_joined: '2021-05-31T12:29:29.965010Z',
        email: 'caroline.millet641@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 2,
        fonction: 3,
        service: 5,
        url: 'http://localhost:8000/users/11/',
        agence_id: 2,
        fonction_id: 3,
        service_id: 5,
      },
      {
        id: 12,
        lastname: 'Fleury',
        firstname: 'Lucas',
        date_joined: '2021-05-31T12:29:29.968377Z',
        email: 'lucas.fleury270@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 3,
        fonction: 4,
        service: 1,
        url: 'http://localhost:8000/users/12/',
        agence_id: 3,
        fonction_id: 4,
        service_id: 1,
      },
      {
        id: 13,
        lastname: 'Barthelemy',
        firstname: 'Zacharie',
        date_joined: '2021-05-31T12:29:29.971701Z',
        email: 'zacharie.barthelemy157@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 4,
        fonction: 5,
        service: 2,
        url: 'http://localhost:8000/users/13/',
        agence_id: 4,
        fonction_id: 5,
        service_id: 2,
      },
      {
        id: 14,
        lastname: 'Hamon',
        firstname: 'Étienne',
        date_joined: '2021-05-31T12:29:29.975592Z',
        email: 'étienne.hamon6302@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 1,
        fonction: 6,
        service: 3,
        url: 'http://localhost:8000/users/14/',
        agence_id: 1,
        fonction_id: 6,
        service_id: 3,
      },
      {
        id: 15,
        lastname: 'Bodin',
        firstname: 'Françoise',
        date_joined: '2021-05-31T12:29:29.978901Z',
        email: 'françoise.bodin2789@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 2,
        fonction: 7,
        service: 4,
        url: 'http://localhost:8000/users/15/',
        agence_id: 2,
        fonction_id: 7,
        service_id: 4,
      },
      {
        id: 16,
        lastname: 'Leroux',
        firstname: 'Marine',
        date_joined: '2021-05-31T12:29:29.981900Z',
        email: 'marine.leroux1963@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 3,
        fonction: 1,
        service: 5,
        url: 'http://localhost:8000/users/16/',
        agence_id: 3,
        fonction_id: 1,
        service_id: 5,
      },
      {
        id: 17,
        lastname: 'Riou',
        firstname: 'Alex',
        date_joined: '2021-05-31T12:29:29.985359Z',
        email: 'alex.riou7403@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 4,
        fonction: 2,
        service: 1,
        url: 'http://localhost:8000/users/17/',
        agence_id: 4,
        fonction_id: 2,
        service_id: 1,
      },
      {
        id: 18,
        lastname: 'Monnier',
        firstname: 'Noël',
        date_joined: '2021-05-31T12:29:29.988295Z',
        email: 'noël.monnier1629@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 1,
        fonction: 3,
        service: 2,
        url: 'http://localhost:8000/users/18/',
        agence_id: 1,
        fonction_id: 3,
        service_id: 2,
      },
      {
        id: 19,
        lastname: 'Godard',
        firstname: 'Benoît',
        date_joined: '2021-05-31T12:29:29.991291Z',
        email: 'benoît.godard1396@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: false,
        agence: 2,
        fonction: 4,
        service: 3,
        url: 'http://localhost:8000/users/19/',
        agence_id: 2,
        fonction_id: 4,
        service_id: 3,
      },
      {
        id: 20,
        lastname: 'Martineau',
        firstname: 'Suzanne',
        date_joined: '2021-05-31T12:29:29.994868Z',
        email: 'suzanne.martineau5999@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: true,
        agence: 3,
        fonction: 5,
        service: 4,
        url: 'http://localhost:8000/users/20/',
        agence_id: 3,
        fonction_id: 5,
        service_id: 4,
      },
      {
        id: 21,
        lastname: 'Vallet',
        firstname: 'Valentine',
        date_joined: '2021-05-31T12:29:29.997877Z',
        email: 'valentine.vallet1820@example.org',
        is_active: true,
        is_staff: true,
        is_superuser: true,
        agence: 4,
        fonction: 6,
        service: 5,
        url: 'http://localhost:8000/users/21/',
        agence_id: 4,
        fonction_id: 6,
        service_id: 5,
      },
      {
        id: 22,
        lastname: 'David',
        firstname: 'Anaïs',
        date_joined: '2021-05-31T12:29:30.000685Z',
        email: 'anaïs.david2555@example.org',
        is_active: false,
        is_staff: true,
        is_superuser: false,
        agence: 1,
        fonction: 7,
        service: 1,
        url: 'http://localhost:8000/users/22/',
        agence_id: 1,
        fonction_id: 7,
        service_id: 1,
      },
      {
        id: 23,
        lastname: 'Roux',
        firstname: 'Frédéric',
        date_joined: '2021-05-31T12:29:30.003594Z',
        email: 'frédéric.roux7897@example.org',
        is_active: false,
        is_staff: true,
        is_superuser: false,
        agence: 2,
        fonction: 1,
        service: 2,
        url: 'http://localhost:8000/users/23/',
        agence_id: 2,
        fonction_id: 1,
        service_id: 2,
      },
      {
        id: 1,
        lastname: 'Tout puissant',
        firstname: 'Bruce',
        date_joined: '2021-05-31T12:29:29.589109Z',
        email: 'admin@admin.com',
        is_active: true,
        is_staff: true,
        is_superuser: true,
        agence: 1,
        fonction: null,
        service: null,
        url: 'http://localhost:8000/users/1/',
        agence_id: 1,
        fonction_id: null,
        service_id: null,
      },
    ],
  },
  usersDetails: {
    id: 1,
    lastname: 'Boutin',
    firstname: 'Thibaut',
    date_joined: '2021-03-26T21:15:41.654127Z',
    email: 'thibaut.boutin7359@example.org',
    is_active: true,
    is_staff: false,
    is_superuser: false,
    agence: {
      id: 1,
      name: 'Saint-Denis',
      zipcode: '97400',
      cedex: 'cedex',
      ville: 1,
    },
    fonction: {
      id: 1,
      name: 'Chargée de clientèle',
    },
    service: {
      id: 1,
      name: 'Production',
    },
    url: 'http://localhost:8000/users/4/',
    agence_id: 1,
    fonction_id: 1,
    service_id: 1,
  },
  ...clients,

  contratsList: {
    count: 8,
    next: null,
    previous: null,
    results: [
      {
        id: 1,
        url: 'http://localhost:8000/users/1/',
        client_id: 1,
        produit_id: 1,
        agence_id: 1,
        reagi_id: '123',
        etat: 'En cours',
        date_effet_contrat: '31/08/1985',
        echeance_principale: '12/02',
        prime_type: 'Confié',
        prime_periodicite: 'Annuelle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 1,
          type: 'Personne physique',
          civilite: 'Monsieur',
          nom: 'BONHEUR',
          prenom: 'Gontrand',
          societe: 'AUSTRAL INTERNATIONAL',
          reagi_id: '123',
          date_naissance: '1985-08-31',
          nom_naissance: 'Gontrand BONHEUR',
          adresse_principale_1: '10 RUE DE LA MATERNITE',
          adresse_principale_2: 'appartement 14',
          adresse_principale_3: '',
          adresse_principale_code_postal: '97490',
          adresse_principale_ville: 'Sainte Clotilde',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: 'TRES IMPORTANT',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 1,
          code: '4A10',
          nom: 'Automobile',
          type: 'Automobile',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 2,
        url: 'http://localhost:8000/users/2/',
        client_id: 1,
        produit_id: 2,
        agence_id: 1,
        reagi_id: '123456',
        etat: 'En cours',
        date_effet_contrat: '31/08/1985',
        echeance_principale: '12/12',
        prime_type: 'Confié',
        prime_periodicite: 'Annuelle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 1,
          type: 'Personne physique',
          civilite: 'Monsieur',
          nom: 'BONHEUR',
          prenom: 'Gontrand',
          societe: 'AUSTRAL INTERNATIONAL',
          reagi_id: '123',
          date_naissance: '1985-08-31',
          nom_naissance: 'Gontrand BONHEUR',
          adresse_principale_1: '10 RUE DE LA MATERNITE',
          adresse_principale_2: 'appartement 14',
          adresse_principale_3: '',
          adresse_principale_code_postal: '97490',
          adresse_principale_ville: 'Sainte Clotilde',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: 'TRES IMPORTANT',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 2,
          code: '4Z12',
          nom: 'Véhicules de plus de 125 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 3,
        url: 'http://localhost:8000/users/3/',
        client_id: 1,
        produit_id: 1,
        agence_id: 1,
        reagi_id: 'adsffsdv',
        etat: 'En cours',
        date_effet_contrat: '31/08/1985',
        echeance_principale: '12/02',
        prime_type: 'Non confié',
        prime_periodicite: 'Annuelle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 1,
          type: 'Personne physique',
          civilite: 'Monsieur',
          nom: 'BONHEUR',
          prenom: 'Gontrand',
          societe: 'AUSTRAL INTERNATIONAL',
          reagi_id: '123',
          date_naissance: '1985-08-31',
          nom_naissance: 'Gontrand BONHEUR',
          adresse_principale_1: '10 RUE DE LA MATERNITE',
          adresse_principale_2: 'appartement 14',
          adresse_principale_3: '',
          adresse_principale_code_postal: '97490',
          adresse_principale_ville: 'Sainte Clotilde',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: 'TRES IMPORTANT',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 1,
          code: '4A10',
          nom: 'Automobile',
          type: 'Automobile',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 4,
        url: 'http://localhost:8000/users/4/',
        client_id: 2,
        produit_id: 2,
        agence_id: 1,
        reagi_id: 'asfsdasdas',
        etat: 'Suspendu',
        date_effet_contrat: '12/12/2023',
        echeance_principale: '12/12',
        prime_type: 'Confié',
        prime_periodicite: 'Trimestrielle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 2,
          type: 'Personne physique',
          civilite: 'Madame',
          nom: 'Boulay',
          prenom: 'Danielle',
          societe: '',
          reagi_id: 'A 000000',
          date_naissance: null,
          nom_naissance: '',
          adresse_principale_1: '',
          adresse_principale_2: '',
          adresse_principale_3: '',
          adresse_principale_code_postal: '',
          adresse_principale_ville: '',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: '',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 2,
          code: '4Z12',
          nom: 'Véhicules de plus de 125 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 5,
        url: 'http://localhost:8000/users/5/',
        client_id: 2,
        produit_id: 5,
        agence_id: 1,
        reagi_id: '1423rwet',
        etat: 'En cours',
        date_effet_contrat: '12/12/2024',
        echeance_principale: '12/12',
        prime_type: 'Confié',
        prime_periodicite: 'Mensuelle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 2,
          type: 'Personne physique',
          civilite: 'Madame',
          nom: 'Boulay',
          prenom: 'Danielle',
          societe: '',
          reagi_id: 'A 000000',
          date_naissance: null,
          nom_naissance: '',
          adresse_principale_1: '',
          adresse_principale_2: '',
          adresse_principale_3: '',
          adresse_principale_code_postal: '',
          adresse_principale_ville: '',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: '',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 5,
          code: '4Z10',
          nom: 'Véhicules de moins de 50 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 6,
        url: 'http://localhost:8000/users/6/',
        client_id: 2,
        produit_id: 2,
        agence_id: 1,
        reagi_id: '123213',
        etat: 'En cours',
        date_effet_contrat: '12/12/2023',
        echeance_principale: '12/12',
        prime_type: 'Non confié',
        prime_periodicite: 'Semestrielle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 2,
          type: 'Personne physique',
          civilite: 'Madame',
          nom: 'Boulay',
          prenom: 'Danielle',
          societe: '',
          reagi_id: 'A 000000',
          date_naissance: null,
          nom_naissance: '',
          adresse_principale_1: '',
          adresse_principale_2: '',
          adresse_principale_3: '',
          adresse_principale_code_postal: '',
          adresse_principale_ville: '',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: '',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 2,
          code: '4Z12',
          nom: 'Véhicules de plus de 125 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 7,
        url: 'http://localhost:8000/users/7/',
        client_id: 2,
        produit_id: 2,
        agence_id: 1,
        reagi_id: '12321312',
        etat: 'En cours',
        date_effet_contrat: '12/12/2023',
        echeance_principale: '12/12',
        prime_type: 'Non confié',
        prime_periodicite: 'Semestrielle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 2,
          type: 'Personne physique',
          civilite: 'Madame',
          nom: 'Boulay',
          prenom: 'Danielle',
          societe: '',
          reagi_id: 'A 000000',
          date_naissance: null,
          nom_naissance: '',
          adresse_principale_1: '',
          adresse_principale_2: '',
          adresse_principale_3: '',
          adresse_principale_code_postal: '',
          adresse_principale_ville: '',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: '',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 2,
          code: '4Z12',
          nom: 'Véhicules de plus de 125 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
      {
        id: 8,
        url: 'http://localhost:8000/users/8/',
        client_id: 2,
        produit_id: 2,
        agence_id: 1,
        reagi_id: '123213121',
        etat: 'En cours',
        date_effet_contrat: '12/12/2023',
        echeance_principale: '12/12',
        prime_type: 'Non confié',
        prime_periodicite: 'Semestrielle',
        titre: '',
        date_expiration_prevue: null,
        duree_ferme: false,
        descriptif: '',
        client: {
          id: 2,
          type: 'Personne physique',
          civilite: 'Madame',
          nom: 'Boulay',
          prenom: 'Danielle',
          societe: '',
          reagi_id: 'A 000000',
          date_naissance: null,
          nom_naissance: '',
          adresse_principale_1: '',
          adresse_principale_2: '',
          adresse_principale_3: '',
          adresse_principale_code_postal: '',
          adresse_principale_ville: '',
          adresse_principale_pays: '',
          adresse_secondaire_1: '',
          adresse_secondaire_2: '',
          adresse_secondaire_3: '',
          adresse_secondaire_code_postal: '',
          adresse_secondaire_ville: '',
          adresse_secondaire_pays: '',
          telephone_fixe: '',
          telephone_mobile: '',
          telephone_bureau: '',
          commentaire: '',
          important: '',
        },
        agence: {
          id: 1,
          name: 'Saint-Denis',
          zipcode: '97400',
          cedex: 'cedex',
          ville: 1,
        },
        produit: {
          id: 2,
          code: '4Z12',
          nom: 'Véhicules de plus de 125 cc',
          type: '2 roues',
          compagnie: 'Prudence Créole',
          contrat_a_aliments: false,
        },
        cgu: null,
      },
    ],
  },
  contratDetails: {
    id: 1,
    url: 'http://localhost:8000/users/1/',
    client_id: 1,
    produit_id: 1,
    agence_id: 1,
    reagi_id: '123',
    etat: 'En cours',
    date_effet_contrat: '31/08/1985',
    echeance_principale: '12/02',
    prime_type: 'Confié',
    prime_periodicite: 'Annuelle',
    titre: 'Titre du contrat',
    date_expiration_prevue: '01/01/2025',
    duree_ferme: true,
    descriptif: 'descriptif du contrat',
    client: {
      id: 1,
      type: 'Personne physique',
      civilite: 'Monsieur',
      nom: 'DUFLOTaaaaaa',
      prenom: 'Sylvain',
      societe: 'AUSTRAL FOOD INTERNATIONAL',
      reagi_id: '123',
      date_naissance: '1985-08-31',
      nom_naissance: 'Sylvain DUFLOT',
      adresse_principale_1: '10 RUE DE LA FRATERNITE',
      adresse_principale_2: 'appartement 14',
      adresse_principale_3: '',
      adresse_principale_code_postal: '97490',
      adresse_principale_ville: 'Sainte Clotilde',
      adresse_principale_pays: '',
      adresse_secondaire_1: '',
      adresse_secondaire_2: '',
      adresse_secondaire_3: '',
      adresse_secondaire_code_postal: '',
      adresse_secondaire_ville: '',
      adresse_secondaire_pays: '',
      telephone_fixe: '',
      telephone_mobile: '',
      telephone_bureau: '',
      commentaire: '',
      important: 'TRES IMPORTANT',
    },
    agence: {
      id: 1,
      name: 'Saint-Denis',
      zipcode: '97400',
      cedex: 'cedex',
      ville: 1,
    },
    produit: {
      id: 1,
      code: '4A10',
      nom: 'Automobile',
      type: 'Automobile',
      compagnie: 'Prudence Créole',
      contrat_a_aliments: false,
    },
    cgu: 'titre des cgus',
  },
  liaisons,
  motifsResiliations,
}

export default allJsons
