import PageTemplate from 'src/components/templates/PageTemplate'
import ContratForm from 'src/components/forms/ContratForm'
import {errorResponseToJson} from 'src/services/crm-api'
import {useNavigate, useParams} from 'react-router'
import {useContrat, useEditContrat} from '../../hooks/api-hooks'
import LoadingCard from '../../components/LoadingCard'

const ContratEdit = ({devis = false, hook = useContrat}) => {
  const {id} = useParams()
  const {isLoading, isIdle, data: contrat, error} = hook(id)

  const {
    isLoading: updateLoading,
    isIdle: updateIdle,
    error: updateError,
    mutate,
  } = useEditContrat(id)

  const navigate = useNavigate()
  const errors = errorResponseToJson(updateError)

  const handleSubmit = values => {
    mutate(values, {
      onSuccess: data => {
        data.etat == 'Devis'
          ? navigate(`/devis/${id}`)
          : navigate(`/contrats/${id}`)
      }, // Redirect good place
    })
  }

  return (
    <PageTemplate
      title={`Modifier un ${devis ? 'devis' : 'contrat'}`}
      sections={[
        {title: 'Contrats', path: '/contrats'},
        {title: `Modifier un ${devis ? 'devis' : 'contrat'}`, current: true},
      ]}
      data-testid="page-contrats-edit"
    >
      {!!error ? `Une erreur est survenue ${error}` : ''}
      {isLoading ? (
        <LoadingCard />
      ) : (
        <ContratForm
          submitLabel="Modifier"
          errors={errors}
          onSubmit={handleSubmit}
          loading={isLoading}
          contrat={contrat}
          clientId={contrat.client_id}
        />
      )}
    </PageTemplate>
  )
}

export default ContratEdit
