import {Fragment, useState, useEffect} from 'react'
import {CircularProgress, TextField} from '@mui/material'

import {Autocomplete} from 'src/components/Form'
import {searchClients} from 'src/services/crm-api'

const ClientAutocomplete = ({name, defaultValue, ...props}) => {
  const [items, setItems] = useState([])
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let active = true
    if (query === '') {
      return setLoading(false)
    }

    setLoading(true)
    const fetchData = async () => {
      const clients = await searchClients(query)
      if (active) {
        setItems(
          clients.results?.map(x => {
            const result = []
            result.push(x.reagi_id)
            result.push(
              x.type === 'Personne morale'
                ? x.denomination_sociale
                : `${x.civilite} ${x.nom} ${x.prenom}`,
            )
            if (x.date_naissance) {
              result.push(x.date_naissance)
            }
            return {text: result.join(' - '), value: x.id}
          }) ?? [],
        )
        setLoading(false)
      }
    }
    fetchData()
    return () => {
      active = false
    }
  }, [query])

  return (
    <Autocomplete
      autoFit={true}
      name={name}
      loading={loading}
      items={
        items.length === 0 && Boolean(defaultValue) ? [defaultValue] : items
      }
      defaultValue={defaultValue?.value ?? defaultValue}
      onInputChange={event => {
        setQuery(event?.target?.value || '')
      }}
      filterOptions={(items, state) => items}
      InputProps={{
        endAdornment: (
          <Fragment>
            {loading ? (
              <CircularProgress color="inherit" size={20} sx={{mr: 0}} />
            ) : null}
            {/* Suppress endAdorment on small */}
            {/* {params.InputProps.endAdornment}*/}
          </Fragment>
        ),
      }}
      {...props}
    />
  )
}

export default ClientAutocomplete
