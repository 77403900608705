import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {useMarquesAuto} from 'src/hooks/api-hooks'

export default function MarquesAutoInput({
  label = 'Marque auto',
  error,
  helperText,
  margin = 'dense',
  size = 'small',
  ...props
}) {
  const {
    isLoading: loading,
    data: marques,
    isError: loadingError,
    error: errorException,
  } = useMarquesAuto()

  const options = marques?.map(x => x.nom) ?? []

  return (
    <Autocomplete
      options={options}
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorException.message : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
