import {TextField} from '@mui/material'

export default function TextInput({
  error,
  helperText,
  label,
  inputRef,
  ...props
}) {
  return (
    <TextField
      error={error}
      helperText={helperText}
      margin="dense"
      size="small"
      fullWidth
      inputRef={inputRef}
      label={label}
      {...props}
    />
  )
}
