import {Controller} from 'react-hook-form'
import ClientAutocompleteInput from '../inputs/ClientAutocompleteInput'
import LiaisonsInput from '../inputs/LiaisonsInput'

export default function LiaisonsInputControlled({
  control,
  name = 'liaison_client',
  label = 'Type de liaisons',
  error,
  helperText,
  defaultValue = '',
  ...props
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {value, onChange}}) => (
        <LiaisonsInput
          onChange={(event, item) => {
            onChange(item)
          }}
          label={label}
          value={value}
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
