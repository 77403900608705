import PageTemplate from 'src/components/templates/PageTemplate'
import {useLocation, useNavigate} from 'react-router'
import {useCreateRemiseEnBanque, useReglements} from 'src/hooks/api-hooks'

import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import AgencesInputControlled from 'src/components/controlled/AgencesInputControlled'
import BanquesRemiseInputControlled from 'src/components/controlled/BanquesRemiseInputControlled'
import {LoadingButton} from '@mui/lab'
import {useState} from 'react'
import DatePickerControlled from 'src/components/controlled/DatePickerInputControlled'
import moment from 'moment'
import {
  Card,
  CardContent,
  Grid,
  Hidden,
  Paper,
  TableContainer,
  Typography,
} from '@mui/material'
import ChequesTable from './ChequesTable'
import EspecesTable from './EspecesTable'
import TypesReglementInputControlled from 'src/components/controlled/TypesReglementInputControlled'
import {Box} from '@mui/system'
import DeviseLabel from 'src/components/DeviseLabel'
import TextInputControlled from 'src/components/controlled/TextInputControlled'
import DeviseInputControlled from 'src/components/controlled/DeviseInputControlled'
import DeviseInput from 'src/components/inputs/DeviseInput'
import useAuth from 'src/hooks/useAuth'
import {errorResponseToJson} from '../../services/crm-api'

const schemaObj = {
  agence: yup.mixed().label('Agence').required(),
  banque_remise: yup.mixed().label('Banque de Remise').required(),
  type_reglement__nom: yup.mixed().label("Type d'encaissement").required(),
}

const schema = yup.object().shape(schemaObj)

const especesSchema = yup.object().shape({
  montant_billets: yup
    .number()
    .required()
    .label('Montant billets')
    .min(0)
    .test(
      'is-modulo-5',
      'Montant billets doit être un multiple de 5',
      value => value % 5 === 0,
    )
    .when('total_credit', (total_credit, schema) =>
      schema.max(
        total_credit,
        'Montant des billets ne doit pas être supérieur au total',
      ),
    )
    .transform(value => (isNaN(value) ? undefined : value)),
  // bordereau_billets: yup.string().required().label('Bordereau billets'),
  // bordereau_pieces: yup.string().required().label('Bordereau pièces'),
})

const chequeSchema = yup.object().shape({})

export default function RemiseEnBanqueCreate() {
  const navigate = useNavigate()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const defaultType = searchParams.get('type')

  const {user} = useAuth()

  const {
    control,
    register,
    handleSubmit,
    formState: {errors: formErrors},
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [params, setParams] = useState()
  const [selectedReglements, setSelectedReglements] = useState([])

  const isCheque =
    params?.type_reglement__nom === 'Chèque' || defaultType === 'Chèque'
  const isEspeces = !isCheque

  const {
    control: especesControl,
    handleSubmit: especesHandleSubmit,
    formState: {errors: especesErrors},
    setValue: especesSetValue,
    register: especesRegister,
    watch: especesWatch,
  } = useForm({
    resolver: yupResolver(isCheque ? chequeSchema : especesSchema),
  })

  const {isLoading, data: reglements} = useReglements(
    {...params},
    {enabled: !!params},
  )
  const isInitiated = reglements !== undefined

  const {
    mutate,
    isLoading: creationIsLoading,
    error: creationError,
  } = useCreateRemiseEnBanque()

  const creationErrors = {
    ...especesErrors,
    ...errorResponseToJson(creationError),
  }

  const nombreDeReglements = selectedReglements.length
  const totalCredit = selectedReglements.reduce(
    (acc, current) => acc + parseFloat(current.montant),
    0,
  )
  especesSetValue('total_credit', totalCredit)

  const createRemiseEnBanque = (moreParams = {}) => {
    const body = {
      reglements_ids: selectedReglements.map(x => x.id),
      date: moment().format('DD/MM/YYYY'),
      ...moreParams,
    }
    delete body.total_credit
    mutate(body, {
      onSuccess: results => {
        isCheque
          ? navigate(`/remises-en-banque/cheque`)
          : navigate(`/caisses/create?agence=${results.agence.id}`)
      },
    })
  }

  return (
    <PageTemplate
      title={
        defaultType
          ? isCheque
            ? 'Remettre des chèques'
            : 'Remettre des espèces'
          : 'Faire une remise en banque'
      }
      sections={[
        {title: 'Remise en banque', current: false, path: '/remises-en-banque'},
        {title: 'Création', current: true},
      ]}
    >
      <form
        style={{marginBottom: '20px'}}
        onSubmit={handleSubmit(values => {
          const newParams = {
            ...values,
            type_reglement__nom:
              values.type_reglement__nom?.nom ?? values.type_reglement__nom,
            agence: values.agence?.id,
            banque_remise: values.banque_remise?.id,
            remis: false,
          }
          if (values.date_start) {
            newParams.date_start = moment(values.date_start).format(
              'DD/MM/YYYY',
            )
          }
          if (values.date_end) {
            newParams.date_end = moment(values.date_end).format('DD/MM/YYYY')
          }
          setSelectedReglements([])
          setParams(newParams)
        })}
      >
        <Card sx={{p: 2}}>
          <Grid container spacing={1}>
            {defaultType ? (
              <input
                type="hidden"
                defaultValue={defaultType}
                {...register('type_reglement__nom')}
                value={defaultType}
              />
            ) : (
              <>
                <Grid item md={6} xs={12}>
                  <TypesReglementInputControlled
                    control={control}
                    name="type_reglement__nom"
                    remise={true}
                    error={!!formErrors.type_reglement__nom}
                    helperText={formErrors.type_reglement__nom?.message}
                  />
                </Grid>
                <Hidden mdDown>
                  <Grid item md={6} />
                </Hidden>
              </>
            )}
            <Grid item md={6} xs={12}>
              <AgencesInputControlled
                defaultValue={user?.agence}
                control={control}
                error={!!formErrors.agence}
                helperText={formErrors.agence?.message}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <BanquesRemiseInputControlled
                defaultFromNetwork={true}
                control={control}
                error={!!formErrors.banque_remise}
                helperText={formErrors.banque_remise?.message}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePickerControlled
                control={control}
                name="date_start"
                label="Règlement après le"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePickerControlled
                control={control}
                name="date_end"
                label="Règlement avant le"
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={2}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{margin: '0 auto'}}
            >
              Afficher les règlements correspondants
            </LoadingButton>
          </Box>
        </Card>
      </form>
      {isInitiated && !isLoading ? (
        reglements.length > 0 ? (
          <form
            onSubmit={especesHandleSubmit(values =>
              createRemiseEnBanque(values),
            )}
          >
            <Grid container spacing={2} sx={{position: 'relative'}}>
              <Grid item md={8} xs={12}>
                <TableContainer component={Paper}>
                  {isCheque && (
                    <ChequesTable
                      reglements={reglements}
                      onSelectionChange={setSelectedReglements}
                    />
                  )}

                  {isEspeces && (
                    <>
                      <input
                        type="hidden"
                        {...especesRegister('total_credit')}
                      />
                      <EspecesTable
                        reglements={reglements}
                        onSelectionChange={setSelectedReglements}
                      />
                    </>
                  )}
                </TableContainer>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                alignSelf="flex-start"
                sx={{position: 'sticky', top: '64px'}}
              >
                <Card>
                  <CardContent>
                    <Grid container spacing={1} mb={2}>
                      <Grid item xs={6}>
                        <Typography
                          textAlign="center"
                          variant="caption"
                          display="block"
                        >
                          Total
                        </Typography>
                        <Typography
                          variant="h3"
                          textAlign="center"
                          display="block"
                        >
                          <DeviseLabel>{totalCredit.toFixed(2)}</DeviseLabel>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          textAlign="center"
                          variant="caption"
                          display="block"
                        >
                          Nombre de {isCheque ? 'chèques' : 'reglements'}
                        </Typography>
                        <Typography
                          variant="h3"
                          textAlign="center"
                          display="block"
                        >
                          {nombreDeReglements}
                        </Typography>
                      </Grid>
                      {isEspeces && (
                        <>
                          <Grid item md={6} xs={12}>
                            <TextInputControlled
                              control={especesControl}
                              name="bordereau_billets"
                              label="Bordereau billets"
                              error={!!creationErrors.bordereau_billets}
                              helperText={
                                creationErrors.bordereau_billets?.message
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <DeviseInputControlled
                              control={especesControl}
                              label="Montant billets"
                              name="montant_billets"
                              margin="dense"
                              size="small"
                              error={!!creationErrors.montant_billets}
                              helperText={
                                creationErrors.montant_billets?.message
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <TextInputControlled
                              control={especesControl}
                              name="bordereau_pieces"
                              label="Bordereau pièces"
                              error={!!creationErrors.bordereau_pieces}
                              helperText={
                                creationErrors?.bordereau_pieces?.message
                              }
                            />
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <DeviseInput
                              label="Montant pièces"
                              margin="dense"
                              size="small"
                              fullWidth
                              readOnly={true}
                              value={
                                totalCredit - especesWatch('montant_billets')
                              }
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      loading={creationIsLoading}
                      type="submit"
                      disabled={nombreDeReglements === 0}
                    >
                      Créer la remise en banque
                    </LoadingButton>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography variant="overline">Aucun résultat</Typography>
          </Box>
        )
      ) : (
        ''
      )}
    </PageTemplate>
  )
}
