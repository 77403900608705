import {Fragment, useEffect, useMemo, useRef, useState} from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Icon,
  IconButton,
  List,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {useExpanded, useTable} from 'react-table'
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  List as ListIcon,
  SyncAlt,
} from '@mui/icons-material'
import {green, orange, red, yellow} from '@mui/material/colors'

import DetteFormDialog from 'src/components/dialogs/DetteFormDialog'
import ReglementFormDialog from 'src/components/dialogs/ReglementFormDialog'
import RemboursementFormDialog from 'src/components/dialogs/RemboursementFormDialog'
import OperationDiverseFormDialog from 'src/components/dialogs/OperationDiverseFormDialog'
import AffectationFormDialog from 'src/components/dialogs/AffectationFormDialog'
import QuittanceFormDialog from 'src/components/dialogs/QuittanceFormDialog'
import ConfirmDialog from 'src/components/ConfirmDialog'
import PerteEtProfitFormDialog from 'src/components/dialogs/PerteEtProfitFormDialog'
import {useDeleteAffectation, useOperation, useOperationsForClient} from 'src/hooks/api-hooks'
import useLocalPagination from '../../../hooks/useLocalPagination'
import useFilters from '../../../hooks/useFilters'
import DatePickerInput from '../../../components/inputs/DatePickerInput'
import moment from 'moment'
import VirementClientFormDialog from '../../../components/dialogs/VirementClientFormDialog'
import AutocompleteInput from '../../../components/inputs/AutocompleteInput'
import PermissionHidder from 'src/components/PermissionHidder'

const ClientDetailsCompteTab = ({client, ...props}) => {
  const {offset, limit, PaginationComponent} = useLocalPagination({})
  const [currentFilterKey, setCurrentFilterKey] = useState('date')
  const {filters, setFilters} = useFilters({})

  const {isLoading, data: operations} = useOperationsForClient(client.id, {
    offset,
    limit,
    ...filters,
  })

  const [dialog, setDialog] = useState()

  const isDetteFormDialog = dialog?.type === 'Dette'
  const isRemboursementormDialog = dialog?.type === 'Remboursement'
  const isVirementClientFormDialog =
    dialog?.type === 'Virement interne envoyé' ||
    dialog?.type === 'Virement interne reçu'
  const isReglementFormDialog = dialog?.type === 'Reglement'
  const isOperationDiverse = dialog?.type === 'Operation diverse'
  const isAffectationDialog = dialog?.type === 'Affectation'
  const isQuittanceFormDialog = dialog?.type === 'Quittance'
  const isRistourneFormDialog = dialog?.type === 'Ristourne'
  const isPerteEtProfit = dialog?.type === 'Perte et profit'

  const handleAdd = operation => {
    setDialog(null)
  }

  const handleCloseOrDeleteDialog = idOrEvent => {
    setDialog(null)
  }

  const handleAffectationButtonClicked = row => {
    setDialog({
      type: 'Affectation',
      args: {
        operation: row,
      },
    })
  }

  return (
    <Box>
      <Box display="flex">
        <Box p={2} component={Paper}>
          <Typography textAlign="right" variant="caption" display="block">
            Solde
          </Typography>
          <Typography variant="h3" display="inline">
            {client?.solde} €
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" display="block">
          Période définie:
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={5} xs={12}>
            <DatePickerInput
              label="Du"
              value={
                filters?.[`${currentFilterKey}__gte`]
                  ? moment(filters?.[`${currentFilterKey}__gte`], 'DD/MM/YYYY')
                  : null
              }
              onChange={value => {
                const newDate = moment(value)
                if ((value && !newDate.isValid()) || newDate.year() < 1900) {
                  return
                }
                setFilters({
                  [`${currentFilterKey}__gte`]: newDate.isValid()
                    ? newDate.format('DD/MM/YYYY')
                    : undefined,
                })
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <DatePickerInput
              label="au"
              value={
                filters?.[`${currentFilterKey}__lte`]
                  ? moment(filters?.[`${currentFilterKey}__lte`], 'DD/MM/YYYY')
                  : null
              }
              onChange={value => {
                const newDate = moment(value)
                if ((value && !newDate.isValid()) || newDate.year() < 1900) {
                  return
                }
                setFilters({
                  [`${currentFilterKey}__lte`]: newDate.isValid()
                    ? newDate.format('DD/MM/YYYY')
                    : undefined,
                })
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <AutocompleteInput
              label={'Type de filtre'}
              disableClearable={true}
              onChange={(event, value) => {
                const newCurrentFilter =
                  value === 'Date' ? 'date' : 'date_effet'
                setCurrentFilterKey(newCurrentFilter)
                const lte = filters.date__lte ?? filters.date_effet__lte
                const gte = filters.date__gte ?? filters.date_effet__gte
                const newFilters = {
                  date__gte: undefined,
                  date__lte: undefined,
                  date_effet__gte: undefined,
                  date_effet__lte: undefined,
                }
                newFilters[`${newCurrentFilter}__lte`] = lte
                newFilters[`${newCurrentFilter}__gte`] = gte
                setFilters(newFilters)
                //console.log(filters)
              }}
              options={['Date', "Date d'effet"]}
              defaultValue={'Date'}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box>
          <Typography variant="subtitle1">Opérations</Typography>
        </Box>
        <Box mb={2}>
          <Button
            variant="contained"
            size="small"
            sx={{mr: 2}}
            onClick={() => setDialog({type: 'Operation diverse'})}
          >
            Opération diverse
          </Button>
          <AddMenu onClose={dialog => setDialog({type: dialog})} />
        </Box>
      </Box>
      <PaginationComponent count={operations?.count} isLoading={isLoading} />
      <TableContainer component={Paper}>
        {Boolean(operations) ? (
          <OperationsTable
            operations={operations}
            onEditButtonClicked={row => {
              setDialog({type: row.type, args: row})
            }}            
            onAffectationButtonClicked={handleAffectationButtonClicked}            
          />
        ) : null}
      </TableContainer>

      {isReglementFormDialog ? (
        <ReglementFormDialog
          client={client}
          reglement={dialog?.args}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
      {isVirementClientFormDialog ? (
        <VirementClientFormDialog
          client={client}
          virementClient={dialog?.args}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}

      {isDetteFormDialog ? (
        <DetteFormDialog
          dette={dialog?.args}
          client={client}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}

      {isOperationDiverse ? (
        <OperationDiverseFormDialog
          operationDiverse={dialog?.args}
          client={client}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}

      {isRemboursementormDialog ? (
        <RemboursementFormDialog
          remboursement={dialog?.args}
          client={client}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}

      {isAffectationDialog ? (
        <AffectationFormDialog
          handleClose={() => setDialog(null)}
          {...dialog?.args}
        />
      ) : null}

      {isQuittanceFormDialog ? (
        <QuittanceFormDialog
          client={client}
          quittance={dialog?.args}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
      {isRistourneFormDialog ? (
        <QuittanceFormDialog
          client={client}
          quittance={dialog?.args}
          isRistourne={true}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
      {isPerteEtProfit ? (
        <PerteEtProfitFormDialog
          client={client}
          perteEtProfit={dialog?.args}
          onSubmit={handleAdd}
          handleClose={handleCloseOrDeleteDialog}
        />
      ) : null}
    </Box>
  )
}

const OperationsTable = ({
  operations = [],
  onRowClick = () => {},  
  onEditButtonClicked = () => {},
  onAffectationButtonClicked = () => {},
  onAffectationDelete = () => {},
}) => {
  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({row}) => {          
          if (row.original.est_affectee) {
            return (
              <Icon
                aria-label="expand row"
                size="small"
                {...row.getToggleRowExpandedProps()}                                      
              >
                {row.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}                
              </Icon>
              
            )
          }
          return null
        },
      },
      {
        Header: 'Date',
        accessor: row => row['date'],
      },
      {
        Header: "Date d'effet",
        accessor: 'date_effet',
      },
      {
        Header: 'Opération',
        accessor: row => row['libelle'],
      },
      {
        Header: 'Débit',
        accessor: row =>
          row.montant < 0 ? Math.abs(row.montant).toFixed(2) : '',
      },
      {
        Header: 'Crédit',
        accessor: row => (row.montant >= 0 ? row.montant : ''),
      },
      {
        Header: 'Lettrage',
        accessor: row => row.affectations?.map(x => x.lettrage)?.join(' ; '),
      },
      {
        header: () => null,
        id: 'affectation',
        Cell: ({row}) => {
          if (parseFloat(row.original.montant) > 0) {
            if (row.original.totalement_affectee) {
              return null
            }
            return (
              <IconButton
                size="small"
                onClick={event => {
                  onAffectationButtonClicked?.(row.original)
                }}
                sx={{fontSize: 15}}
              >
                <SyncAlt fontSize="inherit" />
              </IconButton>
            )
          }
          return null
        },
      },
      {
        header: () => null,
        id: 'edit',
        Cell: ({row}) => {
          if (row.original.est_modifiable) {
            return (
              <PermissionHidder mainRole={'is_superuser'}>
                <IconButton
                  size="small"
                  onClick={event => {
                    onEditButtonClicked?.(row.original)
                  }}
                  sx={{fontSize: 15}}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              </PermissionHidder>
            )
          }
          return null
        },
      },
    ],
    [onAffectationButtonClicked, onEditButtonClicked],
  )
  const data = useMemo(() => {
    return operations?.results ?? []
  }, [operations])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'Date',
            desc: true,
          },
        ],
      },
      autoResetExpanded: false,
    },
    // useSortBy,
    useExpanded,
  )
  const getCellProps = cell => {
    const data = cell.row.original
    if (data.type === 'Ristourne') {
      if (!data.totalement_affectee) {
        return {
          sx: {
            color: orange[500],
          },
        }
      }
    } else if (parseFloat(data.montant) < 0) {
      if ((data.affectations ?? []).length === 0) {
        return {
          sx: {
            color: red[500],
            fontWeight: 'bold',
          },
        }
      } else {
        if (!data.totalement_affectee) {
          return {
            sx: {
              color: red[500],
            },
          }
        }
      }
    } else if (parseFloat(data.montant) > 0) {
      if ((data.affectations ?? []).length === 0) {
        return {
          sx: {
            color: green[500],
            fontWeight: 'bold',
          },
        }
      } else {
        if (!data.totalement_affectee) {
          return {
            sx: {
              color: green[500],
            },
          }
        }
      }
    }
  }

  const [currentHover, setCurrentHover] = useState(null)

  return (
    <Table {...getTableProps()} size="small">
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell
                {
                  ...column.getHeaderProps(/*column.getSortByToggleProps()*/)
                }
              >
                {/* <TableSortLabel
                  active={column.isSorted}
                  direction={column.isSortedDesc ? 'desc' : 'asc'}
                > */}
                {column.render('Header')}
                {/* </TableSortLabel> */}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody
        {...getTableBodyProps()}
        onMouseLeave={() => setCurrentHover(null)}
      >
        {rows.map(row => {
          prepareRow(row)
          return (
            <Fragment key={row.getRowProps().key}>
              <TableRow
                {...row.getRowProps()}
                onMouseEnter={() => {
                  !!row.original.affectations
                    ? setCurrentHover(row.original.id)
                    : setCurrentHover(null)
                }}
                sx={{
                  backgroundColor:
                    (
                      row.original.affectations?.filter(
                        x =>
                          x.debit_id === currentHover ||
                          x.credit_id === currentHover,
                      ) ?? []
                    ).length > 0
                      ? yellow[100]
                      : 'inherit',
                }}
              >
                {row.cells.map(cell => (
                  <TableCell {...cell.getCellProps(getCellProps(cell))}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
              {row.isExpanded &&
              !!row.original.affectations &&
              row.original.affectations?.length !== 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={visibleColumns.length}
                    style={{padding: 0}}
                  >
                    <RowSubComponent
                      row={row}
                      data={data}
                      onDelete={onAffectationDelete}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </Fragment>
          )
        })}
      </TableBody>
    </Table>
  )
}

const RowSubComponent = ({row, data, onDelete = () => {}}) => {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [operation, setOperation] = useState()
  const {isLoading, mutate} = useDeleteAffectation()
  const {data: myOperation} = useOperation(row.original.id)

  const handleConfirm = () => {
    mutate(currentId, {onSuccess: () => setOpenDialog(false)})
  }
  
  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 'inset 0 10px 10px -10px #dddddd',
        }}
        p={2}
        py={1}
      >
        <List>
          {row.original.affectations?.map(x => {            
            const description =
              parseFloat(row.original.credit) > 0
                ? x.debit_libelle
                : x.credit_libelle
            return (
              <ListItem key={x.id}>
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    {x.lettrage} - {x.montant}€ - {description}                   
                    <RowChequeDetails operationId={x.credit_id} />
                  </Box>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={event => {
                        setCurrentId(x.id)
                        setOpenDialog(true)
                      }}
                      sx={{fontSize: 15}}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Box>
              </ListItem>
            )
          })}
        </List>
      </Box>
      <ConfirmDialog
        title={`Voulez-vous vraiment supprimer l'affectation ?`}
        loading={loading}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
      />
    </>
  )
}

const RowChequeDetails = ({operationId}) => {
  const {data: myOperation} = useOperation(operationId)

  return (
    <>
    <br/>
    {myOperation?.type_reglement?.nom === "Chèque" 
      ? myOperation?.type_reglement?.nom + " " + myOperation?.banque_cheque?.nom 
      + " fait le " + myOperation?.date_cheque + 
      " par " + myOperation?.emetteur_cheque 
      : ""}
    </>
  )
}

const AddMenu = ({onClose = () => {}}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])
  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        startIcon={<ListIcon />}
        onClick={handleToggle}
        variant="contained"
        size="small"
      >
        Ajouter
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Reglement')
                    }}
                  >
                    Règlement
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Remboursement')
                    }}
                  >
                    Remboursement
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Dette')
                    }}
                  >
                    Dette
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Quittance')
                    }}
                  >
                    Quittance
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Ristourne')
                    }}
                  >
                    Ristourne
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Perte et profit')
                    }}
                  >
                    Pertes et profits
                  </MenuItem>
                  <MenuItem
                    onClick={evt => {
                      handleClose(evt)
                      onClose('Virement interne envoyé')
                    }}
                  >
                    Virement interne
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ClientDetailsCompteTab
