import {LoadingButton} from '@mui/lab'
import {Link as RouterLink} from 'react-router-dom'

const PrimaryButton = ({children, ...props}) => (
  <LoadingButton
    color="primary"
    sx={{m: 1}}
    component={Boolean(props.to) ? RouterLink : undefined}
    variant="contained"
    {...props}
  >
    {children}
  </LoadingButton>
)

export default PrimaryButton
