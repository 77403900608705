import {Autocomplete} from 'src/components/Form'

const types = ['Personne physique', 'Personne morale'].map(x => ({
  text: x,
  value: x,
}))

const ClientTypeField = props => (
  <Autocomplete items={types} name="type" {...props} />
)

export default ClientTypeField
