import {Box} from '@mui/material'
import Filemanager from '../../../components/Filemanager'

export default function ClientDetailsDocuments({client}) {
  return (
    <Box>
      {client?.reagi_id ? (
        <Filemanager rootFolder={client.reagi_id} />
      ) : undefined}
    </Box>
  )
}
