import {SignalCellularNullOutlined} from '@mui/icons-material'
import {
  useContext,
  useState,
  useEffect,
  createContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react'
import {useSearchParams} from 'react-router-dom'
// https://github.com/ReactTraining/react-router/issues/7353

const QueryStateContext = createContext()

const useQueryState = () => {
  const context = useContext(QueryStateContext)

  if (!context) {
    throw new Error('useQuery must be used withing <QueryStateProvider />.')
  }
  return context
}

export const QueryStateProvider = ({children}) => {
  const [searchParams, setSearchParams] = useSearchParams({})
  const [state, setState] = useReducer(
    (o, n) => ({...o, ...n}),
    null,
    () => {
      const params = {}
      searchParams.forEach((x, key) => (params[key] = x))
      return params
    },
  )

  useEffect(() => {
    setSearchParams(state)
  }, [state])

  return (
    <QueryStateContext.Provider value={[state, setState]}>
      {children}
    </QueryStateContext.Provider>
  )
}

export const useQueryStateSimpleParam = (key, defaultValue = null) => {
  const [query, setQuery] = useQueryState()

  const param = useMemo(() => {
    const value = query[key]
    return value ? value : defaultValue
  }, [query])
  const setParam = useCallback(
    value => {
      setQuery({[key]: value})
    },
    [setQuery],
  )
  return [param, setParam]
}

export const useQueryStateParam = (key, defaultValue = null) => {
  const [query, setQuery] = useQueryState()

  const param = useMemo(() => {
    let value
    try {
      value = JSON.parse(decodeURI(query[key]))
    } catch {
      return defaultValue
    }
    return value ? value : defaultValue
  }, [query])
  const setParam = useCallback(
    value => {
      setQuery({[key]: encodeURI(JSON.stringify(value))})
    },
    [setQuery],
  )
  return [param, setParam]
}

export default useQueryState
