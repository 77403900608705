const liaisons = [
  {
    id: 1,
    lien: 'Conjoint(e) de',
    lien_reverse: 'Conjoint(e) de',
  },
  {
    id: 2,
    lien: 'Parent de',
    lien_reverse: 'Enfant de',
  },
  {
    id: 3,
    lien: 'Holding de',
    lien_reverse: 'Filiale de',
  },
  {
    id: 4,
    lien: 'Représentée par',
    lien_reverse: 'Représentant(e) légal(e) de',
  },
  {
    id: 5,
    lien: 'Employeur de',
    lien_reverse: 'Salarié de',
  },
  {
    id: 6,
    lien: 'Groupe/Famille',
    lien_reverse: 'Membre du Groupe / de la Famille',
  },
  {
    id: 7,
    lien: 'Est la fiche principale de',
    lien_reverse: 'Est le doublon de',
  },
  {
    id: 8,
    lien: 'Enfant de',
    lien_reverse: 'Parent de',
  },
  {
    id: 9,
    lien: 'Filiale de',
    lien_reverse: 'Holding de',
  },
  {
    id: 10,
    lien: 'Représentant(e) légal(e) de',
    lien_reverse: 'Représentée par',
  },
  {
    id: 11,
    lien: 'Salarié de',
    lien_reverse: 'Employeur de',
  },
  {
    id: 12,
    lien: 'Membre du Groupe / de la Famille',
    lien_reverse: 'Groupe/Famille',
  },
  {
    id: 13,
    lien: 'Est le doublon de',
    lien_reverse: 'Est la fiche principale de',
  },
]

export {liaisons}
