import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  addLiaisonToClient,
  changeCurrentUserPassword,
  createAffectation,
  createCaisse,
  createClient,
  createContrat,
  createEnvoiDeFond,
  createExportCompta,
  createFiles,
  createFolder,
  createFusion,
  createGroupeClient,
  createOperation,
  createRemiseEnBanque,
  deleteAffectation,
  deleteCaisse,
  deleteClient,
  deleteContrat,
  deleteEnvoiDeFond,
  deleteExportCompta,
  deleteFile,
  deleteFolder,
  deleteGroupeClient,
  deleteLiaison,
  deleteOperation,
  deleteRemiseEnBanque,
  fetchAgences,
  fetchBanquesRemise,
  fetchCahiersDeTransmission,
  fetchCaisse,
  fetchCaisses,
  fetchClient,
  fetchClients,
  fetchCompteComptable,
  fetchComptesComptable,
  fetchComptesComptableSimple,
  fetchContrat,
  fetchContrats,
  fetchDevis,
  fetchEcritures,
  fetchEnvoisDeFondExport,
  fetchEnvoisDeFonds,
  fetchExportsCompta,
  fetchFiles,
  fetchFolder,
  fetchFusions,
  fetchGroupeClients,
  fetchMarquesAuto,
  fetchMeSettings,
  fetchMotifsResiliation,
  fetchOneDevis,
  fetchOneMeSettings,
  fetchOperation,
  fetchOperationsForClient,
  fetchProduits,
  fetchQuittances,
  fetchReglements,
  fetchRemiseEnBanque,
  fetchRemisesEnBanque,
  fetchTypeReglement,
  fetchUsers,
  fetchUsersList,
  partialUpdateClient,
  partialUpdateGroupeClient,
  partialUpdateOperation,
  rejetReglement,
  resetPassword,
  ristourneQuittance,
  saveOrCreateMeSettings,
  statusCahierDeTransmission,
  updateClient,
  updateContrat,
  partialUpdateContrat,
  updateFile,
  updateGroupeClient,
  updateOperation,
  fetchRepreneurs,
} from 'src/services/crm-api'
import useSnackbar from './useSnackbar'

const NORMAL_CACHE_SETTINGS = {
  staleTime: 60 * 1 * 1000,
  cacheTime: 60 * 1 * 1000,
}

const LONG_CACHE_SETTINGS = {
  staleTime: 60 * 5 * 1000,
  cacheTime: 60 * 10 * 1000,
}

// Users
const usersQueryKey = {
  all: ['users'],
  lists: () => [...usersQueryKey.all, 'list'],
  list: filters => [...usersQueryKey.lists(), {filters}],
  details: () => [...usersQueryKey.all, 'detail'],
  detail: id => [...usersQueryKey.details(), parseInt(id)],
}

export function useUsers(params) {
  return useQuery(usersQueryKey.list(params), () => fetchUsers(params), {
    keepPreviousData: true,
  })
}

export function useUsersList(params) {
  return useQuery('users-list', () => fetchUsersList(params), {
    keepPreviousData: true,
  })
}

// Devis
const devisQueryKey = {
  all: ['devis'],
  lists: () => [...devisQueryKey.all, 'list'],
  list: filters => [...devisQueryKey.lists(), {filters}],
  details: () => [...devisQueryKey.all, 'detail'],
  detail: id => [...devisQueryKey.details(), parseInt(id)],
}

export function useDevis(params) {
  return useQuery(devisQueryKey.list(params), () => fetchDevis(params), {
    keepPreviousData: true,
    ...NORMAL_CACHE_SETTINGS,
  })
}

export function useOneDevis(id, params) {
  const queryContrat = useQueryClient()
  return useQuery(devisQueryKey.detail(id), () => fetchOneDevis(id, params), {
    initialStale: true,
    initialData: () => {
      const caches = queryContrat.getQueriesData(devisQueryKey.lists())
      for (let i = 0; i < caches.length; i++) {
        const cache = caches[i]
        const item = cache[1]['results'].find(x => x.id === id)
        if (item) {
          return item
        }
      }
    },
  })
}

// Contrats
const contratsQueryKey = {
  all: ['contrats'],
  lists: () => [...contratsQueryKey.all, 'list'],
  list: filters => [...contratsQueryKey.lists(), {filters}],
  details: () => [...contratsQueryKey.all, 'detail'],
  detail: id => [...contratsQueryKey.details(), parseInt(id)],
}

export function useContrats(params) {
  return useQuery(contratsQueryKey.list(params), () => fetchContrats(params), {
    keepPreviousData: true,
    ...NORMAL_CACHE_SETTINGS,
  })
}

export function useContrat(id, params) {
  const queryContrat = useQueryClient()
  return useQuery(contratsQueryKey.detail(id), () => fetchContrat(id, params), {
    initialStale: true,
    initialData: () => {
      const caches = queryContrat.getQueriesData(contratsQueryKey.lists())
      for (let i = 0; i < caches.length; i++) {
        const cache = caches[i]
        const item = cache[1]['results'].find(x => x.id === id)
        if (item) {
          return item
        }
      }
    },
  })
}

export function useEditContrat(contratId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return updateContrat(contratId, body)
    },
    {
      onMutate: async newContrat => {
        await queryClient.cancelQueries(contratsQueryKey.all)
        const key = contratsQueryKey.detail(contratId)
        const previousContrat = queryClient.getQueryData(key)
        queryClient.setQueryData(key, newContrat)
        queryClient.invalidateQueries([contratsQueryKey.all])
        return {previousContrat}
      },
      onSuccess: data => {
        queryClient.setQueryData(contratsQueryKey.detail(data.id), data)
        showSuccess('Contrat sauvegardés')
      },
      onError: (err, newClientData, context) => {
        showError(err)
      },
    },
  )
}

export function usePartialEditContrat(contratId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return partialUpdateContrat(contratId, body)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(contratsQueryKey.lists())
        queryClient.invalidateQueries(devisQueryKey.lists())
        queryClient.setQueryData(contratsQueryKey.detail(data.id), data)
        showSuccess('Contrat sauvegardés')
      },
      onError: (err, newClientData, context) => {
        showError(err)
      },
    },
  )
}

export function useCreateContrat() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createContrat(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(contratsQueryKey.lists())
      queryClient.invalidateQueries(devisQueryKey.lists())
      showSuccess('Contrat créé')
    },
    onError: err => showError(err),
  })
}

export function useDeleteContrat() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteContrat(id), {
    onSuccess: data => {
      queryClient.invalidateQueries(contratsQueryKey.lists())
      queryClient.invalidateQueries(contratsQueryKey.details())
      showSuccess('Contrat supprimé')
    },
    onError: err => showError(err),
  })
}

function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay)
  })
}

// Clients
const clientsQueryKey = {
  all: ['clients'],
  lists: () => [...clientsQueryKey.all, 'list'],
  list: filters => [...clientsQueryKey.lists(), {filters}],
  details: () => [...clientsQueryKey.all, 'detail'],
  detail: id => [...clientsQueryKey.details(), parseInt(id)],
}

export function useClients(params) {
  return useQuery(clientsQueryKey.list(params), () => fetchClients(params), {
    keepPreviousData: true,
    ...NORMAL_CACHE_SETTINGS,
  })
}

export function useCreateClient() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createClient(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(clientsQueryKey.lists())
      const key = clientsQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Client créé')
    },
    onError: err => showError(err),
  })
}

export function useClient(id, params) {
  const queryClient = useQueryClient()
  return useQuery(clientsQueryKey.detail(id), () => fetchClient(id, params), {
    initialStale: true,
    initialData: () => {
      const caches = queryClient.getQueriesData(clientsQueryKey.lists())
      for (let i = 0; i < caches.length; i++) {
        const cache = caches[i]
        const item = cache[1]['results'].find(x => x.id === id)
        if (item) {
          return item
        }
      }
    },
  })
}

export function useDeleteClient() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteClient(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(clientsQueryKey.all)
      const key = clientsQueryKey.detail(id)
      const previousClient = queryClient.getQueryData(key)
      return {previousClient}
    },
    onSuccess: data => {
      showSuccess('Client supprimé')
    },
    onError: (err, newClient, context) => {
      queryClient.setQueryData(
        clientsQueryKey.detail(context.previousClient.id),
        context.previousClient,
      )
      showError(err)
    },
    onSettled: data => {
      queryClient.invalidateQueries(clientsQueryKey.detail(data.id))
      queryClient.invalidateQueries(clientsQueryKey.lists())
    },
  })
}

export function usePartialEditClient(clientId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return partialUpdateClient(clientId, body)
    },
    {
      onMutate: async newClientData => {
        await queryClient.cancelQueries(clientsQueryKey.all)
        const key = clientsQueryKey.detail(clientId)
        const previousClient = queryClient.getQueryData(key)
        const newClient = {...previousClient, ...newClientData}
        queryClient.setQueryData(key, newClient)
        queryClient.invalidateQueries([clientsQueryKey.all])
        return {previousClient}
      },
      onSuccess: () => showSuccess('Client sauvegardés'),
      onError: (err, newClientData, context) => {
        queryClient.setQueryData(
          clientsQueryKey.detail(clientId),
          context.previousClient,
        )
        showError(err)
      },
    },
  )
}

export function useEditClient(clientId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return updateClient(clientId, body)
    },
    {
      onMutate: async newClient => {
        await queryClient.cancelQueries(clientsQueryKey.all)
        const key = clientsQueryKey.detail(clientId)
        const previousClient = queryClient.getQueryData(key)
        queryClient.setQueryData(key, newClient)
        queryClient.invalidateQueries([clientsQueryKey.all])
        return {previousClient}
      },
      onSuccess: data => {
        queryClient.setQueryData(clientsQueryKey.detail(data.id), data)
        showSuccess('Client sauvegardés')
      },
      onError: (err, newClientData, context) => {
        // Disable avec l'ancien système de form
        // queryClient.setQueryData(
        //   clientsQueryKey.detail(clientId),
        //   context.previousClient,
        // )
        showError(err)
      },
    },
  )
}

// Liaisons

export function useCreateLiaisons(clientId) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(values => addLiaisonToClient(clientId, values), {
    onSuccess: data => {
      const key = clientsQueryKey.detail(clientId)
      const cache = queryClient.getQueryData(key)
      if (cache) {
        queryClient.setQueryData(key, {
          ...cache,
          liaisons_clients: [...cache.liaisons_clients, data],
        })
      }
      queryClient.invalidateQueries(key)
      showSuccess('Liaison créée')
    },
    onError: err => showError(err),
    onSettled: () => {
      queryClient.invalidateQueries(clientsQueryKey.detail(clientId))
      queryClient.invalidateQueries(clientsQueryKey.lists())
    },
  })
}

export function useDeleteLiaison(clientId) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteLiaison(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(clientsQueryKey.all)
      const key = clientsQueryKey.detail(clientId)
      const previousClient = queryClient.getQueryData(key)
      if (previousClient) {
        queryClient.setQueryData(key, {
          ...previousClient,
          liaisons_clients: previousClient.liaisons_clients.filter(
            x => x.id !== id,
          ),
        })
      }
      return {previousClient}
    },
    onSuccess: data => {
      showSuccess('Liaison supprimée')
    },
    onError: (err, newClient, context) => {
      queryClient.setQueryData(
        clientsQueryKey.detail(clientId),
        context.previousClient,
      )
      showError(err)
    },
    onSettled: () => {
      queryClient.invalidateQueries(clientsQueryKey.detail(clientId))
      queryClient.invalidateQueries(clientsQueryKey.lists())
    },
  })
}

// Agences
const agencesQueryKey = {
  all: ['agences'],
  lists: () => [...agencesQueryKey.all, 'list'],
  list: filters => [...agencesQueryKey.lists(), {filters}],
}

export function useAgences(params) {
  return useQuery(agencesQueryKey.list(params), () => fetchAgences(params), {
    ...LONG_CACHE_SETTINGS,
  })
}

// MotifResiliations
const motifResiliationsQueryKey = {
  all: ['motif-resiliations'],
  lists: () => [...motifResiliationsQueryKey.all, 'list'],
  list: filters => [...motifResiliationsQueryKey.lists(), {filters}],
}

export function useMotifResiliations(params) {
  return useQuery(
    motifResiliationsQueryKey.list(params),
    () => fetchMotifsResiliation(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// MarquesAuto
const marquesAutoQueryKey = {
  all: ['marques-auto'],
  lists: () => [...marquesAutoQueryKey.all, 'list'],
  list: filters => [...marquesAutoQueryKey.lists(), {filters}],
}

export function useMarquesAuto(params) {
  return useQuery(
    marquesAutoQueryKey.list(params),
    () => fetchMarquesAuto(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// Repreneurs
const repreneursQueryKey = {
  all: ['repreneurs'],
  lists: () => [...repreneursQueryKey.all, 'list'],
  list: filters => [...repreneursQueryKey.lists(), {filters}],
}

export function useRepreneurs(params) {
  return useQuery(
    repreneursQueryKey.list(params),
    () => fetchRepreneurs(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// Produits
const produitsQueryKey = {
  all: ['produits'],
  lists: () => [...produitsQueryKey.all, 'list'],
  list: filters => [...produitsQueryKey.lists(), {filters}],
}

export function useProduits(params) {
  return useQuery(produitsQueryKey.list(params), () => fetchProduits(params), {
    ...LONG_CACHE_SETTINGS,
  })
}

// Comptes Comptable Simple
const comptesComptableSimpleQueryKey = {
  all: ['comptes_comptable_simple'],
  lists: () => [...comptesComptableSimpleQueryKey.all, 'list'],
  list: filters => [...comptesComptableSimpleQueryKey.lists(), {filters}],
}

export function useComptesComptableSimple(params) {
  return useQuery(
    comptesComptableSimpleQueryKey.list(params),
    () => fetchComptesComptableSimple(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// Comptes Comptable
const comptesComptableQueryKey = {
  all: ['comptes_comptable'],
  lists: () => [...comptesComptableQueryKey.all, 'list'],
  list: filters => [...comptesComptableQueryKey.lists(), {filters}],
  details: () => [...comptesComptableQueryKey.all, 'detail'],
  detail: id => [...comptesComptableQueryKey.details(), parseInt(id)],
}

export function useComptesComptable(params) {
  return useQuery(
    comptesComptableQueryKey.list(params),
    () => fetchComptesComptable(params),
    {
      keepPreviousData: true,
      ...LONG_CACHE_SETTINGS,
    },
  )
}

export function useCompteComptable(id, params) {
  const queryClient = useQueryClient()
  return useQuery(
    comptesComptableQueryKey.detail(id),
    () => fetchCompteComptable(id, params),
    {
      initialStale: true,
      initialData: () => {
        const caches = queryClient.getQueriesData(
          comptesComptableQueryKey.lists(),
        )
        for (let i = 0; i < caches.length; i++) {
          const cache = caches[i]
          const item = cache[1]['results']?.find(x => x.id === id)
          if (item) {
            return item
          }
        }
      },
    },
  )
}

// Exports Compta
const exportsComptaQueryKey = {
  all: ['exports_compta'],
  lists: () => [...exportsComptaQueryKey.all, 'list'],
  list: filters => [...exportsComptaQueryKey.lists(), {filters}],
  details: () => [...exportsComptaQueryKey.all, 'detail'],
  detail: id => [...exportsComptaQueryKey.details(), parseInt(id)],
}

export function useExportsCompta(params, options = {keepPreviousData: true}) {
  return useQuery(
    exportsComptaQueryKey.list(params),
    () => fetchExportsCompta(params),
    {
      ...LONG_CACHE_SETTINGS,
      ...options,
    },
  )
}

export function useCreateExportCompta() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createExportCompta(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(exportsComptaQueryKey.lists())
      const key = exportsComptaQueryKey.lists()
      queryClient.setQueryData(key, data)
      showSuccess('Export compta créé')
    },
    onError: err => showError(err),
  })
}

export function useDeleteExportCompta() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteExportCompta(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(exportsComptaQueryKey.all)
      const key = exportsComptaQueryKey.detail(id)
      const previousExportCompta = queryClient.getQueryData(key)
      return {previousExportCompta}
    },
    onSuccess: data => {
      showSuccess('Export compta supprimée')
    },
    onError: (err, newExportCompta, context) => {
      showError(err)
      queryClient.setQueryData(
        exportsComptaQueryKey.detail(context.previousExportCompta.id),
        context.previousExportCompta,
      )
    },
    onSettled: data => {
      queryClient.invalidateQueries(exportsComptaQueryKey.detail(data.id))
      queryClient.invalidateQueries(exportsComptaQueryKey.lists())
    },
  })
}

// Ecritures

const EcrituresQueryKey = {
  all: ['ecritures'],
  lists: () => [...EcrituresQueryKey.all, 'list'],
  list: filters => [...EcrituresQueryKey.lists(), {filters}],
}

export function useEcritures(params, options = {keepPreviousData: true}) {
  return useQuery(
    EcrituresQueryKey.list(params),
    () => fetchEcritures(params),
    {
      ...NORMAL_CACHE_SETTINGS,
      ...options,
    },
  )
}

// BanqueRemise
const banquesRemiseQueryKey = {
  all: ['banque_remise'],
  lists: () => [...banquesRemiseQueryKey.all, 'list'],
  list: filters => [...banquesRemiseQueryKey.lists(), {filters}],
}

export function useBanquesRemise(params) {
  return useQuery(
    banquesRemiseQueryKey.list(params),
    () => fetchBanquesRemise(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// TypesReglement
const typesReglementQueryKey = {
  all: ['types_reglement'],
  lists: () => [...typesReglementQueryKey.all, 'list'],
  list: filters => [...typesReglementQueryKey.lists(), {filters}],
}

export function useTypesReglement(params) {
  return useQuery(
    typesReglementQueryKey.list(params),
    () => fetchTypeReglement(params),
    {
      ...LONG_CACHE_SETTINGS,
    },
  )
}

// Reglements
const reglementsQueryKey = {
  all: ['reglements'],
  lists: () => [...reglementsQueryKey.all, 'list'],
  list: filters => [...reglementsQueryKey.lists(), {filters}],
}

export function useReglements(params, options) {
  return useQuery(
    reglementsQueryKey.list(params),
    () => fetchReglements(params),
    {
      ...NORMAL_CACHE_SETTINGS,
      ...options,
    },
  )
}

export function useRejetReglement() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => rejetReglement(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(reglementsQueryKey.all)
    },
    onSuccess: data => {
      showSuccess('Règlement rejeté')
    },
    onError: error => {
      showError(error)
    },
    onSettled: data => {
      queryClient.invalidateQueries(reglementsQueryKey.all)
      queryClient.invalidateQueries(operationsQueryKey.all)
      queryClient.invalidateQueries(clientsQueryKey.all)
    },
  })
}

// cahier-de-transmission
const cahiersDeTransmissionKey = {
  all: ['cahiers_de_transmission'],
  lists: () => [...cahiersDeTransmissionKey.all, 'list'],
  list: filters => [...cahiersDeTransmissionKey.lists(), {filters}],
  details: () => [...cahiersDeTransmissionKey.all, 'detail'],
  detail: id => [...cahiersDeTransmissionKey.details(), parseInt(id)],
}

export function useCahiersDeTransmission(
  params,
  options = {keepPreviousData: true},
) {
  return useQuery(
    cahiersDeTransmissionKey.list(params),
    () => fetchCahiersDeTransmission(params),
    {
      // ...NORMAL_CACHE_SETTINGS,
      ...options,
    },
  )
}

// Envoi de fond export
const envoisDeFondExportQueryKey = {
  all: ['envois_de_fond_export'],
  lists: () => [...envoisDeFondExportQueryKey.all, 'list'],
  list: filters => [...envoisDeFondExportQueryKey.lists(), {filters}],
}

export function useEnvoisDeFondExport(params, options = {}) {
  return useQuery(
    envoisDeFondExportQueryKey.list(params),
    () => fetchEnvoisDeFondExport(params),
    {
      ...NORMAL_CACHE_SETTINGS,
      ...options,
    },
  )
}

// Envoi De Fond
const envoisDeFondsQueryKey = {
  all: ['envois_de_fonds'],
  lists: () => [...envoisDeFondsQueryKey.all, 'list'],
  list: filters => [...envoisDeFondsQueryKey.lists(), {filters}],
  details: () => [...envoisDeFondsQueryKey.all, 'detail'],
  detail: id => [...envoisDeFondsQueryKey.details(), parseInt(id)],
}

export function useEnvoisDeFonds(params, options = {keepPreviousData: true}) {
  return useQuery(
    envoisDeFondsQueryKey.list(params),
    () => fetchEnvoisDeFonds(params),
    {
      ...options,
    },
  )
}

export function useCreateEnvoiDeFond() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createEnvoiDeFond(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(envoisDeFondsQueryKey.lists())
      const key = envoisDeFondsQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Envoi de fond créée')
    },
    onError: err => {
      showError(err)
    },
  })
}

export function useDeleteEnvoiDeFond() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteEnvoiDeFond(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(envoisDeFondsQueryKey.all)
      const key = envoisDeFondsQueryKey.detail(id)
      const previousEnvoiDeFond = queryClient.getQueryData(key)
      return {previousEnvoiDeFond}
    },
    onSuccess: data => {
      showSuccess('Envoi de fonds supprimé')
    },
    onError: (err, newRemiseEnBanque, context) => {
      queryClient.setQueryData(
        envoisDeFondsQueryKey.detail(context.previousEnvoiDeFond.id),
        context.previousEnvoiDeFond,
      )
      showError(err)
    },
    onSettled: data => {
      queryClient.invalidateQueries(envoisDeFondsQueryKey.detail(data.id))
      queryClient.invalidateQueries(envoisDeFondsQueryKey.lists())
    },
  })
}

// Remise en banque
const remisesEnBanqueQueryKey = {
  all: ['remises_en_banque'],
  lists: () => [...remisesEnBanqueQueryKey.all, 'list'],
  list: filters => [...remisesEnBanqueQueryKey.lists(), {filters}],
  details: () => [...remisesEnBanqueQueryKey.all, 'detail'],
  detail: id => [...remisesEnBanqueQueryKey.details(), parseInt(id)],
}

export function useRemisesEnBanques(
  params,
  options = {keepPreviousData: true},
) {
  return useQuery(
    remisesEnBanqueQueryKey.list(params),
    () => fetchRemisesEnBanque(params),
    {
      // ...NORMAL_CACHE_SETTINGS,
      ...options,
    },
  )
}

export function useCreateRemiseEnBanque() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createRemiseEnBanque(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(remisesEnBanqueQueryKey.lists())
      const key = remisesEnBanqueQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Remise en banque créée')
    },
    onError: err => {
      showError(err)
    },
  })
}

export function useDeleteRemiseEnBanque() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteRemiseEnBanque(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(remisesEnBanqueQueryKey.all)
      const key = remisesEnBanqueQueryKey.detail(id)
      const previousRemiseEnBanque = queryClient.getQueryData(key)
      return {previousRemiseEnBanque}
    },
    onSuccess: data => {
      showSuccess('Remise en banque supprimée')
    },
    onError: (err, newRemiseEnBanque, context) => {
      queryClient.setQueryData(
        remisesEnBanqueQueryKey.detail(context.previousRemiseEnBanque.id),
        context.previousRemiseEnBanque,
      )
      showError(err)
    },
    onSettled: data => {
      queryClient.invalidateQueries(remisesEnBanqueQueryKey.detail(data.id))
      queryClient.invalidateQueries(remisesEnBanqueQueryKey.lists())
      queryClient.invalidateQueries(cahiersDeTransmissionKey.all)
    },
  })
}

export function useStatusRemiseEnBanque(id) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(status => statusCahierDeTransmission(id, status), {
    onMutate: async variables => {
      await queryClient.cancelQueries(remisesEnBanqueQueryKey.all)
      const key = remisesEnBanqueQueryKey.detail(id)
      const previousRemiseEnBanque = queryClient.getQueryData(key)
      return {previousRemiseEnBanque}
    },
    onSuccess: data => {
      showSuccess('Remise en banque modifiée')
    },
    onError: (err, variables, context) => {
      showError(err)
    },
    onSettled: data => {
      if (data) {
        queryClient.invalidateQueries(remisesEnBanqueQueryKey.detail(data.id))
        queryClient.invalidateQueries(remisesEnBanqueQueryKey.lists())
        queryClient.invalidateQueries(cahiersDeTransmissionKey.all)
      }
    },
  })
}

export function useRemiseEnBanque(id) {
  const queryClient = useQueryClient()
  return useQuery(
    remisesEnBanqueQueryKey.detail(id),
    () => fetchRemiseEnBanque(id),
    {
      ...NORMAL_CACHE_SETTINGS,
      initialStale: true,
      initialData: () => {
        const caches = queryClient.getQueriesData(
          remisesEnBanqueQueryKey.lists(),
        )
        for (let i = 0; i < caches.length; i++) {
          const cache = caches[i]
          const item = cache[1]['results'].find(x => x.id === id)
          if (item) {
            return item
          }
        }
      },
    },
  )
}

// Caisses
const caissesQueryKey = {
  all: ['caisses'],
  lists: () => [...caissesQueryKey.all, 'list'],
  list: filters => [...caissesQueryKey.lists(), {filters}],
  details: () => [...caissesQueryKey.all, 'detail'],
  detail: id => [...caissesQueryKey.details(), parseInt(id)],
}

export function useCaisses(params, options = {keepPreviousData: true}) {
  return useQuery(caissesQueryKey.list(params), () => fetchCaisses(params), {
    ...NORMAL_CACHE_SETTINGS,
    ...options,
  })
}

export function useCreateCaisse() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createCaisse(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(caissesQueryKey.lists())
      const key = caissesQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Caisse créée')
    },
    onError: err => showError(err),
  })
}

export function useDeleteCaisse() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteCaisse(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(caissesQueryKey.all)
      const key = caissesQueryKey.detail(id)
      const previousCaisse = queryClient.getQueryData(key)
      return {previousCaisse}
    },
    onSuccess: data => {
      showSuccess('Caisse supprimée')
    },
    onError: (err, newCaisse, context) => {
      queryClient.setQueryData(
        caissesQueryKey.detail(context.previousCaisse.id),
        context.previousCaisse,
      )
      showError(err)
    },
    onSettled: data => {
      queryClient.invalidateQueries(caissesQueryKey.detail(data.id))
      queryClient.invalidateQueries(caissesQueryKey.lists())
    },
  })
}

export function useStatusCaisse(id) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(status => statusCahierDeTransmission(id, status), {
    onMutate: async variables => {
      await queryClient.cancelQueries(caissesQueryKey.all)
      const key = caissesQueryKey.detail(id)
      const previousCaisse = queryClient.getQueryData(key)
      return {previousCaisse}
    },
    onSuccess: data => {
      showSuccess('Caisse modifiée')
    },
    onError: (err, variables, context) => {
      showError(err)
    },
    onSettled: data => {
      if (data) {
        queryClient.invalidateQueries(caissesQueryKey.detail(data.id))
        queryClient.invalidateQueries(caissesQueryKey.lists())
      }
    },
  })
}

export function useCaisse(id) {
  const queryClient = useQueryClient()
  return useQuery(caissesQueryKey.detail(id), () => fetchCaisse(id), {
    ...NORMAL_CACHE_SETTINGS,
    initialStale: true,
    initialData: () => {
      const caches = queryClient.getQueriesData(caissesQueryKey.lists())
      for (let i = 0; i < caches.length; i++) {
        const cache = caches[i]
        const item = cache[1]['results'].find(x => x.id === id)
        if (item) {
          return item
        }
      }
    },
  })
}

// Quittances
const quittancesQueryKey = {
  all: ['quittances'],
  lists: () => [...quittancesQueryKey.all, 'list'],
  list: filters => [...quittancesQueryKey.lists(), {filters}],
}

export function useQuittances(params) {
  return useQuery(
    quittancesQueryKey.list(params),
    () => fetchQuittances(params),
    {
      keepPreviousData: true,
    },
  )
}

// Operation
const operationsQueryKey = {
  all: ['operations'],
  lists: () => [...operationsQueryKey.all, 'list'],
  listClient: (clientId, filters) => [
    ...operationsQueryKey.lists(),
    parseInt(clientId),
    {filters},
  ],
  list: filters => [...operationsQueryKey.lists(), {filters}],
  details: () => [...operationsQueryKey.all, 'detail'],
  detail: id => [...operationsQueryKey.details(), parseInt(id)],
}

export function useOperationsForClient(
  id,
  params,
  options = {keepPreviousData: true},
) {
  return useQuery(
    operationsQueryKey.listClient(id, params),
    () => fetchOperationsForClient(id, params),
    {
      ...options,
    },
  )
}

export function useOperation(id, params, options) {
  const queryClient = useQueryClient()
  return useQuery(
    operationsQueryKey.detail(id),
    () => fetchOperation(id, params),
    {
      initialStale: true,
      initialData: () => {
        const caches = queryClient.getQueriesData(operationsQueryKey.lists())
        for (let i = 0; i < caches.length; i++) {
          const cache = caches[i]
          const item = cache[1]['results'].find(x => x.id === id)
          if (item) {
            return item
          }
        }
      },
      ...options,
    },
  )
}

export function useCreateOperation() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createOperation(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(operationsQueryKey.lists())
      console.log('invalidate', quittancesQueryKey.lists())
      queryClient.invalidateQueries(clientsQueryKey.detail(data.client_id))
      if (!data.client_id) {
        // Cas étrange, en gros il n'y a pas de client_id dans la réponse de l'opération. Le mieux est donc de tout vider sur les clients pour les soldes.
        // Princiapalement un soucis lorsqu'on crée un cb3x
        queryClient.invalidateQueries(clientsQueryKey.details())
      }
      queryClient.invalidateQueries(quittancesQueryKey.lists())
      const key = operationsQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Opération créé')
    },
    onError: err => showError(err),
  })
}

export function usePartialEditOperation(id) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return partialUpdateOperation(id, body)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(operationsQueryKey.lists())
        queryClient.invalidateQueries(quittancesQueryKey.lists())
        queryClient.invalidateQueries(clientsQueryKey.detail(data.client_id))
        const key = operationsQueryKey.detail(data.id)
        queryClient.setQueryData(key, data)
        showSuccess('Operation sauvegardés')
      },
      onError: (err, newOperationData, context) => {
        queryClient.setQueryData(
          operationsQueryKey.detail(id),
          context.previousOperation,
        )
        showError(err)
      },
    },
  )
}

export function useEditOperation(id) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return updateOperation(id, body)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(operationsQueryKey.lists())
        queryClient.invalidateQueries(quittancesQueryKey.lists())
        queryClient.invalidateQueries(clientsQueryKey.detail(data.client_id))
        const key = operationsQueryKey.detail(data.id)
        queryClient.setQueryData(key, data)
        showSuccess('Operation sauvegardés')
      },
      onError: (err, newOperationDataData, context) => {
        showError(err)
      },
    },
  )
}

export function useDeleteOperation() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteOperation(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(operationsQueryKey.all)
      const key = operationsQueryKey.detail(id)
      const previous = queryClient.getQueryData(key)
      return {previous}
    },
    onSuccess: data => {
      showSuccess('Operation supprimée')
    },
    onError: (err, newCaisse, context) => {
      queryClient.setQueryData(
        operationsQueryKey.detail(context.previous.id),
        context.previous,
      )
      showError(err)
    },
    onSettled: data => {
      queryClient.invalidateQueries(operationsQueryKey.lists())
      queryClient.invalidateQueries(quittancesQueryKey.lists())
      queryClient.invalidateQueries(operationsQueryKey.details())
      queryClient.invalidateQueries(clientsQueryKey.details())
    },
  })
}

export function useRistourneQuittance() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => ristourneQuittance(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(operationsQueryKey.all)
    },
    onSuccess: data => {
      showSuccess('Ristourne crée')
    },
    onError: error => {
      showError(error)
    },
    onSettled: data => {
      queryClient.invalidateQueries(clientsQueryKey.all)
      queryClient.invalidateQueries(operationsQueryKey.all)
    },
  })
}

export function useCreateAffectation() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createAffectation(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(operationsQueryKey.lists())
      queryClient.invalidateQueries(operationsQueryKey.details())
      showSuccess('Affectation créée')
    },
    onError: err => showError(err),
  })
}

export function useDeleteAffectation() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteAffectation(id), {
    onSuccess: data => {
      queryClient.invalidateQueries(operationsQueryKey.lists())
      queryClient.invalidateQueries(operationsQueryKey.details())
      showSuccess('Affectation supprimée')
    },
    onError: err => showError(err),
  })
}

// FileManager
const foldersQueryKey = {
  all: ['folders'],
  details: () => [...foldersQueryKey.all, 'detail'],
  detail: path => [...foldersQueryKey.details(), path],
}

export function useFolder(path, params, options = {keepPreviousData: true}) {
  return useQuery(
    foldersQueryKey.detail(path),
    () => fetchFolder(path, params),
    {
      ...LONG_CACHE_SETTINGS,
      ...options,
    },
  )
}

export function useCreateFolder(axiosParams) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(
    body => {
      return createFolder(body, axiosParams)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(foldersQueryKey.details())
        showSuccess('Répertoire crée')
      },
      onError: err => {
        showError(err)
      },
    },
  )
}

const filesQueryKey = {
  all: ['files'],
  lists: () => [...filesQueryKey.all, 'list'],
  list: filters => [...filesQueryKey.lists(), {filters}],
}

export function useFiles(params, options = {keepPreviousData: true}) {
  return useQuery(filesQueryKey.list(params), () => fetchFiles(params), {
    // ...NORMAL_CACHE_SETTINGS,
    ...options,
  })
}

export function useCreateFiles(axiosParams) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(
    body => {
      return createFiles(body.files, body.folderId, axiosParams)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(foldersQueryKey.details())
        queryClient.invalidateQueries(filesQueryKey.lists())
        showSuccess('Document(s) téléversé(s)')
      },
      onError: err => {
        showError(err)
      },
    },
  )
}

export function usePartialEditFile(axiosParams) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(
    ({id, body}) => {
      return updateFile(id, body, axiosParams)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(foldersQueryKey.details())
        queryClient.invalidateQueries(filesQueryKey.lists())
        showSuccess('Document modifié')
      },
      onError: err => {
        showError(err)
      },
    },
  )
}

export function useDeleteFolder() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteFolder(id), {
    onSuccess: data => {
      queryClient.invalidateQueries(foldersQueryKey.details())
      showSuccess('Dossier supprimé')
    },
    onError: err => {
      showError(err)
    },
  })
}

export function useDeleteFile() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteFile(id), {
    onSuccess: data => {
      queryClient.invalidateQueries(foldersQueryKey.details())
      queryClient.invalidateQueries(filesQueryKey.lists())
      showSuccess('Fichier supprimé')
    },
    onError: err => {
      showError(err)
    },
  })
}

// Fusions
const fusionsQueryKey = {
  all: ['fusions'],
  lists: () => [...fusionsQueryKey.all, 'list'],
  list: filters => [...fusionsQueryKey.lists(), {filters}],
}

export function useFusions(params) {
  return useQuery(fusionsQueryKey.list(params), () => fetchFusions(params), {
    ...LONG_CACHE_SETTINGS,
  })
}

export function useCreateFusion(id) {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createFusion(id, body), {
    onSuccess: data => {
      queryClient.invalidateQueries(foldersQueryKey.details())
      queryClient.invalidateQueries(filesQueryKey.lists())
      showSuccess('Fusion créée')
    },
    onError: err => {
      showError(err)
    },
  })
}

// GroupeClients
const groupeClientsQueryKey = {
  all: ['groupe-clients'],
  lists: () => [...groupeClientsQueryKey.all, 'list'],
  list: filters => [...groupeClientsQueryKey.lists(), {filters}],
  details: () => [...groupeClientsQueryKey.all, 'detail'],
  detail: id => [...groupeClientsQueryKey.details(), parseInt(id)],
}

export function useGroupeClients(params) {
  return useQuery(
    groupeClientsQueryKey.list(params),
    () => fetchGroupeClients(params),
    {
      keepPreviousData: true,
    },
  )
}

export function useCreateGroupeClient() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => createGroupeClient(body), {
    onSuccess: data => {
      queryClient.invalidateQueries(groupeClientsQueryKey.lists())
      const key = groupeClientsQueryKey.detail(data.id)
      queryClient.setQueryData(key, data)
      showSuccess('Groupe créé')
    },
    onError: err => showError(err),
  })
}

export function useDeleteGroupeClient() {
  const queryClient = useQueryClient()
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => deleteGroupeClient(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(groupeClientsQueryKey.all)
      const key = groupeClientsQueryKey.detail(id)
      const previousGroupeClient = queryClient.getQueryData(key)
      return {previousGroupeClient}
    },
    onSuccess: data => {
      showSuccess('Groupe supprimé')
    },
    onError: (err, newExportCompta, context) => {
      showError(err)
      queryClient.setQueryData(
        groupeClientsQueryKey.detail(context.previousGroupeClient.id),
        context.previousGroupeClient,
      )
    },
    onSettled: data => {
      queryClient.invalidateQueries(groupeClientsQueryKey.detail(data.id))
      queryClient.invalidateQueries(groupeClientsQueryKey.lists())
    },
  })
}

export function useGroupeClient(id, params) {
  const queryClient = useQueryClient()
  return useQuery(
    groupeClientsQueryKey.detail(id),
    () => fetchGroupeClients(id, params),
    {
      initialStale: true,
    },
  )
}

export function usePartialEditGroupeClient(groupeClientId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return partialUpdateGroupeClient(groupeClientId, body)
    },
    {
      onSuccess: () => showSuccess('Groupe sauvegardés'),
      onError: (err, newClientData, context) => {
        queryClient.setQueryData(
          groupeClientsQueryKey.detail(groupeClientId),
          context.previousClient,
        )
        showError(err)
      },
      onSettled: data => {
        queryClient.invalidateQueries(groupeClientsQueryKey.detail(data.id))
        queryClient.invalidateQueries(groupeClientsQueryKey.lists())
      },
    },
  )
}

export function useEditGroupeClient(groupeClientId) {
  const queryClient = useQueryClient()

  const {showSuccess, showError} = useSnackbar()
  return useMutation(
    body => {
      return updateGroupeClient(groupeClientId, body)
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(groupeClientsQueryKey.detail(data.id), data)
        showSuccess('Groupe sauvegardés')
      },
      onError: (err, newClientData, context) => {
        showError(err)
      },
      onSettled: data => {
        queryClient.invalidateQueries(groupeClientsQueryKey.detail(data.id))
        queryClient.invalidateQueries(groupeClientsQueryKey.lists())
      },
    },
  )
}

// MeSettings
const meSettingsQueryKey = {
  all: ['meSettings'],
  lists: () => [...meSettingsQueryKey.all, 'list'],
  list: filters => [...meSettingsQueryKey.lists(), {filters}],
  details: () => [...meSettingsQueryKey.all, 'detail'],
  detail: id => [...meSettingsQueryKey.details(), id],
}

export function useMeSettings(params) {
  return useQuery(
    meSettingsQueryKey.list(params),
    () => fetchMeSettings(params),
    {
      keepPreviousData: true,
    },
  )
}

export function useOneMeSettings(id, params) {
  const queryMeSettings = useQueryClient()
  return useQuery(
    meSettingsQueryKey.detail(id),
    () => fetchOneMeSettings(id, params),
    {
      retry: false,
      initialStale: true,
      initialData: () => {
        const caches = queryMeSettings.getQueriesData(
          meSettingsQueryKey.lists(),
        )
        for (let i = 0; i < caches.length; i++) {
          const cache = caches[i]
          const item = cache[1]['results'].find(x => x.id === id)
          if (item) {
            return item
          }
        }
      },
    },
  )
}

export function useSaveOrCreateMeSettings(meSettingsId) {
  const queryMeSettings = useQueryClient()
  return useMutation(
    body => {
      return saveOrCreateMeSettings(meSettingsId, body)
    },
    {
      onMutate: async newMeSettingsData => {
        await queryMeSettings.cancelQueries(meSettingsQueryKey.all)
        const key = meSettingsQueryKey.detail(meSettingsId)
        const previousMeSettings = queryMeSettings.getQueryData(key)
        const newMeSettings = {...previousMeSettings, ...newMeSettingsData}
        queryMeSettings.setQueryData(key, newMeSettings)
        queryMeSettings.invalidateQueries([clientsQueryKey.all])
        return {previousMeSettings}
      },
      // onSuccess: () => showSuccess('Réglages sauvegardés'),
      onError: (err, newMeSettingsData, context) => {
        queryMeSettings.setQueryData(
          meSettingsQueryKey.detail(meSettingsId),
          context.previousMeSettings,
        )
        // showError(err)
      },
    },
  )
}

// User reset password
export function useResetPassword() {
  const {showError, showSuccess} = useSnackbar()
  return useMutation(id => resetPassword(id), {
    onSuccess: data => {
      showSuccess('Mot de passe envoyé')
    },
    onError: err => {
      showError(err)
    },
  })
}

// User change current password
export function useChangeCurrentUserPassword() {
  const {showError, showSuccess} = useSnackbar()
  return useMutation(body => changeCurrentUserPassword(body), {
    onSuccess: data => {
      showSuccess('Mot de passe changé')
    },
    onError: err => {
      showError(err)
    },
  })
}
