import {DesktopDatePicker} from '@mui/lab'
import TextInput from './TextInput'

export default function DatePickerInput({
  label,
  inputFormat = 'dd/MM/yyyy',
  error,
  helperText,
  ...props
}) {
  return (
    <DesktopDatePicker
      label={label}
      inputFormat="dd/MM/yyyy"
      renderInput={params => (
        <TextInput {...params} error={error} helperText={helperText} />
      )}
      {...props}
    />
  )
}
