import Filemanager from '../../components/Filemanager'
import {useFolder} from '../../hooks/api-hooks'
import ErrorCard from '../../components/ErrorCard'
import LoadingCard from '../../components/LoadingCard'
import PageTemplate from '../../components/templates/PageTemplate'
import {Button} from '@mui/material'
import {ArrowBack} from '@mui/icons-material'
import {useQueryStateParam} from '../../hooks/useQueryState'
import FolderList from '../../components/Filemanager/FolderList'

export default function () {
  const [currentFolderPath, setCurrentFolderPath] = useQueryStateParam(
    'currentFolderPath',
    undefined,
  )
  const {isLoading, data, isError, error} = useFolder('root', {
    lazy: true,
    trashed: false,
  })

  const items = data?.children
    ?.filter(x => x.type === 'folder')
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <PageTemplate
      title="Parcourir"
      sections={[{title: 'Gestionnaire-fichier', current: true}]}
    >
      {isError ? <ErrorCard error={error} /> : undefined}
      {Boolean(currentFolderPath) ? (
        <>
          <Button
            onClick={() => setCurrentFolderPath(undefined)}
            startIcon={<ArrowBack />}
            style={{marginBottom: '5px'}}
          >
            Retour
          </Button>
          <Filemanager rootFolder={currentFolderPath} />
        </>
      ) : isLoading ? (
        <LoadingCard />
      ) : (
        <FolderList
          items={items}
          onClickOnItem={folder => {
            setCurrentFolderPath(folder.path)
          }}
          minWidth={650}
        />
      )}
    </PageTemplate>
  )

  // if (isError) {
  //   return <ErrorCard error={error} />
  // }
  // if (isLoading) {
  //   return <LoadingCard />
  // }
  //
  // // return <Filemanager rootFolder={data.path} />
}
