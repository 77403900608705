import AutocompleteInput from './AutocompleteInput'

const fractionnements = [
  'Annuel',
  'Mensuel',
  'Trimestriel',
  'Semestriel',
  'Unique',
]

export default function PrimeFractionnementsInput({
  label = 'Fractionnement',
  error,
  helperText,
  ...props
}) {
  return (
    <AutocompleteInput
      options={fractionnements}
      label={label}
      error={error}
      helperText={helperText}
      {...props}
    />
  )
}
