import {useState} from 'react'
import {Button} from '@mui/material'
import ConfirmDialog from '../ConfirmDialog'
import {useRejetReglement} from '../../hooks/api-hooks'

const RejetReglementButton = ({onLoading, operation, onRejet, ...props}) => {
  const {mutate: rejet, isLoading} = useRejetReglement()
  const [openDialog, setOpenDialog] = useState(false)

  if (
    operation?.type_reglement?.nom !== 'Chèque' &&
    operation?.type_reglement?.nom !== 'CB 3 fois'
  ) {
    return null
  }

  const handleConfirm = () => {
    rejet(operation.id)
    onRejet()
  }
  return (
    <>
      <ConfirmDialog
        title={`Voulez-vous vraiment rejeter ce règlement ?`}
        loading={isLoading}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        onConfirm={handleConfirm}
      />
      <Button
        variant="contained"
        color="error"
        onClick={() => setOpenDialog(true)}
        {...props}
      >
        Rejeter
      </Button>
    </>
  )
}

export default RejetReglementButton
