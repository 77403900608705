import PageTemplate from 'src/components/templates/PageTemplate'
import AddButton from 'src/components/buttons/AddButton'
import {
  useCreateExportCompta,
  useDeleteExportCompta,
  useExportsCompta,
} from 'src/hooks/api-hooks'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
} from '@mui/material'

import {useMemo, useState} from 'react'
import {LoadingButton} from '@mui/lab'
import DatePickerControlled from 'src/components/controlled/DatePickerInputControlled'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import moment from 'moment'
import useModalConfirmation from '../../hooks/useModalConfirmation'
import useUniversalTableData from '../../hooks/useUniversalTableData'
import {WrappedUniversalTable} from '../../components/UniversalTable'
import {createColumnHelper} from '@tanstack/react-table'
import {Delete, Download} from '@mui/icons-material'

const today = new Date()
today.setHours(23, 59, 59, 59)
const schema = yup.object().shape({
  max_date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .max(today, "La date doit être antérieur ou aujourd'hui")
    .label('Date')
    .required(),
})

const columnHelper = createColumnHelper()

export default function ExportCompta() {
  const {mutate: deleteExportCompta} = useDeleteExportCompta()
  const {
    ConfirmDialogElement,
    args: exportComptaToDelete,
    setArgs: setExportComptaToDelete,
  } = useModalConfirmation({
    onConfirm: args => {
      deleteExportCompta(exportComptaToDelete.id)
      setExportComptaToDelete()
    },
  })

  const [open, setOpen] = useState(false)
  const [link, setLink] = useState(null)

  const {isLoading: isCreateLoading, mutate: create} = useCreateExportCompta()

  const rightActions = useMemo(
    () => <AddButton onClick={() => setOpen(true)}>Exporter</AddButton>,
    [setOpen],
  )

  const handleClose = () => {
    if (!isCreateLoading) {
      setOpen(false)
      setLink(null)
    }
  }

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  })

  const universalTableData = useUniversalTableData(useExportsCompta)
  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {id: 'id', header: 'ID'}),
      columnHelper.accessor('date', {
        id: 'date',
        header: 'Date',
        cell: info => moment(info.getValue()).format('DD/MM/YYYY'),
      }),
      columnHelper.accessor('nombre_ecritures', {
        header: "Nombre d'écritures",
        id: 'nombre_ecritures',
        enableSorting: false,
      }),
      columnHelper.display({
        id: 'actions',
        enableHiding: false,
        cell: info => {
          return (
            <>
              <Tooltip title="Télecharger l'export" arrow>
                <Link href={info.row.original.csv} target="_blank">
                  <IconButton sx={{ml: 1, mr: 1}} size="small" color="primary">
                    <Download />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="Effacer définitivement l'export comptable" arrow>
                <IconButton
                  sx={{ml: 1, mr: 1}}
                  size="small"
                  color="primary"
                  onClick={() => setExportComptaToDelete(info.row.original)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )
        },
      }),
    ],
    [],
  )

  return (
    <PageTemplate
      title="Exports comptable"
      sections={[{title: 'Exports comptable', current: true}]}
      rightActions={rightActions}
    >
      <WrappedUniversalTable
        columns={columns}
        universalTableData={universalTableData}
        name={'export-compta-list'}
      />
      <Dialog open={open} onClose={handleClose}>
        <form
          onSubmit={handleSubmit(values => {
            const body = {}
            body.max_date = moment(values.max_date).format('DD/MM/YYYY')
            create(body, {
              onSuccess: results => {
                setLink(results.csv)
              },
            })
          })}
        >
          <DialogTitle>Export comptable</DialogTitle>
          {Boolean(link) ? (
            <>
              <DialogContent>
                <DialogContentText mb={2}>
                  L'export est prêt !
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button disabled={isCreateLoading} onClick={handleClose}>
                  Fermer la fenêtre
                </Button>
                <Link href={link} target="_blank">
                  <Button variant="contained">Télécharger l'export</Button>
                </Link>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogContent>
                <DialogContentText mb={2}>
                  Pour exporter les écritures, il faut choisir une date butoire
                  (incluse) dans l'export
                </DialogContentText>

                <DatePickerControlled
                  label={'Date'}
                  name={'max_date'}
                  helperText={errors.max_date?.message}
                  error={Boolean(errors.max_date?.message)}
                  defaultValue={today}
                  control={control}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={isCreateLoading} onClick={handleClose}>
                  Annuler
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isCreateLoading}
                  variant="contained"
                >
                  Exporter
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </form>
      </Dialog>
      {Boolean(exportComptaToDelete) && (
        <ConfirmDialogElement>
          Vous êtes sur le point de supprimer cet export comptable:
          <br />
          <strong>{exportComptaToDelete.id}</strong>
        </ConfirmDialogElement>
      )}
    </PageTemplate>
  )
}
