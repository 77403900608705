import PageTemplate from 'src/components/templates/PageTemplate'
import ClientForm from 'src/components/forms/ClientForm'
import {errorResponseToJson} from 'src/services/crm-api'
import {useNavigate} from 'react-router'
import {useCreateClient} from 'src/hooks/api-hooks'

const ClientCreate = () => {
  const {mutate, isLoading, error} = useCreateClient()

  const navigate = useNavigate()
  const errors = errorResponseToJson(error)

  const handleSubmit = values => {
    mutate(values, {
      onSuccess: results => {
        navigate(`/clients/${results.id}`)
      },
    })
  }

  return (
    <PageTemplate
      title="Ajouter un client"
      sections={[
        {title: 'Clients', path: '/clients'},
        {title: 'Ajouter un client', current: true},
      ]}
      data-testid="page-clients-create"
    >
      <ClientForm
        submitLabel="Créer"
        errors={errors}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </PageTemplate>
  )
}

export default ClientCreate
