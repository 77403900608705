// show and hide column
// Rendre bo client

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Settings from '@mui/icons-material/Settings'
import {DndProvider, useDrag, useDrop} from 'react-dnd'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import {Fragment, useEffect, useReducer, useRef, useState} from 'react'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {useTheme} from '@mui/system'
import LoadingCard from './LoadingCard'
import {useOneMeSettings, useSaveOrCreateMeSettings} from '../hooks/api-hooks'
import ErrorCard from './ErrorCard'

export const WrappedUniversalTable = ({
  universalTableData,
  columns,
  dataAccessor = 'results',
  filtersContent,
  name,
  ...props
}) => {
  const {
    PaginationComponent,
    setOrdering,
    isLoading,
    data,
    error,
    isFetching,
    isError,
  } = universalTableData
  const items = dataAccessor ? data?.[dataAccessor] : data
  const [anchorColumnVisibilityEl, setAnchorColumnVisibilityEl] = useState(null)
  if (isError) {
    return <ErrorCard error={error} />
  }
  return isLoading ? (
    <LoadingCard />
  ) : (
    <>
      <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Tooltip title={'Édition des colonnes'}>
          <Button
            color={'secondary'}
            startIcon={<Settings />}
            onClick={event => setAnchorColumnVisibilityEl(event.currentTarget)}
          />
        </Tooltip>
        {!!data?.count && (
          <PaginationComponent count={data?.count} isLoading={isLoading} />
        )}
      </Box>

      <Grid container spacing={2} sx={{position: 'relative'}}>
        <Grid item md={filtersContent ? 9 : 12} xs={12}>
          <UniversalTable
            items={items}
            columns={columns}
            setOrdering={setOrdering}
            loading={isLoading}
            anchorColumnVisibilityEl={anchorColumnVisibilityEl}
            setAnchorColumnVisibilityEl={setAnchorColumnVisibilityEl}
            name={name}
            {...props}
          />
        </Grid>
        {!!filtersContent && (
          <Grid
            item
            md={3}
            xs={12}
            sx={{position: 'sticky', top: '64px', alignSelf: 'flex-start'}}
          >
            <Card>
              <CardHeader title="Filtres" avatar={<Settings />} />
              <Divider />
              <CardContent>{filtersContent}</CardContent>
            </Card>
          </Grid>
        )}

        {isFetching ? <span> Chargement...</span> : null}
        {!!data?.count && (
          <PaginationComponent count={data?.count} isLoading={isLoading} />
        )}
      </Grid>
    </>
  )
}

export default function UniversalTable({
  items,
  columns,
  setOrdering,
  name,
  anchorColumnVisibilityEl,
  setAnchorColumnVisibilityEl,
  getRowCanExpand,
  renderSubComponent,
}) {
  const [sorting, setSorting] = useState([])
  const {data: savedSettings, isLoading: savedSettingsIsLoading} =
    useOneMeSettings(`table-${name}`)
  const {mutate} = useSaveOrCreateMeSettings(`table-${name}`)

  const [savableTableData, setSavableTableData] = useReducer(
    (o, n) => ({...o, ...n}),
    {},
    () => ({
      columnOrder: columns.map(column => column.id),
      columnVisibility: {},
    }),
  )

  const {columnOrder, columnVisibility} = savableTableData
  const setColumnOrder = columnOrder => setSavableTableData({columnOrder})

  const setColumnVisibility = columnVisibility => {
    if (typeof columnVisibility === 'function') {
      setSavableTableData({
        columnVisibility: {
          ...savableTableData?.columnVisibility,
          ...columnVisibility(),
        },
      })
    } else {
      setSavableTableData({columnVisibility})
    }
  }

  useEffect(() => {
    if (savedSettings?.json) {
      setSavableTableData(savedSettings.json)
    }
  }, [savedSettingsIsLoading, savedSettings])

  useEffect(() => {
    if (!!name && !savedSettingsIsLoading) {
      const json = savableTableData
      if (json !== savedSettings) {
        mutate({
          json,
        })
      }
    }
  }, [savableTableData])

  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnOrder,
      columnVisibility,
    },
    onSortingChange: setSorting,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    manualSorting: true,
    // expand
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
  })

  useEffect(() => {
    if (sorting?.length > 0) {
      const column = sorting[0]
      const value = (column.desc ? '-' : '') + column.id
      setOrdering?.(value)
    }
  }, [sorting])

  const settingsOpened = Boolean(anchorColumnVisibilityEl)

  return (
    <DndProvider backend={HTML5Backend}>
      <TableContainer component={Paper}>
        <Popover
          id={'settings-popover'}
          open={settingsOpened}
          anchorEl={anchorColumnVisibilityEl}
          onClose={() => setAnchorColumnVisibilityEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FormGroup sx={{p: 2}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={table.getIsAllColumnsVisible()}
                  onChange={table.getToggleAllColumnsVisibilityHandler()}
                />
              }
              label="Tout afficher"
            />

            {table
              .getAllLeafColumns()
              .filter(column => column.getCanHide())
              .map((column, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={column.getIsVisible()}
                      onChange={({nativeEvent}) => {
                        return column.getToggleVisibilityHandler()(nativeEvent)
                      }}
                    />
                  }
                  label={column.columnDef.header}
                  key={column.columnDef.id}
                />
              ))}
          </FormGroup>
        </Popover>
        {!savedSettingsIsLoading && (
          <Table
            stickyheader
            aria-label="sticky table"
            size={'small'}
            style={{
              tableLayout: 'auto',
            }}
            width={table.getCenterTotalSize()}
          >
            <TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <DraggableColumnHeader
                      key={header.id}
                      header={header}
                      table={table}
                      last={index === headerGroup.headers.length - 1}
                      index={index}
                    />
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map(row => (
                <Fragment key={row.id}>
                  <TableRow hover>
                    {row.getVisibleCells().map(cell => (
                      <TableCell
                        key={cell.id}
                        width={cell.column.getSize()}
                        style={{width: cell.column.getSize()}}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() && (
                    <TableRow>
                      <TableCell
                        colSpan={row.getVisibleCells().length}
                        style={{padding: 0}}
                      >
                        {renderSubComponent({row})}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </DndProvider>
  )
}

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0],
  )
  return [...columnOrder]
}
export const DraggableColumnHeader = ({header, table, last = false}) => {
  const theme = useTheme()
  const {getState, setColumnOrder} = table
  const {columnOrder} = getState()
  const {column} = header

  const ref = useRef(null)

  const [{canDrop, isOver}, dropRef] = useDrop({
    accept: 'column',
    drop: draggedColumn => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder,
      )
      setColumnOrder(newColumnOrder)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const [{isDragging, dragIndex}, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  })

  previewRef(dragRef(dropRef(ref)))

  return (
    <TableCell
      ref={ref}
      key={header.id}
      colSpan={header.colSpan}
      width={header.getSize()}
      style={{
        backgroundColor:
          isOver && canDrop ? theme.palette.primary.dark : 'inherit',
        color: isOver && canDrop ? 'white' : 'inherit',
      }}
    >
      <TableSortLabel
        active={header.column.getIsSorted() ?? false}
        disabled={!header.column.getCanSort()}
        direction={header.column.getIsSorted() || undefined}
        onClick={header.column.getToggleSortingHandler()}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </TableSortLabel>
    </TableCell>
  )
}
