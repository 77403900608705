import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Alert, Box, Button} from '@mui/material'
// import useAuth from '../../../hooks/useAuth'
// import {errorMessage} from 'src/services/crm-api'
import Form, {TextField} from 'src/components/Form'
import {useState} from 'react'

const schema = yup.object().shape({
  email: yup.string().email().required().max(35).label('Adresse email'),
  password: yup
    .string()
    .required()
    .max(35)
    .min(3, 'Mot de passe doit faire au moins 3 caractères')
    .label('Mot de passe'),
})

const defaultValues = {
  email: '',
  password: '',
}

const LoginForm = ({onSubmit, loading = false, errors = {}, ...props}) => {
  // const {login} = useAuth()
  const [values, setValues] = useState(defaultValues)
  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      resolver={yupResolver(schema)}
      errors={errors}
      values={values}
    >
      <TextField
        label="Adresse email"
        name="email"
        type="email"
        disabled={loading}
      />
      <TextField
        label="Mot de passe"
        name="password"
        type="password"
        disabled={loading}
        sx={{mt: 2}}
      />
      {errors.submit && (
        <Box sx={{mt: 3}}>
          <Alert severity="error">
            <div>{errors.submit}</div>
          </Alert>
        </Box>
      )}
      {Boolean(errors?.message) && (
        <Alert severity="error" data-testid="error-alert" sx={{mt: 2}}>
          <div>{errors.message}</div>
        </Alert>
      )}
      <Box sx={{mt: 2}}>
        <Button
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={loading}
        >
          Se connecter
        </Button>
      </Box>
    </Form>
  )
}

export default LoginForm
