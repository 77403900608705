import PageTemplate from 'src/components/templates/PageTemplate'
import ContratForm from 'src/components/forms/ContratForm'
import {errorResponseToJson} from 'src/services/crm-api'
import {useNavigate, useParams} from 'react-router'
import {useCreateContrat} from '../../hooks/api-hooks'

const ContratCreate = ({devis = false}) => {
  const {id: clientId} = useParams()
  const {mutate: createContrat, isLoading, error} = useCreateContrat()
  const navigate = useNavigate()
  const errors = errorResponseToJson(error)

  const handleSubmit = values => {
    const body = {
      ...values,
      etat: devis ? 'Devis' : values.etat,
    }
    createContrat(body, {
      onSuccess: () => navigate(`/clients/${clientId}?currentTab=contrats`),
    })
  }

  return (
    <PageTemplate
      title={`Ajouter un ${devis ? 'devis' : 'contrat'}`}
      sections={[
        {
          title: 'Fiche client',
          path: `/clients/${clientId}?currentTab=contrats`,
        },
        {title: 'Ajouter un contrat', current: true},
      ]}
      data-testid="page-contrats-create"
    >
      <ContratForm
        submitLabel="Créer"
        errors={errors}
        onSubmit={handleSubmit}
        loading={isLoading}
        clientId={clientId}
        devis={devis}
      />
    </PageTemplate>
  )
}

export default ContratCreate
