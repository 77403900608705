import {useRef, useState} from 'react'
import {Link as RouterLink, useNavigate} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import useAuth from 'src/hooks/useAuth'

const AccountPopover = () => {
  const anchorRef = useRef(null)
  const {user, logout} = useAuth()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
        data-testid="profile-account-button"
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {maxWidth: 500},
        }}
      >
        <Box sx={{p: 2}}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.firstname} {user.lastname}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{mt: 2}}>
          <MenuItem component={RouterLink} to="/change-password">
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Changer de mot de passe
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{p: 2}}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Se déconnecter
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default AccountPopover
