import {Controller} from 'react-hook-form'
import {useBanquesRemise} from 'src/hooks/api-hooks'
import BanquesRemiseInput from '../inputs/BanquesRemiseInput'

export default function BanquesRemiseInputControlled({
  control,
  name = 'banque_remise',
  defaultValue = null,
  defaultFromNetwork,
  error,
  helperText,
  ...props
}) {
  const {isLoading, data, isError} = useBanquesRemise()
  if (isLoading) return 'Chargement ...'
  if (isError) return 'Une erreur est survenue'
  const defaultFetched = data.find(x => x.default === true)

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultFromNetwork ? defaultFetched : defaultValue}
      render={({field: {value, onChange}}) => (
        <BanquesRemiseInput
          onChange={(event, item) => {
            onChange(item)
          }}
          value={value}
          margin="dense"
          size="small"
          error={error}
          helperText={helperText}
          {...props}
        />
      )}
    />
  )
}
