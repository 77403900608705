import {Autocomplete, CircularProgress, TextField} from '@mui/material'
import {useComptesComptableSimple} from 'src/hooks/api-hooks'

const ComptesComptableInput = ({
  label = 'Compte comptable',
  error,
  helperText,
  margin = 'dense',
  size = 'small',
  ...props
}) => {
  const {
    isLoading: loading,
    data: options,
    isError: loadingError,
    error: errorException,
  } = useComptesComptableSimple()

  return (
    <Autocomplete
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.id} - ${option.libelle}` ?? ''}
      options={options ?? []}
      loading={loading}
      {...props}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={loadingError ? errorException.message : helperText}
          error={error || loadingError}
          margin={margin}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default ComptesComptableInput
