import {frFR} from '@mui/material/locale'
import merge from 'lodash/merge'
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import {lightShadows} from './shadows'
import {grey} from '@mui/material/colors'

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          '&.form-grid-dense' : {
            paddingBottom: '0px',
            paddingTop: '0px'
          }
        }        
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
}

const themeOptions = {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: '#6b778c',
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff',
    },
    error: {
      contrastText: '#ffffff',
      main: '#f44336',
    },
    mode: 'light',
    // primary: {
    //   contrastText: '#ffffff',
    //   main: '#22a4dd',
    // },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    secondary: {
      main: grey[500],
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800',
    },
  },
  shadows: lightShadows,
}

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
    frFR,
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
