import AutocompleteInput from './AutocompleteInput'

const etats = ['En cours', 'Suspendu', 'Résilié', 'Échu']

const ContratEtatsInput = ({label = 'État', error, helperText, ...props}) => (
  <AutocompleteInput
    options={etats}
    label={label}
    error={error}
    helperText={helperText}
    {...props}
  />
)

export default ContratEtatsInput
