import {useState} from 'react'
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  Divider,
  CardActions,
  Button,
} from '@mui/material'
import {NavLink as RouterLink} from 'react-router-dom'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Person, Edit, Close, ArrowRightAlt} from '@mui/icons-material'

import Form, {AlertError} from 'src/components/Form'
import LabelRow from 'src/components/LabelRow'
import useSimplePartialUpdateForm from 'src/hooks/useSimplePartialUpdateForm'
import {errorResponseToJson, partialUpdateContrat} from 'src/services/crm-api'

const resolver = yupResolver(yup.object().shape({}))

const DisplayBody = ({contrat, setEditing, editing, isLoading}) => {
  return (
    <>
      <Table>
        <TableBody>
          <LabelRow label="Agence">{contrat?.agence?.name}</LabelRow>
          <LabelRow label="Référent">
            {contrat?.referent ? (
              <Button
                component={RouterLink}
                to={`/users/${contrat?.referent?.id}`}
              >
                <ArrowRightAlt size="small" />
                {`${contrat?.referent?.lastname} ${contrat?.referent?.firstname}`}
              </Button>
            ) : (
              ''
            )}
          </LabelRow>
          <LabelRow label="Début SP">{contrat?.date_debut_sp}</LabelRow>
          <LabelRow label="Fin SP">{contrat?.date_fin_sp}</LabelRow>
          <LabelRow label="Décision SP année n">
            {contrat?.decision_sp_n}
          </LabelRow>
          <LabelRow label="Décision SP année n-1">
            {contrat?.decision_sp_n_m_1}
          </LabelRow>
        </TableBody>
      </Table>
      {/* <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={!Boolean(contrat)}
        >
          Modifier
        </Button>
      </CardActions> */}
    </>
  )
}

const EditBody = ({contrat, onSubmit, setEditing, editing, setContrat}) => {
  const {isLoading, error, save} = useSimplePartialUpdateForm({
    promise: partialUpdateContrat,
    id: contrat.id,
    onSuccess: data => {
      setContrat(data)
      setEditing(false)
    },
  })

  const errors = errorResponseToJson(error)
  const defaultValue = {}
  return (
    <Form
      onSubmit={save}
      errors={errors}
      loading={isLoading}
      resolver={resolver}
      defaultValues={defaultValue}
    >
      <AlertError />
      <Table>
        <TableBody>
          <LabelRow label="ID">#{contrat?.id}</LabelRow>
        </TableBody>
      </Table>
      <CardActions sx={{justifyContent: 'space-evenly'}}>
        <Button
          startIcon={<Close fontSize="small" />}
          color="primary"
          variant="text"
          onClick={() => {
            setEditing(!editing)
          }}
          disabled={isLoading}
        >
          Annuler
        </Button>
        <Button
          startIcon={<Edit fontSize="small" />}
          color="primary"
          variant="text"
          type="submit"
          disabled={isLoading}
        >
          Sauvegarder
        </Button>
      </CardActions>
    </Form>
  )
}

const ContratGestionCard = ({contrat, setContrat, ...props}) => {
  const [editing, setEditing] = useState(false)
  return (
    <Card {...props}>
      <CardHeader title="Gestion" avatar={<Person />} />
      <Divider />
      {editing ? (
        <EditBody
          contrat={contrat}
          setContrat={setContrat}
          setEditing={setEditing}
          editing={editing}
        />
      ) : (
        <DisplayBody
          contrat={contrat}
          setEditing={setEditing}
          editing={editing}
        />
      )}
    </Card>
  )
}

export default ContratGestionCard
