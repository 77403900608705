import PageTemplate from 'src/components/templates/PageTemplate'
import ClientForm from 'src/components/forms/ClientForm'
import {errorResponseToJson} from 'src/services/crm-api'
import {useNavigate, useParams} from 'react-router'
import {useClient, useEditClient} from 'src/hooks/api-hooks'
import LoadingCard from 'src/components/LoadingCard'

const ClientEdit = () => {
  const {id} = useParams()
  const {isLoading, isIdle, data: client, error} = useClient(id)
  const {
    isLoading: updateLoading,
    isIdle: updateIdle,
    error: updateError,
    mutate,
  } = useEditClient(id)

  const navigate = useNavigate()
  const errors = errorResponseToJson(updateError)

  const handleSubmit = values => {
    mutate(values, {
      onSuccess: () => navigate(`/clients/${id}`),
    })
  }

  const sections = client
    ? [
        {title: 'Clients', path: '/clients'},
        {title: client.description, path: `/clients/${client.id}`},
        {title: 'Modifier', current: true},
      ]
    : [
        {title: 'Clients', path: '/clients'},
        {title: 'Modifier un client', current: true},
      ]

  return (
    <PageTemplate
      title={
        client ? `Modification de ${client.description}` : 'Modifier un client'
      }
      sections={sections}
      data-testid="page-clients-edit"
    >
      {!!error ? `Une erreur est survenue ${error}` : ''}
      {isLoading ? (
        <LoadingCard />
      ) : (
        <ClientForm
          submitLabel="Modifier"
          errors={errors}
          onSubmit={handleSubmit}
          isLoading={isLoading || isIdle || updateLoading || updateIdle}
          client={client}
        />
      )}
    </PageTemplate>
  )
}

export default ClientEdit
