import * as yup from 'yup'
import {Controller, useForm} from 'react-hook-form'
import moment from 'moment'
import {Grid, TextField} from '@mui/material'
import {DesktopDatePicker} from '@mui/lab'
import DeviseInput from '../inputs/DeviseInput'
import {yupResolver} from '@hookform/resolvers/yup'
import ClientAutocompleteInputControlled from '../controlled/ClientAutocompleteInputControlled'
import {useMemo} from 'react'

const today = new Date()
today.setHours(23, 59, 59, 59)

const schema = yup.object().shape({
  montant: yup
    .number()
    .label('Montant')
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  date: yup
    .date()
    .transform(value => (isNaN(value) ? undefined : value))
    .label('Date')
    .max(today, "Date doit être antérieur ou aujourd'hui")
    .required(),
  autre_client_id: yup
    .mixed()
    .label('Destinataire')
    .transform(value => (value === '' ? undefined : value))
    .required(),
})

export default function VirementClientForm({
  virementClient,
  client,
  onSubmit,
  ...props
}) {
  const liaisons = useMemo(() => {
    const results = []
    if (!client) {
      return []
    }
    client.liaisons_clients.map(x => {
      if (x.client_1_id === client.id) {
        results.push({
          ...x.client_2,
          description: x.client_2.description + ` (${x.lien.lien})`,
        })
      } else {
        results.push({
          ...x.client_1,
          description: x.client_1.description + ` (${x.lien.lien_reverse})`,
        })
      }
    })
    return results
  }, [client])

  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...virementClient,
      montant: Boolean(virementClient?.montant)
        ? -parseFloat(virementClient.montant)
        : undefined,
      autre_client_id: Boolean(virementClient?.autre_client_id)
        ? virementClient.autre_client_id
        : undefined,
      date: Boolean(virementClient?.date)
        ? moment(virementClient.date, 'DD/MM/YYYY')
        : undefined,
    },
  })

  return (
    <form
      onSubmit={handleSubmit(values => {
        const body = {
          ...values,
        }
        body.montant = -body.montant
        body.autre_client_id = body.autre_client_id?.id
        body.type = 'Virement interne envoyé'
        body.client_id = client.id
        body.date = moment(values.date).format('DD/MM/YYYY')
        delete body.sens
        onSubmit?.(body)
      })}
      {...props}
    >
      <input type="hidden" {...register('id')} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            name="date"
            control={control}
            defaultValue={moment(today).format('MM/DD/YYYY')}
            render={({field}) => (
              <DesktopDatePicker
                {...field}
                label="Date"
                inputFormat="dd/MM/yyyy"
                renderInput={params => (
                  <TextField
                    {...params}
                    margin="dense"
                    size="small"
                    fullWidth
                    error={!!errors?.date?.message}
                    helperText={errors?.date?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Controller
            control={control}
            name="montant"
            render={({field}) => (
              <DeviseInput
                {...field}
                margin="dense"
                size="small"
                fullWidth
                error={!!errors?.montant?.message}
                helperText={errors?.montant?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClientAutocompleteInputControlled
            control={control}
            name="autre_client_id"
            label="Destinataire"
            margin="dense"
            size="small"
            localOptions={liaisons}
            error={!!errors?.autre_client_id?.message}
            helperText={errors?.autre_client_id?.message}
          />
        </Grid>
      </Grid>
    </form>
  )
}
