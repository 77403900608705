import useOperationDialog from './useOperationDialog'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material'
import AddButton from '../buttons/AddButton'
import VirementClientForm from '../forms/VirementClientForm'
import DeleteOperationButton from './DeleteOperationButton'

export default function VirementClientFormDialog({
  open = true,
  virementClient,
  handleClose = () => {},
  onSubmit,
  client,
  ...props
}) {
  const {isEditing, operation, create, update, isMutateLoading, isLoading} =
    useOperationDialog(virementClient?.id)

  const handleSubmit = values => {
    if (isEditing) {
      update(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    } else {
      create(values, {
        onSuccess: results => {
          onSubmit?.(results)
        },
      })
    }
  }
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose(event, reason)
        }
      }}
      aria-labelledby="virement-client-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="virement-client-dialog">
        {isEditing ? 'Supprimer le virement' : 'Faire un virement'}
      </DialogTitle>
      <Divider />

      <DialogContent>
        {!isEditing ? (
          <VirementClientForm
            client={client}
            virementClient={operation}
            onSubmit={handleSubmit}
            id="virement-client-dialog-form"
          />
        ) : (
          <DialogContentText>
            <>Voulez vous supprimer</>
            <b>{operation.libelle}</b>
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          disabled={isMutateLoading}
        >
          Annuler
        </Button>
        {isEditing ? (
          <DeleteOperationButton
            operationId={virementClient?.id}
            onDelete={handleClose}
          />
        ) : (
          <AddButton
            form="virement-client-dialog-form"
            loading={isMutateLoading}
            loadingPosition="start"
            type="submit"
          >
            Ajouter
          </AddButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
