import {useState} from 'react'
import {TablePagination} from '@mui/material'

export default function useLocalPagination({
  defaultRowPerPage = 50,
  rowsPerPageOptions = [50, 100, 200],
}) {
  const [perPage, setPerPage] = useState(defaultRowPerPage)
  const [page, setPage] = useState(0)
  const currentPage = Math.min(page, perPage * page)
  const offset = currentPage * perPage
  const limit = perPage

  const PaginationComponent = ({isLoading = false, count = 0}) => (
    <TablePagination
      component="div"
      count={count}
      page={currentPage}
      onPageChange={(_, newPage) => setPage(newPage)}
      onRowsPerPageChange={event => setPerPage(parseInt(event.target.value))}
      rowsPerPage={perPage}
      rowsPerPageOptions={rowsPerPageOptions}
      disabled={isLoading}
    />
  )

  return {
    perPage,
    setPerPage,
    page,
    setPage,
    currentPage,
    offset,
    limit,
    PaginationComponent,
  }
}
