import {InputAdornment, TextField} from '@mui/material'
import React from 'react'
import NumberFormat from 'react-number-format'

const DeviseInput = ({
  onChange,
  readOnly,
  label = 'Montant',
  inputRef,
  ...props
}) => {
  return (
    <NumberFormat
      onValueChange={({value, floatValue, ...other}) => {
        onChange?.(floatValue)
      }}
      fixedDecimalScale={true}
      label={label}
      decimalScale={2}
      InputProps={{
        startAdornment: <InputAdornment position="start">€</InputAdornment>,
        readOnly: readOnly,
      }}
      thousandsGroupStyle="thousand"
      decimalSeparator=","
      thousandSeparator=" "
      customInput={TextField}
      getInputRef={() => inputRef}
      size={'small'}
      fullWidth
      {...props}
    />
  )
}
export default DeviseInput
