import {Autocomplete as MuiAutocomplete, Popper, TextField} from '@mui/material'
import {Controller, useForm} from './Form'

const styles = theme => ({
  popper: {
    width: 'fit-content',
  },
})

const PopperFitWidth = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />
}

const Autocomplete = ({
  items = [],
  label,
  name,
  onChange,
  loading,
  freeSolo = false,
  onInputChange,
  onOpen,
  filterOptions,
  onClose,
  size,
  margin,
  InputProps,
  isError,
  errorMessage,
  autoFocus,
  defaultValue,
  autoFit = false,
  ...props
}) => {
  const {control, isLoading, errors} = useForm()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...props}
      render={({field}) => {
        return (
          <MuiAutocomplete
            {...field}
            PopperComponent={autoFit ? PopperFitWidth : undefined}
            size={size}
            onInputChange={onInputChange}
            onOpen={onOpen}
            onClose={onClose}
            filterOptions={filterOptions}
            loading={loading}
            options={[...items]}
            autoSelect
            disabled={isLoading}
            freeSolo={freeSolo}
            ref={props.ref}
            id={`${name}-field`}
            getOptionLabel={item => {
              if (item) {
                return (
                  item.text ?? items.find(x => x.value === item)?.text ?? item
                )
              }
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
            value={field.value === '' ? null : field.value}
            renderInput={({inputProps, ...params}) => {
              return (
                <TextField
                  {...params}
                  autoFocus={autoFocus}
                  label={label}
                  margin={margin}
                  error={Boolean(errors[name]) || isError}
                  helperText={errors[name]?.message || errorMessage}
                  variant="outlined"
                  inputProps={{...inputProps, 'data-testid': `field-${name}`}}
                  InputProps={{...params.InputProps, ...InputProps}}
                />
              )
            }}
            onChange={(_, data) => {
              const value = data?.value ?? ''
              onChange?.(value)
              return field.onChange(value) // ?? data)
            }}
          />
        )
      }}
    />
  )
}

export default Autocomplete
