import {Suspense} from 'react'
import LoadingScreen from './components/LoadingScreen'
import AuthGuard from './components/AuthGuard'
import DashboardLayout from 'src/components/layout/DashboardLayout'
import Login from './pages/authentication/Login'

// Users
import UserCreate from './pages/users/UserCreate'
import UserList from './pages/users/UserList'
import UserEdit from './pages/users/UserEdit'
import UserDetails from './pages/users/UserDetails'

// clients
import ClientCreate from './pages/clients/ClientCreate'
import ClientList from './pages/clients/ClientList'
import ClientDetails from './pages/clients/ClientDetails'
import ClientEdit from './pages/clients/ClientEdit'

// Contrats
import ContratCreate from './pages/contrats/ContratCreate'
import ContratList from './pages/contrats/ContratList'
import ContratEdit from './pages/contrats/ContratEdit'
import ContratDetails from './pages/contrats/ContratDetails'

// Gestionnaire fichiers
import GestionnaireFichiers from './pages/gestionnaire-fichiers/GestionnaireFichiers'
import GestionnaireFichiersSearch from './pages/gestionnaire-fichiers/GestionnaireFichiersSearch'
import GestionnaireDeFichiersTrash from './pages/gestionnaire-fichiers/GestionnaireDeFichiersTrash'

// Compta
import RemiseEnBanqueCreate from './pages/remises-en-banque/RemiseEnBanqueCreate'
import RemiseEnBanqueList from './pages/remises-en-banque/RemiseEnBanqueList'
import RemiseEnBanqueDetails from './pages/remises-en-banque/RemiseEnBanqueDetails'
import CahierTransmissionList from './pages/cahiers-transmission/CahierTransmissionList'
import EnvoiDeFondList from './pages/envois-de-fonds/EnvoiDeFondList'

// caisses
import CaisseCreate from './pages/caisses/CaisseCreate'
import CaisseList from './pages/caisses/CaisseList'
import CaisseDetails from './pages/caisses/CaisseDetails'

// compte comptable
import CompteComptableList from './pages/comptes-comptable/CompteComptableList'
import CompteComptableDetails from './pages/comptes-comptable/CompteComptableDetails'

// ecriture
import EcritureList from './pages/comptes-comptable/EcritureList'

// export comptable
import ExportComptaList from './pages/exports-compta/ExportComptaList'

// groupes
import GroupeClientList from './pages/groupe-client/GroupeClientList'
import ChangePassword from './pages/settings/ChangePassword'

import {useOneDevis} from 'src/hooks/api-hooks'
import PermissionHidder from './components/PermissionHidder'

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },

  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (<PermissionHidder mainRole={"is_staff"}><ClientList /></PermissionHidder>),
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (<PermissionHidder mainRole={"is_superuser"}><UserList /></PermissionHidder>),
          },
          {
            path: 'create',
            element: (<PermissionHidder mainRole={"is_superuser"}><UserCreate /></PermissionHidder>),
          },
          {
            path: ':id/edit',
            element: (<PermissionHidder mainRole={"is_superuser"}><UserEdit /></PermissionHidder>),
          },
          {
            path: ':id',
            element: <UserDetails />,
          },
        ],
      },
      {
        path: 'remises-en-banque',
        children: [
          {
            path: '',
          },
          {
            path: 'cheque',
            element: <RemiseEnBanqueList typeRemise={'Chèque'} />,
          },
          {
            path: 'especes',
            element: <RemiseEnBanqueList typeRemise={'Espèces'} />,
          },
          {
            path: 'create',
            element: <RemiseEnBanqueCreate />,
          },
          {
            path: ':id',
            element: <RemiseEnBanqueDetails />,
          },
        ],
      },
      {
        path: 'cahiers-de-transmission',
        children: [
          {
            path: '',
            element: <CahierTransmissionList />,
          },
        ],
      },
      {
        path: 'envois-de-fonds',
        children: [
          {
            path: '',
            element: <EnvoiDeFondList />,
          },
        ],
      },
      {
        path: 'caisses',
        children: [
          {
            path: '',
            element: <CaisseList />,
          },
          {
            path: 'create',
            element: <CaisseCreate />,
          },
          {
            path: ':id',
            element: <CaisseDetails />,
          },
        ],
      },
      {
        path: 'comptes-comptable',
        children: [
          {
            path: '',
            element: (<PermissionHidder mainRole={"is_superuser"}><CompteComptableList /></PermissionHidder>),
          },
          {
            path: ':id',
            element: (<PermissionHidder mainRole={"is_superuser"}><CompteComptableDetails /></PermissionHidder>),
          },
          {
            path: 'ecritures',
            element: (<PermissionHidder mainRole={"is_superuser"}><EcritureList /></PermissionHidder>),
          },
        ],
      },
      {
        path: 'exports-compta',
        children: [
          {
            path: '',
            element:(<PermissionHidder mainRole={"is_superuser"}><ExportComptaList /></PermissionHidder>),
          },
          // {
          //   path: 'create',
          //   element: <ExportComptaCreate />,
          // },
        ],
      },
      {
        path: 'clients',
        children: [
          {
            path: '',
            element: <ClientList />,
          },
          {
            path: 'create',
            element: <ClientCreate />,
          },
          {
            path: ':id/edit',
            element: <ClientEdit />,
          },
          {
            path: ':id/create-contrat',
            element: <ContratCreate />,
          },
          {
            path: ':id/create-devis',
            element: <ContratCreate devis={true} />,
          },
          {
            path: ':id',
            element: <ClientDetails />,
          },
        ],
      },
      {
        path: 'groupes',
        children: [
          {
            path: '',
            element: <GroupeClientList />,
          },
        ],
      },
      {
        path: 'devis',
        children: [
          {
            path: '',
            element: <ContratList devis={true} />,
          },
          {
            path: ':id/edit',
            element: <ContratEdit devis={true} hook={useOneDevis} />,
          },
          {
            path: ':id',
            element: <ContratDetails devis={true} />,
          },
        ],
      },
      {
        path: 'contrats',
        children: [
          {
            path: '',
            element: <ContratList />,
          },
          {
            path: ':id/edit',
            element: <ContratEdit />,
          },
          {
            path: ':id',
            element: <ContratDetails />,
          },
        ],
      },
      {
        path: 'gestionnaire-fichiers',
        children: [
          {
            path: '',
            element: <GestionnaireFichiers />,
          },
          {
            path: 'rechercher',
            element: <GestionnaireFichiersSearch />,
          },
          {
            path: 'corbeille',
            element: <GestionnaireDeFichiersTrash />,
          },
        ],
      },
      {
        path: 'change-password',
        element: <ChangePassword />,
      },
    ],
  },
]

export default routes
