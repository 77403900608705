import {useEffect} from 'react'
import {Grid} from '@mui/material'
import {useParams} from 'react-router-dom'

import useAsync from 'src/hooks/useAsync'
import {fetchUser} from 'src/services/crm-api'
import UserCard from './UserCard'
import EditButton from 'src/components/buttons/EditButton'

import PageTemplate from 'src/components/templates/PageTemplate'
import ErrorCard from 'src/components/ErrorCard'
import LoadingCard from 'src/components/LoadingCard'

const UserDetails = () => {
  const {id} = useParams()

  const {
    isLoading,
    isIdle,
    isError,
    error,
    run,
    data: user,
    isSuccess,
  } = useAsync()

  useEffect(() => {
    run(fetchUser(id)).catch(error => {})
  }, [id, run])

  const rightActions = user && (
    <EditButton to={`/users/${user.id}/edit`} variant="contained">
      Modifier
    </EditButton>
  )

  return (
    <PageTemplate
      data-testid="page-users-details"
      title="Info utilisateur"
      sections={[
        {title: 'Utilisateurs', path: '/users'},
        {title: 'Info utilisateur', current: true},
      ]}
      rightActions={rightActions}
    >
      {(isLoading || isIdle) && <LoadingCard />}
      {isError && <ErrorCard error={error} />}
      {isSuccess && (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <UserCard user={user} />
          </Grid>
        </Grid>
      )}
    </PageTemplate>
  )
}

export default UserDetails
