import AutocompleteInput from './AutocompleteInput'

import countries from 'src/services/countries'
const countriesParsed = countries.map(x => x.text)
const CountryInput = ({label = 'Pays', error, helperText, ...props}) => (
  <AutocompleteInput
    options={countriesParsed}
    label={label}
    error={error}
    helperText={helperText}
    freeSolo
    {...props}
  />
)

export default CountryInput
