import {Autocomplete, TextField} from '@mui/material'

const types = ['Personne physique', 'Personne morale']

const TypeClientInput = ({
  label = 'Type',
  error,
  helperText,
  margin,
  size,
  ...props
}) => (
  <Autocomplete
    options={types}
    {...props}
    renderInput={params => (
      <TextField
        {...params}
        label={label}
        helperText={helperText}
        error={error}
        margin={margin}
        size={size}
      />
    )}
  />
)

export default TypeClientInput
