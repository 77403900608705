import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {useParams} from 'react-router'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import DeviseLabel from 'src/components/DeviseLabel'
import ErrorCard from 'src/components/ErrorCard'
import LoadingCard from 'src/components/LoadingCard'
import PageTemplate from 'src/components/templates/PageTemplate'
import {useCaisse} from 'src/hooks/api-hooks'
import {Box} from '@mui/system'
import {red} from '@mui/material/colors'

export default function CaisseDetails() {
  const {id} = useParams()
  const {isLoading, data: caisse, isError, error} = useCaisse(parseInt(id))

  return (
    <PageTemplate
      title={
        caisse
          ? `Fermeture de caisse n° ${caisse.id}`
          : 'Fermeture de caisse chargement ...'
      }
      sections={[
        {title: 'Fermeture de caisse', path: '/caisses'},
        {title: 'Consultation', current: true},
      ]}
    >
      {isError && <ErrorCard error={error} />}
      {isLoading && <LoadingCard />}

      {caisse && (
        <Card>
          <CardHeader title={`Fermeture de caisse n° ${caisse.id}`} />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid
                display="flex"
                flexDirection="column"
                item
                xs={12}
                md={4}
                justifyContent="center"
                p={2}
              >
                <Box>
                  Agence de <b>{caisse.agence.name}</b>
                  <br />
                  Arrétée le <b>{caisse.date}</b>
                  <br />
                  Par{' '}
                  <b>
                    {caisse.arretee_par?.firstname}{' '}
                    {caisse.arretee_par?.lastname}
                  </b>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={2}
                  mb={2}
                  maxWidth="400px"
                >
                  <Typography variant="h6">
                    <DeviseLabel>{caisse.initial}</DeviseLabel>
                  </Typography>
                  <DoubleArrowIcon />
                  <Typography variant="h6">
                    <DeviseLabel>{caisse.total}</DeviseLabel>
                  </Typography>
                </Box>
                <Box mt={2} mb={2}>
                  <Typography
                    variant="h6"
                    color={
                      parseFloat(caisse.equilibre) === 0 ? undefined : red[500]
                    }
                  >
                    {parseFloat(caisse.equilibre) > 0
                      ? "Trop d'argent dans la caisse"
                      : parseFloat(caisse.equilibre) < 0
                      ? "Manque d'argent dans la caisse"
                      : 'La caisse est équilibrée'}
                  </Typography>
                  <Typography
                    color={
                      parseFloat(caisse.equilibre) === 0 ? undefined : red[500]
                    }
                  >
                    <DeviseLabel>
                      {Math.abs(parseFloat(caisse.equilibre))}
                    </DeviseLabel>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Billets 500 €</TableCell>
                      <TableCell>x {caisse.billets_500}</TableCell>
                      <TableCell variant="head">Pièce de 2 €</TableCell>
                      <TableCell>x {caisse.pieces_200}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 200 €</TableCell>
                      <TableCell>x {caisse.billets_200}</TableCell>
                      <TableCell variant="head">Pièce de 1 €</TableCell>
                      <TableCell>x {caisse.pieces_100}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 100 €</TableCell>
                      <TableCell>x {caisse.billets_100}</TableCell>
                      <TableCell variant="head">Pièce de 0,50 €</TableCell>
                      <TableCell>x {caisse.pieces_050}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 50 €</TableCell>
                      <TableCell>x {caisse.billets_050}</TableCell>
                      <TableCell variant="head">Pièce de 0,20 €</TableCell>
                      <TableCell>x {caisse.pieces_020}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 20 €</TableCell>
                      <TableCell>x {caisse.billets_020}</TableCell>
                      <TableCell variant="head">Pièce de 0,10 €</TableCell>
                      <TableCell>x {caisse.pieces_010}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 10 €</TableCell>
                      <TableCell>x {caisse.billets_010}</TableCell>
                      <TableCell variant="head">Pièce de 0,05 €</TableCell>
                      <TableCell>x {caisse.pieces_005}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Billets 5 €</TableCell>
                      <TableCell>x {caisse.billets_005}</TableCell>
                      <TableCell variant="head">Pièce de 0,02 €</TableCell>
                      <TableCell>x {caisse.pieces_002}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head"></TableCell>
                      <TableCell></TableCell>
                      <TableCell variant="head">Pièce de 0,01 €</TableCell>
                      <TableCell>x {caisse.pieces_001}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </PageTemplate>
  )
}
